<template>
  <ts-alert />
  <div
    class="fixed top-0 left-0 h-full lg:w-[60%] mdlg:w-[50%] mdlg:flex hidden flex-row !bg-white"
  >
    <ts-image-loader
      :photoUrl="'/images/auth-image1.png'"
      :customClass="'w-[50%] h-full'"
    />
    <div class="h-full w-[50%] flex flex-col justify-between">
      <ts-image-loader
        :photoUrl="'/images/auth-image2.png'"
        :customClass="'h-[50%] w-full'"
      />
      <ts-image-loader
        :photoUrl="'/images/auth-image3.png'"
        :customClass="'h-[50%] w-full relative'"
      >
        <div
          class="absolute h-full w-full top-0 left-0 bg-gray-800 bg-opacity-30 flex flex-row justify-center items-center"
        >
          <ts-icon :name="'play'" :customClass="'h-[38px]'" />
        </div>
      </ts-image-loader>
    </div>
  </div>
  <div
    class="h-full lg:w-[40%] mdlg:w-[50%] !bg-white w-full text-center overflow-x-hidden relative px-0 mdlg:px-0 mdlg:ml-[50%] lg:ml-[60%] flex flex-row lg:text-sm mdlg:text-[12px] text-xs"
  >
    <div
      class="loader z-[45666664999998] !absolute top-0 left-0"
      v-if="loadingState"
    >
      <div class="bar"></div>
    </div>
    <div class="w-full pb-3 h-full flex flex-col items-center">
      <div class="w-full h-full flex flex-col relative">
        <div
          class="w-full h-full flex flex-col justify-start items-start mdlg:!space-y-3 mdlg:px-0 px-0"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { TsImageLoader, TsIcon, TsAlert } from "tutorstack-ui-components";
import { composables } from "@/composables";

export default defineComponent({
  components: {
    TsImageLoader,
    TsIcon,
    TsAlert,
  },
  name: "AuthLayout",
  setup() {
    const router = useRouter();
    const loadingState = ref(false);

    const goBack = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    const goToRoute = (route: string) => {
      router.push(route);
    };

    const FrontendLogic = composables.logic;

    onMounted(() => {
      FrontendLogic.common.watchProperty("loadingState", loadingState);
      const AuthUser = localStorage.getItem("AuthUser")
        ? JSON.parse(localStorage.getItem("AuthUser") || "{}")
        : undefined;

      if (AuthUser != undefined) {
        router.push("/");
      }
    });

    return {
      goBack,
      goToRoute,
      loadingState,
    };
  },
});
</script>
