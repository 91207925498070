import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col col-span-full space-y-2" }
const _hoisted_2 = { class: "flex w-full flex-row items-center justify-between cursor-pointer px-3 py-3 border-[1px] rounded" }
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($props.iconName)
          ? (_openBlock(), _createBlock(_component_ts_icon, {
              key: 0,
              name: $props.iconName,
              customClass: `${$props.iconSize}`
            }, null, 8, ["name", "customClass"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "name")
      ]),
      _renderSlot(_ctx.$slots, "extra")
    ])
  ]))
}