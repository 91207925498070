import { AxiosResponse } from "axios"
import { Logic } from "../../../logic/modules"
import { AuthResponse } from "../../../logic/types/domains/auth"
import { EmailVerifyForm, SignInForm, SignUpForm, VerifyWithTokenForm } from "../../../logic/types/forms/auth"
import { ModelApiService } from "../../common/ModelService"


export default class EmailApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("auth/emails");
	}

	public async SendVerifyEmail(
		data: EmailVerifyForm
	): Promise<AxiosResponse<void>> {
		try {
			const response: AxiosResponse<void> = await this.axiosInstance?.post(
				this.getUrl() + "/verify/mail",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async SignUp(
		data: SignUpForm
	): Promise<AxiosResponse<AuthResponse>> {
		try {
			const response: AxiosResponse<AuthResponse> = await this.axiosInstance?.post(
				this.getUrl() + "/signup",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async SignIn(
		data: SignInForm
	): Promise<AxiosResponse<AuthResponse>> {
		try {
			console.log(this.axiosInstance.defaults, this.getUrl() + "/signin")
			const response: AxiosResponse<AuthResponse> = await this.axiosInstance?.post(
				this.getUrl() + "/signin",
				data
			);

			return response;
		} catch (err: any) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async VerifyEmail(
		data: VerifyWithTokenForm
	): Promise<AxiosResponse<AuthResponse>> {
		try {

			// convert request data to formData
			const formData: FormData = Logic.common.convertToFormData(data)

			const response: AxiosResponse<AuthResponse> = await this.axiosInstance?.post(
				this.getUrl() + "/verify",
				formData
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}
}