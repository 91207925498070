import { FormRule } from "../../types/forms/common";

export default class Validations {
	constructor() {
		// initiate things here
	}

	public RequiredRule: FormRule = {
		type: "isRequired",
		errorMessage: "",
		value: 0,
	};

	public EmailRule: FormRule = {
		type: "isRegex",
		value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
		errorMessage: "Email must be valid",
	};

	public PasswordRule: FormRule = {
		type: "isRegex",
		value: /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/,
		errorMessage:
			"Password must contain at least 8 characters that includes alphabets, symbols and numbers",
	};

	public handleConfirmPassword = (password: string, comfirm_password: string) => {
		const rule: FormRule = {
			type: "isCondition",
			value: password == comfirm_password,
			errorMessage: "Do not match password"
		}
		return rule
	}

	public handleIsNumber = (value: string) => {
		const rule: FormRule = {
			type: "isCondition",
			value: !isNaN(parseInt(value)),
			errorMessage: "Must be a number"
		}

		return rule
	}


}