import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "videoPlayer",
  class: "video-js w-full h-full relative"
}
const _hoisted_2 = {
  key: 2,
  class: "flex flex-row items-center justify-center rounded absolute h-full w-full top-0 left-0 bg-black bg-opacity-60 z-index"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_image_loader = _resolveComponent("ts-image-loader")!
  const _component_ts_icon = _resolveComponent("ts-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${$props.customClass} relative`)
  }, [
    ($props.videoUrl)
      ? (_openBlock(), _createElementBlock("video", _hoisted_1, null, 512))
      : (_openBlock(), _createBlock(_component_ts_image_loader, {
          key: 1,
          customClass: 'w-full h-full rounded',
          photoUrl: '/images/default-video.jpg'
        })),
    (!$setup.videoIsPlaying)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ts_icon, {
            name: "play-video",
            customClass: `${$props.iconSize} cursor-pointer`,
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.playVideo()))
          }, null, 8, ["customClass"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}