import axios, { AxiosError, AxiosInstance } from "axios"
import { Logic } from "../../logic/modules"
import { AuthResponse } from "../../logic/types/domains"

export class BaseApiService {
	public axiosInstance: AxiosInstance;

	constructor (public resource: string) {
		if (!resource) throw new Error("Resource is not provided");

		this.axiosInstance = axios.create();
	}

	public getUrl(id = ""): string {
		// auth token
		const tokens: AuthResponse = localStorage.getItem('AuthTokens') ? JSON.parse(localStorage.getItem('AuthTokens') || '{}') : undefined
		this.axiosInstance.defaults.baseURL = Logic.common.apiUrl
		this.axiosInstance.defaults.headers.common["Access-Token"] = tokens ? tokens.accessToken : '';
		this.axiosInstance.defaults.headers.common["Refresh-Token"] = tokens ? tokens.refreshToken : '';
		return id ? `/${this.resource}/${id}` : `/${this.resource}`;
	}

	public handleErrors(err: AxiosError | any): void {
		// Note: here you may want to add your errors handling
		if (err.response?.status == 461) {
			Logic.auth.user.SignOut()
		}
	}
}
