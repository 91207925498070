import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(`px-2 py-1 text-xs rounded !bg-opacity-[0.05]
	${$props.color == 'purple' ? ' text-primaryPurple bg-primaryPurple ' : ''}
	${$props.color == 'green' ? ' text-primaryGreen bg-primaryGreen ' : ''}
	${$props.color == 'yellow' ? ' text-primaryYellow bg-primaryYellow ' : ''} `)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}