<template>
  <div class="w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3">
    <ts-transaction-history :data="transactionHistory" />
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { TsTransactionHistory } from "tutorstack-ui-components";
import { composables } from "../../../composables";
import { Paginated, Transaction } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsTransactionHistory,
  },
  name: "TransactionHistoryPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Transaction history`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const Transactions = ref<Paginated<Transaction>>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Transaction history`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;

      FrontendLogic.payments.transactions.watchProperty(
        "Transactions",
        Transactions
      );
    });

    const transactionHistory = ref<any[]>([]);

    watch(Transactions, () => {
      transactionHistory.value = [];
      Transactions.value?.results.forEach((transaction) => {
        transactionHistory.value.push({
          date: FrontendLogic.common.fomartDate(
            transaction.createdAt,
            "MMM DD, YYYY"
          ),
          type: transaction.data.type == "PayForSession" ? "debit" : "credit",
          content: [transaction.title],
          amount:
            transaction.data.type == "PayForSession"
              ? `-$${transaction.amount}`
              : `+$${transaction.amount}`,
        });
      });
    });

    return {
      transactionHistory,
    };
  },
});
</script>
