import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-2 flex-grow" }
const _hoisted_2 = { class: "flex flex-col mdlg:!space-y-[3px] space-y-[2px] items-start" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_paragraph_text = _resolveComponent("ts-paragraph-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full flex flex-col justify-start items-start space-y-3 pt-2 mdlg:!px-5 mdlg:!py-5 py-3 px-3 border-[1px] rounded ${$props.customClass}`)
  }, [
    _createVNode(_component_ts_header_text, { size: 'base' }, {
      default: _withCtx(() => [
        _createTextVNode(" Attachments ")
      ]),
      _: 1
    }),
    ($props.files)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.files, (file, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-full flex flex-row items-center justify-between",
            key: index
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ts_icon, {
                name: "file",
                customClass: "mdlg:!h-[37px] h-[30px] "
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ts_normal_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(file.name), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ts_paragraph_text, {
                  faded: true,
                  customClass: 'text-xs'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($setup.Logic.common.formatBytes(file.size)), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _createElementVNode("a", {
              href: file.link,
              target: "_blank",
              class: "mdlg:!py-3 mdlg:!px-3 px-2 py-2 rounded-full border-[1px] border-gray-200"
            }, [
              _createVNode(_component_ts_icon, {
                name: "download",
                customClass: "mdlg:h-[17px] h-[14px]"
              })
            ], 8, _hoisted_3)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ], 2))
}