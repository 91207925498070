import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col justify-start items-start h-full space-y-3 mdlg:px-0 px-3"
}
const _hoisted_2 = { class: "flex flex-row space-x-2 w-full justify-between items-center mdlg:pt-1 pt-2" }
const _hoisted_3 = { class: "flex flex-col w-full space-y-3" }
const _hoisted_4 = {
  key: 1,
  class: "flex flex-row items-center justify-center h-screen absolute top-0 left-0 w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_session_card = _resolveComponent("ts-session-card")!
  const _component_ts_empty_state = _resolveComponent("ts-empty-state")!

  return (_ctx.Sessions)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.Sessions.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Sessions, (session, index) => {
                    return (_openBlock(), _createBlock(_component_ts_session_card, {
                      "show-button": false,
                      "avatar-url": session.tutor.bio.photo?.link || '',
                      status: _ctx.selectedTopTab,
                      "badge-color": `${
              session.students.length == 1 ? 'purple' : 'green'
            }`,
                      "card-linecolor": _ctx.cardLineColor,
                      "session-is-close": false,
                      key: index,
                      data: session
                    }, null, 8, ["avatar-url", "status", "badge-color", "card-linecolor", "data"]))
                  }), 128))
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_ts_empty_state, {
                avatarUrl: '/images/no-session.svg',
                avatarSize: 'h-[60px]',
                ctaUrl: _ctx.TsCommon.accountType.value != 'tutor' ? '/tutors' : '',
                customClass: 'h-[400px] mdlg:!h-[540px] '
              }, {
                title: _withCtx(() => [
                  _createTextVNode(" No sessions here ")
                ]),
                subtext: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.TsCommon.accountType.value != "tutor"
              ? "Book sessions with your tutors, alone or with friends"
              : `All your ${_ctx.selectedTopTab} sessions will show up here`), 1)
                ]),
                "cta-text": _withCtx(() => [
                  _createTextVNode(" My tutors ")
                ]),
                _: 1
              }, 8, ["ctaUrl"])
            ]))
      ], 64))
    : _createCommentVNode("", true)
}