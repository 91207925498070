<template>
  <div class="flex w-full flex-col space-y-2">
    <div
      v-if="hasSelectAll"
      @click="toggleSelectAll()"
      class="flex w-full flex-row items-center justify-between cursor-pointer px-3 py-3 border-[1px] rounded"
    >
      <div class="flex flex-row space-x-2 items-center">
        <ts-normal-text> Select all </ts-normal-text>
      </div>
      <ts-icon
        :name="`${
          selectedOption.length == options.length
            ? 'checkbox-active'
            : 'checkbox'
        }`"
        :customClass="`h-[15px]`"
      />
    </div>
    <div
      v-for="(option, index) in options"
      :key="index"
      @click="selectOption(option.key)"
      class="flex w-full flex-row items-center justify-between cursor-pointer px-3 py-3 border-[1px] rounded"
    >
      <div class="flex flex-row space-x-2 items-center">
        <ts-normal-text v-for="(name, index) in option.name" :key="index">
          <template v-if="index == 0">
            {{ name }}
          </template>
          <template v-else>
            <span
              :class="`h-[4px] mr-2 w-[4px] bg-paragraphTextLight rounded-full`"
            >
            </span>
            {{ name }}
          </template>
        </ts-normal-text>
      </div>
      <ts-icon
        :name="`${
          selectedOption.includes(option.key) ? 'checkbox-active' : 'checkbox'
        }`"
        :customClass="`h-[15px]`"
      />
    </div>
  </div>
</template>
<script lang="ts">
import TsNormalText from "../TsTypography/normalText.vue";
import { ref, watch } from "vue";
import TsIcon from "../TsIcon/index.vue";

export default {
  components: {
    TsNormalText,
    TsIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    existingOptions: {
      type: Array,
      required: false,
    },
  },
  name: "TsCheckbox",
  emits: ["optionsSelected"],
  setup(props: any, context: any) {
    const selectedOption = ref<any[]>([]);

    const selectOption = (key: any) => {
      if (selectedOption.value.includes(key)) {
        selectedOption.value = selectedOption.value.filter(
          (eachKey) => eachKey != key
        );
      } else {
        selectedOption.value.push(key);
      }
      context.emit("optionsSelected", selectedOption.value);
    };

    const toggleSelectAll = () => {
      if (selectedOption.value.length < props.options.length) {
        selectedOption.value = [];
        props.options.forEach((content: any) => {
          selectedOption.value.push(content.key);
        });
      } else {
        selectedOption.value = [];
      }
      context.emit("optionsSelected", selectedOption.value);
    };

    watch(props, () => {
      if (props.existingOptions) {
        selectedOption.value = props.existingOptions;
      }
    });

    return {
      selectedOption,
      toggleSelectAll,
      selectOption,
    };
  },
};
</script>
