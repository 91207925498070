import { AxiosResponse } from "axios";
import { Paginated, Transaction } from "../../types/domains";
import { $api } from "../../../services";
import Common from "../Common";

export default class Transactions extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public Transactions: Paginated<Transaction> | undefined = undefined

	public GetTransactions = () => {
		try {
			const request = $api.payments.transactions.fetch().then((response: AxiosResponse<Paginated<Transaction>>) => {
				this.Transactions = response.data
			})
			return request
		} catch (error) {
			console.log(error)
		}
	}

}