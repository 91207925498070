import { Logic } from '../index';
import { AxiosResponse } from 'axios';
import { reactive } from 'vue';
import { Paginated, SingleUser, Tutor } from '../../types/domains';
import { SavedTutorForm, SelectOption, UpdateTutorSubjectForm } from '../../types/forms';
import { $api } from "../../../services";
import Common from '../Common';

export default class Users extends Common {
	constructor() {
		// initiate things here
		super()
		const AuthUser = localStorage.getItem('AuthUser') ? JSON.parse(localStorage.getItem('AuthUser') || '{}') : undefined
		if (AuthUser) {
			this.GetUserProfile(AuthUser.id)
		}
	}

	public Users: Paginated<SingleUser> | undefined = undefined
	public User: SingleUser | undefined = undefined
	public UserProfile: SingleUser | undefined = undefined
	public SavedTutors: Paginated<Tutor> | undefined = undefined

	public UserSelectOptions = reactive<SelectOption[]>([])

	public SavedTutorForm: SavedTutorForm = {
		add: true,
		tutorId: ''
	}

	public UpdateSubjectList: UpdateTutorSubjectForm = {
		add: true,
		subjectId: ''
	}

	public GetUsers = (searchValue = '') => {
		Logic.common.showLoader()
		$api.users.users.fetch({
			where: [
				{
					field: "id",
					value: this.UserProfile?.id,
					condition: "ne"
				}
			],
			search: {
				value: searchValue, fields: ['bio.name.first', 'bio.name.last', 'bio.email']
			}
		}).then((response: AxiosResponse<Paginated<SingleUser>>) => {
			this.Users = response.data
			this.UserSelectOptions = []
			this.Users.results.forEach((user) => {
				if (!user.roles.isTutor) {
					this.UserSelectOptions.push({
						key: user.id,
						value: user.bio.name.full
					})
				}

			})
			Logic.common.hideLoader()
		}).catch(() => {
			Logic.common.hideLoader()
		})

	}


	public GetSavedTutors = (extraSearchParam = {}) => {

		Logic.common.showLoader()
		const request = $api.users.users.fetch({
			where: [
				{
					field: "roles.isTutor",
					value: true
				},
				{
					field: "id",
					value: this.UserProfile?.tutors,
					condition: "in"
				}
			],
			...extraSearchParam
		}).then((response: AxiosResponse<Paginated<Tutor>>) => {
			this.SavedTutors = response.data
			Logic.common.hideLoader()
		}).catch(() => {
			Logic.common.hideLoader()
		})

		return request;

	}



	public GetUserProfile = (userId: string) => {
		try {
			const request = $api.users.users.get(userId).then((response: AxiosResponse<SingleUser>) => {
				this.UserProfile = response.data
				this.GetSavedTutors()
			})

			return request;
		} catch (error) {
			console.log(error)
		}
	}

	public GetSingleUser = (userId: string) => {
		try {
			$api.users.users.get(userId).then((response: AxiosResponse<SingleUser>) => {
				this.User = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateUserSavedTutor = () => {
		try {
			$api.users.users.UpdateSavedTutors(this.SavedTutorForm).then((response: AxiosResponse<SingleUser>) => {
				this.UserProfile = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateTutorSubject = () => {
		try {
			$api.users.users.UpdateTutorSubjects(this.UpdateSubjectList).then((response: AxiosResponse<SingleUser>) => {
				this.GetUserProfile(response.data.id)
			})
		} catch (error) {
			console.log(error)
		}
	}

}