import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full fixed z-[345678987654345] px-3 mdlg:max-w-[390px] top-[3%] left-0 mdlg:!top-[3%] mdlg:!left-auto mdlg:!right-[1%]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return ($setup.Logic.common.alertConfig.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(`w-full flex flex-row items-center space-x-2 px-3 py-3 border-[1px] animate-bounce rounded bg-white ${
        $setup.Logic.common.alertConfig.type == 'error' ? 'border-errorColor' : ''
      }${
        $setup.Logic.common.alertConfig.type == 'success' ? 'border-primaryGreen' : ''
      }${
        $setup.Logic.common.alertConfig.type == 'info' ? 'border-primaryPurple' : ''
      }`)
        }, [
          _createVNode(_component_ts_icon, {
            name: `${
          $setup.Logic.common.alertConfig.type == 'error' ? 'alert-circle' : ''
        }${$setup.Logic.common.alertConfig.type == 'success' ? 'check-green' : ''}${
          $setup.Logic.common.alertConfig.type == 'info' ? 'alert-circle-purple' : ''
        }`,
            "custom-class": "h-[14px]"
          }, null, 8, ["name"]),
          _createVNode(_component_ts_normal_text, { color: "'text-headerText'" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.Logic.common.alertConfig.message), 1)
            ]),
            _: 1
          })
        ], 2)
      ]))
    : _createCommentVNode("", true)
}