import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex w-full  py-0 z-[5699] justify-between mdlg:px-0 px-0 sticky mdlg:pt-2 top-0 bg-white border-b-[1px]
	${
    $props.options.type == 'basic' || !$setup.showMobileSearch
      ? ' flex-row items-center '
      : 'mdlg:!flex-row lg:!flex-row flex-col mdlg:!items-center lg:!items-center '
  } border-gray-200
  ${!$props.options.showBottomBorder ? 'mdlg:border-none' : 'mdlg:border-b-[1px]'}
  ${
    $props.options.type != 'basic' && $props.options.type != 'subpage'
      ? 'px-0'
      : 'mdlg:!px-0 px-3'
  }
  ${$props.options.type == 'subpage' ? 'mdlg:!border-none' : ''}`)
  }, [
    ($props.options.type == 'basic' || $props.options.type == 'subpage')
      ? (_openBlock(), _createBlock(_component_ts_header_text, {
          key: 0,
          size: "xl",
          "custom-class": ` py-3 lg:py-3 mdlg:py-4  flex flex-row items-center lg:pt-6 ${
        $props.options.type == 'subpage' || $props.options.showTitle ? '' : 'mdlg:hidden'
      } ${$setup.showMobileSearch || $setup.searchFocused ? '!hidden' : '!w-full'}`
        }, {
          default: _withCtx(() => [
            (!$setup.searchFocused)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  ($props.options.type == 'subpage')
                    ? (_openBlock(), _createBlock(_component_ts_icon, {
                        key: 0,
                        name: 'chevron-left',
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.goBack())),
                        "custom-class": "h-[14px] pr-[10px] cursor-pointer"
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString($props.options.pageTitle), 1)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["custom-class"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(` ${
        $setup.showMobileSearch
          ? ' border-b-[1px]  border-gray-200 mdlg:!border-none'
          : ''
      } flex flex-row items-center space-x-3 mdlg:!space-x-5 mdlg:!px-0 `)
        }, [
          (!$setup.searchFocused)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.options.switchItems, (item, index) => {
                return (_openBlock(), _createBlock(_component_ts_header_text, {
                  size: "xl",
                  key: index,
                  onClick: _withModifiers(($event: any) => ($setup.selectedTab = item.key), ["stop"]),
                  "custom-class": ` mdlg:border-b-[3px] border-b-[1.5px] px-3 mdlg:px-0 cursor-pointer ${
            $setup.selectedTab == item.key
              ? ' border-headerText '
              : 'border-transparent '
          }   py-3 lg:!pb-2 mdlg:py-3 lg:pt-6`,
                  color: `${
            $setup.selectedTab == item.key
              ? ' text-headerText'
              : 'text-headerText opacity-40'
          }`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.value), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick", "custom-class", "color"]))
              }), 128))
            : _createCommentVNode("", true)
        ], 2)),
    ($props.options.type != 'subpage')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(`hidden  items-center space-x-3 flex-grow mdlg:flex py-3   lg:pt-6 lg:py-3 mdlg:py-5 ${
        $props.options.type == 'basic' && !$props.options.showTitle
          ? 'flex-row'
          : 'flex-row-reverse'
      }`)
        }, [
          (!$setup.searchFocused)
            ? (_openBlock(), _createBlock(_component_ts_icon, {
                key: 0,
                name: "search",
                "custom-class": "h-[17px]"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(`flex flex-row space-x-1 px-2 py-2 ${
          $setup.searchFocused ? 'w-full' : 'w-[200px]'
        } ${
          $props.options.type != 'basic'
            ? ' border-primaryPurple'
            : 'border-transparent'
        } ${$setup.searchFocused ? 'border-[2px]' : 'border-none'} rounded`)
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "w-full focus:outline-none placeholder-slate-600",
              placeholder: "Search",
              onFocus: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchFocused = true)),
              onBlur: _cache[2] || (_cache[2] = ($event: any) => {
            $setup.searchFocused = false;
            $setup.searchValue = '';
          }),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.searchValue) = $event))
            }, null, 544), [
              [_vModelText, $setup.searchValue]
            ]),
            ($setup.searchFocused)
              ? (_openBlock(), _createBlock(_component_ts_icon, {
                  key: 0,
                  name: "close-search",
                  "custom-class": "h-[17px]",
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.searchValue = ''), ["stop"]))
                }))
              : _createCommentVNode("", true)
          ], 2)
        ], 2))
      : _createCommentVNode("", true),
    ($props.options.type != 'subpage')
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(` ${
        $props.options.type == 'basic' ? '' : 'px-3 mdlg:!px-0'
      } space-x-5 flex mdlg:!hidden flex-row items-center py-3 lg:py-3 lg:pt-6 mdlg:py-5 ${
        $setup.searchFocused ? 'w-full' : 'w-auto'
      }`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`flex mdlg:!hidden flex-row space-x-1 ${
          $setup.searchFocused ? 'w-full px-2 py-1 ' : 'w-auto'
        } border-primaryPurple ${
          $setup.searchFocused ? 'border-[2px]' : 'border-none'
        } rounded`)
          }, [
            ($setup.showMobileSearch)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  type: "text",
                  class: "w-full focus:outline-none placeholder-slate-600",
                  placeholder: "Search",
                  onFocus: _cache[5] || (_cache[5] = ($event: any) => {
            $setup.searchFocused = true;
            $setup.showMobileSearch = true;
          }),
                  onBlur: _cache[6] || (_cache[6] = ($event: any) => {
            $setup.searchFocused = false;
            $setup.searchValue = '';
            $setup.showMobileSearch = false;
          }),
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.searchValue) = $event))
                }, null, 544)), [
                  [_vModelText, $setup.searchValue]
                ])
              : _createCommentVNode("", true),
            ($setup.searchFocused)
              ? (_openBlock(), _createBlock(_component_ts_icon, {
                  key: 1,
                  name: "close-search",
                  "custom-class": "h-[17px]",
                  onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => {
            $setup.searchValue = '';
            $setup.showMobileSearch = false;
            $setup.searchFocused = false;
          }, ["stop"]))
                }))
              : _createCommentVNode("", true),
            (!$setup.searchFocused)
              ? (_openBlock(), _createBlock(_component_ts_icon, {
                  key: 2,
                  name: "search",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => {
            $setup.searchFocused = true;
            $setup.showMobileSearch = true;
          }),
                  "custom-class": "mdlg:!h-[20px] h-[19px] inline-block mdlg:hidden"
                }))
              : _createCommentVNode("", true)
          ], 2),
          ($props.options.hasNotification)
            ? (_openBlock(), _createBlock(_component_ts_icon, {
                key: 0,
                name: "bell",
                onClick: _cache[10] || (_cache[10] = ($event: any) => ($props.goToRoute('/notifications'))),
                "custom-class": "h-[22px] cursor-pointer"
              }))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    ($props.options.hasNotification && !$setup.searchFocused)
      ? (_openBlock(), _createBlock(_component_ts_icon, {
          key: 4,
          name: "bell",
          onClick: _cache[11] || (_cache[11] = ($event: any) => ($props.goToRoute('/notifications'))),
          "custom-class": "mdlg:!h-[22px] h-[22px] cursor-pointer hidden mdlg:!inline-block"
        }))
      : _createCommentVNode("", true)
  ], 2))
}