<template>
  <div :class="`flex flex-row items-center space-x-1 ${customClass}`">
    <ts-icon
      :name="`${ratings >= 1 ? 'star_active' : 'star'}`"
      :custom-class="size"
      @click="readonly ? '' : (ratings = 1)"
    />
    <ts-icon
      :name="`${ratings >= 2 ? 'star_active' : 'star'}`"
      :custom-class="size"
      @click="readonly ? '' : (ratings = 2)"
    />
    <ts-icon
      :name="`${ratings >= 3 ? 'star_active' : 'star'}`"
      :custom-class="size"
      @click="readonly ? '' : (ratings = 3)"
    />
    <ts-icon
      :name="`${ratings >= 4 ? 'star_active' : 'star'}`"
      :custom-class="size"
      @click="readonly ? '' : (ratings = 4)"
    />
    <ts-icon
      :name="`${ratings >= 5 ? 'star_active' : 'star'}`"
      :custom-class="size"
      @click="readonly ? '' : (ratings = 5)"
    />
  </div>
</template>
<script lang="ts">
import { onMounted, ref, watch } from "vue";
import TsIcon from "../TsIcon/index.vue";

export default {
  components: {
    TsIcon,
  },
  props: {
    count: {
      type: Number,
      default: 5,
    },
    customClass: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "h-[13px] mdlg:!h-[17px]",
    },
  },
  emits: ["update:modelValue"],
  name: "TsRatings",
  setup(props: any, context: any) {
    const ratings = ref(0);

    onMounted(() => {
      if (props.count) {
        ratings.value = Math.round(props.count);
      }
    });

    watch(ratings, () => {
      context.emit("update:modelValue", ratings.value);
    });

    return {
      ratings,
    };
  },
};
</script>
