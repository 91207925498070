import { AxiosResponse } from "axios";
import { Paginated, Subject } from "../../types/domains";
import { CreateSubjectForm, SelectOption } from "../../types/forms";
import { $api } from "../../../services";

export default class Subjects {
	constructor() {
		// initiate things here

	}

	public Subjects: Paginated<Subject> | undefined = undefined
	public Subject: Subject | undefined = undefined

	public CreateSubjectForm: CreateSubjectForm = {
		title: ''
	}

	public GetSubjectOptions = (addAll = false) => {
		const SelectOptions: SelectOption[] = []

		if (this.Subjects) {
			if (addAll) {
				SelectOptions.push({
					key: 'all',
					value: 'All Subjects'
				})
			}
			this.Subjects.results.forEach((subject) => {
				SelectOptions.push({
					key: subject.id,
					value: subject.title
				})
			})
		}
		return SelectOptions
	}

	public GetSubjectTitle = (subjectId: string) => {
		const subject = this.Subjects?.results.filter((subject) => {
			return subject.id == subjectId
		})

		if (subject) {
			return subject[0].title
		} else {
			return ''
		}

	}

	public GetSubjects = () => {
		try {
			const request = $api.questions.subjects.fetch().then((response: AxiosResponse<Paginated<Subject>>) => {
				this.Subjects = response.data
			})
			return request;
		} catch (error) {
			console.log(error)
		}
	}

	public GetSubject = (subjectId: string) => {
		try {
			$api.questions.subjects.get(subjectId).then((response: AxiosResponse<Subject>) => {
				this.Subject = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public CreateSubject = (formIsValid: boolean) => {
		try {
			if (formIsValid) {
				$api.questions.subjects.post(this.CreateSubjectForm).then((response: AxiosResponse<Subject>) => {
					this.Subjects?.results.push(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateSubject = (formIsValid: boolean, subjectId: string) => {
		try {
			if (formIsValid) {
				$api.questions.subjects.put(subjectId, this.CreateSubjectForm).then((response: AxiosResponse<Subject>) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public DeleteSubject = (subjectId: string) => {
		try {
			$api.questions.subjects.delete(subjectId).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}


}