import Emails from "./Auth/Email"
import Identities from "./Auth/Identities"
import Password from "./Auth/Password"
import AuthUser from "./Auth/User"
import Common from "./Common"
import Validations from "./Forms/Validations"
import Comments from "./Interactions/Comments"
import Notifications from "./Notifications/Notifications"
import Cards from "./Payments/Cards"
import Transactions from "./Payments/Transactions"
import Wallet from "./Payments/Wallet"
import Answers from "./Questions/Answers"
import Questions from "./Questions/Questions"
import Subjects from "./Questions/Subjects"
import Sessions from "./Sessions/Sessions"
import Tutors from "./Users/Tutors"
import Users from "./Users/Users"

export const Logic = {
	common: new Common(),
	auth: {
		email: new Emails(),
		user: new AuthUser(),
		password: new Password(),
		identities: new Identities()
	},
	users: {
		users: new Users(),
		tutors: new Tutors()
	},
	questions: {
		answers: new Answers(),
		questions: new Questions(),
		subjects: new Subjects()
	},
	notifications: {
		notifications: new Notifications()
	},
	payments: {
		cards: new Cards(),
		transactions: new Transactions(),
		wallet: new Wallet()
	},
	sessions: {
		sessions: new Sessions()
	},
	forms: {
		validations: new Validations()
	},
	interactions: {
		comments: new Comments()
	}
};

