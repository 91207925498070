<template>
  <div
    :class="` border-[1px] border-gray-200 ${customClass} w-full col-span-full py-3 px-3 mdlg:!px-5 mdlg:!py-5 ${bgColor} rounded flex justify-between flex-row items-center`"
  >
    <div class="flex flex-col space-y-1 items-start">
      <ts-header-text :size="'xl'" :color="`${color}`">
        <slot name="header" />
      </ts-header-text>
      <ts-normal-text :color="`${color}`">
        <slot name="sub-header" />
      </ts-normal-text>
    </div>
    <div>
      <slot name="extra" />
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
  },
  props: {
    bgColor: {
      type: String,
      default: "bg-primaryPurple",
    },
    color: {
      type: String,
      default: "text-white",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  name: "TsInfoCard",
};
</script>
