import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full col-span-full flex flex-col space-y-2 items-center justify-center ${_ctx.customClass} `)
  }, [
    _createElementVNode("img", {
      src: _ctx.avatarUrl,
      class: _normalizeClass(_ctx.avatarSize)
    }, null, 10, _hoisted_1),
    _createVNode(_component_ts_header_text, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _: 3
    }),
    _createVNode(_component_ts_normal_text, {
      color: 'text-paragraphTextLight',
      customClass: 'px-3'
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "subtext")
      ]),
      _: 3
    }),
    (_ctx.accountType == 'student')
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.ctaUrl
        }, {
          default: _withCtx(() => [
            (_ctx.ctaUrl)
              ? (_openBlock(), _createBlock(_component_ts_button, {
                  key: 0,
                  customClass: 'border-[1px] border-paragraphTextLight',
                  bgColor: 'bg-white',
                  textColor: 'text-paragraphTextLight'
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "cta-text")
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["to"]))
      : _createCommentVNode("", true)
  ], 2))
}