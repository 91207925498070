import { Logic } from '../index';
import { AxiosResponse } from "axios";
import { Paginated, Question } from "../../types/domains";
import { CreateQuestionForm } from "../../types/forms";
import { $api } from "../../../services";
import Common from '../Common';
import { reactive } from 'vue';

export default class Questions extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public Questions: Paginated<Question> | undefined = undefined
	public Question: Question | undefined = undefined
	public AllQuestions: Paginated<Question> | undefined = undefined

	public ShowQuestionPrompt = false

	public QuestionLoaders = reactive({
		ask: false
	})

	public CreateQuestionForm: CreateQuestionForm = {
		attachment: '',
		body: '',
		subjectId: '',
		topic: ''
	}

	public clearQuestionForm = () => {
		this.CreateQuestionForm = {
			attachment: '',
			body: '',
			subjectId: '',
			topic: ''
		}
	}

	public GetQuestions = (query: string | null, sortType = '') => {

		Logic.common.showLoader()
		return $api.questions.questions.fetch(query ? query : undefined).then((response: AxiosResponse<Paginated<Question>>) => {
			this.Questions = response.data
			localStorage.setItem('AllQuestions', JSON.stringify(this.Questions.results))
			if (sortType) {
				this.SortQuestions(sortType)
			}
			Logic.common.hideLoader()
		})

	}

	public SortQuestions = (type: string) => {
		if (this.Questions) {
			const questions: Question[] = localStorage.getItem('AllQuestions') ? JSON.parse(localStorage.getItem('AllQuestions') || '{}') : []

			if (type == 'held') {
				const heldQuestions = questions?.filter((question) => {
					return question.heldBy != null && question.heldBy?.userId == Logic.auth.user.AuthUser?.id && question.answers.length == 0
				})

				this.Questions.results = heldQuestions.reverse();
			}

			if (type == 'unheld') {
				const unHeldQuestions = questions?.filter((question) => {
					return question.heldBy == null && question.answers.length == 0
				})

				this.Questions.results = unHeldQuestions.reverse();
			}

			if (type == 'answered') {
				const answeredQuestions = questions?.filter((question) => {
					return question.answers.length > 0
				})

				this.Questions.results = answeredQuestions.reverse();
			}

			if (type == 'myanswers') {
				const answeredQuestions = questions?.filter((question) => {
					return question.answers.length > 0 && question.answers[0].userId == Logic.auth.user.AuthUser?.id
				})

				this.Questions.results = answeredQuestions.reverse();
			}

			if (type == 'unanswered') {
				const unAnsweredQuestions = questions?.filter((question) => {
					return question.answers.length == 0
				})

				this.Questions.results = unAnsweredQuestions.reverse();
			}
		}
	}

	public GetQuestion = (questionId: string) => {
		try {
			const request = $api.questions.questions.get(questionId).then((response: AxiosResponse<Question>) => {
				this.Question = response.data
			})
			return request;
		} catch (error) {
			console.log(error)
		}
	}

	public CreateQuestion = (formIsValid: boolean, questionForm: any) => {

		if (formIsValid) {
			this.QuestionLoaders.ask = true
			Logic.common.showLoader()
			$api.questions.questions.post(this.CreateQuestionForm).then((response: AxiosResponse<Question>) => {
				this.Questions?.results.push(response.data)
				this.ShowQuestionPrompt = true
				this.QuestionLoaders.ask = false
				Logic.common.hideLoader()
				Logic.common.GoToRoute('/questions/' + response.data.id)
			}).catch((error) => {
				Logic.common.showValidationError(error.response.data, questionForm)
				this.QuestionLoaders.ask = false
				Logic.common.hideLoader()
			})
		}

	}

	public UpdateQuestion = (formIsValid: boolean, questionId: string) => {
		try {
			if (formIsValid) {
				$api.questions.questions.put(questionId, this.CreateQuestionForm).then((response: AxiosResponse<Question>) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public DeleteQuestion = (questionId: string) => {
		try {
			$api.questions.questions.delete(questionId).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

	public HoldQuestions = (questionId: string) => {
		Logic.common.showLoader()
		$api.questions.questions.HoldQuestion(questionId).then((response: AxiosResponse<Question>) => {
			this.Question = response.data
			this.GetQuestions(null, 'unheld')
			this.GetQuestion(questionId)
			Logic.common.hideLoader()
			Logic.common.showAlert("success", 'Question held');
		}).catch((error: any) => {
			Logic.common.hideLoader()
			Logic.common.showAlert("error", error.message);
		})

	}

	public ReleaseQuestions = (questionId: string) => {
		Logic.common.showLoader()
		$api.questions.questions.ReleaseQuestion(questionId).then((response: AxiosResponse<Question>) => {
			this.Question = response.data
			this.GetQuestions(null, 'held')
			this.GetQuestion(questionId)
			Logic.common.hideLoader()

			Logic.common.showAlert("success", 'Question released');
		}).catch((error: any) => {
			Logic.common.hideLoader()
			Logic.common.showAlert("error", error.message);
		})
	}


}