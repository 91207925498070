<template>
  <button
    :class="`focus:outline-none rounded ${padding} ${bgColor} ${textColor} ${customClass} ${
      loading ? '!opacity-50' : ''
    }`"
    :disabled="loading"
  >
    <slot />
  </button>
</template>
<script lang="ts">
export default {
  props: {
    bgColor: {
      type: String,
      default: "bg-primaryPurple",
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    customClass: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "py-2 px-4 ",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  name: "TsButton",
};
</script>
