<template>
  <div
    class="w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full flex flex-row items-center justify-between">
      <ts-normal-text> Bank account </ts-normal-text>
      <span class="flex flex-row items-center space-x-2">
        <ts-normal-text :customClass="'text-primaryPurple'">
          Add account
        </ts-normal-text>
        <ts-icon name="plus-purple" :customClass="'h-[10px]'" />
      </span>
    </div>

    <ts-icon-card :iconName="'bank'" :iconSize="'h-[17px]'">
      <template v-slot:name>
        <ts-paragraph-text :faded="true"> *****42436 </ts-paragraph-text>
      </template>
    </ts-icon-card>
  </div>

  <div
    class="w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full flex flex-row items-center justify-between">
      <ts-normal-text> Paypal </ts-normal-text>
      <span class="flex flex-row items-center space-x-2">
        <ts-normal-text :customClass="'text-primaryPurple'">
          Add account
        </ts-normal-text>
        <ts-icon name="plus-purple" :customClass="'h-[10px]'" />
      </span>
    </div>

    <ts-icon-card :iconName="'paypal'" :iconSize="'h-[17px]'">
      <template v-slot:name>
        <ts-paragraph-text :faded="true"> timmy@gmail.com </ts-paragraph-text>
      </template>
    </ts-icon-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useMeta } from "vue-meta";
import {
  TsNormalText,
  TsParagraphText,
  TsIcon,
  TsIconCard,
} from "tutorstack-ui-components";
import { composables } from "../../../composables";

export default defineComponent({
  components: {
    TsNormalText,
    TsIcon,
    TsIconCard,
    TsParagraphText,
  },
  name: "WithdrawalMethodPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Withdrawal method",
    });

    const TsCommon = composables.common;

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = "Withdrawal method";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.hasNotification = true;
      TsCommon.pageTopBarOptions.showBottomBorder = false;
    });

    return {};
  },
});
</script>
