<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full grid-cols-12 grid gap-2">
      <div class="flex flex-col col-span-12">
        <ts-select
          placeholder="Select one subject you teach perfectly"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="false"
          :options="
            FrontendLogic?.questions?.subjects.GetSubjectOptions() || []
          "
          v-model="UpdateTutorSubjectForm.subjectId"
        >
        </ts-select>
        <div class="flex flex-row justify-end w-full mt-2">
          <ts-button
            :padding="'mdlg:!px-5 mdlg:!py-3 px-5 py-2'"
            @click="addSubject()"
          >
            Add
          </ts-button>
        </div>
      </div>

      <div class="col-span-full flex flex-col space-y-2 text-start pt-3">
        <ts-header-text :size="'base text-start'"> Added </ts-header-text>
        <template v-if="UserProfile">
          <div
            class="w-full flex flex-row items-center justify-between"
            v-for="(subjectId, index) in UserProfile.tutor.subjects"
            :key="index"
          >
            <ts-normal-text>
              {{ FrontendLogic.questions.subjects.GetSubjectTitle(subjectId) }}
            </ts-normal-text>
            <ts-icon
              :name="'remove'"
              :customClass="'h-[13px] cursor-pointer'"
              @click="removeSubject(subjectId)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsSelect,
  TsHeaderText,
  TsNormalText,
  TsIcon,
  TsButton,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { SingleUser } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsSelect,
    TsHeaderText,
    TsNormalText,
    TsIcon,
    TsButton,
  },
  name: "SubjectsPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Subjects`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const UpdateTutorSubjectForm = FrontendLogic.users.users.UpdateSubjectList;
    const UserProfile = ref<SingleUser>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Subjects`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.questions.subjects.GetSubjects();
      FrontendLogic.users.users.watchProperty("UserProfile", UserProfile);
    });

    const addSubject = () => {
      UpdateTutorSubjectForm.add = true;
      FrontendLogic.users.users.UpdateTutorSubject();
    };

    const removeSubject = (subjectId: string) => {
      UpdateTutorSubjectForm.subjectId = subjectId;
      UpdateTutorSubjectForm.add = false;
      FrontendLogic.users.users.UpdateTutorSubject();
    };

    return {
      FrontendLogic,
      UpdateTutorSubjectForm,
      addSubject,
      removeSubject,
      UserProfile,
    };
  },
});
</script>
