import { Logic } from '../index';
import { AxiosResponse } from "axios";
import { Paginated, PaymentMethod, Plan, UserWallet } from "../../types/domains";
import { $api } from "../../../services";
import Common from "../Common";
import braintree from 'braintree-web-drop-in'
import { FundWalletForm, SubScribeToPlan } from '../../types/forms';
import { reactive } from 'vue';

export default class Wallet extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public UserWallet: UserWallet | undefined = undefined
	public PaymentMethods: Paginated<PaymentMethod> | undefined = undefined
	public AvailablePlans: Paginated<Plan> | undefined = undefined
	public FundWalletForm: FundWalletForm = {
		amount: 0,
		methodId: ''
	}

	public SubscribeToPlanForm: SubScribeToPlan = {
		planId: ''
	}

	public WalletLoaders = reactive({
		fund: false,
		subscribe: false,
		cancle: false
	})


	public GetUserWallet = () => {
		try {
			const request = $api.payments.wallets.fetch().then((response: AxiosResponse<UserWallet>) => {
				this.UserWallet = response.data
			})

			return request;
		} catch (error) {
			console.log(error)
		}
	}

	public GetPaymentMethods = () => {
		try {
			const request = $api.payments.cards.fetch({
				where: [
					{
						field: 'userId',
						value: Logic.auth.user.AuthUser?.id
					}
				]
			}).then((response: AxiosResponse<Paginated<PaymentMethod>>) => {
				this.PaymentMethods = response.data
			})

			return request
		} catch (error) {
			console.log(error)
		}
	}


	public InitiatePaymentMethod = () => {
		const submitBtn = document.querySelector('#submit-button');
		try {
			$api.payments.cards.GetPaymentToken().then((response) => {

				braintree.create({
					authorization: response.data.clientToken,
					container: '#payment-container',
					paypal: {
						flow: 'checkout',
						amount: '1.00',
						currency: 'USD'
					},
					card: true
				}, (err: any, instance: any) => {
					submitBtn?.addEventListener('click', () => {
						instance.requestPaymentMethod((err: any, payload: any) => {
							// Submit payload.nonce to your server
							$api.payments.cards.post({
								nonce: payload.nonce
							}).then(() => {
								this.GetPaymentMethods()
							})
						});
					})
				});
			})

		} catch (error) {
			console.log()
		}

	}

	public FundUserWallet = (formIsValid: boolean) => {

		if (formIsValid) {
			Logic.common.showLoader();
			this.WalletLoaders.fund = true
			$api.payments.wallets.FundWallet(this.FundWalletForm).then(() => {

				Logic.common.hideLoader();
				this.WalletLoaders.fund = false
				Logic.common.GoToRoute('/profile/wallet')
				Logic.common.showAlert("success", 'Wallet funded');
			}).catch((error: any) => {
				Logic.common.hideLoader();
				this.WalletLoaders.fund = false
				Logic.common.showAlert("error", error.message);
			})
		}
	}

	public SubscribeToPlan = (planId: string) => {
		Logic.common.showLoader()
		this.WalletLoaders.subscribe = true
		this.SubscribeToPlanForm.planId = planId
		$api.payments.wallets.SubscribeToPlan(this.SubscribeToPlanForm).then(() => {
			this.GetUserWallet();
			Logic.common.hideLoader()
			this.WalletLoaders.subscribe = false
			Logic.common.GoToRoute('/profile/subscription')
			Logic.common.showAlert("success", 'Subscription added');
		}).catch((error: any) => {
			Logic.common.hideLoader()
			this.WalletLoaders.subscribe = false
			Logic.common.GoToRoute('/profile/wallet/payment-method')
			Logic.common.showAlert("error", error.message);
		})
	}

	public CancelPlan = () => {
		Logic.common.showLoader()
		this.WalletLoaders.cancle = true
		$api.payments.wallets.CancelCurrentPlan().then(() => {
			this.GetUserWallet();
			Logic.common.hideLoader()
			this.WalletLoaders.cancle = false
			Logic.common.showAlert("success", 'Plan canceled');
		}).then((error: any) => {
			Logic.common.hideLoader()
			this.WalletLoaders.cancle = false
			Logic.common.showAlert("error", error.message);
		})

	}

	public GetAllPlans = () => {
		const request = $api.payments.plans.fetch().then((response: AxiosResponse<Paginated<Plan>>) => {
			this.AvailablePlans = response.data
		})

		return request
	}

	public GetPlan = (planId: string) => {
		return this.AvailablePlans?.results.filter((plan) => {
			return plan.id == planId
		})[0]
	}



}