<template>
  <div
    class="rounded shadow-md bg-white md:!py-5 md:!px-5 space-y-2 lg:!text-sm mdlg:!text-[12px] text-xs py-3 pb-4 px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
    @click.stop="true"
  >
    <ts-header-text :size="'lg'" :customClass="' w-full text-center'">
      Add Payment Method
    </ts-header-text>

    <div class="w-full flex flex-col py-3 mdlg:!px-3 px-0 space-y-2">
      <div id="payment-container" class="!w-full"></div>
      <div class="w-full flex flex-row items-center justify-center">
        <ts-button id="submit-button" :padding="'py-2 px-6'"> Add </ts-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { onMounted } from "vue";
import { Logic } from "tutorstack-frontend-logic";
import TsButton from "../TsButton";
import TsHeaderText from "../TsTypography/headerText.vue";
export default {
  components: {
    TsButton,
    TsHeaderText,
  },
  props: {},
  name: "TsPaymentMethod",
  setup() {
    onMounted(() => {
      Logic.payments.wallet.InitiatePaymentMethod();
    });
  },
};
</script>
