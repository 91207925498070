import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_image_loader = _resolveComponent("ts-image-loader")!

  return (_openBlock(), _createBlock(_component_ts_image_loader, {
    "photo-url": _ctx.photoUrl,
    "custom-class": `${_ctx.bgColor} rounded-full flex text-xs font-semibold flex-row items-center justify-center ${_ctx.customClass} `,
    "custom-style": `width: ${_ctx.size}px; height: ${_ctx.size}px;`
  }, {
    default: _withCtx(() => [
      (_ctx.photoUrl == '')
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["photo-url", "custom-class", "custom-style"]))
}