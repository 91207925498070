<template>
  <div
    class="rounded shadow-md bg-white md:!py-5 md:!px-5 space-y-2 lg:!text-sm mdlg:!text-[12px] text-xs py-3 pb-4 px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
    @click.stop="true"
  >
    <ts-header-text :size="'lg'" :customClass="' w-full text-center'">
      Add Education
    </ts-header-text>

    <ts-form-wrapper
      :parentRefs="parentRefs"
      ref="formComp"
      class="w-full grid grid-cols-12 py-3 mdlg:!px-3 px-0 gap-2"
    >
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="Hogwarts"
          custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
          v-model="formData.school"
          paddings="py-3"
          :hasTitle="true"
          ref="school"
          name="School"
          :rules="[Logic.forms.validations.RequiredRule]"
        >
          <template v-slot:title> School </template>
        </ts-text-field>
      </div>
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="Ph.D"
          custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          ref="degree"
          name="Degree"
          v-model="formData.degree"
          :rules="[Logic.forms.validations.RequiredRule]"
        >
          <template v-slot:title> Degree </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col space-y-1 col-span-12">
        <ts-normal-text> Year </ts-normal-text>
        <div class="flex flex-row items-center space-x-2 w-full">
          <div class="flex flex-col w-full">
            <ts-text-field
              placeholder="From"
              custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
              paddings="py-3"
              :hasTitle="false"
              v-model="formData.from"
              ref="from"
              name="From date"
              :rules="[Logic.forms.validations.RequiredRule]"
              type="date"
            >
            </ts-text-field>
          </div>
          <ts-icon :name="'dash'" :customClass="'h-[3px]'" />

          <div class="flex flex-col w-full">
            <ts-text-field
              placeholder="To"
              custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
              paddings="py-3"
              type="date"
              v-model="formData.to"
              ref="to"
              name="To date"
              :rules="[Logic.forms.validations.RequiredRule]"
              :hasTitle="false"
            >
            </ts-text-field>
          </div>
        </div>
      </div>

      <div class="flex flex-col col-span-12">
        <ts-file-attachment
          placeholder="Upload file"
          v-model="formData.verification"
        >
          <template v-slot:title> Verification </template>
        </ts-file-attachment>
      </div>

      <div class="col-span-12 gap-2 grid grid-cols-12 pt-2">
        <ts-button
          :bgColor="'bg-white'"
          :padding="'px-4 py-3'"
          :customClass="'border-[1px] border-primaryPurple col-span-6'"
          :textColor="'text-primaryPurple'"
          @click="close ? close() : ''"
        >
          Cancel
        </ts-button>

        <ts-button
          @click="addEducation"
          :customClass="'border-[1px] border-primaryPurple col-span-6'"
          :padding="'px-4 py-3'"
        >
          Done
        </ts-button>
      </div>
    </ts-form-wrapper>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsTextField from "../TsForm/textField.vue";
import TsFileAttachment from "../TsForm/fileAttachment.vue";
import TsIcon from "../TsIcon/index.vue";
import TsButton from "../TsButton/index.vue";
import { Logic } from "tutorstack-frontend-logic";
import { TsFormWrapper } from "../TsForm";
import { ref, defineComponent } from "vue";

export default defineComponent({
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  components: {
    TsHeaderText,
    TsFileAttachment,
    TsTextField,
    TsNormalText,
    TsIcon,
    TsButton,
    TsFormWrapper,
  },
  name: "TsAddEducation",
  setup() {
    const formData = Logic.users.tutors.CreateEducationForm;

    const formComp = ref<any>(null);

    const addEducation = (e: any) => {
      e.preventDefault();
      formData.to = Logic.common.dateToMilliseconds(formData.to.toString());
      formData.from = Logic.common.dateToMilliseconds(formData.from.toString());
      const formState: boolean = formComp.value.validate();
      Logic.users.tutors.CreateTutorEducation(formState);
    };

    return {
      formData,
      addEducation,
      formComp,
      Logic,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
