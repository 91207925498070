<template>
  <div
    :class="`w-full flex flex-col justify-start items-start space-y-3 pt-2 mdlg:!px-5 mdlg:!py-5 py-3 px-3 border-[1px] rounded ${customClass}`"
  >
    <ts-header-text :size="'base'"> Attachments </ts-header-text>

    <template v-if="files">
      <div
        class="w-full flex flex-row items-center justify-between"
        v-for="(file, index) in files"
        :key="index"
      >
        <div class="flex flex-row items-center space-x-2 flex-grow">
          <ts-icon name="file" customClass="mdlg:!h-[37px] h-[30px] " />
          <div
            class="flex flex-col mdlg:!space-y-[3px] space-y-[2px] items-start"
          >
            <ts-normal-text> {{ file.name }} </ts-normal-text>
            <ts-paragraph-text :faded="true" :customClass="'text-xs'">
              {{ Logic.common.formatBytes(file.size) }}
            </ts-paragraph-text>
          </div>
        </div>

        <a
          :href="file.link"
          target="_blank"
          class="mdlg:!py-3 mdlg:!px-3 px-2 py-2 rounded-full border-[1px] border-gray-200"
        >
          <ts-icon name="download" customClass="mdlg:h-[17px] h-[14px]" />
        </a>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsParagraphText from "../TsTypography/paragraphText.vue";
import TsIcon from "../TsIcon/index.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import { FileData, Logic } from "tutorstack-frontend-logic";

export default {
  components: {
    TsHeaderText,
    TsParagraphText,
    TsIcon,
    TsNormalText,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    files: {
      type: Object as () => FileData[],
    },
  },
  name: "TsAttachmentList",
  setup() {
    return {
      Logic,
    };
  },
};
</script>
