import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full mdlg:!py-0 mdlg:px-0 px-3 py-3" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-row items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full flex flex-row items-center space-x-2"
}
const _hoisted_4 = {
  key: 0,
  class: "px-3 mdlg:!px-0 w-full flex flex-col space-y-2 mb-3"
}
const _hoisted_5 = { class: "w-full flex flex-col space-y-2 border-[1px] rounded border-gray-200 px-3 py-3 mdlg:!px-5 mdlg:!py-5 justify-start" }
const _hoisted_6 = { class: "w-full mt-2 flex flex-row space-x-2 flex-wrap" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "px-3 mdlg:!px-0 w-full flex flex-col space-y-2" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-2 border-[1px] rounded border-gray-200 px-3 py-3 justify-start" }
const _hoisted_10 = {
  key: 1,
  class: "px-3 mdlg:!px-0 w-full"
}
const _hoisted_11 = {
  key: 0,
  class: "w-full flex flex-col justify-start items-start mt-3 mdlg:!mt-0 space-y-3 mdlg:!px-5 mdlg:!py-5 px-3 py-3 border-[1px] rounded"
}
const _hoisted_12 = {
  key: 2,
  class: "w-full mdlg:!py-0 mdlg:px-0 px-3 py-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_session_info = _resolveComponent("ts-session-info")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_video = _resolveComponent("ts-video")!
  const _component_ts_empty_state = _resolveComponent("ts-empty-state")!
  const _component_ts_users_list = _resolveComponent("ts-users-list")!
  const _component_ts_radio_card = _resolveComponent("ts-radio-card")!
  const _component_ts_attachment_list = _resolveComponent("ts-attachment-list")!
  const _component_ts_tutor_rating = _resolveComponent("ts-tutor-rating")!
  const _component_ts_modal = _resolveComponent("ts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.SessionData)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ts_session_info, { data: _ctx.SessionData }, {
              subject: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.FrontendLogic.questions.subjects.GetSubjectTitle(
              _ctx.SessionData?.subjectId || ""
            )), 1)
              ]),
              date: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.FrontendLogic.common.fomartDate(_ctx.SessionData.startedAt, "MMM D")), 1)
              ]),
              "time-to-from": _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.FrontendLogic.common.fomartDate(_ctx.SessionData.startedAt, "HH:mm a")) + " - " + _toDisplayString(_ctx.FrontendLogic.common.fomartDate(
              _ctx.SessionData.startedAt + _ctx.SessionData.lengthInMinutes * 60000,
              "HH:mm a"
            )), 1)
              ]),
              extras: _withCtx(() => [
                (
              _ctx.FrontendLogic.sessions.sessions.getSessionStatus(_ctx.SessionData) ==
              'upcoming'
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ts_button, {
                        padding: 'px-4 py-2',
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.joinSession(_ctx.SessionData?.id || '')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Enter Session ")
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                (
              _ctx.FrontendLogic.sessions.sessions.getSessionStatus(_ctx.SessionData) ==
              'previous'
            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (
                _ctx.SessionData?.closedAt && _ctx.TsCommon.accountType.value != 'tutor'
              )
                        ? (_openBlock(), _createBlock(_component_ts_button, {
                            key: 0,
                            padding: 'px-4 py-2',
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showRateTutor = true))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Rate session ")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data"])
          ]),
          (
        _ctx.FrontendLogic.sessions.sessions.getSessionStatus(_ctx.SessionData) ==
          'previous' &&
        _ctx.UserWallet?.subscription.active == true &&
        _ctx.TsCommon.accountType.value != 'tutor'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ts_header_text, { customClass: 'w-full text-left' }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Session Recordings ")
                    ]),
                    _: 1
                  }),
                  (_ctx.SessionVideos.length > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_ts_video, {
                          customClass: 'w-full mdlg:!h-[350px] h-[230px]',
                          iconSize: 'mdlg:!h-[37px] h-[28px]',
                          "video-url": _ctx.SessionVideos[_ctx.selectedVideoId - 1]
                        }, null, 8, ["video-url"]),
                        _createElementVNode("div", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SessionVideos, (video, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              onClick: ($event: any) => (_ctx.selectedVideoId = index + 1),
                              class: _normalizeClass(`w-[30px] h-[30px] mdlg:!w-[40px] mdlg:!h-[40px] rounded border-[2px] cursor-pointer border-primaryPurple flex flex-row items-center justify-center
			${_ctx.selectedVideoId == index + 1 ? 'bg-primaryPurple' : 'bg-white'}`)
                            }, [
                              _createVNode(_component_ts_header_text, {
                                color: `${
                  _ctx.selectedVideoId == index + 1
                    ? 'text-white'
                    : 'text-primaryPurple'
                }`,
                                size: "lg"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(`${index + 1}`), 1)
                                ]),
                                _: 2
                              }, 1032, ["color"])
                            ], 10, _hoisted_7))
                          }), 128))
                        ])
                      ], 64))
                    : (_openBlock(), _createBlock(_component_ts_empty_state, {
                        key: 1,
                        avatarUrl: '/images/no-session.svg',
                        avatarSize: 'h-[60px]',
                        ctaUrl: '',
                        customClass: 'h-[200px] mdlg:!h-[240px] '
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" No session recording found ")
                        ]),
                        subtext: _withCtx(() => []),
                        _: 1
                      }))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_ts_header_text, { customClass: 'w-full text-left' }, {
                default: _withCtx(() => [
                  _createTextVNode(" Participants ")
                ]),
                _: 1
              }),
              _createVNode(_component_ts_users_list, {
                users: _ctx.users,
                paidUsers: _ctx.paidUsers,
                payForSession: _ctx.payForSession,
                currentUserId: _ctx.FrontendLogic.auth.user.AuthUser?.id || '',
                sessionState: 
            _ctx.FrontendLogic.sessions.sessions.getSessionStatus(_ctx.SessionData)
          
              }, null, 8, ["users", "paidUsers", "payForSession", "currentUserId", "sessionState"])
            ])
          ]),
          (
        _ctx.FrontendLogic.sessions.sessions.getSessionStatus(_ctx.SessionData) !=
        'previous'
      )
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_ctx.SessionData.paid.length < _ctx.SessionData.students.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_ts_header_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Payment Methods ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ts_radio_card, {
                        options: _ctx.paymentOptions,
                        modelValue: _ctx.FrontendLogic.sessions.sessions.PayForSessionForm.methodId,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.FrontendLogic.sessions.sessions.PayForSessionForm.methodId) = $event))
                      }, null, 8, ["options", "modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.SessionData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_ts_attachment_list, {
                  files: _ctx.SessionData.attachments
                }, null, 8, ["files"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showRateTutor)
      ? (_openBlock(), _createBlock(_component_ts_modal, {
          key: 1,
          canClose: true,
          close: 
      () => {
        _ctx.showRateTutor = false;
      }
    
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_tutor_rating, {
              tutor: _ctx.SessionData?.tutor,
              close: 
        () => {
          _ctx.showRateTutor = false;
        }
      ,
              confirm: _ctx.executeAction,
              ratings: _ctx.FrontendLogic.sessions.sessions.RateSessionForm.rating,
              "onUpdate:ratings": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.FrontendLogic.sessions.sessions.RateSessionForm.rating) = $event)),
              review: _ctx.FrontendLogic.sessions.sessions.RateSessionForm.message,
              "onUpdate:review": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.FrontendLogic.sessions.sessions.RateSessionForm.message) = $event))
            }, null, 8, ["tutor", "close", "confirm", "ratings", "review"])
          ]),
          _: 1
        }, 8, ["close"]))
      : _createCommentVNode("", true)
  ], 64))
}