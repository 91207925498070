import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "w-full grid-cols-12 grid gap-2" }
const _hoisted_3 = { class: "flex flex-col col-span-12" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_5 = { class: "flex flex-row justify-end w-full mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_select = _resolveComponent("ts-select")!
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_radio_card = _resolveComponent("ts-radio-card")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ts_text_field, {
          placeholder: "0",
          "custom-class": "mdlg:px-3 px-2 border-[1px] rounded border-gray-200",
          paddings: "py-3",
          hasTitle: true
        }, {
          title: _withCtx(() => [
            _createTextVNode(" Amount ")
          ]),
          "outer-suffix": _withCtx(() => [
            _createVNode(_component_ts_select, {
              placeholder: "USD",
              "custom-class": "mdlg:px-3 px-2 border-[1px] border-l-[0px]  rounded border-gray-200",
              paddings: "py-3",
              defaultSize: "w-[90px]",
              hasTitle: false
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ts_normal_text, null, {
        default: _withCtx(() => [
          _createTextVNode(" Payment method ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_radio_card, { options: _ctx.paymentOptions }, null, 8, ["options"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ts_button, { padding: 'mdlg:!px-4 mdlg:!py-3  px-3 py-2' }, {
        default: _withCtx(() => [
          _createTextVNode(" Withdraw ")
        ]),
        _: 1
      })
    ])
  ]))
}