import { AuthResponse } from "../../../logic/types/domains/auth";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";

export default class TokenApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("auth/token");
	}

	public async ExchangeToken(): Promise<AxiosResponse<AuthResponse>> {
		try {
			const response: AxiosResponse<AuthResponse> = await this.axiosInstance.post(
				this.getUrl() + "",
				{}
			);

			return response;
		} catch (err: any) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}


}