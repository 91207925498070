import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, renderList as _renderList, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full justify-between mdlg:!space-y-3 items-center mdlg:pt-1 pt-3 px-0 mdlg:!px-0" }
const _hoisted_2 = {
  key: 0,
  class: "w-full items-center justify-end flex flex-row mdlg:!px-0 px-3"
}
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row items-center space-x-2 mdlg:!space-x-2 justify-between mdlg:!py-3 py-2 mdlg:!border-t-[1px] border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-r-0 border-t-0 border-l-0 mdlg:!px-3 px-3 border-gray-200 mdlg:rounded-md"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-row items-center space-x-1 justify-between py-2 border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] mdlg:!border-t-[1px] border-r-0 border-t-0 border-l-0 px-2 border-gray-200 mdlg:rounded-md"
}
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col space-y-3 w-full pt-3 mdlg:!pt-0 px-3 mdlg:!px-0"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "flex flex-row items-start pt-1 space-x-2"
}
const _hoisted_8 = { class: "flex flex-row items-center space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_select = _resolveComponent("ts-select")!
  const _component_ts_question_card = _resolveComponent("ts-question-card")!
  const _component_ts_empty_state = _resolveComponent("ts-empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.TsCommon.accountType.value == 'student')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ts_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedTab = 'answered')),
            bgColor: `${
          _ctx.selectedTab == 'answered'
            ? '!bg-primaryPurple'
            : 'border-[1px] border-gray-200 '
        } rounded-r-none`,
            padding: "px-4 py-2",
            textColor: `${
          _ctx.selectedTab == 'answered' ? 'text-white' : 'text-paragraphTextLight'
        }`
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Answered ")
            ]),
            _: 1
          }, 8, ["bgColor", "textColor"]),
          _createVNode(_component_ts_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedTab = 'unanswered')),
            bgColor: "bg-white",
            customClass: `${
          _ctx.selectedTab == 'unanswered'
            ? '!bg-primaryPurple'
            : 'border-[1px] border-gray-200 '
        }  rounded-l-none `,
            padding: "px-4 py-2",
            textColor: `${
          _ctx.selectedTab == 'unanswered' ? 'text-white' : 'text-paragraphTextLight'
        }`
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Unanswered ")
            ]),
            _: 1
          }, 8, ["customClass", "textColor"])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.selectedTopTab != 'my_answers')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_ts_icon, {
                  name: "info",
                  customClass: 'h-[16px]'
                }),
                _createVNode(_component_ts_normal_text, { customClass: 'w-full text-left' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.selectedTopTab == "unheld"
              ? " Questions you hold will be reserved for you to answer within one hour."
              : "") + " " + _toDisplayString(_ctx.selectedTopTab == "held"
              ? " Only you can see and answer these questions before the time runs out."
              : ""), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ts_icon, {
                  name: "close",
                  customClass: 'h-[14px]'
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.selectedTopTab == 'unheld')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ts_select, {
                  placeholder: "Subjects",
                  "custom-class": "mdlg:px-3 px-2 ",
                  paddings: "py-1",
                  options: _ctx.FrontendLogic.questions.subjects.GetSubjectOptions(true),
                  modelValue: _ctx.selectedSubject,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSubject) = $event))
                }, null, 8, ["options", "modelValue"])
              ]))
            : _createCommentVNode("", true)
        ], 64)),
    (_ctx.Questions && _ctx.Questions?.results?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Questions.results, (question, index) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.FrontendLogic.common.GoToRoute(`/questions/${question.id}`)),
              key: index,
              class: "w-full flex flex-col"
            }, [
              _createVNode(_component_ts_question_card, {
                status: _ctx.selectedTab,
                data: question
              }, _createSlots({
                subject: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.FrontendLogic.questions.subjects.GetSubjectTitle(
                question.subjectId
              )), 1)
                ]),
                "past-time": _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.moment(question.createdAt).fromNow()), 1)
                ]),
                content: _withCtx(() => [
                  _createTextVNode(_toDisplayString(question.body), 1)
                ]),
                extra: _withCtx(() => [
                  (_ctx.TsCommon.accountType.value == 'tutor')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_ctx.selectedTopTab == 'unheld')
                          ? (_openBlock(), _createBlock(_component_ts_button, {
                              key: 0,
                              padding: 'px-4 py-1',
                              onClick: _withModifiers(($event: any) => (_ctx.holdQuestion(question.id)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Hold ")
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.selectedTopTab == 'held')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createVNode(_component_ts_button, {
                                customClass: 'border-[1px] border-primaryPurple',
                                padding: 'px-4 py-1'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Upload video answer ")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ts_button, {
                                bgColor: 'bg-white',
                                padding: 'px-4 py-1',
                                customClass: 'border-[1px] border-primaryPurple',
                                textColor: 'text-primaryPurple',
                                onClick: _withModifiers(($event: any) => (_ctx.ReleaseQuestion(question.id)), ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Release ")
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ], 64))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, [
                (_ctx.selectedTopTab == 'held')
                  ? {
                      name: "time",
                      fn: _withCtx(() => [
                        _createElementVNode("span", _hoisted_8, [
                          _createVNode(_component_ts_icon, {
                            name: "stopwatch-red",
                            customClass: 'h-[12px]'
                          }),
                          _createVNode(_component_ts_normal_text, {
                            color: 'text-errorColor',
                            customClass: '!text-xs'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.FrontendLogic.common.countDownTime(
                    question?.heldBy?.releasedAt || 0
                  )), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["status", "data"])
            ], 8, _hoisted_6))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_ts_empty_state, {
          key: 3,
          avatarUrl: '/images/no-question.svg',
          avatarSize: 'h-[60px]',
          ctaUrl: _ctx.TsCommon.accountType.value != 'tutor' ? '/questions/ask' : '',
          customClass: 'h-[400px] mdlg:!h-[540px] '
        }, {
          title: _withCtx(() => [
            _createTextVNode(" No questions here ")
          ]),
          subtext: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.TsCommon.accountType.value != "tutor"
            ? "Ask questions you need help with and get expert video solutions."
            : ""), 1)
          ]),
          "cta-text": _withCtx(() => [
            _createTextVNode(" Ask a question ")
          ]),
          _: 1
        }, 8, ["ctaUrl"]))
  ]))
}