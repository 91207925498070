import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_calendar = _resolveComponent("ts-calendar")!

  return (_openBlock(), _createElementBlock("div", {
    class: "rounded shadow-md items-center justify-center bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full",
    onClick: _withModifiers(($event: any) => (true), ["stop"])
  }, [
    _createVNode(_component_ts_header_text, { "custom-class": "w-full text-center" }, {
      default: _withCtx(() => [
        _createTextVNode(" Pick a date ")
      ]),
      _: 1
    }),
    _createVNode(_component_ts_calendar, {
      "is-picker": true,
      "date-allowed": _ctx.availableDays,
      modelValue: _ctx.selectedDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event))
    }, null, 8, ["date-allowed", "modelValue"])
  ]))
}