import { AxiosResponse } from "axios";
import { ModelApiService } from "../../common/ModelService";

export default class CardsApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("payment/methods");
	}

	public async GetPaymentToken(): Promise<AxiosResponse<{
		clientToken: string
	}>> {
		try {
			const response: AxiosResponse<{
				clientToken: string
			}> = await this.axiosInstance.get(
				this.getUrl() + "/tokens"
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}
}