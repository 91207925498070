<template>
  <div :class="`${customClass} relative`">
    <video
      ref="videoPlayer"
      v-if="videoUrl"
      class="video-js w-full h-full relative"
    ></video>
    <ts-image-loader
      v-else
      :customClass="'w-full h-full rounded'"
      :photoUrl="'/images/default-video.jpg'"
    >
    </ts-image-loader>

    <div
      v-if="!videoIsPlaying"
      class="flex flex-row items-center justify-center rounded absolute h-full w-full top-0 left-0 bg-black bg-opacity-60 z-index"
    >
      <ts-icon
        name="play-video"
        :customClass="`${iconSize} cursor-pointer`"
        @click="playVideo()"
      />
    </div>
  </div>
</template>
<script lang="ts">
import TsIcon from "../TsIcon/index.vue";
import TsImageLoader from "../TsImageLoader";
import { onMounted, onUnmounted, ref, watch } from "vue";
import videojs from "video.js";

export default {
  components: {
    TsIcon,
    TsImageLoader,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    videoUrl: {
      type: String,
      default:
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
    },
    iconSize: {
      type: String,
      default: "h-[18px]",
    },
  },
  name: "TsVideo",
  setup(props: any) {
    let player = ref<videojs.Player>();

    const videoPlayer = ref();
    const videoIsPlaying = ref(false);

    const options = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: props.videoUrl,
          type: "video/mp4",
        },
      ],
    };

    const playVideo = () => {
      player.value?.play();
      videoIsPlaying.value = true;
    };

    watch(props, () => {
      options.sources[0].src = props.videoUrl;
      player.value?.src(props.videoUrl);
      playVideo();
    });
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      player.value = window.videojs(videoPlayer.value, options, () => {
        //
      });
    });

    onUnmounted(() => {
      player.value?.dispose();
    });

    return {
      videoPlayer,
      videoIsPlaying,
      playVideo,
    };
  },
};
</script>
