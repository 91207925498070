import CommonTs from "./Common";
import { Logic } from 'tutorstack-frontend-logic'
import { ref } from "vue";

export const composables = {
	common: new CommonTs(),
	logic: Logic
}

export const selectedSessionTab = ref('')
export const selectedQuestionTab = ref('')
export const selectedTopTab = ref('')

