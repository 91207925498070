import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col col-span-12" }
const _hoisted_2 = { class: "flex flex-col col-span-12" }
const _hoisted_3 = { class: "flex flex-col space-y-1 col-span-12" }
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = { class: "flex flex-col flex-grow" }
const _hoisted_6 = { class: "flex flex-col flex-grow" }
const _hoisted_7 = { class: "flex flex-col col-span-12" }
const _hoisted_8 = { class: "col-span-12 gap-2 grid grid-cols-12 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_file_attachment = _resolveComponent("ts-file-attachment")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_form_wrapper = _resolveComponent("ts-form-wrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full",
    onClick: _withModifiers(($event: any) => (true), ["stop"])
  }, [
    _createVNode(_component_ts_header_text, {
      size: 'lg',
      customClass: ' w-full text-center'
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Add Work ")
      ]),
      _: 1
    }),
    _createVNode(_component_ts_form_wrapper, {
      parentRefs: _ctx.parentRefs,
      ref: "formComp",
      class: "w-full py-3 mdlg:!px-3 px-0 grid grid-cols-12 gap-2"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ts_text_field, {
            placeholder: "Hogwarts",
            "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
            paddings: "py-3",
            hasTitle: true,
            modelValue: _ctx.formData.institution,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.institution) = $event)),
            ref: "institution",
            name: "Institution",
            rules: [_ctx.Logic.forms.validations.RequiredRule]
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Instituition ")
            ]),
            _: 1
          }, 8, ["modelValue", "rules"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ts_text_field, {
            placeholder: "Chemistry Teacher",
            "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
            paddings: "py-3",
            hasTitle: true,
            modelValue: _ctx.formData.position,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.position) = $event)),
            ref: "position",
            name: "Position",
            rules: [_ctx.Logic.forms.validations.RequiredRule]
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Position ")
            ]),
            _: 1
          }, 8, ["modelValue", "rules"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ts_normal_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Year ")
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ts_text_field, {
                placeholder: "From",
                "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
                paddings: "py-3",
                hasTitle: false,
                type: "date",
                modelValue: _ctx.formData.from,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.from) = $event)),
                ref: "from",
                name: "From date",
                rules: [_ctx.Logic.forms.validations.RequiredRule]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _createVNode(_component_ts_icon, {
              name: 'dash',
              customClass: 'h-[3px]'
            }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ts_text_field, {
                placeholder: "To",
                "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
                paddings: "py-3",
                hasTitle: false,
                modelValue: _ctx.formData.to,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.to) = $event)),
                ref: "to",
                name: "To date",
                rules: [_ctx.Logic.forms.validations.RequiredRule],
                type: "date"
              }, null, 8, ["modelValue", "rules"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ts_file_attachment, {
            placeholder: "Upload file",
            modelValue: _ctx.formData.verification,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.verification) = $event))
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Verification ")
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ts_button, {
            bgColor: 'bg-white',
            padding: 'px-4 py-3',
            customClass: 'border-[1px] border-primaryPurple col-span-6',
            textColor: 'text-primaryPurple',
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.close ? _ctx.close() : ''))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_button, {
            customClass: 'border-[1px] border-primaryPurple col-span-6',
            padding: 'px-4 py-3',
            onClick: _withModifiers(_ctx.addWork, ["stop"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Done ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["parentRefs"])
  ]))
}