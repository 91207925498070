import { BottomBarOptions } from '../modules/common';
import { TopBarOptions } from "../modules/common";
import { reactive, ref } from "vue";
import { AuthUser } from 'tutorstack-frontend-logic';

export default class CommonTs {

	constructor() {
		const AuthUser: AuthUser = localStorage.getItem('AuthUser') ? JSON.parse(localStorage.getItem('AuthUser') || '{}') : undefined
		this.accountType.value = AuthUser?.roles.isTutor ? 'tutor' : 'student'
	}

	public pageTopBarOptions = reactive<TopBarOptions>({
		type: "basic",
		hasNotification: false,
		hasBackIcon: false,
		hasSearch: false,
		switchItems: [],
		pageTitle: "",
		showTitle: false,
		showBottomBorder: true,
		backRoute: ''
	})

	public selectedTopTab = ''

	public accountType = ref('student')

	public bottomTabOptions = reactive<BottomBarOptions>({
		subPage: false,
		actions: []
	})

	public GoogleClientID = process.env.VUE_APP_GOOGLE_CLIENT_ID

	public showBottomBar = ref(true)

	public setSelectedTopBar = (item: string) => {
		this.selectedTopTab = item;
	}

	public resetTopBarOptions = () => {
		this.pageTopBarOptions.type = 'basic'
		this.pageTopBarOptions.hasNotification = false
		this.pageTopBarOptions.hasBackIcon = false
		this.pageTopBarOptions.hasSearch = false
		this.pageTopBarOptions.switchItems = []
		this.pageTopBarOptions.pageTitle = ""
		this.pageTopBarOptions.showTitle = false
		this.pageTopBarOptions.showBottomBorder = true
		this.pageTopBarOptions.backRoute = ''

	}

	public watchItem = (objectToWatch: any, objectToUpdate: any) => {
		let upatedValue = (this as any)[`${objectToWatch}`];
		const watchAction = () => {
			upatedValue = (this as any)[`${objectToWatch}`];
			if (objectToUpdate) {
				objectToUpdate.value = upatedValue;
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			window.watchInterval = window.requestAnimationFrame(watchAction)
		}

		watchAction()
	}

	public stopWatchItem = () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		if (window.watchInterval != undefined && window.watchInterval != 'undefined') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			window.cancelAnimationFrame(window.watchInterval);
		}
	}


}
