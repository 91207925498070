import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 mdlg:!px-0 mdlg:!py-0 px-3 py-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_ctx.Notifications)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Notifications.results, (notification, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-full items-start flex flex-col mdlg:!py-5 mdlg:!px-5 px-2 py-3 space-y-1 border-[1px] border-gray-200 rounded",
            key: index
          }, [
            (!notification.seen)
              ? (_openBlock(), _createBlock(_component_ts_header_text, {
                  key: 0,
                  size: 'base',
                  color: 'text-primaryPurple',
                  customClass: 'text-start'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(notification.title), 1)
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createBlock(_component_ts_normal_text, {
                  key: 1,
                  color: 'text-paragraphTextLight',
                  customClass: 'text-start'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(notification.title), 1)
                  ]),
                  _: 2
                }, 1024)),
            _createVNode(_component_ts_normal_text, {
              color: 'text-paragraphTextLight',
              customClass: 'text-xs'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.FrontendLogic.common.timeFromNow(notification.createdAt)), 1)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}