<template>
  <div class="flex w-full flex-col col-span-full space-y-2">
    <div
      class="flex w-full flex-row items-center justify-between cursor-pointer px-3 py-3 border-[1px] rounded"
    >
      <div class="flex flex-row space-x-2 items-center">
        <ts-icon v-if="iconName" :name="iconName" :customClass="`${iconSize}`" />
        <slot name="name" />
      </div>
      <slot name="extra" />
    </div>
  </div>
</template>
<script lang="ts">
import TsIcon from "../TsIcon/index.vue";

export default {
  components: {
    TsIcon,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: "h-[20px]",
    },
  },
  name: "TsIconCard",
};
</script>
