import { AxiosResponse } from 'axios';
import { Paginated, Review, SingleUser, Tutor, TutorEducation, TutorWork } from '../../types/domains';
import { CreateTutorEducationForm, CreateTutorWorkForm, UpdateTutorSubjectForm } from '../../types/forms';
import { Logic } from '..';
import { $api } from "../../../services";
import Common from '../Common';
import { reactive } from 'vue';

export default class Tutors extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public UpdateSubjectList: UpdateTutorSubjectForm = {
		add: true,
		subjectId: ''
	}

	public Tutors: Paginated<Tutor> | undefined = undefined
	public Tutor: Tutor | undefined = undefined

	public TutorEducations: Paginated<TutorEducation> | undefined = undefined
	public TutorEducation: TutorEducation | undefined = undefined

	public TutorWorks: Paginated<TutorWork> | undefined = undefined

	public TutorReviews: Paginated<Review> | undefined = undefined

	public TutorWork: TutorWork | undefined = undefined

	public TutorRatingsArray = reactive({
		'5': {
			name: "5 stars",
			count: 0,
		},
		'4': {
			name: "4 stars",
			count: 0,
		},
		'3': {
			name: "3 stars",
			count: 0,
		},
		'2': {
			name: "2 stars",
			count: 0,
		},
		'1': {
			name: "1 star",
			count: 0,
		},

	});


	public CreateEducationForm: CreateTutorEducationForm = {
		degree: '',
		from: 0,
		school: '',
		to: 0,
		verification: ''
	}

	public CreateWorkForm: CreateTutorWorkForm = {
		from: 0,
		institution: '',
		position: '',
		to: 0,
		verification: ''
	}

	private ResetRatings = () => {
		this.TutorRatingsArray = reactive({
			'5': {
				name: "5 stars",
				count: 0,
			},
			'4': {
				name: "4 stars",
				count: 0,
			},
			'3': {
				name: "3 stars",
				count: 0,
			},
			'2': {
				name: "2 stars",
				count: 0,
			},
			'1': {
				name: "1 star",
				count: 0,
			},

		});
	}


	public GetTutors = (extraSearchParam = {}, extraQueryParam: any[] = []) => {
		try {
			Logic.common.showLoader()
			const request = $api.users.users.fetch({
				where: [
					{
						field: "roles.isTutor",
						value: true
					},
					...extraQueryParam],
				...extraSearchParam
			}).then((response: AxiosResponse<Paginated<Tutor>>) => {
				this.Tutors = response.data
				Logic.common.hideLoader()
			}).catch(() => {
				Logic.common.hideLoader()
			})


			return request;
		} catch (error) {
			console.log(error)
		}
	}


	public GetTutor = (tutorId: string) => {
		try {

			const request = $api.users.users.fetch({
				where: [
					{
						field: "roles.isTutor",
						value: true
					},
					{
						field: "id",
						value: tutorId
					}
				]
			}).then((response: AxiosResponse<Paginated<Tutor>>) => {
				this.Tutor = response.data.results[0]
			})

			return request
		} catch (error) {
			console.log(error)
		}
	}



	public UpdateTutorSubject = () => {
		try {
			$api.users.users.UpdateTutorSubjects(this.UpdateSubjectList).then((response: AxiosResponse<SingleUser>) => {
				Logic.users.users.User = response.data
				Logic.common.showAlert("success", 'Subject added');
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetTutorEducations = (tutorId: string) => {
		try {
			const request = $api.users.tutorEducations.fetch({
				where: [
					{
						field: 'userId',
						value: tutorId
					}
				]
			}).then((response: AxiosResponse<Paginated<TutorEducation>>) => {
				this.TutorEducations = response.data
			})

			return request
		} catch (error) {
			console.log(error)
		}
	}

	public GetTutorEducation = (educationId: string) => {
		try {
			$api.users.tutorEducations.get(educationId).then((response: AxiosResponse<TutorEducation>) => {
				this.TutorEducation = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public CreateTutorEducation = (formIsValid: boolean) => {
		try {
			if (formIsValid) {
				$api.users.tutorEducations.post(this.CreateEducationForm).then((response: AxiosResponse<TutorEducation>) => {
					this.TutorEducations?.results.push(response.data)

					Logic.common.showAlert("success", 'Education added');
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateTutorEducation = (formIsValid: boolean, educationId: string) => {
		try {
			if (formIsValid) {
				$api.users.tutorEducations.put(educationId, this.CreateEducationForm).then((response: AxiosResponse<TutorEducation>) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public DeleteTutorEducation = (educationId: string) => {
		try {
			$api.users.tutorEducations.delete(educationId).then((response: AxiosResponse<boolean>) => {
				if (response.data) {
					this.GetTutorEducations(this.Tutor?.id || '')
				}
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetTutorWorks = (tutorId: string) => {
		try {
			const request = $api.users.tutorWorks.fetch({
				where: [
					{
						field: 'userId',
						value: tutorId
					}
				]
			}).then((response: AxiosResponse<Paginated<TutorWork>>) => {
				this.TutorWorks = response.data
			})
			return request
		} catch (error) {
			console.log(error)
		}
	}

	public GetTutorWork = (workId: string) => {
		try {
			$api.users.tutorWorks.get(workId).then((response: AxiosResponse<TutorWork>) => {
				this.TutorWork = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public CreateTutorWork = (formIsValid: boolean) => {
		try {
			if (formIsValid) {
				$api.users.tutorWorks.post(this.CreateWorkForm).then((response: AxiosResponse<TutorWork>) => {
					this.TutorWorks?.results.push(response.data)
					Logic.common.showAlert("success", 'Work added');
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateTutorWork = (formIsValid: boolean, workId: string) => {
		try {
			if (formIsValid) {
				$api.users.tutorWorks.put(workId, this.CreateWorkForm).then((response: AxiosResponse<TutorWork>) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public DeleteTutorWork = (workId: string) => {
		try {
			$api.users.tutorWorks.delete(workId).then((response: AxiosResponse<boolean>) => {
				if (response.data) {
					this.GetTutorWorks(this.Tutor?.id || '')
				}
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetTutorReviews = (tutorId: string) => {
		const request = $api.sessions.reviews.fetch({
			where: [
				{
					field: "to",
					value: tutorId
				}
			]
		}).then((response: AxiosResponse<Paginated<Review>>) => {
			this.TutorReviews = response.data

			this.ResetRatings();

			this.TutorReviews.results.forEach((review) => {
				const ratings = review.rating ? review.rating : 1
				switch (ratings) {
					case 1:
						this.TutorRatingsArray[`${ratings}`].count += 1
						break;
					case 2:
						this.TutorRatingsArray[`${ratings}`].count += 1
						break;
					case 3:
						this.TutorRatingsArray[`${ratings}`].count += 1
						break;
					case 4:
						this.TutorRatingsArray[`${ratings}`].count += 1
						break;
					case 5:
						this.TutorRatingsArray[`${ratings}`].count += 1
						break;
					default:
						break;
				}

			})
		})
		return request;
	}

}