import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!px-24 px-3 lg:!pt-10 mdlg:!pt-10 pt-4" }
const _hoisted_2 = { class: "flex flex-col col-span-12" }
const _hoisted_3 = { class: "w-full flex flex-col pt-3" }
const _hoisted_4 = { class: "w-full flex flex-row space-x-1 items-center justify-center pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_form_wrapper = _resolveComponent("ts-form-wrapper")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ts_header_text, {
      customClass: 'w-full text-center lg:!text-xl',
      size: 'xl'
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Verify your email ")
      ]),
      _: 1
    }),
    _createVNode(_component_ts_form_wrapper, {
      class: "w-full grid grid-cols-12 gap-2",
      parentRefs: _ctx.parentRefs,
      ref: "verifyForm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ts_text_field, {
            placeholder: "Enter 6-digit code",
            "custom-class": "mdlg:px-3 px-2 border-[1px] rounded border-gray-200",
            paddings: "py-3",
            modelValue: _ctx.EmailLogic.VerifyWithTokenForm.token,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.EmailLogic.VerifyWithTokenForm.token) = $event)),
            hasTitle: true,
            name: "Token",
            type: "tel",
            rules: [_ctx.FormValidations.RequiredRule],
            ref: "token"
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Verification code ")
            ]),
            _: 1
          }, 8, ["modelValue", "rules"])
        ])
      ]),
      _: 1
    }, 8, ["parentRefs"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ts_button, {
        customClass: ' w-full',
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.VerifyEmail()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Verify ")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ts_normal_text, { color: 'text-paragraphTextLight' }, {
        default: _withCtx(() => [
          _createTextVNode("Have an account?")
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/auth/login" }, {
        default: _withCtx(() => [
          _createVNode(_component_ts_normal_text, { color: 'text-primaryPurple' }, {
            default: _withCtx(() => [
              _createTextVNode("Log in")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}