<template>
  <div
    class="w-full flex flex-col space-y-2 mdlg:!px-0 mdlg:!py-0 px-3 py-3"
    v-if="Notifications"
  >
    <div
      class="w-full items-start flex flex-col mdlg:!py-5 mdlg:!px-5 px-2 py-3 space-y-1 border-[1px] border-gray-200 rounded"
      v-for="(notification, index) in Notifications.results"
      :key="index"
    >
      <ts-header-text
        :size="'base'"
        :color="'text-primaryPurple'"
        v-if="!notification.seen"
        :customClass="'text-start'"
      >
        {{ notification.title }}
      </ts-header-text>
      <ts-normal-text
        :color="'text-paragraphTextLight'"
        :customClass="'text-start'"
        v-else
      >
        {{ notification.title }}
      </ts-normal-text>
      <ts-normal-text
        :color="'text-paragraphTextLight'"
        :customClass="'text-xs'"
      >
        {{ FrontendLogic.common.timeFromNow(notification.createdAt) }}
      </ts-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import { TsHeaderText, TsNormalText } from "tutorstack-ui-components";
import { composables } from "../../composables";
import { Notification, Paginated } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsHeaderText,
    TsNormalText,
  },
  name: "NotificationIndexPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Notification`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const Notifications = ref<Paginated<Notification>>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Notification`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.notifications.notifications.GetNotifications();
      FrontendLogic.notifications.notifications.watchProperty(
        "Notifications",
        Notifications
      );
    });

    return {
      Notifications,
      FrontendLogic,
    };
  },
});
</script>
