import { createApp } from "vue"
import { createMetaManager } from "vue-meta"
import { createRouter, createWebHistory } from "vue-router"
import App from "./App.vue"
import { composables } from './composables/index'
import AppLayout from "./layouts/AppLayout.vue"
import routes from "./router/routes"

import { onMounted } from 'vue'
import "./assets/app.css"

const routerPromise = Promise.all(routes).then((routes) => {
	const router = createRouter({
		history: createWebHistory(),
		routes,
	});

	router.beforeEach((to, from, next) => {
		const toRouter: any = to
		return composables.logic.common.preFetchRouteData(toRouter, next)
	});

	return router;
});

const init = async () => {
	const router = await routerPromise

	composables.logic.common.SetRouter(router)
	composables.logic.common.SetApiUrl(process.env.VUE_APP_API_URL!)

	createApp({
		components: {
			App,
		},
		setup() {
			onMounted(() => {
				const AuthUser = localStorage.getItem('AuthUser') ? JSON.parse(localStorage.getItem('AuthUser') || '{}') : undefined

				if (AuthUser == undefined) {
					router.push('/auth/login')
				}
			})
		}
	})
		.component("AppLayout", AppLayout)
		.use(router)
		.use(createMetaManager())
		.mount("#app");
};

init();
