<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3"
    v-if="Question"
  >
    <div
      class="flex flex-col space-y-2 border-[1px] border-gray-200 rounded px-3 py-3 mdlg:!px-5 mdlg:!py-5"
    >
      <div class="w-full flex flex-row items-center space-x-2">
        <ts-avatar
          :photoUrl="Question?.user?.bio?.photo?.link || ''"
          :bgColor="'bg-primaryPurple'"
          :size="'24'"
          :customClass="'text-white'"
        >
          {{ Question?.user?.bio?.name.first.charAt(0) }}
        </ts-avatar>
        <ts-header-text :size="'xs'">
          {{ Question?.user?.bio?.name.full }}
        </ts-header-text>
        <span
          :class="`h-[3px] w-[3px] bg-paragraphTextLight rounded-full`"
        ></span>
        <ts-paragraph-text :faded="true" :customClass="'text-xs'">
          {{
            FrontendLogic.questions.subjects.GetSubjectTitle(
              Question?.subjectId || ""
            )
          }}
        </ts-paragraph-text>
        <span
          :class="`h-[3px] w-[3px] bg-paragraphTextLight rounded-full`"
        ></span>
        <ts-paragraph-text :faded="true" :customClass="'text-xs'">
          {{ FrontendLogic.common.timeFromNow(Question.createdAt) }}
        </ts-paragraph-text>
      </div>

      <ts-paragraph-text :customClass="'text-left'">
        {{ Question?.body }}
      </ts-paragraph-text>

      <div
        class="flex flex-row items-start pt-1 space-x-2"
        v-if="TsCommon.accountType.value == 'tutor'"
      >
        <template
          v-if="Question.heldBy == null && Question.answers?.length == 0"
        >
          <ts-button
            :padding="'px-4 py-2'"
            @click.stop="holdQuestion(Question?.id || '')"
          >
            Hold
          </ts-button>
        </template>
        <template
          v-if="Question.heldBy?.userId == FrontendLogic.auth.user.AuthUser?.id"
        >
          <div>
            <ts-file-attachment
              v-model:localFileUrl="localFileUrl"
              :isWrapper="true"
              :accept="'.mp4'"
              v-model="
                FrontendLogic.questions.answers.CreateAnswerForm.attachment
              "
            >
              <template v-slot:content>
                <ts-button
                  :customClass="'border-[1px] border-primaryPurple'"
                  :padding="'px-4 py-2'"
                >
                  Upload video answer
                </ts-button>
              </template>
            </ts-file-attachment>
          </div>

          <ts-button
            :bgColor="'bg-white'"
            :padding="'px-4 py-2'"
            :customClass="'border-[1px] border-primaryPurple'"
            :textColor="'text-primaryPurple'"
            @click.stop="ReleaseQuestion(Question?.id || '')"
          >
            Release
          </ts-button>
        </template>
      </div>
    </div>

    <div
      class="flex flex-col space-y-3 border-[1px] border-gray-200 rounded px-3 py-3 mdlg:!px-5 mdlg:!py-5"
      v-if="(Answers && Answers?.results?.length > 0) || localFileUrl"
    >
      <ts-video
        :customClass="'w-full mdlg:!h-[350px] h-[230px]'"
        :iconSize="'mdlg:!h-[37px] h-[28px]'"
        :videoUrl="
          Answers && Answers?.results?.length > 0
            ? Answers.results[0].attachment?.link
            : localFileUrl
        "
      />
      <div
        class="w-full my-2 flex flex-col space-y-1"
        v-if="
          AnswerUploadPercentage > 0 &&
          FrontendLogic.questions.answers.AnswerLoaders.add
        "
      >
        <div class="w-full flex flex-row items-center justify-between">
          <ts-normal-text> Uploading video... </ts-normal-text>
          <ts-normal-text :custom-class="'font-semibold'">
            {{ AnswerUploadPercentage }}%
          </ts-normal-text>
        </div>
        <div
          class="w-full rounded-3xl border-[1px] h-[10px] border-gray-200 relative"
        >
          <span
            class="rounded-3xl bg-primaryPurple absolute h-full left-0 top-0"
            :style="`width: ${AnswerUploadPercentage}%`"
          >
          </span>
        </div>
      </div>
      <div
        class="w-full flex flex-row items-center space-x-2"
        v-if="Answers && Answers?.results?.length > 0"
      >
        <ts-avatar
          :photoUrl="Answers.results[0].user.bio.photo?.link || ''"
          :size="'24'"
        />

        <ts-header-text :size="'xs'">
          {{ Answers.results[0].user.bio.name.full }}
        </ts-header-text>
        <span
          :class="`h-[3px] w-[3px] bg-paragraphTextLight rounded-full`"
        ></span>
        <ts-paragraph-text :faded="true" :customClass="'text-xs'">
          Answer
        </ts-paragraph-text>
      </div>
      <div
        class="w-full flex flex-row items-center justify-end space-x-2"
        v-else
      >
        <ts-button
          :bgColor="'bg-white'"
          :padding="'px-4 py-2'"
          :customClass="'border-[1px] border-errorColor'"
          :textColor="'text-errorColor'"
          @click.stop="localFileUrl = ''"
        >
          Cancle answer
        </ts-button>

        <ts-button
          :customClass="'border-[1px] border-primaryPurple'"
          :padding="'px-4 py-2'"
          @click="submitAnswer()"
          :loading="FrontendLogic.questions.answers.AnswerLoaders.add"
        >
          Confirm answer
        </ts-button>
      </div>
    </div>

    <ts-discussion :data="messageList" :makeComment="makeComment" />
  </div>

  <ts-modal
    :canClose="true"
    :close="closePrompt"
    v-if="ShowQuestionPrompt && TsCommon.accountType.value != 'tutor'"
  >
    <ts-question-prompt
      :close="closePrompt"
      :remainingQuestions="UserWallet?.subscription.data.questions"
    ></ts-question-prompt>
  </ts-modal>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  TsParagraphText,
  TsHeaderText,
  TsAvatar,
  TsVideo,
  TsDiscussion,
  TsButton,
  TsFileAttachment,
  TsModal,
  TsQuestionPrompt,
  TsNormalText,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import {
  Question,
  Paginated,
  Answer,
  Comment,
  UserWallet,
} from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsParagraphText,
    TsHeaderText,
    TsAvatar,
    TsVideo,
    TsButton,
    TsDiscussion,
    TsFileAttachment,
    TsModal,
    TsQuestionPrompt,
    TsNormalText,
  },
  name: "QuestionInfoPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Question Info`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;
    const Comments = ref<Paginated<Comment>>();

    const Question = ref<Question>();
    const Answers = ref<Paginated<Answer>>();

    const ShowQuestionPrompt = ref(true);

    const AnswerUploadPercentage = ref(0);

    const UserWallet = ref<UserWallet>();

    const localFileUrl = ref("");

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Question`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.pageTopBarOptions.backRoute = "/questions";
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.questions.questions.watchProperty("Question", Question);
      FrontendLogic.questions.answers.watchProperty("Answers", Answers);
      FrontendLogic.interactions.comments.watchProperty("Comments", Comments);
      FrontendLogic.questions.questions.watchProperty(
        "ShowQuestionPrompt",
        ShowQuestionPrompt
      );
      FrontendLogic.questions.answers.watchProperty(
        "AnswerUploadPercentage",
        AnswerUploadPercentage
      );
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
    });

    const messageList = ref<any[]>([]);

    watch(Question, () => {
      if (Question.value) {
        FrontendLogic.interactions.comments.GetComments(
          "questions",
          Question.value.id
        );
      }
    });

    watch(Comments, () => {
      if (Comments.value) {
        messageList.value = [];
        Comments.value.results.forEach((comment) => {
          messageList.value.push({
            username: comment.user.bio.name.full,
            photoUrl: comment.user.bio.photo?.link || "",
            content: comment.body,
          });
        });
      }
    });

    const holdQuestion = (questionId: string) => {
      FrontendLogic.questions.questions.HoldQuestions(questionId);
      FrontendLogic.questions.questions.SortQuestions("held");
    };

    const ReleaseQuestion = (questionId: string) => {
      FrontendLogic.questions.questions.ReleaseQuestions(questionId);
      FrontendLogic.questions.questions.SortQuestions("unheld");
    };

    const submitAnswer = () => {
      FrontendLogic.questions.answers.CreateAnswerForm.questionId =
        Question.value?.id || "";
      FrontendLogic.questions.answers.CreateAnswer(true);
    };

    const makeComment = (body: string) => {
      if (body) {
        (FrontendLogic.interactions.comments.CreateCommentForm.entity.type =
          "questions"),
          (FrontendLogic.interactions.comments.CreateCommentForm.entity.id =
            Question.value?.id || "");
        FrontendLogic.interactions.comments.CreateCommentForm.body = body;

        FrontendLogic.interactions.comments.CreateComment(true);
      }
    };

    const closePrompt = () => {
      FrontendLogic.questions.questions.ShowQuestionPrompt = false;
    };

    return {
      messageList,
      Question,
      FrontendLogic,
      TsCommon,
      holdQuestion,
      ReleaseQuestion,
      localFileUrl,
      submitAnswer,
      Answers,
      makeComment,
      closePrompt,
      ShowQuestionPrompt,
      AnswerUploadPercentage,
      UserWallet,
    };
  },
});
</script>
