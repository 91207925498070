<template>
  <div class="flex w-full flex-col space-y-2">
    <div
      v-for="(option, index) in options"
      :key="index"
      @click.stop="selectedOption = option.key"
      class="flex w-full flex-row items-center justify-between cursor-pointer px-3 py-3 border-[1px] rounded"
    >
      <div class="flex flex-row space-x-2 items-center">
        <ts-icon
          :name="option.iconName"
          :customClass="`h-[14px]`"
          v-if="option.iconName"
        />
        <ts-normal-text v-for="(name, index) in option.name" :key="index">
          <template v-if="index == 0">
            {{ name }}
          </template>
          <template v-else>
            <span
              :class="`h-[4px] mr-2 w-[4px] bg-paragraphTextLight rounded-full`"
            >
            </span>
            {{ name }}
          </template>
        </ts-normal-text>
      </div>
      <ts-icon
        :name="`${option.key == selectedOption ? 'radio-active' : 'radio'}`"
        :customClass="`h-[13px]`"
      />
    </div>
  </div>
</template>
<script lang="ts">
import TsNormalText from "../TsTypography/normalText.vue";
import { onMounted, ref, watch } from "vue";
import TsIcon from "../TsIcon/index.vue";

export default {
  components: {
    TsNormalText,
    TsIcon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: false,
    },
  },
  name: "TsRadioCard",
  emits: ["update:modelValue"],
  setup(props: any, context: any) {
    const selectedOption = ref("");

    watch(selectedOption, () => {
      context.emit("update:modelValue", selectedOption.value);
    });

    onMounted(() => {
      if (props.modelValue) {
        selectedOption.value = props.modelValue;
      }
    });

    return {
      selectedOption,
    };
  },
};
</script>
