import { CancleSessionForm, PayForSessionForm, RateSessionForm } from "../../../logic/types/forms/sessions";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";
import { SessionRecording, SessionTokens, Tutor } from "../../../logic/types/domains";

export default class SessionsApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("sessions/sessions");
	}

	public async PayForSession(sessionId: string, data: PayForSessionForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl(sessionId) + "/pay",
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async GetSessionRecordings(sessionId: string): Promise<AxiosResponse<SessionRecording[]>> {
		try {
			const response: AxiosResponse<SessionRecording[]> = await this.axiosInstance.get(
				this.getUrl(sessionId) + "/details");

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async CancleSession(sessionId: string, data: CancleSessionForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl(sessionId) + "/cancle",
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async CloseSession(sessionId: string): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl(sessionId) + "/close");

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async JoinSession(sessionId: string): Promise<AxiosResponse<SessionTokens>> {
		try {
			const response: AxiosResponse<SessionTokens> = await this.axiosInstance.post(
				this.getUrl(sessionId) + "/join");

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async RateSession(sessionId: string, data: RateSessionForm): Promise<AxiosResponse<Tutor>> {
		try {
			const response: AxiosResponse<Tutor> = await this.axiosInstance.post(
				this.getUrl(sessionId) + "/rate", data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}


}
