<template>
  <div class="flex flex-col w-full col-span-full space-y-2">
    <div
      class="mdlg:!px-3 mdlg:!py-3 px-2 py-2 border-[1px] mdlg:!space-y-0 space-y-1 border-gray-200 rounded flex mdlg:!flex-row flex-col mdlg:!items-center items-start"
    >
      <div class="mdlg:!w-[20%] w-full flex mdlg:!flex-row flex-row justify-between">
        <ts-header-text :size="'base'"> Date </ts-header-text>
        <ts-header-text :size="'base'" :customClass="'inline mdlg:!hidden'">
          Amount
        </ts-header-text>
      </div>
      <div
        class="mdlg:!w-[65%] w-full flex mdlg:!flex-row flex-col mdlg:!justify-between items-start mdlg:!space-y-0 space-y-1"
      >
        <ts-header-text :size="'base'"> Details </ts-header-text>
      </div>

      <div class="mdlg:!w-[15%] w-[50%] flex-row justify-end mdlg:!flex hidden">
        <ts-header-text :size="'base'"> Amount </ts-header-text>
      </div>
    </div>

    <div
      class="mdlg:!px-3 mdlg:!py-3 px-2 py-2 border-[1px] border-gray-200 rounded flex mdlg:!flex-row mdlg:!space-y-0 space-y-1 flex-col mdlg:!items-center items-start"
      v-for="(transaction, index) in data"
      :key="index"
    >
      <div
        class="mdlg:!w-[20%] w-full flex mdlg:!flex-row flex-row mdlg:!justify-start justify-between"
      >
        <ts-paragraph-text :faded="true">
          {{ transaction.date }}
        </ts-paragraph-text>
        <ts-normal-text
          :customClass="'inline-block mdlg:!hidden'"
          :color="`${
            transaction.type == 'credit' ? 'text-primaryGreen' : 'text-errorColor'
          }`"
        >
          {{ transaction.amount }}
        </ts-normal-text>
      </div>
      <div class="mdlg:!w-[65%] w-full flex flex-row space-x-2 items-center">
        <ts-normal-text
          v-for="(name, index) in transaction.content"
          :key="index"
          :color="'text-paragraphTextLight'"
        >
          <template v-if="index == 0">
            {{ name }}
          </template>
          <template v-else>
            <span :class="`h-[4px] mr-2 w-[4px] bg-paragraphTextLight rounded-full`">
            </span>
            {{ name }}
          </template>
        </ts-normal-text>
      </div>
      <div class="mdlg:!w-[15%] w-[10%] mdlg:!flex hidden flex-row justify-end">
        <ts-normal-text
          :color="`${
            transaction.type == 'credit' ? 'text-primaryGreen' : 'text-errorColor'
          }`"
        >
          {{ transaction.amount }}
        </ts-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsParagraphText from "../TsTypography/paragraphText.vue";
import TsNormalText from "../TsTypography/normalText.vue";

export default {
  components: {
    TsHeaderText,
    TsParagraphText,
    TsNormalText,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  name: "TsTransactionHistory",
};
</script>
