<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full flex flex-col space-y-2 items-start" v-if="UserWallet">
      <ts-normal-text> Current plan </ts-normal-text>
      <div
        class="w-full flex flex-row justify-start border-[1px] border-gray-200 rounded py-2 px-3"
      >
        <ts-normal-text
          color="text-paragraphTextLight"
          v-if="!UserWallet.subscription.active"
        >
          No active plan
        </ts-normal-text>
        <div
          v-else
          class="w-full flex mdlg:!flex-row flex-col mdlg:!space-y-0 space-y-2 items-start mdlg:!items-center mdlg:!justify-between"
        >
          <div class="flex flex-col">
            <ts-header-text custom-class="capitalize">
              {{ currentPlan?.name }} (${{ currentPlan?.amount }}/{{
                currentPlan?.interval
              }})
            </ts-header-text>
            <ts-normal-text
              color="text-paragraphTextLight"
              custom-class="!text-[11px]"
            >
              Expires by
              {{
                FrontendLogic.common.fomartDate(
                  UserWallet?.subscription.current.expiredAt || 0,
                  "hh:mm"
                )
              }}
              on
              {{
                FrontendLogic.common.fomartDate(
                  UserWallet?.subscription.current.expiredAt || 0,
                  "DD/MM/YYYY"
                )
              }}
            </ts-normal-text>
          </div>
          <ts-button
            :bg-color="'bg-errorColor'"
            :loading="FrontendLogic.payments.wallet.WalletLoaders.cancle"
            custom-class="font-semibold"
            @click="CanclePlan()"
            v-if="UserWallet.subscription.next"
          >
            Cancle renewal
          </ts-button>
          <ts-button
            custom-class="font-semibold"
            :loading="FrontendLogic.payments.wallet.WalletLoaders.subscribe"
            v-else
            @click="SubscribeToPlan(currentPlan?.id || '')"
          >
            Activate renewal
          </ts-button>
        </div>
      </div>
      <router-link to="/profile/plans" v-if="!UserWallet.subscription.active">
        <ts-normal-text
          custom-class="flex flex-row space-x-2 items-center !text-primaryPurple pt-1 cursor-pointer"
        >
          <span>See available plans </span>
          <ts-icon :name="'right-arrow'" custom-class="h-[12px]" />
        </ts-normal-text>
      </router-link>
      <div class="flex flex-col space-y-1 pt-2 items-start w-full" v-else>
        <div class="flex flex-row space-x-2 items-center">
          <span
            class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
          ></span>
          <ts-paragraph-text>
            Ask {{ UserWallet?.subscription.data.questions }} questions and get
            video answers
          </ts-paragraph-text>
        </div>
        <div class="flex flex-row space-x-2 items-center">
          <span
            class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
          ></span>
          <ts-paragraph-text> Watch previous sessions </ts-paragraph-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  TsIcon,
  TsButton,
  TsNormalText,
  TsHeaderText,
  TsParagraphText,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { Plan, UserWallet } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsNormalText,
    TsIcon,
    TsHeaderText,
    TsButton,
    TsParagraphText,
  },
  name: "SubscriptionPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Subscriptions`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;
    const UserWallet = ref<UserWallet>();
    const currentPlan = ref<Plan>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Subscription`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
    });

    watch(UserWallet, () => {
      if (UserWallet.value) {
        currentPlan.value = FrontendLogic.payments.wallet.GetPlan(
          UserWallet.value?.subscription.current.id || ""
        );
      }
    });

    const SubscribeToPlan = (planId: string) => {
      FrontendLogic.payments.wallet.SubscribeToPlan(planId);
    };

    const CanclePlan = () => {
      FrontendLogic.payments.wallet.CancelPlan();
    };

    return {
      FrontendLogic,
      UserWallet,
      currentPlan,
      SubscribeToPlan,
      CanclePlan,
    };
  },
});
</script>
