import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start space-y-2 border-[1px] border-gray-200 rounded px-3 py-3 mdlg:!px-5 mdlg:!py-5" }
const _hoisted_2 = { class: "flex flex-col space-y-[1px] flex-grow" }
const _hoisted_3 = { class: "w-full flex flex-row space-x-2 pt-2 items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_avatar = _resolveComponent("ts-avatar")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ts_header_text, { customClass: 'pb-2' }, {
      default: _withCtx(() => [
        _createTextVNode(" Discussion ")
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (message, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "w-full flex flex-row space-x-2 items-start",
        key: index
      }, [
        _createVNode(_component_ts_avatar, {
          photoUrl: message.photoUrl,
          size: '32',
          bgColor: 'bg-primaryPurple',
          customClass: 'text-white min-w-[32.5px]'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(message.photoUrl ? "" : message.username.charAt(0)), 1)
          ]),
          _: 2
        }, 1032, ["photoUrl"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ts_header_text, {
            size: "xs",
            customClass: 'text-left'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(message.username), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_ts_normal_text, { customClass: ' text-left' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(message.content), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ts_text_field, {
        placeholder: 'Write a message',
        customClass: 'border-[1px] w-full',
        modelValue: _ctx.body,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.body) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_ts_button, {
        padding: 'px-4 py-3',
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendComment()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ts_icon, {
            name: 'send',
            customClass: 'h-[20px]'
          })
        ]),
        _: 1
      })
    ])
  ]))
}