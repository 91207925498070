import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_2 = { class: "flex flex-col space-y-1 items-center justify-center pt-2" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-1 mt-2" }
const _hoisted_4 = { class: "w-full flex flex-row items-center justify-between py-3 px-3 border-[1px] border-gray-200 rounded" }
const _hoisted_5 = { class: "w-full py-3 grid grid-cols-12 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_avatar = _resolveComponent("ts-avatar")!
  const _component_ts_ratings = _resolveComponent("ts-ratings")!
  const _component_ts_text_area = _resolveComponent("ts-text-area")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _withModifiers(($event: any) => (true), ["stop"]),
    class: "rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ts_header_text, {
        size: 'lg',
        customClass: ' w-full text-center'
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Before you leave ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_normal_text, {
        customClass: "text-center",
        color: "text-paragraphTextLight"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Review your tutor ")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ts_avatar, {
        "photo-url": `${$props.tutor?.bio.photo?.link}`,
        size: "70",
        "custom-class": "flex",
        bgColor: 'bg-primaryPurple'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.tutor?.bio.name.first.charAt(0)), 1)
        ]),
        _: 1
      }, 8, ["photo-url"]),
      _createVNode(_component_ts_normal_text, { customClass: "text-center" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.tutor?.bio.name.full), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ts_normal_text, { customClass: "text-center" }, {
          default: _withCtx(() => [
            _createTextVNode(" Tap star to rate ")
          ]),
          _: 1
        }),
        _createVNode(_component_ts_ratings, {
          readonly: false,
          modelValue: $setup.ratings,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.ratings) = $event)),
          count: 0,
          "custom-class": "cursor-pointer",
          size: "h-[13px] mdlg:!h-[17px]"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_ts_text_area, {
        placeholder: "Write your review",
        paddings: "py-3",
        hasTitle: false,
        modelValue: $setup.review,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.review) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ts_button, {
        customClass: 'border-[1px] border-primaryPurple !text-primaryPurple col-span-6',
        padding: 'px-4 py-3',
        "bg-color": 'bg-white',
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => {
          $setup.Logic.common.GoToRoute(
            '/sessions/' + $setup.Logic.sessions.sessions.Session?.id
          );
          $props.close ? $props.close() : '';
        }, ["stop"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Leave ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_button, {
        customClass: 'border-[1px] border-primaryPurple col-span-6',
        padding: 'px-4 py-3',
        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($props.confirm ? $props.confirm() : ''), ["stop"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Submit review ")
        ]),
        _: 1
      })
    ])
  ]))
}