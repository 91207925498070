import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row items-center mdlg:!py-5 mdlg:!px-5 px-2 py-3 gap-2 border-[1px] border-gray-200 rounded" }
const _hoisted_2 = { class: "flex flex-col space-y-2 items-center h-full justify-center mdlg:!px-8 px-4 py-4 w-1/3" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col space-y-3 flex-grow mdlg:!px-4 px-2 w-2/3 border-l-[1px] border-gray-200"
}
const _hoisted_4 = { class: "flex-grow flex relative rounded mdlg:!h-[6px] h-[6px] bg-slate-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_ratings = _resolveComponent("ts-ratings")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ts_header_text, { size: 'lg' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.Logic.users.tutors.Tutor?.ratings.avg.toFixed(1)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ts_ratings, { size: 'mdlg:!h-[18px] h-[14px]' }),
      _createVNode(_component_ts_normal_text, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.Logic.users.tutors.Tutor?.ratings.count) + " reviews ", 1)
        ]),
        _: 1
      })
    ]),
    ($setup.Logic.users.tutors.Tutor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.Logic.users.tutors.TutorRatingsArray, (rating, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "flex flex-row items-center mdlg:!space-x-4 space-x-2",
              key: index
            }, [
              _createVNode(_component_ts_normal_text, {
                customClass: `text-xs ${
            rating.count / $setup.Logic.users.tutors.Tutor.ratings.count <= 0
              ? 'text-paragraphTextLight'
              : ''
          }`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(rating.name), 1)
                ]),
                _: 2
              }, 1032, ["customClass"]),
              _createElementVNode("span", _hoisted_4, [
                _createElementVNode("span", {
                  class: "absolute h-full rounded bg-primaryYellow",
                  style: _normalizeStyle(`width: ${
              (rating.count / $setup.Logic.users.tutors.Tutor.ratings.count) * 100
            }%;`)
                }, null, 4)
              ]),
              _createVNode(_component_ts_normal_text, {
                customClass: `text-xs ${
            rating.count / $setup.Logic.users.tutors.Tutor.ratings.count
              ? 'text-paragraphTextLight'
              : ''
          }`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" (" + _toDisplayString(rating.count) + ") ", 1)
                ]),
                _: 2
              }, 1032, ["customClass"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}