<template>
  <ts-image-loader
    :photo-url="photoUrl"
    :custom-class="`${bgColor} rounded-full flex text-xs font-semibold flex-row items-center justify-center ${customClass} `"
    :custom-style="`width: ${size}px; height: ${size}px;`"
  >
    <template v-if="photoUrl == ''">
      <slot />
    </template>
  </ts-image-loader>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TsImageLoader from "../TsImageLoader/index.vue";

export default defineComponent({
  components: {
    TsImageLoader,
  },
  props: {
    size: {
      type: String,
      default: "50",
    },
    photoUrl: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
    },
  },
  name: "TsAvatar",
});
</script>
