<template>
  <div
    class="flex flex-col items-center py-2 z-50 w-full justify-center mdlg:hidden px-3 fixed left-0 bottom-0 bg-white border-t-[1px] border-gray-200"
  >
    <template v-if="!options.subPage">
      <div
        :class="`w-full grid ${
          tabs.length == 5 ? 'grid-cols-10' : 'grid-cols-8'
        }  gap-1 py-2`"
      >
        <router-link
          v-for="(tab, index) in tabs"
          :key="index"
          :to="tab.path"
          class="col-span-2 flex flex-row items-center justify-center"
        >
          <ts-icon
            :name="
              hoverTab == tab.icon || tabIsActive(tab.routeTag)
                ? `${tab.icon}_active`
                : tab.icon
            "
            :custom-class="` ${
              tab.icon_size ? tab.icon_size : 'lg:h-[19px] mdlg:h-[17px]'
            } `"
          />
        </router-link>
      </div>
    </template>
    <template v-else>
      <div class="w-full flex flex-row items-center space-x-2 justify-end">
        <ts-button
          v-for="(action, index) in options.actions"
          :key="index"
          @click="action.action()"
          :customClass="`${
            action.outlined ? 'border-[2px]  border-primaryPurple' : ''
          }`"
          :bgColor="`${action.outlined ? 'bg-white' : 'bg-primaryPurple'}`"
          :padding="'px-4 py-2'"
        >
          {{ action.label }}
        </ts-button>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import TsIcon from "../TsIcon/index.vue";
import TsButton from "../TsButton/index.vue";
import { ref, defineComponent, watch, onMounted } from "vue";
import { Logic } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsIcon,
    TsButton,
  },
  props: {
    tabIsActive: {
      type: Function,
      required: true,
    },
    options: {
      required: false,
    },
    accountType: {
      type: String,
      default: "student",
    },
  },
  name: "TsBottomBar",
  setup(props: any) {
    const tabs = ref<any[]>([]);

    const setRoutes = () => {
      tabs.value = [];
      tabs.value.push({
        path: "/",
        icon: "home",
        routeTag: "base",
      });
      if (props.accountType == "student") {
        tabs.value.push({
          path: "/tutors",
          icon: "tutors",
          routeTag: "tutors",
        });
      }

      tabs.value.push(
        {
          path: "/sessions",
          icon: "sessions",
          routeTag: "sessions",
          icon_size: "h-[17px]",
        },
        {
          path: "/questions",
          icon: "questions",
          routeTag: "questions",
        },
        {
          path:
            props.accountType == "student"
              ? "/profile"
              : "/tutors/" + Logic.auth.user.AuthUser?.id,
          icon: "profile",
          routeTag: "profile",
        }
      );
    };

    const hoverTab = ref("");

    watch(props, () => {
      setRoutes();
    });

    onMounted(() => {
      setRoutes();
    });

    return {
      hoverTab,
      tabs,
    };
  },
});
</script>
