import EmailApi from "./Emails";
import IdentitiesApi from "./Identities";
import PassowrdApi from "./Passwords";
import TokenApi from "./Token";
import UserAPI from "./User";

export const Auth = {
	user: new UserAPI(),
	emails: new EmailApi(),
	token: new TokenApi(),
	passwords: new PassowrdApi(),
	identities: new IdentitiesApi
}