import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center py-2 z-50 w-full justify-center mdlg:hidden px-3 fixed left-0 bottom-0 bg-white border-t-[1px] border-gray-200" }
const _hoisted_2 = {
  key: 1,
  class: "w-full flex flex-row items-center space-x-2 justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.options.subPage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`w-full grid ${
          _ctx.tabs.length == 5 ? 'grid-cols-10' : 'grid-cols-8'
        }  gap-1 py-2`)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: index,
              to: tab.path,
              class: "col-span-2 flex flex-row items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ts_icon, {
                  name: 
              _ctx.hoverTab == tab.icon || _ctx.tabIsActive(tab.routeTag)
                ? `${tab.icon}_active`
                : tab.icon
            ,
                  "custom-class": ` ${
              tab.icon_size ? tab.icon_size : 'lg:h-[19px] mdlg:h-[17px]'
            } `
                }, null, 8, ["name", "custom-class"])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.actions, (action, index) => {
            return (_openBlock(), _createBlock(_component_ts_button, {
              key: index,
              onClick: ($event: any) => (action.action()),
              customClass: `${
            action.outlined ? 'border-[2px]  border-primaryPurple' : ''
          }`,
              bgColor: `${action.outlined ? 'bg-white' : 'bg-primaryPurple'}`,
              padding: 'px-4 py-2'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(action.label), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "customClass", "bgColor"]))
          }), 128))
        ]))
  ]))
}