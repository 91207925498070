import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full col-span-full flex justify-between flex-row items-center border-b-[1px] mdlg:!border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-r-0 border-l-0 border-gray-200 mdlg:rounded-t-[4px] mdlg:rounded-b-[0px]" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.stepItems, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => ($setup.selectedStepValue = step.key),
        class: _normalizeClass(`py-3 flex flex-row w-full items-center cursor-pointer justify-center space-x-2 ${
        $props.selectedStep == step.key ? 'border-b-[1px] border-primaryPurple' : ''
      }  `)
      }, [
        _createVNode(_component_ts_normal_text, {
          color: `${
          $setup.selectedStepValue == step.key
            ? 'text-primaryPurple '
            : 'text-paragraphTextLight'
        }`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(index + 1), 1)
          ]),
          _: 2
        }, 1032, ["color"]),
        _createElementVNode("span", {
          class: _normalizeClass(`h-[4px] w-[4px] rounded-full ${
          $setup.selectedStepValue == step.key
            ? 'bg-primaryPurple '
            : 'bg-paragraphTextLight'
        } hidden md:!inline-block`)
        }, null, 2),
        _createVNode(_component_ts_normal_text, {
          color: `${
          $setup.selectedStepValue == step.key
            ? 'text-primaryPurple '
            : 'text-paragraphTextLight'
        }`,
          customClass: 'hidden md:!inline-block'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(step.value), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}