import { ResetPasswordForm, UpdatePasswordForm } from '../../types/forms/auth';
import { SendResetMailForm } from "../../types/forms";
import { $api } from "../../../services";
import { reactive } from 'vue';
import { Logic } from '..';

export default class Password {
	constructor() {
		// initiate things here
	}

	public PasswordResetForm: SendResetMailForm = {
		email: '',

	}

	public ResetPasswordForm: ResetPasswordForm = {
		token: '',
		password: ''
	}

	public UpdatePasswordForm: UpdatePasswordForm = {
		oldPassword: '',
		password: ''
	}

	public PasswordLoaders = reactive({
		sendResetPassword: false,
		resetPassword: false,
	})

	public SendPasswordReset = (formIsValid: boolean, formComp: any) => {
		this.PasswordLoaders.sendResetPassword = true
		Logic.common.showLoader()
		if (formIsValid) {
			$api.auth.passwords.SendResetPassword(this.PasswordResetForm).then((response) => {
				if (response) {
					this.PasswordLoaders.sendResetPassword = false
					Logic.common.hideLoader()
					Logic.common.router?.push('/auth/reset-password')
					Logic.common.showAlert("success", "Password reset code sent")
				}
			}).catch((error) => {
				Logic.common.showValidationError(error.response.data, formComp)
				this.PasswordLoaders.sendResetPassword = false
				Logic.common.hideLoader()
			})
		}

	}

	public ResetPasswordToken = (formIsValid: boolean, formComp: any) => {
		this.PasswordLoaders.resetPassword = true
		Logic.common.showLoader()
		if (formIsValid) {
			$api.auth.passwords.ResetPassword(this.ResetPasswordForm).then((response) => {
				if (response) {
					this.PasswordLoaders.resetPassword = false
					Logic.common.hideLoader()
					Logic.common.router?.push('/auth/login')
					Logic.common.showAlert("success", "Your password has been reset")
				}
			}).catch((error) => {
				Logic.common.showValidationError(error.response.data, formComp)
				this.PasswordLoaders.resetPassword = false
				Logic.common.hideLoader()
			})
		}

	}

	public UpdatePassword = (formIsValid: boolean) => {
		try {
			if (formIsValid) {
				$api.auth.passwords.UpdatePassword(this.UpdatePasswordForm).then((response) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}


}