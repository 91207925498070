<template>
  <div
    :class="`flex w-full  py-0 z-[5699] justify-between mdlg:px-0 px-0 sticky mdlg:pt-2 top-0 bg-white border-b-[1px]
	${
    options.type == 'basic' || !showMobileSearch
      ? ' flex-row items-center '
      : 'mdlg:!flex-row lg:!flex-row flex-col mdlg:!items-center lg:!items-center '
  } border-gray-200
  ${!options.showBottomBorder ? 'mdlg:border-none' : 'mdlg:border-b-[1px]'}
  ${
    options.type != 'basic' && options.type != 'subpage'
      ? 'px-0'
      : 'mdlg:!px-0 px-3'
  }
  ${options.type == 'subpage' ? 'mdlg:!border-none' : ''}`"
  >
    <ts-header-text
      size="xl"
      :custom-class="` py-3 lg:py-3 mdlg:py-4  flex flex-row items-center lg:pt-6 ${
        options.type == 'subpage' || options.showTitle ? '' : 'mdlg:hidden'
      } ${showMobileSearch || searchFocused ? '!hidden' : '!w-full'}`"
      v-if="options.type == 'basic' || options.type == 'subpage'"
    >
      <template v-if="!searchFocused">
        <ts-icon
          v-if="options.type == 'subpage'"
          :name="'chevron-left'"
          @click="goBack()"
          custom-class="h-[14px] pr-[10px] cursor-pointer"
        />
        {{ options.pageTitle }}
      </template>
    </ts-header-text>
    <div
      v-else
      :class="` ${
        showMobileSearch
          ? ' border-b-[1px]  border-gray-200 mdlg:!border-none'
          : ''
      } flex flex-row items-center space-x-3 mdlg:!space-x-5 mdlg:!px-0 `"
    >
      <template v-if="!searchFocused">
        <ts-header-text
          size="xl"
          v-for="(item, index) in options.switchItems"
          :key="index"
          @click.stop="selectedTab = item.key"
          :custom-class="` mdlg:border-b-[3px] border-b-[1.5px] px-3 mdlg:px-0 cursor-pointer ${
            selectedTab == item.key
              ? ' border-headerText '
              : 'border-transparent '
          }   py-3 lg:!pb-2 mdlg:py-3 lg:pt-6`"
          :color="`${
            selectedTab == item.key
              ? ' text-headerText'
              : 'text-headerText opacity-40'
          }`"
        >
          {{ item.value }}
        </ts-header-text>
      </template>
    </div>
    <div
      v-if="options.type != 'subpage'"
      :class="`hidden  items-center space-x-3 flex-grow mdlg:flex py-3   lg:pt-6 lg:py-3 mdlg:py-5 ${
        options.type == 'basic' && !options.showTitle
          ? 'flex-row'
          : 'flex-row-reverse'
      }`"
    >
      <ts-icon name="search" custom-class="h-[17px]" v-if="!searchFocused" />
      <div
        :class="`flex flex-row space-x-1 px-2 py-2 ${
          searchFocused ? 'w-full' : 'w-[200px]'
        } ${
          options.type != 'basic'
            ? ' border-primaryPurple'
            : 'border-transparent'
        } ${searchFocused ? 'border-[2px]' : 'border-none'} rounded`"
      >
        <input
          type="text"
          class="w-full focus:outline-none placeholder-slate-600"
          placeholder="Search"
          @focus="searchFocused = true"
          @blur="
            searchFocused = false;
            searchValue = '';
          "
          v-model="searchValue"
        />
        <ts-icon
          name="close-search"
          custom-class="h-[17px]"
          @click.stop="searchValue = ''"
          v-if="searchFocused"
        />
      </div>
    </div>
    <div
      v-if="options.type != 'subpage'"
      :class="` ${
        options.type == 'basic' ? '' : 'px-3 mdlg:!px-0'
      } space-x-5 flex mdlg:!hidden flex-row items-center py-3 lg:py-3 lg:pt-6 mdlg:py-5 ${
        searchFocused ? 'w-full' : 'w-auto'
      }`"
    >
      <div
        :class="`flex mdlg:!hidden flex-row space-x-1 ${
          searchFocused ? 'w-full px-2 py-1 ' : 'w-auto'
        } border-primaryPurple ${
          searchFocused ? 'border-[2px]' : 'border-none'
        } rounded`"
      >
        <input
          type="text"
          class="w-full focus:outline-none placeholder-slate-600"
          placeholder="Search"
          v-if="showMobileSearch"
          @focus="
            searchFocused = true;
            showMobileSearch = true;
          "
          @blur="
            searchFocused = false;
            searchValue = '';
            showMobileSearch = false;
          "
          v-model="searchValue"
        />
        <ts-icon
          name="close-search"
          custom-class="h-[17px]"
          @click.stop="
            searchValue = '';
            showMobileSearch = false;
            searchFocused = false;
          "
          v-if="searchFocused"
        />
        <ts-icon
          name="search"
          @click="
            searchFocused = true;
            showMobileSearch = true;
          "
          v-if="!searchFocused"
          custom-class="mdlg:!h-[20px] h-[19px] inline-block mdlg:hidden"
        />
      </div>
      <ts-icon
        name="bell"
        v-if="options.hasNotification"
        @click="goToRoute('/notifications')"
        custom-class="h-[22px] cursor-pointer"
      />
    </div>
    <ts-icon
      name="bell"
      v-if="options.hasNotification && !searchFocused"
      @click="goToRoute('/notifications')"
      custom-class="mdlg:!h-[22px] h-[22px] cursor-pointer hidden mdlg:!inline-block"
    />
  </div>
</template>
<script lang="ts">
import TsIcon from "../TsIcon/index.vue";
import TsHeaderText from "../TsTypography/headerText.vue";
import { ref, onMounted, watch } from "vue";
import { Logic } from "tutorstack-frontend-logic";

export default {
  components: {
    TsIcon,
    TsHeaderText,
  },
  props: {
    options: {
      required: true,
    },
    goBack: {
      type: Function,
      required: true,
    },
    goToRoute: {
      type: Function,
      required: true,
    },
    selectedTab: {
      required: false,
    },
  },
  emits: ["update:selectedTab", "onSearch"],
  name: "TsTopBar",
  setup(props: any, context: any) {
    const selectedTab = ref("");
    const showMobileSearch = ref(false);

    const updateValue = ref("");

    const searchValue = ref("");

    const selectDefaultTab = () => {
      if (props.selectedTab) {
        selectedTab.value = props.selectedTab;
      } else {
        if (props.options.type == "switch") {
          if (props.options.switchItems) {
            selectedTab.value = props.options.switchItems[0]?.key;
          }
        }
      }
    };

    const searchFocused = ref(false);

    const loadingState = ref(false);

    onMounted(() => {
      selectDefaultTab();
      Logic.common.watchProperty("loadingState", loadingState);
      Logic.common.watchProperty("selectedTopBarOption", updateValue);
    });

    watch(props, () => {
      selectDefaultTab();
    });

    watch(updateValue, () => {
      selectedTab.value = updateValue.value;
    });

    watch(selectedTab, () => {
      context.emit("update:selectedTab", selectedTab.value);
    });

    watch(searchValue, () => {
      Logic.common.debounce(() => {
        const searchParams = {
          search: {
            value: searchValue.value,
            fields: [
              "bio.name.first",
              "bio.name.last",
              "bio.email",
              "bio.description",
            ],
          },
        };

        if (props.options.type != "basic") {
          Logic.users.tutors.GetTutors(searchParams);
        } else {
          Logic.users.users.GetSavedTutors(searchParams);
        }
      }, 500);
    });

    return {
      selectedTab,
      showMobileSearch,
      loadingState,
      searchFocused,
      searchValue,
    };
  },
};
</script>
