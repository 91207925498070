import { Auth } from "./Auth";
import { Interactions } from "./Interactions";
import { Notifications } from "./Notifications";
import { Payment } from "./Payment";
import { Questions } from "./Questions";
import { Sessions } from "./Sessions";
import { Users } from "./Users";

export const $api = {
	auth: Auth,
	notifications: Notifications,
	payments: Payment,
	questions: Questions,
	sessions: Sessions,
	users: Users,
	interactions: Interactions
};
