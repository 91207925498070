<template>
  <div class="w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3">
    <div class="w-full grid-cols-12 grid gap-3">
      <ts-icon-card :iconName="''">
        <template v-slot:name>
          <ts-normal-text> Push notifications </ts-normal-text>
        </template>
        <template v-slot:extra>
          <ts-icon
            :name="'switch-on'"
            :customClass="'mdlg:h-[17px] h-[15px]'"
          />
        </template>
      </ts-icon-card>

      <ts-icon-card :iconName="''">
        <template v-slot:name>
          <ts-normal-text> Sound </ts-normal-text>
        </template>
        <template v-slot:extra>
          <ts-icon
            :name="'switch-off'"
            :customClass="'mdlg:h-[17px] h-[15px]'"
          />
        </template>
      </ts-icon-card>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useMeta } from "vue-meta";
import { TsIconCard, TsNormalText, TsIcon } from "tutorstack-ui-components";
import { composables } from "../../composables";

export default defineComponent({
  components: {
    TsIconCard,
    TsNormalText,
    TsIcon,
  },
  name: "NotificationPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Manage notifications`,
    });

    const TsCommon = composables.common;

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Manage notifications`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
    });
  },
});
</script>
