<template>
  <div
    :class="`w-full col-span-full flex flex-col space-y-2 items-center justify-center ${customClass} `"
  >
    <img :src="avatarUrl" :class="avatarSize" />
    <ts-header-text>
      <slot name="title" />
    </ts-header-text>

    <ts-normal-text :color="'text-paragraphTextLight'" :customClass="'px-3'">
      <slot name="subtext" />
    </ts-normal-text>

    <router-link :to="ctaUrl" v-if="accountType == 'student'">
      <ts-button
        :customClass="'border-[1px] border-paragraphTextLight'"
        :bgColor="'bg-white'"
        :textColor="'text-paragraphTextLight'"
        v-if="ctaUrl"
      >
        <slot name="cta-text" />
      </ts-button>
    </router-link>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsButton from "../TsButton";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    TsHeaderText,
    TsNormalText,
    TsButton,
  },
  props: {
    avatarSize: {
      type: String,
      default: "h-[40px]",
    },
    avatarUrl: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    ctaUrl: {
      type: String,
      default: "",
    },
    accountType: {
      type: String,
      default: "student",
    },
  },
  name: "TsEmptyState",
});
</script>
