<template>
  <div class="w-full flex space-y-2 flex-col mdlg:!py-1 mdlg:px-0 px-3 py-3">
    <div
      class="w-full flex flex-row items-center justify-between space-x-2 overflow-x-auto pb-1"
    >
      <ts-button
        v-for="(day, index) in Next14Days"
        :key="index"
        :bgColor="`${
          selectedDay == day.date ? 'bg-primaryPurple' : 'bg-white'
        }`"
        :textColor="`${
          selectedDay == day.date ? 'text-white' : 'text-paragraphTextLight'
        }`"
        :padding="'mdlg:!py-2 mdlg:!px-5 px-4 py-2'"
        :customClass="` ${
          selectedDay == day.date
            ? 'border-primaryPurple border-[1px]'
            : 'border-gray-200 border-[1px]'
        }`"
        @click="
          selectedDay = day.date;
          selectedTime.length = 0;
          checkExistingAvailibility();
        "
      >
        <ts-normal-text>
          {{ day.name }}
        </ts-normal-text>
      </ts-button>
    </div>
    <div class="w-full flex flex-col space-y-2 pt-1">
      <ts-checkbox
        :options="timeOptions"
        @options-selected="handleSelect"
        :hasSelectAll="true"
        v-model="selectedTime"
        :existingOptions="existingTime"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { TsButton, TsNormalText, TsCheckbox } from "tutorstack-ui-components";
import { composables } from "../../composables";
import { TutorAvailability } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsButton,
    TsNormalText,
    TsCheckbox,
  },
  name: "SetAvailabilityPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Set availability`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;
    const TutorAvailability = ref<TutorAvailability>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Set availability`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.sessions.sessions.GetTutorAvailability(
        FrontendLogic.auth.user.AuthUser?.id || ""
      );
      FrontendLogic.sessions.sessions.watchProperty(
        "TutorAvailability",
        TutorAvailability
      );
    });

    const selectedDay = ref("");
    const selectedTime = ref<any[]>([]);

    const existingTime = ref<any[]>([]);

    const Next14Days: any[] = FrontendLogic.common.getDaysFromToday(14);

    selectedDay.value = Next14Days[0].date;

    const timeOptions: any[] = [];

    for (let index = 0; index < Array.from(Array(24).keys()).length; index++) {
      const getTimeLabel = (index: number) => {
        if (index == 0) {
          return "Midnight";
        }

        if (index == 12) {
          return "Noon";
        }

        const timeIndex = index % 12;

        const timeSuffix = index < 12 ? "am" : "pm";

        const timeString = `${
          timeIndex.toString().length == 1 ? `0${timeIndex}` : timeIndex
        }:00 ${timeSuffix}`;

        return timeString;
      };
      const eachTime = {
        name: [getTimeLabel(index)],
        key: `${index.toString().length == 1 ? `0${index}` : index}:00`,
      };
      timeOptions.push(eachTime);
    }

    const setAvailability = (time: string, add: boolean) => {
      FrontendLogic.sessions.sessions.TutorAvailabilityForm.time =
        FrontendLogic.common.dateToMilliseconds(`${selectedDay.value} ${time}`);
      FrontendLogic.sessions.sessions.TutorAvailabilityForm.add = add;
      FrontendLogic.sessions.sessions.UpdateTutorAvailability();
    };

    const setTimeAvailability = () => {
      if (selectedTime.value.length > 0) {
        timeOptions.forEach((time) => {
          const timeIsSelected =
            selectedTime.value.filter((value) => value == time.key).length > 0;
          setAvailability(time.key, timeIsSelected);
        });
      }
    };

    const handleSelect = (selectedOptions: any) => {
      selectedTime.value = selectedOptions;
      setTimeAvailability();
    };

    const checkExistingAvailibility = () => {
      if (TutorAvailability.value) {
        existingTime.value = [];
        TutorAvailability.value?.free.forEach((datetime) => {
          const day = FrontendLogic.common.fomartDate(datetime, "YYYY-MM-DD");
          if (day == selectedDay.value) {
            existingTime.value.push(
              FrontendLogic.common.fomartDate(datetime, "HH:mm")
            );
          }
        });
      }
    };

    watch(TutorAvailability, () => {
      checkExistingAvailibility();
    });

    return {
      Next14Days,
      selectedDay,
      timeOptions,
      selectedTime,
      handleSelect,
      TutorAvailability,
      checkExistingAvailibility,
      existingTime,
    };
  },
});
</script>
