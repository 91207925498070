<template>
  <div class="w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:!px-0 px-3 py-3">
    <template v-if="AvailablePlans">
      <div
        v-for="(plan, index) in AvailablePlans.results"
        :key="index"
        class="w-full flex flex-col items-center py-3 mdlg:!my-5 justify-center border-[1px] border-gray-200 rounded"
      >
        <ts-normal-text color="text-headerText font-semibold">
          {{ plan.name }}
        </ts-normal-text>

        <ts-header-text custom-class="!text-[28px] mdlg:!text-[30px]"
          >${{ plan.amount }}</ts-header-text
        >
        <ts-normal-text color="text-paragraphTextLight ">
          {{ plan.interval }}
        </ts-normal-text>

        <div class="flex flex-col space-y-1 pt-2 justify-center items-center">
          <div class="flex flex-row space-x-2 items-center">
            <span
              class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
            ></span>
            <ts-paragraph-text>
              Ask {{ plan.data.questions }} questions and get video answers
            </ts-paragraph-text>
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <span
              class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
            ></span>
            <ts-paragraph-text> Watch previous sessions </ts-paragraph-text>
          </div>
        </div>

        <ts-button
          custom-class="mt-3"
          @click="SubscribeToPlan(plan.id)"
          :loading="FrontendLogic.payments.wallet.WalletLoaders.subscribe"
        >
          Get started
        </ts-button>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsButton,
  TsNormalText,
  TsHeaderText,
  TsParagraphText,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { Paginated, Plan } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsNormalText,
    TsHeaderText,
    TsParagraphText,
    TsButton,
  },
  name: "SubscriptionPlansPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Available plans`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const AvailablePlans = ref<Paginated<Plan>>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Available plans`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.payments.wallet.watchProperty(
        "AvailablePlans",
        AvailablePlans
      );
    });

    const SubscribeToPlan = (planId: string) => {
      FrontendLogic.payments.wallet.SubscribeToPlan(planId);
    };

    return {
      FrontendLogic,
      AvailablePlans,
      SubscribeToPlan,
    };
  },
});
</script>
