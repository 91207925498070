import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-primaryPurple"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(` 
	${_ctx.customClass} 
	${_ctx.faded ? 'text-paragraphTextLight' : 'text-paragraphText'}`)
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.hasReadmore)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Read more"))
      : _createCommentVNode("", true)
  ], 2))
}