import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(` flex flex-row items-center
  ${_ctx.customClass} ${_ctx.color}
  ${_ctx.size == 'base' ? 'lg:text-sm mdlg:text-[12px] text-xs' : ''}
  ${_ctx.size == 'small' ? ' text-xs' : ''}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}