import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "flex flex-row w-full" }
const _hoisted_3 = { class: "rounded absolute top-0 left-0 h-full w-full bg-black bg-opacity-60 flex items-center justify-center" }
const _hoisted_4 = { class: "flex flex-col col-span-6" }
const _hoisted_5 = { class: "flex flex-col col-span-6" }
const _hoisted_6 = { class: "flex flex-col col-span-12" }
const _hoisted_7 = { class: "flex flex-row justify-end w-full mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_image_loader = _resolveComponent("ts-image-loader")!
  const _component_ts_file_attachment = _resolveComponent("ts-file-attachment")!
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_text_area = _resolveComponent("ts-text-area")!
  const _component_ts_form_wrapper = _resolveComponent("ts-form-wrapper")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ts_file_attachment, {
      isWrapper: true,
      modelValue: _ctx.EditProfileForm.photo,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.EditProfileForm.photo) = $event))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ts_image_loader, {
            customClass: 'relative rounded h-[100px] w-[100px]',
            photoUrl: _ctx.UserProfile?.bio.photo?.link || ''
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_ts_icon, {
                  name: 'camera',
                  customClass: 'h-[20px]'
                })
              ])
            ]),
            _: 1
          }, 8, ["photoUrl"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ts_form_wrapper, {
      class: "w-full grid-cols-12 grid gap-2",
      parentRefs: _ctx.parentRefs,
      ref: "profileForm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ts_text_field, {
            placeholder: "Ginny",
            "custom-class": "mdlg:px-3 px-2 border-[1px] rounded border-gray-200",
            paddings: "py-3",
            hasTitle: true,
            name: "First name",
            rules: [_ctx.FormValidations.RequiredRule],
            modelValue: _ctx.EditProfileForm.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.EditProfileForm.firstName) = $event)),
            ref: "firstName"
          }, {
            title: _withCtx(() => [
              _createTextVNode(" First name ")
            ]),
            _: 1
          }, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ts_text_field, {
            placeholder: "Weasely",
            "custom-class": "mdlg:px-3 px-2  col-span-6 border-[1px] rounded border-gray-200",
            paddings: "py-3",
            hasTitle: true,
            name: "Last name",
            rules: [_ctx.FormValidations.RequiredRule],
            modelValue: _ctx.EditProfileForm.lastName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.EditProfileForm.lastName) = $event)),
            ref: "lastName"
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Last name ")
            ]),
            _: 1
          }, 8, ["rules", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ts_text_area, {
            placeholder: "Tell us about you",
            paddings: "py-3",
            rules: [_ctx.FormValidations.RequiredRule],
            name: "Description",
            ref: "description",
            hasTitle: true,
            modelValue: _ctx.EditProfileForm.description,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.EditProfileForm.description) = $event))
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Description ")
            ]),
            _: 1
          }, 8, ["rules", "modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["parentRefs"]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_ts_button, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.UpdateProfile())),
        loading: _ctx.UserLoaders?.update
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Save ")
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}