import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative w-full flex flex-row justify-center items-center h-full overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(`fixed top-0 w-screen h-screen bg-black bg-opacity-60 flex flex-col overflow-y-auto ${$props.customClass}`),
          style: {"z-index":"9999999999999999"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeModal()))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 2)
      ]),
      _: 3
    })
  ]))
}