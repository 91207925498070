<template>
  <div class="flex w-full flex-col space-y-2">
    <template v-if="isWrapper">
      <div class="flex flex-row relative">
        <input
          type="file"
          style="
            opacity: 0;
            width: 100%;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            z-index: 10;
          "
          :accept="accept"
          :multiple="isMultiple"
          @change="uploadHandler"
        />
        <slot name="content" />
      </div>
    </template>
    <template v-else>
      <ts-normal-text>
        <slot name="title" />
      </ts-normal-text>
      <div
        class="rounded flex flex-row items-center justify-center relative space-x-2 py-3 border-[1px] border-gray-400 border-dashed"
      >
        <input
          type="file"
          style="
            opacity: 0;
            width: 100%;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            z-index: 10;
          "
          :accept="accept"
          :multiple="isMultiple"
          @change="uploadHandler"
        />
        <ts-icon :name="`${iconName}`" :customClass="'h-[13px]'" />
        <ts-normal-text color="text-paragraphTextLight">
          {{ placeholder }}
        </ts-normal-text>
      </div>
      <div class="w-full grid grid-cols-3 gap-3">
        <div
          v-for="(file, index) in fileListArray"
          :key="index"
          class="mdlg:col-span-1 col-span-3 flex py-2 space-x-2 flex-row items-center justify-between"
        >
          <div class="flex flex-row space-x-2 items-center w-full">
            <ts-icon name="file" :customClass="'h-[17px]'" />
            <ts-normal-text :customClass="'line-clamp-1'">{{
              file.name
            }}</ts-normal-text>
          </div>
          <ts-icon
            :name="'close'"
            :customClass="'h-[14px] cursor-pointer'"
            @click="removeFile(index)"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsIcon from "../TsIcon/index.vue";

export default defineComponent({
  components: {
    TsNormalText,
    TsIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: "Upload File",
    },
    iconName: {
      type: String,
      default: "upload",
    },
    accept: {
      type: String,
      default: "*",
    },
    modelValue: {
      required: false,
    },
    isWrapper: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "update:localFileUrl"],
  name: "TsFileAttachment",
  setup(props: any, context: any) {
    const files = ref<FileList>();

    const fileListArray = ref<any[]>([]);
    const uploadHandler = (e: any) => {
      const input = e.target;

      if (props.isMultiple) {
        files.value = input.files;
      } else {
        files.value = input.files;

        // create readable url
        const fr = new FileReader();
        if (files.value) {
          fr.readAsDataURL(files.value[0]);
          fr.addEventListener("load", () => {
            context.emit(
              "update:localFileUrl",
              fr.result?.toString() ? fr.result?.toString() : ""
            );
          });
        }
      }
    };

    watch(files, () => {
      if (files.value) {
        fileListArray.value = [];
        for (let index = 0; index < files.value.length; index++) {
          const file = files.value?.item(index);
          fileListArray.value.push(file);
        }
        context.emit(
          "update:modelValue",
          props.isMultiple ? fileListArray.value : fileListArray.value[0]
        );
      }
    });

    onMounted(() => {
      if (props.modelValue) {
        fileListArray.value = props.modelValue;
      }
    });

    const removeFile = (index: number) => {
      fileListArray.value = fileListArray.value.filter((file, fileIndex) => {
        return fileIndex != index;
      });
      context.emit(
        "update:modelValue",
        props.isMultiple ? fileListArray.value : fileListArray.value[0]
      );
    };

    return {
      uploadHandler,
      fileListArray,
      removeFile,
    };
  },
});
</script>
