<template>
  <div class="w-full flex flex-col col-span-full">
    <div
      :class="` border-b-[1px] w-full px-3  border-gray-200 flex flex-row items-center justify-between mdlg:!px-0 `"
    >
      <div class="flex flex-row items-center space-x-2 mdlg:!space-x-5">
        <ts-header-text
          size="lg"
          v-for="(item, index) in items"
          :key="index"
          @click.stop="selectedTab = item.key"
          :custom-class="` mdlg:border-b-[2px] border-b-[1.5px] px-3 mdlg:px-0 cursor-pointer ${
            selectedTab == item.key ? ' border-headerText ' : 'border-transparent '
          }   py-2 lg:!pb-2 mdlg:py-3 lg:pt-6`"
          :color="`${
            selectedTab == item.key ? ' text-headerText' : 'text-headerText opacity-40'
          }`"
        >
          {{ item.value }}
        </ts-header-text>
      </div>
      <slot name="extra" />
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import { onMounted, ref, watch } from "vue";

export default {
  components: {
    TsHeaderText,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:selectedTab"],
  name: "TsTabs",
  setup(props: any, context: any) {
    const selectedTab = ref("");

    const selectDefaultTab = () => {
      selectedTab.value = props.items[0]?.key;
    };

    onMounted(() => {
      selectDefaultTab();
    });

    watch(props.options, () => {
      selectDefaultTab();
    });

    watch(selectedTab, () => {
      context.emit("update:selectedTab", selectedTab.value);
    });

    return {
      selectedTab,
    };
  },
};
</script>
