<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full grid-cols-12 grid gap-2">
      <div class="flex flex-col col-span-12">
        <ts-text-area
          placeholder="Tell us how we can help"
          paddings="py-3"
          :hasTitle="true"
        >
          <template v-slot:title> Send a message </template>
        </ts-text-area>
      </div>
    </div>

    <div class="flex flex-row justify-end w-full mt-3">
      <ts-button> Save </ts-button>
    </div>
  </div>
  <div
    class="w-full flex flex-col space-y-3 pt-4 mdlg:!py-0 mdlg:px-0 px-3 py-3"
  >
    <ts-icon-card :iconName="'mail'" :iconSize="'h-[15px]'">
      <template v-slot:name>
        <ts-paragraph-text :faded="true"> Email </ts-paragraph-text>
      </template>
    </ts-icon-card>

    <ts-icon-card :iconName="'whatsapp'" :iconSize="'h-[19px]'">
      <template v-slot:name>
        <ts-paragraph-text :faded="true"> WhatsApp </ts-paragraph-text>
      </template>
    </ts-icon-card>

    <ts-icon-card :iconName="'twitter'" :iconSize="'h-[16px]'">
      <template v-slot:name>
        <ts-paragraph-text :faded="true"> Twitter </ts-paragraph-text>
      </template>
    </ts-icon-card>

    <ts-icon-card :iconName="'instagram'" :iconSize="'h-[20px]'">
      <template v-slot:name>
        <ts-paragraph-text :faded="true"> Instagram </ts-paragraph-text>
      </template>
    </ts-icon-card>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useMeta } from "vue-meta";
import {
  TsButton,
  TsTextArea,
  TsIconCard,
  TsParagraphText,
} from "tutorstack-ui-components";
import { composables } from "../../composables";

export default defineComponent({
  components: {
    TsButton,
    TsTextArea,
    TsIconCard,
    TsParagraphText,
  },
  name: "ContactUsPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Contact us`,
    });

    const TsCommon = composables.common;

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Contact us`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
    });
  },
});
</script>
