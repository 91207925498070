<template>
  <ts-alert />
  <div
    class="loader z-[45666664999998] !sticky top-0 overflow-x-hidden"
    v-if="loadingState"
  >
    <div class="bar"></div>
  </div>
  <div
    class="fixed top-0 left-0 h-full lg:w-[18%] mdlg:w-[16%] mdlg:flex hidden px-6 py-5 flex-col !bg-white space-y-3 border-r-[1px] border-gray-200"
  >
    <ts-side-bar
      :tabIsActive="tabIsActive"
      :accountType="composables.common.accountType.value"
    />
  </div>
  <div
    class="h-full lg:w-[82%] mdlg:w-[84%] w-full text-center !bg-white relative px-0 mdlg:px-0 mdlg:ml-[16%] lg:ml-[18%] flex flex-row lg:text-sm mdlg:text-[12px] text-xs"
  >
    <div
      class="mdlg:w-[71%] lg:w-[70%] h-full w-full pb-3 flex flex-col items-center"
    >
      <div
        class="lg:!w-[92%] mdlg:!w-[96%] w-full h-full flex flex-col relative"
      >
        <div
          class="w-full flex flex-col h-full justify-start items-start mdlg:!space-y-3 mdlg:px-0 px-0"
        >
          <ts-top-bar
            :options="composables.common.pageTopBarOptions"
            v-model:selectedTab="selectedTopTab"
            :goBack="goBack"
            :goToRoute="goToRoute"
          />
          <slot />
          <div class="py-5 my-5 w-full"></div>
        </div>
        <ts-bottom-bar
          :tabIsActive="tabIsActive"
          :options="composables.common.bottomTabOptions"
          v-if="composables.common.showBottomBar.value"
          :accountType="composables.common.accountType.value"
        />
      </div>
    </div>
    <div
      class="lg:w-[30%] mdlg:w-[29%] sticky top-[0px] mdlg:border-l-[1px] border-gray-200 min-h-screen mdlg:flex pb-4 px-3 hidden flex-col"
    >
      <ts-calendar-bar :accountType="composables.common.accountType.value" />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from "vue";
import {
  TsSideBar,
  TsCalendarBar,
  TsBottomBar,
  TsTopBar,
  TsAlert,
} from "tutorstack-ui-components";
import { useRouter } from "vue-router";
import { composables, selectedTopTab } from "../composables";

export default defineComponent({
  components: {
    TsSideBar,
    TsCalendarBar,
    TsBottomBar,
    TsTopBar,
    TsAlert,
  },
  name: "DashboardLayout",
  setup() {
    const router = useRouter();

    const selectedTab = ref("");

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const tabIsActive = (tabName: string) => {
      const mainName = tabName;

      if (mainName == "base" && router.currentRoute.value.path == "/") {
        return true;
      } else if (
        mainName != "base" &&
        router.currentRoute.value.path.includes(mainName)
      ) {
        selectedTab.value = mainName;
        return true;
      }

      return false;
    };

    const goBack = () => {
      if (TsCommon.pageTopBarOptions.backRoute != "") {
        goToRoute(TsCommon.pageTopBarOptions.backRoute);
      } else {
        window.history.length > 1 ? router.go(-1) : router.push("/");
      }
    };

    const goToRoute = (route: string) => {
      router.push(route);
    };

    onMounted(() => {
      TsCommon.accountType.value = FrontendLogic.auth.user.AuthUser?.roles
        .isTutor
        ? "tutor"
        : "student";
    });

    const loadingState = ref(false);

    onMounted(() => {
      FrontendLogic.common.watchProperty("loadingState", loadingState);
    });

    return {
      tabIsActive,
      composables,
      goBack,
      goToRoute,
      loadingState,
      selectedTopTab,
    };
  },
});
</script>
