import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "fixed w-full h-full flex flex-col z-[5880089990000000]" }
const _hoisted_2 = { class: "w-full grid grid-cols-3 space-x-2 py-2 bg-white px-2 items-center justify-between" }
const _hoisted_3 = { class: "flex-row items-center justify-start space-x-2 col-span-1 hidden mdlg:flex" }
const _hoisted_4 = { class: "col-span-1 flex flex-row items-center mdlg:!justify-center justify-start" }
const _hoisted_5 = { class: "flex flex-row items-center justify-end space-x-2 mdlg:!col-span-1 col-span-2" }
const _hoisted_6 = { class: "flex flex-col w-full h-full z-[48857890099999] relative bg-white" }
const _hoisted_7 = {
  key: 0,
  class: "h-full w-full top-0 left-0 bg-gray-100 animate-pulse absolute"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_confirm_action = _resolveComponent("ts-confirm-action")!
  const _component_ts_tutor_rating = _resolveComponent("ts-tutor-rating")!
  const _component_ts_modal = _resolveComponent("ts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#classroom-container" }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ts_button, {
              padding: "px-3 py-2",
              "custom-class": "!text-xs",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmAction('leave')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Leave ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ts_header_text, {
              size: "xl",
              customClass: 'flex flex-row space-x-2 items-center justify-center line-clamp-1 '
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Session?.topic), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ts_button, {
              padding: "px-3 py-2",
              "custom-class": "!text-xs inline-block mdlg:!hidden",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showConfirmAction('leave')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Leave ")
              ]),
              _: 1
            }),
            (_ctx.Session?.tutor.id == _ctx.FrontendLogic.auth.user.AuthUser?.id)
              ? (_openBlock(), _createBlock(_component_ts_button, {
                  key: 0,
                  padding: "px-3 py-2",
                  "custom-class": "!text-xs",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showConfirmAction('close')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Close ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (
              _ctx.Session?.tutor.id == _ctx.FrontendLogic.auth.user.AuthUser?.id ||
              _ctx.Session?.students[0].id == _ctx.FrontendLogic.auth.user.AuthUser?.id
            )
              ? (_openBlock(), _createBlock(_component_ts_button, {
                  key: 1,
                  padding: "px-3 py-2",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showConfirmAction('cancel'))),
                  "custom-class": "!text-xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Cancel ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.showLoader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("", true),
          _createElementVNode("iframe", {
            class: "w-full h-full border-none",
            src: `https://tutorstack-classroom.vercel.app/preview/${_ctx.roomId}/${
            _ctx.TsCommon.accountType.value == 'student'
              ? 'session-member'
              : 'session-tutor'
          }?auth_token=${_ctx.authToken}&user=${_ctx.userName}`,
            allow: "camera; microphone; display-capture",
            onLoad: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.classroomLoaded && _ctx.classroomLoaded(...args)))
          }, null, 40, _hoisted_8)
        ])
      ])
    ])),
    (_ctx.showConfirmPrompt)
      ? (_openBlock(), _createBlock(_component_ts_modal, {
          key: 0,
          canClose: true,
          close: 
      () => {
        _ctx.showConfirmPrompt = false;
      }
    
        }, {
          default: _withCtx(() => [
            (_ctx.modalType == 'prompt')
              ? (_openBlock(), _createBlock(_component_ts_confirm_action, {
                  key: 0,
                  reason: _ctx.actionReason,
                  "onUpdate:reason": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.actionReason) = $event)),
                  "has-reason": _ctx.actionType == 'cancel',
                  close: 
        () => {
          _ctx.showConfirmPrompt = false;
        }
      ,
                  question: _ctx.confirmationQuestion,
                  confirm: _ctx.executeAction
                }, null, 8, ["reason", "has-reason", "close", "question", "confirm"]))
              : _createCommentVNode("", true),
            (_ctx.modalType == 'ratings')
              ? (_openBlock(), _createBlock(_component_ts_tutor_rating, {
                  key: 1,
                  tutor: _ctx.Session?.tutor,
                  close: 
        () => {
          _ctx.showConfirmPrompt = false;
        }
      ,
                  confirm: _ctx.executeAction,
                  ratings: _ctx.FrontendLogic.sessions.sessions.RateSessionForm.rating,
                  "onUpdate:ratings": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.FrontendLogic.sessions.sessions.RateSessionForm.rating) = $event)),
                  review: _ctx.FrontendLogic.sessions.sessions.RateSessionForm.message,
                  "onUpdate:review": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.FrontendLogic.sessions.sessions.RateSessionForm.message) = $event))
                }, null, 8, ["tutor", "close", "confirm", "ratings", "review"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["close"]))
      : _createCommentVNode("", true)
  ], 64))
}