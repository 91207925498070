<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!px-24 px-3 lg:!pt-10 mdlg:!pt-10 pt-4"
  >
    <ts-header-text
      :customClass="'w-full text-center lg:!text-xl'"
      :size="'xl'"
    >
      Create an account
    </ts-header-text>

    <div class="w-full flex flex-row items-center justify-center">
      <GoogleLogin
        :client-id="composables.common.GoogleClientID"
        :callback="authCompleted"
        prompt
      />
    </div>

    <div
      class="w-full flex flex-row items-center space-x-1 justify-between pt-3"
    >
      <span class="border-t-[1px] border-gray-200 flex-grow"> </span>
      <span class="px-3 pt-3">
        <ts-normal-text :color="'text-paragraphTextLight'"
          >Or sign in with</ts-normal-text
        >
      </span>
      <span class="border-t-[1px] border-gray-200 flex-grow"> </span>
    </div>

    <ts-form-wrapper
      class="w-full grid grid-cols-12 gap-2"
      :parentRefs="parentRefs"
      ref="registerForm"
    >
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="John"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          v-model="EmailLogic.SignUpForm.firstName"
          :hasTitle="true"
          name="First Name"
          :rules="[FormValidations.RequiredRule]"
          ref="firstName"
        >
          <template v-slot:title> First Name </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="Doe"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          v-model="EmailLogic.SignUpForm.lastName"
          name="Last Name"
          :rules="[FormValidations.RequiredRule]"
          ref="lastName"
        >
          <template v-slot:title> Last Name </template>
        </ts-text-field>
      </div>
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="ginny@weasely.com"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          :rules="[FormValidations.RequiredRule, FormValidations.EmailRule]"
          name="Email"
          ref="email"
          v-model="EmailLogic.SignUpForm.email"
        >
          <template v-slot:title> Email </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="Password"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          name="Password"
          type="password"
          ref="password"
          :rules="[FormValidations.RequiredRule]"
          v-model="EmailLogic.SignUpForm.password"
          :hasTitle="true"
        >
          <template v-slot:title> Password </template>
        </ts-text-field>
      </div>
    </ts-form-wrapper>

    <div
      class="w-full text-center items-center justify-center flex flex-row space-x-1"
    >
      <span :class="'text-paragraphTextLight'"
        >By signing up, you accept Tutor Stacks
        <router-link to="#" class="text-primaryPurple"
          >Terms of Service and Privacy Policy</router-link
        >
      </span>
    </div>

    <div class="w-full flex flex-col pt-3">
      <ts-button :customClass="' w-full'" @click="SignUp()">
        Sign up
      </ts-button>
    </div>

    <div
      class="w-full flex flex-row space-x-1 items-center justify-center pt-1"
    >
      <ts-normal-text :color="'text-paragraphTextLight'"
        >Have an account?</ts-normal-text
      >
      <router-link to="/auth/login">
        <ts-normal-text :color="'text-primaryPurple'">Log in</ts-normal-text>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsTextField,
  TsButton,
  TsHeaderText,
  TsNormalText,
  TsFormWrapper,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { GoogleLogin } from "vue3-google-login";

export default defineComponent({
  components: {
    TsTextField,
    TsButton,
    TsHeaderText,
    TsNormalText,
    TsFormWrapper,
    GoogleLogin,
  },
  name: "RegisterPage",
  layout: "Auth",
  setup() {
    useMeta({
      title: `Create an account`,
    });

    const EmailLogic = composables.logic.auth.email;
    const FormValidations = composables.logic.forms.validations;
    const FrontendLogic = composables.logic;

    const registerForm = ref<any>(null);

    const SignUp = () => {
      const formState: boolean = registerForm.value.validate();
      EmailLogic.SignUp(formState, registerForm.value);
    };

    const authCompleted = (response: any) => {
      FrontendLogic.auth.identities.GoogleSignInForm.idToken =
        response.credential;
      FrontendLogic.auth.identities.GoogleSignIn();
    };

    return {
      EmailLogic,
      SignUp,
      registerForm,
      FormValidations,
      authCompleted,
      composables,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
