import { AxiosError, AxiosResponse } from "axios";
import { Logic } from "..";
import { Paginated, UserCard } from "../../types/domains";
import { AddCardForm } from "../../types/forms";
import { $api } from "../../../services";

export default class Cards {
	constructor() {
		// initiate things here

	}

	public UserCards: Paginated<UserCard> | undefined = undefined
	public UserCard: UserCard | undefined = undefined

	public AddUserCardForm: AddCardForm = {
		cvc: '',
		expMonth: 0,
		expYear: 0,
		number: ''
	}


	public GetUserCards = () => {
		try {
			$api.payments.cards.fetch().then((response: AxiosResponse<Paginated<UserCard>>) => {
				this.UserCards = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetUserCard = (cardId: string) => {
		try {
			$api.payments.cards.get(cardId).then((response: AxiosResponse<UserCard>) => {
				this.UserCard = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public AddUserCard = (formIsValid: boolean) => {
		try {
			if (formIsValid) {
				$api.payments.cards.post(this.AddUserCardForm).then((response: AxiosResponse<UserCard>) => {
					this.UserCards?.results.push(response.data)
					Logic.common.showAlert("success", 'Debit card added');
				}).catch((error: AxiosError<boolean>) => {
					Logic.common.showAlert("error", error.message);
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

}