import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row relative"
}
const _hoisted_3 = ["accept", "multiple"]
const _hoisted_4 = { class: "rounded flex flex-row items-center justify-center relative space-x-2 py-3 border-[1px] border-gray-400 border-dashed" }
const _hoisted_5 = ["accept", "multiple"]
const _hoisted_6 = { class: "w-full grid grid-cols-3 gap-3" }
const _hoisted_7 = { class: "flex flex-row space-x-2 items-center w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isWrapper)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("input", {
            type: "file",
            style: {"opacity":"0","width":"100%","height":"100%","left":"0","overflow":"hidden","position":"absolute","z-index":"10"},
            accept: _ctx.accept,
            multiple: _ctx.isMultiple,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadHandler && _ctx.uploadHandler(...args)))
          }, null, 40, _hoisted_3),
          _renderSlot(_ctx.$slots, "content")
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ts_normal_text, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title")
            ]),
            _: 3
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("input", {
              type: "file",
              style: {"opacity":"0","width":"100%","height":"100%","left":"0","overflow":"hidden","position":"absolute","z-index":"10"},
              accept: _ctx.accept,
              multiple: _ctx.isMultiple,
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadHandler && _ctx.uploadHandler(...args)))
            }, null, 40, _hoisted_5),
            _createVNode(_component_ts_icon, {
              name: `${_ctx.iconName}`,
              customClass: 'h-[13px]'
            }, null, 8, ["name"]),
            _createVNode(_component_ts_normal_text, { color: "text-paragraphTextLight" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileListArray, (file, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "mdlg:col-span-1 col-span-3 flex py-2 space-x-2 flex-row items-center justify-between"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ts_icon, {
                    name: "file",
                    customClass: 'h-[17px]'
                  }),
                  _createVNode(_component_ts_normal_text, { customClass: 'line-clamp-1' }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(file.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createVNode(_component_ts_icon, {
                  name: 'close',
                  customClass: 'h-[14px] cursor-pointer',
                  onClick: ($event: any) => (_ctx.removeFile(index))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ])
        ], 64))
  ]))
}