import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3" }
const _hoisted_2 = { class: "w-full grid-cols-12 grid gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_icon_card = _resolveComponent("ts-icon-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ts_icon_card, { iconName: '' }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_normal_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Push notifications ")
            ]),
            _: 1
          })
        ]),
        extra: _withCtx(() => [
          _createVNode(_component_ts_icon, {
            name: 'switch-on',
            customClass: 'mdlg:h-[17px] h-[15px]'
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ts_icon_card, { iconName: '' }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_normal_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Sound ")
            ]),
            _: 1
          })
        ]),
        extra: _withCtx(() => [
          _createVNode(_component_ts_icon, {
            name: 'switch-off',
            customClass: 'mdlg:h-[17px] h-[15px]'
          })
        ]),
        _: 1
      })
    ])
  ]))
}