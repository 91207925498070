import { reactive } from "vue";
import { Logic } from "..";
import { AuthUser } from "../../types/domains/auth";
import { UpdateProfileForm, UpdateRoleForm } from "../../types/forms";
import { $api } from "../../../services";
import Common from "../Common";

export default class Users extends Common {
	constructor() {
		// load user from local storage
		super()
		this.AuthUser = localStorage.getItem('AuthUser') ? JSON.parse(localStorage.getItem('AuthUser') || '{}') : undefined
	}

	public AuthUser: AuthUser | undefined = undefined

	public ProfileUpdateForm: UpdateProfileForm = {
		description: '',
		firstName: '',
		lastName: '',
		photo: '',
		phone: ''
	}

	public UserLoaders = reactive({
		update: false
	})

	public UserRoleForm: UpdateRoleForm = {
		role: 'isAdmin',
		userId: this.AuthUser?.id || '',
		value: false
	}

	public GetAuthUser = () => {
		try {
			$api.auth.user.GetAuthUser().then((response) => {
				this.AuthUser = response.data
			});
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateProfile = (formIsValid: boolean, profileForm: any) => {

		if (formIsValid) {
			this.UserLoaders.update = true
			Logic.common.showLoader()
			$api.auth.user.UpdateUserProfile(this.ProfileUpdateForm).then((response) => {
				this.AuthUser = response.data
				this.UserLoaders.update = false
				Logic.common.hideLoader()
				Logic.users.users.GetUserProfile(this.AuthUser?.id || '')
				if (this.AuthUser?.roles.isTutor) {
					Logic.common.GoToRoute('/tutors/' + this.AuthUser?.id)
				} else {
					Logic.common.GoToRoute('/profile')
				}


			}).catch((error: any) => {
				Logic.common.showValidationError(error.response.data, profileForm)
				this.UserLoaders.update = false
				Logic.common.hideLoader()
			})
		}

	}

	public UpdateUserRole = () => {
		try {
			$api.auth.user.UpdateUserRoles(this.UserRoleForm).then((response) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

	public SignOut = () => {
		try {
			Logic.common.showLoader()
			$api.auth.user.SignOut().then(() => {
				localStorage.removeItem('AuthUser')
				localStorage.removeItem('AuthToken')
				localStorage.removeItem('UserProfile')
				Logic.common.hideLoader()
				Logic.common.GoToRoute('/auth/login')
			})
		} catch (error) {
			console.log(error)
		}
	}

	public ExchangeToken = () => {
		try {
			$api.auth.token.ExchangeToken().then((response) => {
				this.AuthUser = response.data.user
				Logic.auth.email.SaveUserToLocal()
				Logic.auth.email.SetTokens(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

}