<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full grid-cols-12 grid gap-2">
      <div class="flex flex-col col-span-6">
        <ts-text-field
          placeholder="Enter password"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          v-model="FrontendLogic.auth.password.UpdatePasswordForm.oldPassword"
          type="password"
        >
          <template v-slot:title> Old Password </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col col-span-6">
        <ts-text-field
          placeholder="Enter password"
          custom-class="mdlg:px-3 px-2  col-span-6 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          type="password"
          v-model="FrontendLogic.auth.password.UpdatePasswordForm.password"
        >
          <template v-slot:title> New Password </template>
        </ts-text-field>
      </div>
    </div>

    <div class="flex flex-row justify-end w-full mt-3">
      <ts-button @click="updatePassword()"> Save </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useMeta } from "vue-meta";
import { TsTextField, TsButton } from "tutorstack-ui-components";
import { composables } from "../../composables";

export default defineComponent({
  components: {
    TsTextField,
    TsButton,
  },
  name: "SecurityPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Security`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Security`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
    });

    const updatePassword = () => {
      FrontendLogic.auth.password.UpdatePassword(true);
    };

    return {
      FrontendLogic,
      updatePassword,
    };
  },
});
</script>
