import moment from 'moment'
import { ValidationError } from '../../types/domains'
import { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router'
import { Logic } from '..'
import currency from 'currency.js'
import { reactive } from 'vue'

export default class Common {
	constructor() {
		// initiate things here

	}

	public router: Router | undefined = undefined

	public apiUrl: string | undefined = undefined

	public watchInterval: number | undefined = undefined

	public loadingState = false

	public selectedTopBarOption = ''

	public alertConfig = reactive({
		show: false,
		message: 'Hello testing',
		type: 'success'
	})

	public showAlert = (type: 'error' | 'success' | 'info', message: string, duration = 3000) => {
		this.alertConfig.message = message
		this.alertConfig.type = type
		this.alertConfig.show = true

		setTimeout(() => {
			this.alertConfig.show = false
		}, duration);
	}

	public SetRouter = (router: Router) => {
		this.router = router
	}

	public SetApiUrl = (apiUrl: string) => {
		this.apiUrl = apiUrl;
	}

	public GoToRoute = (path: string) => {
		this.router?.push(path)
	}

	public showLoader = () => {
		this.loadingState = true
	}

	public hideLoader = () => {
		this.loadingState = false
	}

	public formatCurrency = (value: any) => {
		return currency(value)
	}

	public debounce = (method = () => {
		//
	}, delay = 500) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		if (typeof window.LIT !== "undefined") {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			clearTimeout(window.LIT);
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		window.LIT = setTimeout(() => {
			method()
		}, delay);
	}

	public showValidationError = (validationErrors: ValidationError[], formElement: any) => {
		validationErrors.forEach((validation) => {
			const field: any = formElement.fieldsToValidate[validation.field]

			if (field) {
				field.showError(validation.message)
			}

		})
	}

	public watchProperty = (objectToWatch: any, objectToUpdate: any) => {
		let upatedValue = (this as any)[`${objectToWatch}`];
		const watchAction = () => {
			upatedValue = (this as any)[`${objectToWatch}`];
			if (objectToUpdate) {
				objectToUpdate.value = upatedValue;
			}
			this.watchInterval = window.requestAnimationFrame(watchAction)
		}

		watchAction()
	}

	public watchMethod = (methodToWatch: any, objectToUpdate: any, params1?: any, params2?: any, params3?: any) => {
		let upatedValue = (this as any)[`${methodToWatch}`];
		const watchAction = () => {
			upatedValue = (this as any)[`${methodToWatch}`](params1, params2, params3);
			if (objectToUpdate) {
				objectToUpdate.value = upatedValue;
			}
			this.watchInterval = window.requestAnimationFrame(watchAction)
		}

		watchAction()
	}

	public stopWatchAction = () => {
		if (this.watchInterval != undefined) {
			window.cancelAnimationFrame(this.watchInterval);
		}
	}

	public convertToFormData = (data: any) => {
		// convert request data to formData
		const formData: FormData = new FormData()

		for (const key in data) {
			const param = data[key];
			if (Array.isArray(param)) {
				param.forEach((eachItem) => {
					formData.append(`${key}`, eachItem)
				})

			} else {
				formData.append(key, param)
			}

		}

		return formData
	}

	private fetchFile = (url: string) => {
		return new Promise(function (resolve, reject) {
			// Get file name from url.
			const xhr = new XMLHttpRequest();
			xhr.responseType = 'blob';
			xhr.onload = function () {
				resolve(xhr);
			};
			xhr.onerror = reject;
			xhr.open('GET', url);
			xhr.send();
		}).then(function (xhr: any) {
			const filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
			const a = document.createElement('a');
			a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
			a.download = filename; // Set the file name.
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			return xhr;
		});
	}

	public downloadFiles = (urls = []) => {
		return Promise.all(urls.map(this.fetchFile));
	}

	public formatBytes = (bytes: number, decimals = 2) => {
		if (!+bytes) return '0 Bytes'

		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}


	public dateToMilliseconds = (date: string) => {
		return parseInt(moment(date).format('x'))
	}

	public fomartDate = (date: number, format: string) => {
		return moment(date).format(format)
	}

	public countDownTime = (endTime: number) => {
		return moment(moment(endTime).diff(moment.now())).format('mm:ss')
	}

	public timeFromNow = (time: number) => {
		return moment(time).fromNow()
	}

	public getDaysFromToday(count: number) {
		const days = [

		];
		const curr = new Date(); // get current date
		const first = curr.getDate();
		for (let i = first; i < first + count; i++) {
			const day = new Date(curr.setDate(i)).toISOString().slice(0, 10);
			days.push(
				{
					name: moment(day).format('ddd,MMM Do'),
					date: day,
					key: i,
				},
			);
		}
		return days;
	}

	// handle routing

	public preFetchRouteData = (routeTo: RouteLocationNormalized, next: NavigationGuardNext) => {
		const allActions: Promise<any>[] = []

		if (routeTo.fullPath == '/') {
			if (Logic.auth.user.AuthUser) {
				if (Logic.users.users.SavedTutors == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.users.GetSavedTutors()

						request?.then((value) => {
							resolve(value)
						})

					}))
				}

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.users.users.UserProfile == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.users.GetUserProfile(Logic.auth.user.AuthUser?.id || '')
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.UserWallet == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetUserWallet()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}
			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/profile') {
			if (Logic.auth.user.AuthUser) {
				if (Logic.users.users.UserProfile == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.users.GetUserProfile(Logic.auth.user.AuthUser?.id || '')
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.UserWallet == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetUserWallet()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}
			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/profile/edit') {
			if (Logic.auth.user.AuthUser) {
				if (Logic.users.users.UserProfile == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.users.GetUserProfile(Logic.auth.user.AuthUser?.id || '')
						request?.then((value) => {
							if (Logic.users.users.UserProfile) {
								Logic.auth.user.ProfileUpdateForm.description =
									Logic.users.users.UserProfile.bio.description;
								Logic.auth.user.ProfileUpdateForm.firstName =
									Logic.users.users.UserProfile.bio.name.first;
								Logic.auth.user.ProfileUpdateForm.lastName =
									Logic.users.users.UserProfile.bio.name.last;
							}
							console.log(Logic.users.users.UserProfile)
							resolve(value)
						})
					}))
				} else {
					if (Logic.users.users.UserProfile) {
						Logic.auth.user.ProfileUpdateForm.description =
							Logic.users.users.UserProfile.bio.description;
						Logic.auth.user.ProfileUpdateForm.firstName =
							Logic.users.users.UserProfile.bio.name.first;
						Logic.auth.user.ProfileUpdateForm.lastName =
							Logic.users.users.UserProfile.bio.name.last;
					}
				}
			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/profile/wallet/payment-method') {
			if (Logic.auth.user.AuthUser) {
				if (Logic.payments.wallet.PaymentMethods == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetPaymentMethods()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}
			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/profile/wallet/history') {
			if (Logic.auth.user.AuthUser) {
				if (Logic.payments.transactions.Transactions == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.transactions.GetTransactions()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}
			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/tutors') {
			if (Logic.auth.user.AuthUser) {

				if (Logic.users.tutors.Tutors == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.tutors.GetTutors()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.users.users.UserProfile == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.users.GetUserProfile(Logic.auth.user.AuthUser?.id || '')
						request?.then((value) => {
							resolve(value)
						})
					}))
				}
			} else {
				return next()
			}
		}

		if (routeTo.fullPath.includes('/tutors/')) {
			if (Logic.auth.user.AuthUser) {


				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.UserWallet == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetUserWallet()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (routeTo.params.id) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.tutors.GetTutor(routeTo.params.id.toString())
						request?.then((value) => {
							resolve(value)
						})
					}))

					allActions.push(new Promise((resolve) => {
						const request = Logic.users.tutors.GetTutorEducations(routeTo.params.id.toString())
						request?.then((value) => {
							resolve(value)
						})
					}))

					allActions.push(new Promise((resolve) => {
						const request = Logic.users.tutors.GetTutorWorks(routeTo.params.id.toString())
						request?.then((value) => {
							resolve(value)
						})
					}))

					allActions.push(new Promise((resolve) => {
						const request = Logic.users.tutors.GetTutorReviews(routeTo.params.id.toString())
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

			} else {
				return next()
			}
		}


		if (routeTo.fullPath == '/sessions') {
			if (Logic.auth.user.AuthUser) {

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}
				if (Logic.sessions.sessions.Sessions == undefined) {
					const filterQuery = {
						where: [
							{
								field:
									Logic.auth.user.AuthUser.roles.isTutor
										? "tutor.id"
										: "students.id",
								value: Logic.auth.user.AuthUser?.id || "",
							},
						],
					};

					allActions.push(new Promise((resolve) => {
						const request = Logic.sessions.sessions.GetSessions(
							filterQuery,
							'upcoming'
						)
						request?.then((value) => {
							resolve(value)
						})
					}))


				}

			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/questions/ask') {
			if (Logic.auth.user.AuthUser) {

				Logic.questions.questions.clearQuestionForm()

				if (Logic.users.users.UserProfile == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.users.GetUserProfile(Logic.auth.user.AuthUser?.id || '')
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.UserWallet == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetUserWallet()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/questions') {
			if (Logic.auth.user.AuthUser) {

				if (Logic.questions.questions.Questions == undefined) {
					const queryOptions: any = {
						where: [
							{
								field: "user.id",
								value: Logic.auth.user.AuthUser?.id,
							},
						],
					};
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.questions.GetQuestions(
							!Logic.auth.user.AuthUser?.roles.isTutor ? queryOptions : null,
							!Logic.auth.user.AuthUser?.roles.isTutor ? "answered" : "unheld"
						)
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}


			} else {
				return next()
			}
		}

		if (routeTo.fullPath.includes('/questions/') && routeTo.fullPath != '/questions/ask') {
			if (Logic.auth.user.AuthUser) {

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				Logic.questions.questions.Question = undefined
				Logic.questions.answers.Answers = undefined
				Logic.interactions.comments.Comments = undefined

				allActions.push(new Promise((resolve) => {
					const request = Logic.questions.questions.GetQuestion(routeTo.params.id.toString())
					request?.then((value) => {
						resolve(value)
					})
				}))

				allActions.push(new Promise((resolve) => {
					const request = Logic.questions.answers.GetAnswers(routeTo.params.id.toString())
					request?.then((value) => {
						resolve(value)
					})
				}))


			} else {
				return next()
			}
		}

		if (routeTo.fullPath.includes('/sessions/book')) {
			if (Logic.auth.user.AuthUser) {

				Logic.sessions.sessions.resetSessionForm();

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.users.tutors.Tutors == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.users.tutors.GetTutors()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.PaymentMethods == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetPaymentMethods()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}


			} else {
				return next()
			}
		}

		if (!routeTo.fullPath.includes('/sessions/book') && !routeTo.fullPath.includes('/sessions/classroom') && routeTo.fullPath.includes('/sessions/')) {
			if (Logic.auth.user.AuthUser) {

				if (Logic.questions.subjects.Subjects == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.questions.subjects.GetSubjects()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (routeTo.params.id) {
					Logic.sessions.sessions.Session = undefined
					Logic.sessions.sessions.SessionRecordings = undefined
					allActions.push(new Promise((resolve) => {
						const request = Logic.sessions.sessions.GetSession(routeTo.params.id.toString())
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.PaymentMethods == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetPaymentMethods()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.UserWallet == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetUserWallet()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.sessions.sessions.SessionRecordings == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.sessions.sessions.GetSessionRecordings(routeTo.params.id.toString())
						request?.then((value) => {
							resolve(value)
						})
					}))
				}


			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/profile/plans') {
			if (Logic.auth.user.AuthUser) {

				if (Logic.payments.wallet.AvailablePlans == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetAllPlans()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

			} else {
				return next()
			}
		}

		if (routeTo.fullPath == '/profile/subscription') {
			if (Logic.auth.user.AuthUser) {

				if (Logic.payments.wallet.AvailablePlans == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetAllPlans()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

				if (Logic.payments.wallet.UserWallet == undefined) {
					allActions.push(new Promise((resolve) => {
						const request = Logic.payments.wallet.GetUserWallet()
						request?.then((value) => {
							resolve(value)
						})
					}))
				}

			} else {
				return next()
			}
		}

		const middlewares: any = routeTo.meta.middlewares

		if (allActions.length > 0) {
			this.showLoader()
			Promise.all(allActions).then(() => {
				this.hideLoader()

				if (middlewares?.navSetup) {
					this.selectedTopBarOption = middlewares.navSetup()
				}

				return next()
			})
		} else {

			if (middlewares?.navSetup) {
				this.selectedTopBarOption = middlewares.navSetup()
			}

			return next()
		}


	}

}