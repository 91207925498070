import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { reactive } from "vue";
import { Logic } from "..";
import { Paginated, TutorAvailability, SingleSession, SessionTokens, SessionRecording } from "../../types/domains";
import { CancleSessionForm, CreateSessionForm, PayForSessionForm, RateSessionForm, SelectOption, TutorAvailabilityForm } from "../../types/forms";
import { $api } from "../../../services";
import Common from "../Common";


export default class Sessions extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public TutorAvailability: TutorAvailability | undefined = undefined
	public Sessions: Paginated<SingleSession> | undefined = undefined
	public Session: SingleSession | undefined = undefined
	public UserSessions: SingleSession[] | undefined = undefined
	public SessionRecordings: SessionRecording[] | undefined = undefined

	public TutorAvailableDaysOptions = reactive<SelectOption[]>([])
	public TutorAvailableTimeOptions = reactive<SelectOption[]>([])


	public SessionsByMonth: Paginated<SingleSession> | undefined = undefined


	public SessionLoaders = reactive({
		create: false
	})

	public TutorAvailabilityForm: TutorAvailabilityForm = {
		add: false,
		time: 0
	}

	public RateSessionForm: RateSessionForm = {
		message: '',
		rating: 0
	}

	public CreateSessionForm: CreateSessionForm = {
		description: '',
		invites: [],
		lengthInMinutes: 60,
		startedAt: 0,
		subjectId: '',
		topic: '',
		tutorId: '',
		attachments: []
	}

	public SessionHours: SelectOption[] = [
		{
			key: 60,
			value: "1 hour"
		},
		{
			key: 120,
			value: "2 hour"
		},
		{
			key: 180,
			value: "3 hour"
		},
	]


	public PayForSessionForm: PayForSessionForm = {
		methodId: '',
		userId: '',
		useWallet: false
	}
	public CancleSessionForm: CancleSessionForm = {
		reason: ''
	}

	public resetSessionForm = () => {
		this.CreateSessionForm = {
			description: '',
			invites: [],
			lengthInMinutes: 60,
			startedAt: 0,
			subjectId: '',
			topic: '',
			tutorId: '',
			attachments: []
		}
	}

	private generateTutorScheduleOptions = () => {
		if (this.TutorAvailability) {
			const availableDate = this.TutorAvailability.free

			let freeDays = availableDate.map((date) => {
				return Logic.common.fomartDate(date, 'YYYY-MM-DD')
			})
			freeDays = [... new Set(freeDays)]

			this.TutorAvailableDaysOptions = []

			freeDays.forEach((day) => {
				this.TutorAvailableDaysOptions.push({
					key: day,
					value: day
				})
			})
		}
	}

	public setDateAvailableTime = (day: string) => {
		if (this.TutorAvailability) {
			const availableDate = this.TutorAvailability.free

			const freeTime = availableDate.map((date) => {
				if (day == Logic.common.fomartDate(date, 'YYYY-MM-DD')) {
					return {
						key: Logic.common.fomartDate(date, 'HH:mm'),
						value: Logic.common.fomartDate(date, 'HH:mm a')
					}
				}
			})

			this.TutorAvailableTimeOptions = []
			freeTime.forEach((time) => {
				if (time) {
					this.TutorAvailableTimeOptions.push({
						key: time.key,
						value: time.value
					})
				}
			})

		}
	}


	public getSessionStatus = (session: SingleSession) => {
		if (
			session.paid.length == session.students.length &&
			moment(session.startedAt + 60 * session.lengthInMinutes).isSameOrAfter(
				moment.now()
			) && session.closedAt == null && session.cancelled != true
		) {
			return "upcoming";
		} else if (
			session.paid.length < session.students.length &&
			moment(session.startedAt + 60 * session.lengthInMinutes).isSameOrAfter(
				moment.now()
			)
		) {
			return "pending";
		} else if (
			!moment(session.startedAt + 60 * session.lengthInMinutes).isSameOrAfter(
				moment.now()
			) || session.closedAt != null || session.cancelled == true
		) {
			return "previous";
		} else {
			return "previous";
		}
	};


	public GetTutorAvailability = (tutorId: string) => {
		try {
			$api.sessions.tutorAvailability.get(tutorId).then((response: AxiosResponse<TutorAvailability>) => {
				this.TutorAvailability = response.data
				this.generateTutorScheduleOptions();
			})
		} catch (error) {
			console.log(error)
		}
	}

	public UpdateTutorAvailability = () => {
		try {
			$api.sessions.tutorAvailability.post(this.TutorAvailabilityForm).then((response: AxiosResponse<TutorAvailability>) => {
				this.TutorAvailability = response.data
				Logic.common.showAlert("success", 'Availability updated');
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetCalendarSession = (query: any) => {
		try {
			$api.sessions.sessions.fetch(query).then((response: AxiosResponse<Paginated<SingleSession>>) => {
				this.SessionsByMonth = response.data
				localStorage.setItem("SessionByMonth", JSON.stringify(response.data.results));
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetSessions = (query: any, sortType = '') => {
		try {
			const request = $api.sessions.sessions.fetch(query).then((response: AxiosResponse<Paginated<SingleSession>>) => {
				this.Sessions = response.data
				this.UserSessions = response.data.results
				localStorage.setItem('AllSessions', JSON.stringify(this.Sessions.results))
				if (sortType) {
					this.SortSessions(sortType)
				}
			})

			return request
		} catch (error) {
			console.log(error)
		}
	}

	public SortSessions = (type: string) => {
		if (this.UserSessions) {
			const sessions: SingleSession[] = localStorage.getItem('AllSessions') ? JSON.parse(localStorage.getItem('AllSessions') || '{}') : []

			if (type == 'upcoming') {
				const upcomingSession = sessions?.filter((session) => {
					return session.paid.length == session.students.length && (moment(session.startedAt + (60 * session.lengthInMinutes)).isSameOrAfter(moment.now())) && session.closedAt == null && session.cancelled != true
				})
				this.UserSessions = upcomingSession.reverse();

			}

			if (type == 'pending') {
				const pendingSession = sessions?.filter((session) => {

					return session.paid.length < session.students.length && (moment(session.startedAt + (60 * session.lengthInMinutes)).isSameOrAfter(moment.now()))
				})

				this.UserSessions = pendingSession.reverse();
			}

			if (type == 'previous') {
				const previousSession = sessions?.filter((session) => {
					return !moment(session.startedAt + (60 * session.lengthInMinutes)).isSameOrAfter(moment.now()) || session.closedAt != null || session.cancelled == true
				})

				this.UserSessions = previousSession.reverse();
			}
		}
	}

	public GetSession = (sessionId: string) => {
		try {
			const request = $api.sessions.sessions.get(sessionId).then((response: AxiosResponse<SingleSession>) => {
				this.Session = response.data
			})
			return request
		} catch (error) {
			console.log(error)
		}
	}

	public CreateSession = (formIsValid: boolean, filterQuery: any, sessionForm: any) => {

		if (formIsValid) {
			this.CreateSessionForm.invites = JSON.stringify(this.CreateSessionForm.invites);
			// this.CreateSessionForm.attachments = JSON.stringify(this.CreateSessionForm.attachments)
			Logic.common.showLoader()
			this.SessionLoaders.create = true
			$api.sessions.sessions.post(this.CreateSessionForm).then((response: AxiosResponse<SingleSession>) => {
				this.Session = response.data
				this.GetSessions('', filterQuery)
				this.GetCalendarSession(filterQuery)
				Logic.common.hideLoader()
				this.SessionLoaders.create = false

				Logic.common.showAlert("success", 'Session created');

			}).catch((error) => {
				Logic.common.showValidationError(error.response.data, sessionForm)
				this.SessionLoaders.create = false
				Logic.common.hideLoader()
			})
		}

	}

	public PayForSession = (sessionId: string, userIds: string[], redirectRoute = '/sessions') => {


		const allPayments: Promise<any>[] = []

		userIds.forEach((userId) => {
			allPayments.push(new Promise((resolve) => {
				this.PayForSessionForm.userId = userId
				const request = $api.sessions.sessions.PayForSession(sessionId, this.PayForSessionForm).then(() => {
					//
				})

				request?.then((value) => {
					resolve(value)
				})
			}))
		})

		this.SessionLoaders.create = true
		Logic.common.showLoader()

		Promise.all(allPayments).then(() => {
			this.SessionLoaders.create = false
			Logic.common.hideLoader()
			Logic.common.GoToRoute(redirectRoute)
			this.GetSession(sessionId)
			this.GetSessions('', 'upcoming')
			Logic.common.showAlert("success", 'Session payment successful');
		}).catch(() => {
			this.SessionLoaders.create = false
			Logic.common.hideLoader()
		})
	}

	public CancleSession = (formIsValid: boolean, sessionId: string) => {

		if (formIsValid) {
			Logic.common.showLoader()
			$api.sessions.sessions.CancleSession(sessionId, this.CancleSessionForm).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
				Logic.common.showAlert("success", 'Session cancel');
				Logic.common.hideLoader()
			}).catch((error: any) => {
				Logic.common.hideLoader()
				Logic.common.showAlert('error', error.response?.data[0].message)
			})
		}

	}

	public CloseSession = (sessionId: string) => {

		Logic.common.showLoader()
		$api.sessions.sessions.CloseSession(sessionId).then(() => {

			Logic.common.showAlert("success", 'Session closed');
			Logic.common.hideLoader()

		}).catch((error: AxiosError<any>) => {
			Logic.common.hideLoader()
			Logic.common.showAlert('error', error.response?.data[0].message)
		})

	}

	public RateSession = (sessionId: string) => {
		Logic.common.showLoader()
		$api.sessions.sessions.RateSession(sessionId, this.RateSessionForm).then(() => {

			Logic.common.showAlert("success", 'Thank you for your review');
			Logic.common.hideLoader()
		}).catch((error: AxiosError<any>) => {
			Logic.common.hideLoader()
			Logic.common.showAlert('error', error.response?.data[0].message)
		})

	}

	public JoinSession = (sessionId: string) => {

		Logic.common.showLoader()
		$api.sessions.sessions.JoinSession(sessionId).then((response: AxiosResponse<SessionTokens>) => {
			Logic.common.GoToRoute('/sessions/classroom?roomId=' + response.data.roomId + '&auth_token=' + response.data.authToken + '&name=' + response.data.userName)
			Logic.common.hideLoader()
		}).catch((error: AxiosError<any>) => {
			Logic.common.hideLoader()
			Logic.common.showAlert('error', error.response?.data[0].message)
		})

	}

	public GetSessionRecordings = (sessionId: string) => {
		return $api.sessions.sessions.GetSessionRecordings(sessionId).then((response) => {
			this.SessionRecordings = response.data
		})
	}




}