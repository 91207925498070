<template>
  <div
    v-if="Logic.common.alertConfig.show"
    class="w-full fixed z-[345678987654345] px-3 mdlg:max-w-[390px] top-[3%] left-0 mdlg:!top-[3%] mdlg:!left-auto mdlg:!right-[1%]"
  >
    <div
      :class="`w-full flex flex-row items-center space-x-2 px-3 py-3 border-[1px] animate-bounce rounded bg-white ${
        Logic.common.alertConfig.type == 'error' ? 'border-errorColor' : ''
      }${
        Logic.common.alertConfig.type == 'success' ? 'border-primaryGreen' : ''
      }${
        Logic.common.alertConfig.type == 'info' ? 'border-primaryPurple' : ''
      }`"
    >
      <ts-icon
        :name="`${
          Logic.common.alertConfig.type == 'error' ? 'alert-circle' : ''
        }${Logic.common.alertConfig.type == 'success' ? 'check-green' : ''}${
          Logic.common.alertConfig.type == 'info' ? 'alert-circle-purple' : ''
        }`"
        custom-class="h-[14px]"
      />

      <ts-normal-text color="'text-headerText'">
        {{ Logic.common.alertConfig.message }}
      </ts-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "tutorstack-frontend-logic";
import TsIcon from "../TsIcon/index.vue";
import TsNormalText from "../TsTypography/normalText.vue";
export default {
  components: {
    TsIcon,
    TsNormalText,
  },
  name: "TsAlert",
  setup() {
    return {
      Logic,
    };
  },
};
</script>
