<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!px-24 px-3 lg:!pt-10 mdlg:!pt-10 pt-4"
  >
    <ts-header-text
      :customClass="'w-full text-center lg:!text-xl'"
      :size="'xl'"
    >
      Verify your email
    </ts-header-text>

    <ts-form-wrapper
      class="w-full grid grid-cols-12 gap-2"
      :parentRefs="parentRefs"
      ref="verifyForm"
    >
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="Enter 6-digit code"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          v-model="EmailLogic.VerifyWithTokenForm.token"
          :hasTitle="true"
          name="Token"
          type="tel"
          :rules="[FormValidations.RequiredRule]"
          ref="token"
        >
          <template v-slot:title> Verification code </template>
        </ts-text-field>
      </div>
    </ts-form-wrapper>

    <div class="w-full flex flex-col pt-3">
      <ts-button :customClass="' w-full'" @click="VerifyEmail()">
        Verify
      </ts-button>
    </div>

    <div
      class="w-full flex flex-row space-x-1 items-center justify-center pt-1"
    >
      <ts-normal-text :color="'text-paragraphTextLight'"
        >Have an account?</ts-normal-text
      >
      <router-link to="/auth/login">
        <ts-normal-text :color="'text-primaryPurple'">Log in</ts-normal-text>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsTextField,
  TsButton,
  TsHeaderText,
  TsNormalText,
  TsFormWrapper,
} from "tutorstack-ui-components";
import { composables } from "../../composables";

export default defineComponent({
  components: {
    TsTextField,
    TsButton,
    TsHeaderText,
    TsNormalText,
    TsFormWrapper,
  },
  name: "VerifyEmailPage",
  layout: "Auth",
  setup() {
    useMeta({
      title: `Verify your email`,
    });

    const EmailLogic = composables.logic.auth.email;
    const FormValidations = composables.logic.forms.validations;

    const verifyForm = ref<any>(null);

    const VerifyEmail = () => {
      const formState: boolean = verifyForm.value.validate();
      EmailLogic.VerifyWithTokenForm.token = JSON.stringify(
        EmailLogic.VerifyWithTokenForm.token
      );
      EmailLogic.VerifyEmail(formState, verifyForm.value);
    };

    return {
      EmailLogic,
      VerifyEmail,
      verifyForm,
      FormValidations,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
