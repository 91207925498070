import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "w-full grid-cols-12 grid gap-2" }
const _hoisted_3 = { class: "flex flex-col col-span-12" }
const _hoisted_4 = { class: "flex flex-row justify-end w-full mt-3" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-3 pt-4 mdlg:!py-0 mdlg:px-0 px-3 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_text_area = _resolveComponent("ts-text-area")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_paragraph_text = _resolveComponent("ts-paragraph-text")!
  const _component_ts_icon_card = _resolveComponent("ts-icon-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ts_text_area, {
            placeholder: "Tell us how we can help",
            paddings: "py-3",
            hasTitle: true
          }, {
            title: _withCtx(() => [
              _createTextVNode(" Send a message ")
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ts_button, null, {
          default: _withCtx(() => [
            _createTextVNode(" Save ")
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ts_icon_card, {
        iconName: 'mail',
        iconSize: 'h-[15px]'
      }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_paragraph_text, { faded: true }, {
            default: _withCtx(() => [
              _createTextVNode(" Email ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ts_icon_card, {
        iconName: 'whatsapp',
        iconSize: 'h-[19px]'
      }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_paragraph_text, { faded: true }, {
            default: _withCtx(() => [
              _createTextVNode(" WhatsApp ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ts_icon_card, {
        iconName: 'twitter',
        iconSize: 'h-[16px]'
      }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_paragraph_text, { faded: true }, {
            default: _withCtx(() => [
              _createTextVNode(" Twitter ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ts_icon_card, {
        iconName: 'instagram',
        iconSize: 'h-[20px]'
      }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_paragraph_text, { faded: true }, {
            default: _withCtx(() => [
              _createTextVNode(" Instagram ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}