import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col" }
const _hoisted_2 = { class: "w-full flex flex-row items-center" }
const _hoisted_3 = ["placeholder", "type", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-row justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createBlock(_component_ts_normal_text, {
          key: 0,
          customClass: 'pb-2'
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "outer-prefix"),
      _createElementVNode("div", {
        class: _normalizeClass(`flew-grow w-full space-x-2 flex-row flex items-center justify-between ${_ctx.padding} ${_ctx.customClass} ${
          _ctx.validationStatus
            ? 'focus:!border-primaryPurple'
            : '!border-errorColor'
        } rounded`)
      }, [
        _renderSlot(_ctx.$slots, "inner-prefix"),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
          placeholder: _ctx.placeholder,
          type: _ctx.type,
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkValidation())),
          disabled: _ctx.disabled,
          onKeypress: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.isNumber && _ctx.isNumber(...args))),
          class: _normalizeClass(`flex-grow bg-transparent placeholder-gray-600 focus input w-full focus:outline-none `)
        }, null, 40, _hoisted_3), [
          [_vModelDynamic, _ctx.content]
        ]),
        _renderSlot(_ctx.$slots, "inner-suffix")
      ], 2),
      _renderSlot(_ctx.$slots, "outer-suffix")
    ]),
    (!_ctx.validationStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ts_normal_text, {
            customClass: 'pt-1 text-right',
            color: `text-errorColor`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}