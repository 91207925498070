<template>
  <div
    class="w-full flex flex-col justify-start items-start space-y-3 mdlg:px-0 px-3"
  >
    <div
      class="flex flex-row space-x-2 w-full justify-between items-center mdlg:pt-1 pt-2"
    >
      <div class="flex flex-col space-y-1 flex-grow items-start">
        <ts-header-text size="xl" custom-class="mdlg:flex hidden">
          {{ `Welcome ${FrontendLogic.auth.user.AuthUser?.allNames.first}` }}
        </ts-header-text>
        <ts-header-text size="lg" custom-class="mdlg:hidden flex">
          {{ `Welcome ${FrontendLogic.auth.user.AuthUser?.allNames.first}` }}
        </ts-header-text>

        <ts-paragraph-text :faded="true" custom-class="lg:text-base text-xs">
          {{
            TsCommon.accountType.value == "student"
              ? " Explore new ways to learn from expert "
              : "Take your tutoring to another level"
          }}
        </ts-paragraph-text>
      </div>

      <router-link
        v-if="UserProfile"
        :to="`${
          TsCommon.accountType.value == 'student'
            ? '/profile'
            : '/tutors/' + FrontendLogic.auth.user.AuthUser?.id
        }`"
      >
        <ts-avatar
          :photo-url="`${UserProfile?.bio.photo?.link}`"
          size="50"
          :bgColor="'bg-primaryPurple'"
          custom-class="mdlg:flex hidden"
        >
          {{ UserProfile?.bio.name.first.charAt(0) }}
        </ts-avatar>
        <ts-avatar
          :photo-url="`${UserProfile?.bio.photo?.link}`"
          size="40"
          custom-class="mdlg:hidden flex"
          :bgColor="'bg-primaryPurple'"
        >
          {{ UserProfile?.bio.name.first.charAt(0) }}
        </ts-avatar>
      </router-link>
    </div>

    <template v-if="TsCommon.accountType.value == 'student'">
      <div class="grid grid-cols-12 gap-3 pt-3 w-full">
        <ts-cta-card
          @click="FrontendLogic.common.GoToRoute('/sessions/book/private')"
          bg-color="bg-primaryPurple"
          custom-class="w-full mdlg:!h-[100px] h-[80px] md:col-span-4 col-span-12 cursor-pointer"
        >
          <template v-slot:header> Private tutoring </template>
          <template v-slot:content>
            One-on-one session with an expert tutor.
          </template>
        </ts-cta-card>

        <ts-cta-card
          @click="FrontendLogic.common.GoToRoute('/sessions/book/shared')"
          bg-color="bg-primaryGreen"
          custom-class="w-full mdlg:!h-[100px] h-[80px] md:col-span-4 col-span-12 cursor-pointer"
        >
          <template v-slot:header> Shared tutoring </template>
          <template v-slot:content
            >Split cost with up to 4 friends by sharing a session.
          </template>
        </ts-cta-card>

        <ts-cta-card
          @click="askAQuestion()"
          bg-color="bg-primaryBlue"
          custom-class="w-full mdlg:!h-[100px] h-[80px] md:col-span-4 col-span-12 cursor-pointer"
        >
          <template v-slot:header> Ask a question </template>
          <template v-slot:content>
            Get expert video answers to questions troubling you.
          </template>
        </ts-cta-card>
      </div>

      <div class="grid grid-cols-12 w-full gap-3 pt-3">
        <div class="col-span-12 flex flex-row justify-between items-center">
          <ts-header-text size="lg">Your Tutors </ts-header-text>
          <div>
            <router-link to="/tutors" class="text-primaryPurple"
              >View all</router-link
            >
          </div>
        </div>

        <template v-if="SavedTutors && SavedTutors?.results?.length > 0">
          <div
            class="col-span-12 flex flex-col space-y-2 cursor-pointer"
            v-for="(tutor, index) in SavedTutors.results"
            :key="index"
          >
            <ts-tutor-card custom-class="w-full" :data="tutor" />
          </div>
        </template>

        <ts-empty-state
          v-else
          :avatarUrl="'/images/saved-tutor.svg'"
          :avatarSize="'h-[80px]'"
          :ctaUrl="'/tutors'"
          :customClass="'h-[290px] mdlg:!h-[400px] rounded border-[1px] border-gray-200'"
        >
          <template v-slot:title> You have no tutors </template>
          <template v-slot:subtext>
            Browse through our wide range of expert tutors to find and save
            tutors that meet your specifications.
          </template>
          <template v-slot:cta-text> Explore tutors </template>
        </ts-empty-state>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col space-y-3 w-full pt-3">
        <ts-info-card>
          <template v-slot:header>
            ${{
              FrontendLogic.common.formatCurrency(UserWallet?.balance.amount)
            }}
          </template>

          <template v-slot:sub-header> Total earnings </template>
        </ts-info-card>

        <ts-info-card :bgColor="'bg-white'" :color="''">
          <template v-slot:header>
            ${{
              FrontendLogic.common.formatCurrency(UserWallet?.balance.amount)
            }}
          </template>

          <template v-slot:sub-header> Available balance </template>
        </ts-info-card>

        <ts-info-card :bgColor="'bg-white'" :color="''">
          <template v-slot:header>
            {{ UserProfile?.meta.sessionsHosted }}
          </template>

          <template v-slot:sub-header> Sessions completed </template>
        </ts-info-card>

        <ts-info-card :bgColor="'bg-white'" :color="''">
          <template v-slot:header> 0 </template>

          <template v-slot:sub-header> Students tutored </template>
        </ts-info-card>

        <ts-info-card :bgColor="'bg-white'" :color="''">
          <template v-slot:header> {{ UserProfile?.meta.answers }} </template>

          <template v-slot:sub-header> Questions answered </template>
        </ts-info-card>
      </div>
    </template>
  </div>

  <ts-modal
    :canClose="true"
    :close="
      () => {
        showSubscribePrompt = false;
      }
    "
    v-if="
      showSubscribePrompt &&
      TsCommon.accountType.value != 'tutor' &&
      UserWallet?.subscription.active == false
    "
  >
    <ts-subscribe-prompt
      :close="
        () => {
          showSubscribePrompt = false;
        }
      "
      :remainingQuestions="UserWallet?.subscription.data.questions"
    ></ts-subscribe-prompt>
  </ts-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsHeaderText,
  TsTutorCard,
  TsAvatar,
  TsCtaCard,
  TsParagraphText,
  TsInfoCard,
  TsEmptyState,
  TsModal,
  TsSubscribePrompt,
} from "tutorstack-ui-components";
import { composables } from "../composables";
import {
  Paginated,
  SingleUser,
  Tutor,
  UserWallet,
} from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsHeaderText,
    TsTutorCard,
    TsAvatar,
    TsCtaCard,
    TsInfoCard,
    TsParagraphText,
    TsEmptyState,
    TsModal,
    TsSubscribePrompt,
  },
  name: "HomePage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Home",
    });

    const showSubscribePrompt = ref(false);

    const SavedTutors = ref<Paginated<Tutor>>();

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const UserProfile = ref<SingleUser>();
    const UserWallet = ref<UserWallet>();

    onMounted(() => {
      TsCommon.showBottomBar.value = true;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "basic";
      TsCommon.pageTopBarOptions.pageTitle = "Home";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.hasNotification = true;
      TsCommon.pageTopBarOptions.showBottomBorder = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.users.users.watchProperty("SavedTutors", SavedTutors);
      FrontendLogic.users.users.watchProperty("UserProfile", UserProfile);
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
      TsCommon.accountType.value = FrontendLogic.auth.user.AuthUser?.roles
        .isTutor
        ? "tutor"
        : "student";
    });

    onUnmounted(() => {
      FrontendLogic.users.users.stopWatchAction();
    });

    const askAQuestion = () => {
      if (UserWallet.value?.subscription.active == false) {
        showSubscribePrompt.value = true;
        return;
      }
      FrontendLogic.common.GoToRoute("/questions/ask");
    };

    return {
      TsCommon,
      FrontendLogic,
      SavedTutors,
      UserProfile,
      UserWallet,
      showSubscribePrompt,
      askAQuestion,
    };
  },
});
</script>
