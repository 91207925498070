import { AxiosResponse } from 'axios';
import { SignUpForm, VerifyWithTokenForm } from '../../types/forms/auth';
import { SignInForm } from '../../types/forms/auth';
import { SendResetMailForm } from "../../types/forms";
import { $api } from "../../../services";
import { AuthResponse } from '../../types/domains';
import Common from '../Common';
import { reactive } from 'vue';
import { Logic } from '..';

export default class Emails extends Common {
	constructor() {
		// initiate things here
		super()
		this.Tokens = localStorage.getItem('AuthTokens') ? JSON.parse(localStorage.getItem('AuthTokens') || '{}') : {}
	}

	public Tokens = {
		accessToken: '',
		refreshToken: ''
	}

	public VerificationMailForm: SendResetMailForm = {
		email: ''
	}

	public SignInForm: SignInForm = {
		email: '',
		password: ''
	}

	public SignUpForm: SignUpForm = {
		firstName: '',
		lastName: '',
		password: '',
		email: '',
		description: ''
	}

	public AuthLoaders = reactive({
		signUp: false,
		signIn: false,
		verifyEmail: false
	})

	public VerifyWithTokenForm: VerifyWithTokenForm = {
		token: ''
	}

	public SaveUserToLocal = () => {
		Logic.users.users.UserProfile = undefined
		Logic.payments.wallet.UserWallet = undefined
		Logic.payments.wallet.PaymentMethods = undefined
		Logic.notifications.notifications.Notifications = undefined
		Logic.sessions.sessions.Sessions = undefined
		Logic.sessions.sessions.SessionsByMonth = undefined
		Logic.users.tutors.Tutors = undefined
		Logic.questions.questions.AllQuestions = undefined
		localStorage.setItem('AuthUser', JSON.stringify(Logic.auth.user.AuthUser))
		// Get user profile
		Logic.users.users.GetUserProfile(Logic.auth.user.AuthUser?.id || '')
	}

	public SetTokens = (AuthResponse: AuthResponse) => {
		this.Tokens.accessToken = AuthResponse.accessToken
		this.Tokens.refreshToken = AuthResponse.refreshToken
		localStorage.setItem('AuthTokens', JSON.stringify(this.Tokens))
	}

	public SendVerificationMail = (formIsValid: boolean) => {
		try {
			if (formIsValid) {
				$api.auth.emails.SendVerifyEmail(this.VerificationMailForm).then((response) => {
					console.log(response)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public SignIn = (formIsValid: boolean, loginForm: any) => {

		if (formIsValid) {
			this.AuthLoaders.signIn = true
			Logic.common.showLoader()
			$api.auth.emails.SignIn(this.SignInForm).then((response) => {
				Logic.auth.user.AuthUser = response.data.user
				this.SaveUserToLocal()
				this.SetTokens(response.data)
				this.AuthLoaders.signIn = false
				Logic.common.hideLoader()
				if (response.data.user.isVerified) {
					Logic.common.router?.push('/')
				} else {
					this.VerificationMailForm.email = response.data.user.email
					this.SendVerificationMail(true)
					Logic.common.router?.push('/auth/verify-email')
				}

			}).catch((error: any) => {
				Logic.common.showValidationError(error.response.data, loginForm)
				this.AuthLoaders.signIn = false
				Logic.common.hideLoader()
			})
		}

	}

	public SignUp = (formIsValid: boolean, registerForm: any) => {

		if (formIsValid) {
			this.AuthLoaders.signUp = true
			Logic.common.showLoader()
			$api.auth.emails.SignUp(this.SignUpForm).then((response) => {
				this.AuthLoaders.signUp = false
				Logic.common.hideLoader()
				Logic.auth.user.AuthUser = response.data.user
				this.SetTokens(response.data)
				this.SaveUserToLocal()
				this.VerificationMailForm.email = response.data.user.email
				this.SendVerificationMail(true)
				Logic.common.router?.push('/auth/verify-email')

			}).catch((error: any) => {
				Logic.common.showValidationError(error.response.data, registerForm)
				this.AuthLoaders.signUp = false
				Logic.common.hideLoader()
			})
		}

	}

	public VerifyEmail = (formIsValid: boolean, verifyForm: any) => {

		if (formIsValid) {
			this.AuthLoaders.verifyEmail = true
			Logic.common.showLoader()
			$api.auth.emails.VerifyEmail(this.VerifyWithTokenForm).then((response: AxiosResponse<AuthResponse>) => {
				this.AuthLoaders.verifyEmail = false
				Logic.common.hideLoader()
				Logic.auth.user.AuthUser = response.data.user
				this.SetTokens(response.data)
				this.SaveUserToLocal()
				Logic.common.router?.push('/')

			}).catch((error: any) => {
				Logic.common.showValidationError(error.response.data, verifyForm)
				this.AuthLoaders.verifyEmail = false
				Logic.common.hideLoader()
			})
		}

	}

}