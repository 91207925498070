<template>
  <div :class="`flex flex-col space-y-2 ${defaultSize}`">
    <ts-normal-text v-if="hasTitle">
      <slot name="title" />
    </ts-normal-text>
    <div
      :class="`flex relative flex-row items-center space-x-1 justify-between w-full ${customClass} `"
      :id="'container' + tabIndex"
      @focus="
        showOption = true;
        isFocused = true;
        ShowSelectModal = true;
      "
      @blur="
        isFocused = false;
        showOption = false;
      "
      :tabindex="tabIndex"
      @click="ShowSelectModal = true"
    >
      <input
        ref="select"
        v-model="textValue"
        :placeholder="placeholder"
        v-if="!isMultiple"
        @focus="
          showOption = true;
          isFocused = true;
        "
        @blur="
          isFocused = false;
          showOption = false;
        "
        :disabled="!autoComplete"
        :class="` ${paddings} flex-grow cursor-pointer bg-transparent placeholder-gray-600 focus input w-full filled focus:outline-none rounded-md focus:border-primary`"
      />
      <div
        v-else
        @click="
          showOption = true;
          isFocused = true;
        "
        :class="` ${paddings} flex-grow  cursor-pointer bg-transparent text-gray-600 focus input border-[1px] w-full filled focus:outline-none
		rounded-md focus:border-primary border-gray-200 bg-white`"
      >
        {{ selectedItems.length == 0 ? "Select" : "" }}
        <span v-for="(selected, index) in selectedItems" :key="index"
          >{{ getSelectedOption(selected) }}
          {{ index == selectedItems.length - 1 ? "" : ", " }}</span
        >
      </div>
      <ts-icon
        @click="
          showOption = true;
          isFocused = true;
        "
        name="dropdown"
        custom-class="md:h-[7px] h-[6px] cursor-pointer"
      />

      <div
        v-if="showOption"
        class="absolute top-[90%] hidden mdlg:!flex flex-col left-0 w-full py-2 px-2 max-h-48 overflow-y-auto z-40 bg-white drop-shadow-lg"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          class="py-3 px-3 hover:bg-gray-100 flex flex-row items-center space-x-2 cursor-pointer"
          @click.stop="selectValue(option)"
        >
          <span
            :class="`h-[10px] w-[10px] rounded-full border-[1px] border-primary ${
              itemIsSelected(option.key) ? 'bg-primary' : ''
            }`"
            v-if="isMultiple"
          ></span>
          <span>{{ withKey ? option.key + " - " : "" }} {{ option.value }}</span>
        </div>
      </div>
    </div>
  </div>
  <ts-modal
    :canClose="true"
    custom-class="mdlg:!hidden"
    :close="
      () => {
        ShowSelectModal = false;
      }
    "
    v-if="ShowSelectModal"
  >
    <div
      @click.stop="true"
      class="rounded-t-md flex flex-col space-y-4 bg-white w-full absolute overflow-y-auto h-auto max-h-[80%] bottom-0 left-0 pb-3 px-3 lg:!text-sm mdlg:!text-[12px] text-xs"
    >
      <div class="flex items-center justify-center sticky top-0 bg-white w-full pt-3">
        <span class="bg-gray-200 rounded-full w-[30px] h-[4px]"></span>
      </div>
      <div class="border-b-[1px] w-full border-gray-500" v-if="autoComplete">
        <input
          ref="select"
          v-model="textValue"
          :placeholder="placeholder"
          autofocus
          :class="`${paddings} flex-grow cursor-pointer bg-transparent placeholder-gray-600 focus input w-full filled focus:outline-none rounded-md focus:border-primary`"
        />
      </div>
      <ts-radio-card :options="selectOptions" v-model="selectedKey" @click.stop="true" />
    </div>
  </ts-modal>
</template>

<script lang="ts">
import { onMounted, ref, toRef, defineComponent, watch } from "vue";
import TsIcon from "../TsIcon/index.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsModal from "../TsModal";
import TsRadioCard from "./radioCard.vue";
import { SelectOption } from "tutorstack-frontend-logic";

export default defineComponent({
  name: "TsSelect",
  components: {
    TsIcon,
    TsNormalText,
    TsRadioCard,
    TsModal,
  },
  props: {
    withKey: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Object as () => SelectOption[],
    },
    paddings: {
      type: String,
      default: "py-4 px-3",
    },
    customClass: {
      type: String,
      default: "",
    },
    defaultValues: {
      required: false,
      default: [],
    },
    modelValue: {
      type: String || Array,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    defaultSize: {
      type: String,
      default: "w-full",
    },
  },
  emits: ["update:modelValue", "OnSearch", "OnOptionSelected"],
  setup(props: any, context: any) {
    const isFocused = ref(false);
    const showOption = ref(false);

    const tabIndex = Math.random();

    const ShowSelectModal = ref(false);

    const selectedKey = ref();

    const valueData = toRef(props, "modelValue");

    const textValue = ref("");

    const selectOptions = ref<any[]>([]);

    const prepareSelectOptions = () => {
      selectOptions.value.length = 0;
      selectOptions.value.push({
        key: 0,
        name: [props.placeholder],
      });

      if (Array.isArray(props.options)) {
        props.options.forEach((item: any) => {
          selectOptions.value.push({
            key: item.key,
            name: [item.value],
          });
        });
      }
    };

    watch(selectedKey, () => {
      if (selectedKey.value != 0) {
        const selectedOption = props.options.filter((eachItem: any) => {
          return eachItem.key == selectedKey.value;
        });
        selectValue(selectedOption[0]);
        ShowSelectModal.value = false;
      }
    });

    watch(textValue, () => {
      context.emit("OnSearch", textValue.value);
    });

    const selectedItems = ref<any>([]);

    onMounted(() => {
      if (props.modelValue) {
        const selectedOption = props.options.filter((eachItem: any) => {
          return eachItem.key == props.modelValue;
        });

        if (selectedOption.length > 0) {
          selectValue(selectedOption[0]);
        }
      }
      prepareSelectOptions();
    });

    watch(props, () => {
      prepareSelectOptions();
    });

    onMounted(() => {
      if (props.defaultValues.length > 0) {
        selectedItems.value = props.defaultValues;
      }
    });

    const itemIsSelected = (inputKey: string) => {
      const item = selectedItems.value.filter((key: any) => {
        return key == inputKey;
      });

      return item.length > 0;
    };

    const selectValue = (option: any) => {
      if (props.autoComplete) {
        context.emit("OnOptionSelected", option.key);

        isFocused.value = false;
        showOption.value = false;

        document.getElementById("container" + tabIndex)?.blur();
        return;
      }
      if (props.isMultiple) {
        if (itemIsSelected(option.key)) {
          selectedItems.value = selectedItems.value.filter((key: any) => {
            return key != option.key;
          });

          return;
        }
        selectedItems.value.push(option.key);
        context.emit("update:modelValue", selectedItems.value);
      } else {
        context.emit("update:modelValue", option.key);
        if (props.withKey) {
          valueData.value = option.key;
        } else {
          valueData.value = option.value;
          textValue.value = option.value;
        }
        isFocused.value = false;
        showOption.value = false;

        document.getElementById("container" + tabIndex)?.blur();
      }
    };

    const getSelectedOption = (keyValue: any) => {
      const option = props.options.filter((eachItem: any) => {
        return eachItem.key == keyValue;
      });

      return option[0].value;
    };

    return {
      showOption,
      valueData,
      isFocused,
      selectValue,
      tabIndex,
      textValue,
      itemIsSelected,
      selectedItems,
      getSelectedOption,
      ShowSelectModal,
      selectOptions,
      selectedKey,
    };
  },
});
</script>
