import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex flex-row items-center space-x-1 ${$props.customClass}`)
  }, [
    _createVNode(_component_ts_icon, {
      name: `${$setup.ratings >= 1 ? 'star_active' : 'star'}`,
      "custom-class": $props.size,
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.readonly ? '' : ($setup.ratings = 1)))
    }, null, 8, ["name", "custom-class"]),
    _createVNode(_component_ts_icon, {
      name: `${$setup.ratings >= 2 ? 'star_active' : 'star'}`,
      "custom-class": $props.size,
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($props.readonly ? '' : ($setup.ratings = 2)))
    }, null, 8, ["name", "custom-class"]),
    _createVNode(_component_ts_icon, {
      name: `${$setup.ratings >= 3 ? 'star_active' : 'star'}`,
      "custom-class": $props.size,
      onClick: _cache[2] || (_cache[2] = ($event: any) => ($props.readonly ? '' : ($setup.ratings = 3)))
    }, null, 8, ["name", "custom-class"]),
    _createVNode(_component_ts_icon, {
      name: `${$setup.ratings >= 4 ? 'star_active' : 'star'}`,
      "custom-class": $props.size,
      onClick: _cache[3] || (_cache[3] = ($event: any) => ($props.readonly ? '' : ($setup.ratings = 4)))
    }, null, 8, ["name", "custom-class"]),
    _createVNode(_component_ts_icon, {
      name: `${$setup.ratings >= 5 ? 'star_active' : 'star'}`,
      "custom-class": $props.size,
      onClick: _cache[4] || (_cache[4] = ($event: any) => ($props.readonly ? '' : ($setup.ratings = 5)))
    }, null, 8, ["name", "custom-class"])
  ], 2))
}