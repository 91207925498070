<template>
  <div
    v-if="data"
    class="w-full flex flex-col justify-start items-start space-y-3 pt-2 mdlg:!px-5 mdlg:!py-5 py-3 px-3 border-[1px] rounded"
  >
    <div class="w-full flex flex-col space-y-2 items-start">
      <ts-header-text :size="'base'"> {{ data.topic }} </ts-header-text>
      <ts-paragraph-text :customClass="'text-left'">
        {{ data.description }}
      </ts-paragraph-text>
    </div>

    <div class="w-full flex flex-row items-center space-x-2 justify-start pt-3">
      <ts-icon :name="'tutors_active'" :customClass="'h-[16px]'" />
      <ts-paragraph-text> <slot name="subject" /> </ts-paragraph-text>
      <span :class="`h-[4px] w-[4px] bg-paragraphText rounded-full`"></span>
      <ts-paragraph-text> Private Session </ts-paragraph-text>
    </div>

    <div class="w-full flex flex-row items-center space-x-2 justify-start">
      <ts-icon :name="'calender'" :customClass="'h-[18px]'" />
      <ts-paragraph-text> <slot name="date" /> </ts-paragraph-text>
      <span :class="`h-[4px] w-[4px] bg-paragraphText rounded-full`"></span>
      <ts-paragraph-text>
        11am - 12pm <slot name="time-from-to" />
      </ts-paragraph-text>
    </div>

    <div class="w-full flex flex-row items-center space-x-2 justify-start">
      <ts-icon :name="'attachment'" :customClass="'h-[12px]'" />
      <ts-paragraph-text>
        {{ data.attachments.length }} attachments
      </ts-paragraph-text>
    </div>

    <div class="w-full flex flex-row items-center space-x-2 justify-start">
      <ts-avatar size="20" :photoUrl="data.tutor.bio.photo?.link || ''" />
      <ts-paragraph-text> {{ data.tutor.bio.name.full }} </ts-paragraph-text>
    </div>

    <div class="w-full flex flex-row items-center space-x-2 justify-start">
      <ts-icon :name="'money-green'" :customClass="'h-[16px]'" />
      <ts-normal-text :color="'text-primaryGreen'">
        ${{ data.price }}
      </ts-normal-text>
    </div>

    <slot name="extras" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TsHeaderText from "../TsTypography/headerText.vue";
import TsParagraphText from "../TsTypography/paragraphText.vue";
import TsIcon from "../TsIcon/index.vue";
import TsAvatar from "../TsAvatar/index.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import { SingleSession } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsHeaderText,
    TsParagraphText,
    TsIcon,
    TsAvatar,
    TsNormalText,
  },
  props: {
    bgColor: {
      type: String,
      default: "bg-primaryPurple",
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    customClass: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "py-2 px-4 ",
    },
    data: {
      type: Object as () => SingleSession,
    },
  },
  name: "TsSessionInfo",
});
</script>
