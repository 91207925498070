<template>
  <div
    @click.stop="true"
    class="rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
  >
    <div class="w-full flex flex-row items-center justify-center">
      <ts-icon name="check-circle" customClass="h-[40px]" />
    </div>
    <ts-header-text :size="'lg'" :customClass="' w-full text-center pt-3'">
      Question asked successfully
    </ts-header-text>
    <div class="w-full flex flex-row items-center justify-center py-2 pt-1">
      <ts-normal-text customClass="text-center">
        An expert tutor will answer with a video soon.
      </ts-normal-text>
    </div>

    <div
      class="flex flex-row items-center justify-center rounded px-3 py-3 bg-[#ECEEF2] mt-2"
    >
      <span
        >You have
        <span class="font-bold"
          >{{ remainingQuestions }} question{{
            remainingQuestions > 1 ? "s" : ""
          }}</span
        >
        to ask</span
      >
    </div>

    <div class="w-full py-3 grid grid-cols-12 gap-2">
      <ts-button
        :customClass="'border-[1px] border-primaryPurple col-span-12'"
        :padding="'px-4 py-3'"
        @click.stop="close ? close() : ''"
      >
        Got it
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";

import TsIcon from "../TsIcon/index.vue";
import TsButton from "../TsButton/index.vue";
import { Logic } from "tutorstack-frontend-logic";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsIcon,
    TsButton,
  },
  props: {
    close: Function,
    remainingQuestions: {
      type: Number,
      default: 0,
    },
  },
  name: "TsQuestionPrompt",
  setup() {
    return {
      Logic,
    };
  },
};
</script>
