import { SubscribeDeviceForm, ToggleNotificationForm } from "../../../logic/types/forms/notifications";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";

export default class NotificationsApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("notifications/notifications");
	}

	public async ToggleNotification(notificationId: string, data: ToggleNotificationForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.put(
				this.getUrl(notificationId) + '/seen',
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async SubscribeDevice(data: SubscribeDeviceForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl() + '/devices/subscribe',
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async UnSubscribeDevice(data: SubscribeDeviceForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl() + '/devices/unsubscribe',
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}
}