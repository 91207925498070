<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!px-20 px-3 lg:!pt-52 mdlg:!pt-32 pt-12"
  >
    <ts-header-text
      :customClass="'w-full text-center lg:!text-xl'"
      :size="'xl'"
    >
      Log in and start learning
    </ts-header-text>

    <div class="w-full flex flex-row items-center justify-center">
      <GoogleLogin
        :client-id="composables.common.GoogleClientID"
        :callback="authCompleted"
        prompt
      />
    </div>

    <div class="w-full flex flex-row items-center space-x-1 justify-between">
      <span class="border-t-[1px] border-gray-200 flex-grow"> </span>
      <span class="px-3 pt-3">
        <ts-normal-text :color="'text-paragraphTextLight'"
          >Or sign in with</ts-normal-text
        >
      </span>
      <span class="border-t-[1px] border-gray-200 flex-grow"> </span>
    </div>

    <ts-form-wrapper
      class="w-full grid grid-cols-12 gap-2"
      :parentRefs="parentRefs"
      ref="loginForm"
    >
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="ginny@weasely.com"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          name="Email"
          v-model="EmailLogic.SignInForm.email"
          ref="email"
          :rules="[FormValidations.RequiredRule, FormValidations.EmailRule]"
          :hasTitle="true"
        >
          <template v-slot:title> Email </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="password"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          type="password"
          ref="password"
          :rules="[FormValidations.RequiredRule]"
          v-model="EmailLogic.SignInForm.password"
          name="Password"
        >
          <template v-slot:title> Password </template>
        </ts-text-field>
      </div>
    </ts-form-wrapper>

    <div class="w-full text-center items-center justify-center flex">
      <ts-normal-text :color="'text-primaryPurple'"
        >Forgot Password</ts-normal-text
      >
    </div>

    <div class="w-full flex flex-col">
      <ts-button
        :customClass="' w-full'"
        @click="SignIn()"
        :loading="AuthLoaders?.signIn"
      >
        Log in
      </ts-button>
    </div>

    <div
      class="w-full flex flex-row space-x-2 items-center justify-center pt-1"
    >
      <ts-normal-text :color="'text-paragraphTextLight'"
        >Don’t have an account?</ts-normal-text
      >
      <router-link to="/auth/register">
        <ts-normal-text :color="'text-primaryPurple'"
          >Create account</ts-normal-text
        >
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsTextField,
  TsButton,
  TsHeaderText,
  TsNormalText,
  TsFormWrapper,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { GoogleLogin } from "vue3-google-login";

export default defineComponent({
  components: {
    TsTextField,
    TsButton,
    TsHeaderText,
    TsNormalText,
    TsFormWrapper,
    GoogleLogin,
  },
  name: "LoginPage",
  layout: "Auth",
  setup() {
    useMeta({
      title: `Log in and start learning`,
    });

    const FrontendLogic = composables.logic;
    const EmailLogic = composables.logic.auth.email;
    const FormValidations = composables.logic.forms.validations;

    const AuthLoaders = ref<any>();

    const loginForm = ref<any>(null);

    const SignIn = () => {
      const formState: boolean = loginForm.value.validate();
      EmailLogic.SignIn(formState, loginForm.value);
    };

    onMounted(() => {
      FrontendLogic.auth.email.watchProperty("AuthLoaders", AuthLoaders);
    });

    const authCompleted = (response: any) => {
      FrontendLogic.auth.identities.GoogleSignInForm.idToken =
        response.credential;
      FrontendLogic.auth.identities.GoogleSignIn();
    };

    return {
      EmailLogic,
      SignIn,
      loginForm,
      FormValidations,
      AuthLoaders,
      composables,
      authCompleted,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
