import { AuthUser } from "../../../logic/types/domains/auth";
import { ResetPasswordForm, SendResetMailForm, UpdatePasswordForm } from "../../../logic/types/forms/auth";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";

export default class PassowrdApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("auth/passwords");
	}

	public async SendResetPassword(data: SendResetMailForm): Promise<AxiosResponse<void>> {
		try {
			const response: AxiosResponse<void> = await this.axiosInstance.post(
				this.getUrl() + "/reset/mail",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async ResetPassword(data: ResetPasswordForm): Promise<AxiosResponse<AuthUser>> {
		try {
			const response: AxiosResponse<AuthUser> = await this.axiosInstance.post(
				this.getUrl() + "/reset",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async UpdatePassword(data: UpdatePasswordForm): Promise<AxiosResponse<void>> {
		try {
			const response: AxiosResponse<void> = await this.axiosInstance.post(
				this.getUrl() + "/update",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}


}