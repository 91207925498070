import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-start items-start space-y-3 mdlg:px-0 px-3" }
const _hoisted_2 = { class: "flex flex-row space-x-2 w-full justify-between items-center mdlg:pt-1 pt-2" }
const _hoisted_3 = { class: "flex flex-col space-y-1 flex-grow items-start" }
const _hoisted_4 = { class: "grid grid-cols-12 gap-3 pt-3 w-full" }
const _hoisted_5 = { class: "grid grid-cols-12 w-full gap-3 pt-3" }
const _hoisted_6 = { class: "col-span-12 flex flex-row justify-between items-center" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col space-y-3 w-full pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_paragraph_text = _resolveComponent("ts-paragraph-text")!
  const _component_ts_avatar = _resolveComponent("ts-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ts_cta_card = _resolveComponent("ts-cta-card")!
  const _component_ts_tutor_card = _resolveComponent("ts-tutor-card")!
  const _component_ts_empty_state = _resolveComponent("ts-empty-state")!
  const _component_ts_info_card = _resolveComponent("ts-info-card")!
  const _component_ts_subscribe_prompt = _resolveComponent("ts-subscribe-prompt")!
  const _component_ts_modal = _resolveComponent("ts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ts_header_text, {
            size: "xl",
            "custom-class": "mdlg:flex hidden"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`Welcome ${_ctx.FrontendLogic.auth.user.AuthUser?.allNames.first}`), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ts_header_text, {
            size: "lg",
            "custom-class": "mdlg:hidden flex"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`Welcome ${_ctx.FrontendLogic.auth.user.AuthUser?.allNames.first}`), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ts_paragraph_text, {
            faded: true,
            "custom-class": "lg:text-base text-xs"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.TsCommon.accountType.value == "student"
              ? " Explore new ways to learn from expert "
              : "Take your tutoring to another level"), 1)
            ]),
            _: 1
          })
        ]),
        (_ctx.UserProfile)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `${
          _ctx.TsCommon.accountType.value == 'student'
            ? '/profile'
            : '/tutors/' + _ctx.FrontendLogic.auth.user.AuthUser?.id
        }`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ts_avatar, {
                  "photo-url": `${_ctx.UserProfile?.bio.photo?.link}`,
                  size: "50",
                  bgColor: 'bg-primaryPurple',
                  "custom-class": "mdlg:flex hidden"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.UserProfile?.bio.name.first.charAt(0)), 1)
                  ]),
                  _: 1
                }, 8, ["photo-url"]),
                _createVNode(_component_ts_avatar, {
                  "photo-url": `${_ctx.UserProfile?.bio.photo?.link}`,
                  size: "40",
                  "custom-class": "mdlg:hidden flex",
                  bgColor: 'bg-primaryPurple'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.UserProfile?.bio.name.first.charAt(0)), 1)
                  ]),
                  _: 1
                }, 8, ["photo-url"])
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.TsCommon.accountType.value == 'student')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ts_cta_card, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.FrontendLogic.common.GoToRoute('/sessions/book/private'))),
                "bg-color": "bg-primaryPurple",
                "custom-class": "w-full mdlg:!h-[100px] h-[80px] md:col-span-4 col-span-12 cursor-pointer"
              }, {
                header: _withCtx(() => [
                  _createTextVNode(" Private tutoring ")
                ]),
                content: _withCtx(() => [
                  _createTextVNode(" One-on-one session with an expert tutor. ")
                ]),
                _: 1
              }),
              _createVNode(_component_ts_cta_card, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.FrontendLogic.common.GoToRoute('/sessions/book/shared'))),
                "bg-color": "bg-primaryGreen",
                "custom-class": "w-full mdlg:!h-[100px] h-[80px] md:col-span-4 col-span-12 cursor-pointer"
              }, {
                header: _withCtx(() => [
                  _createTextVNode(" Shared tutoring ")
                ]),
                content: _withCtx(() => [
                  _createTextVNode("Split cost with up to 4 friends by sharing a session. ")
                ]),
                _: 1
              }),
              _createVNode(_component_ts_cta_card, {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.askAQuestion())),
                "bg-color": "bg-primaryBlue",
                "custom-class": "w-full mdlg:!h-[100px] h-[80px] md:col-span-4 col-span-12 cursor-pointer"
              }, {
                header: _withCtx(() => [
                  _createTextVNode(" Ask a question ")
                ]),
                content: _withCtx(() => [
                  _createTextVNode(" Get expert video answers to questions troubling you. ")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ts_header_text, { size: "lg" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Your Tutors ")
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createVNode(_component_router_link, {
                    to: "/tutors",
                    class: "text-primaryPurple"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("View all")
                    ]),
                    _: 1
                  })
                ])
              ]),
              (_ctx.SavedTutors && _ctx.SavedTutors?.results?.length > 0)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.SavedTutors.results, (tutor, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-span-12 flex flex-col space-y-2 cursor-pointer",
                      key: index
                    }, [
                      _createVNode(_component_ts_tutor_card, {
                        "custom-class": "w-full",
                        data: tutor
                      }, null, 8, ["data"])
                    ]))
                  }), 128))
                : (_openBlock(), _createBlock(_component_ts_empty_state, {
                    key: 1,
                    avatarUrl: '/images/saved-tutor.svg',
                    avatarSize: 'h-[80px]',
                    ctaUrl: '/tutors',
                    customClass: 'h-[290px] mdlg:!h-[400px] rounded border-[1px] border-gray-200'
                  }, {
                    title: _withCtx(() => [
                      _createTextVNode(" You have no tutors ")
                    ]),
                    subtext: _withCtx(() => [
                      _createTextVNode(" Browse through our wide range of expert tutors to find and save tutors that meet your specifications. ")
                    ]),
                    "cta-text": _withCtx(() => [
                      _createTextVNode(" Explore tutors ")
                    ]),
                    _: 1
                  }))
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_ts_info_card, null, {
              header: _withCtx(() => [
                _createTextVNode(" $" + _toDisplayString(_ctx.FrontendLogic.common.formatCurrency(_ctx.UserWallet?.balance.amount)), 1)
              ]),
              "sub-header": _withCtx(() => [
                _createTextVNode(" Total earnings ")
              ]),
              _: 1
            }),
            _createVNode(_component_ts_info_card, {
              bgColor: 'bg-white',
              color: ''
            }, {
              header: _withCtx(() => [
                _createTextVNode(" $" + _toDisplayString(_ctx.FrontendLogic.common.formatCurrency(_ctx.UserWallet?.balance.amount)), 1)
              ]),
              "sub-header": _withCtx(() => [
                _createTextVNode(" Available balance ")
              ]),
              _: 1
            }),
            _createVNode(_component_ts_info_card, {
              bgColor: 'bg-white',
              color: ''
            }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.UserProfile?.meta.sessionsHosted), 1)
              ]),
              "sub-header": _withCtx(() => [
                _createTextVNode(" Sessions completed ")
              ]),
              _: 1
            }),
            _createVNode(_component_ts_info_card, {
              bgColor: 'bg-white',
              color: ''
            }, {
              header: _withCtx(() => [
                _createTextVNode(" 0 ")
              ]),
              "sub-header": _withCtx(() => [
                _createTextVNode(" Students tutored ")
              ]),
              _: 1
            }),
            _createVNode(_component_ts_info_card, {
              bgColor: 'bg-white',
              color: ''
            }, {
              header: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.UserProfile?.meta.answers), 1)
              ]),
              "sub-header": _withCtx(() => [
                _createTextVNode(" Questions answered ")
              ]),
              _: 1
            })
          ]))
    ]),
    (
      _ctx.showSubscribePrompt &&
      _ctx.TsCommon.accountType.value != 'tutor' &&
      _ctx.UserWallet?.subscription.active == false
    )
      ? (_openBlock(), _createBlock(_component_ts_modal, {
          key: 0,
          canClose: true,
          close: 
      () => {
        _ctx.showSubscribePrompt = false;
      }
    
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_subscribe_prompt, {
              close: 
        () => {
          _ctx.showSubscribePrompt = false;
        }
      ,
              remainingQuestions: _ctx.UserWallet?.subscription.data.questions
            }, null, 8, ["close", "remainingQuestions"])
          ]),
          _: 1
        }, 8, ["close"]))
      : _createCommentVNode("", true)
  ], 64))
}