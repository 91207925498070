import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "",
    class: _normalizeClass(`${_ctx.customClass} blend-in`),
    style: _normalizeStyle([{"background-size":"cover","background-repeat":"no-repeat","background-position":"center"}, ` ${
      _ctx.imageUrl ? `background-image:url(${_ctx.imageUrl});` : ''
    }  ${_ctx.customStyle}`])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}