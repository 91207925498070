import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-1 items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(` border-[1px] border-gray-200 ${$props.customClass} w-full col-span-full py-3 px-3 mdlg:!px-5 mdlg:!py-5 ${$props.bgColor} rounded flex justify-between flex-row items-center`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ts_header_text, {
        size: 'xl',
        color: `${$props.color}`
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _: 3
      }, 8, ["color"]),
      _createVNode(_component_ts_normal_text, {
        color: `${$props.color}`
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "sub-header")
        ]),
        _: 3
      }, 8, ["color"])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "extra")
    ])
  ], 2))
}