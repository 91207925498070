<template>
  <span
    :class="` 
	${customClass} 
	${faded ? 'text-paragraphTextLight' : 'text-paragraphText'}`"
  >
    <slot />
    <span class="text-primaryPurple" v-if="hasReadmore">Read more</span>
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    faded: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    hasReadmore: {
      type: Boolean,
      default: false,
    },
  },
  name: "TsParagraphText",
});
</script>
