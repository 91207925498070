<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div
      @click="
        UserWallet?.subscription.active
          ? ''
          : FrontendLogic.common.GoToRoute('/profile/plans')
      "
      class="flex flex-row items-center justify-center rounded px-3 py-3 bg-[#ECEEF2] cursor-pointer"
    >
      <span v-if="UserWallet?.subscription.active"
        >You have
        <span class="font-bold"
          >{{ UserWallet?.subscription.data.questions }} questions</span
        >
        to ask</span
      >

      <span v-else>Upgrade to Tutor Stack Premium</span>
    </div>
    <ts-form-wrapper
      class="w-full grid-cols-12 grid gap-2"
      ref="questionForm"
      :parentRefs="parentRefs"
    >
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder=""
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          v-model="FrontendLogic.questions.questions.CreateQuestionForm.topic"
          :hasTitle="true"
          name="Topic"
          :rules="[FormValidations.RequiredRule]"
          ref="topic"
        >
          <template v-slot:title> Topic </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col col-span-12">
        <ts-text-area
          placeholder="Write your question here"
          paddings="py-3"
          :hasTitle="true"
          name="Question"
          v-model="FrontendLogic.questions.questions.CreateQuestionForm.body"
          :rules="[FormValidations.RequiredRule]"
          ref="body"
        >
          <template v-slot:title> Question </template>
        </ts-text-area>
      </div>

      <div class="flex flex-col col-span-6">
        <ts-select
          placeholder="Select a subject"
          custom-class="mdlg:px-3 px-2  col-span-6 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          v-model="
            FrontendLogic.questions.questions.CreateQuestionForm.subjectId
          "
          :options="FrontendLogic.questions.subjects.GetSubjectOptions()"
        >
          <template v-slot:title> Subject </template>
        </ts-select>
      </div>

      <div class="flex flex-col col-span-6">
        <ts-file-attachment
          :iconName="'camera-gray'"
          :hasTitle="true"
          v-model="
            FrontendLogic.questions.questions.CreateQuestionForm.attachment
          "
        >
          <template v-slot:title> Image </template>
        </ts-file-attachment>
      </div>
    </ts-form-wrapper>

    <div class="flex flex-row justify-end w-full mt-3">
      <ts-button
        :padding="'mdlg:!px-4 mdlg:!py-3 px-3 py-2'"
        @click="sendQuestion()"
        :loading="QuestionLoaders?.ask"
      >
        Ask question
      </ts-button>
    </div>
  </div>
  <ts-modal
    :canClose="true"
    :close="
      () => {
        showSubscribePrompt = false;
      }
    "
    v-if="
      showSubscribePrompt &&
      TsCommon.accountType.value != 'tutor' &&
      UserWallet?.subscription.active == false
    "
  >
    <ts-subscribe-prompt
      :close="
        () => {
          showSubscribePrompt = false;
        }
      "
      :remainingQuestions="UserWallet?.subscription.data.questions"
    ></ts-subscribe-prompt>
  </ts-modal>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsSelect,
  TsTextArea,
  TsButton,
  TsFileAttachment,
  TsTextField,
  TsFormWrapper,
  TsModal,
  TsSubscribePrompt,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { SingleUser, UserWallet } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsSelect,
    TsButton,
    TsTextArea,
    TsFileAttachment,
    TsTextField,
    TsFormWrapper,
    TsModal,
    TsSubscribePrompt,
  },
  name: "AskQuestionPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Ask a question`,
    });

    const questionForm = ref<any>(null);

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const UserProfile = ref<SingleUser>();

    const QuestionLoaders = ref<any>();

    const UserWallet = ref<UserWallet>();

    const showSubscribePrompt = ref(true);

    const sendQuestion = () => {
      if (UserWallet.value) {
        if (UserWallet.value.subscription.data.questions > 0) {
          const formState: boolean = questionForm.value.validate();
          FrontendLogic.questions.questions.CreateQuestion(
            formState &&
              FrontendLogic.questions.questions.CreateQuestionForm.subjectId !=
                "",
            questionForm.value
          );
        }
      }
    };
    const FormValidations = composables.logic.forms.validations;

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Ask a question`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.questions.questions.Question = undefined;
      FrontendLogic.questions.subjects.GetSubjects();
      FrontendLogic.questions.questions.watchProperty(
        "QuestionLoaders",
        QuestionLoaders
      );
      FrontendLogic.users.users.watchProperty("UserProfile", UserProfile);
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
    });
    return {
      FrontendLogic,
      sendQuestion,
      questionForm,
      FormValidations,
      QuestionLoaders,
      UserProfile,
      UserWallet,
      showSubscribePrompt,
      TsCommon,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
