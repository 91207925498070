<template>
  <div class="flex flex-col space-y-2 w-full">
    <div
      class="flex flex-row items-center space-x-2 justify-between"
      v-for="(user, index) in users"
      :key="index"
    >
      <div
        class="flex flex-row items-center space-x-2 cursor-pointer"
        @click="selectOption(user.id)"
      >
        <ts-avatar
          :bgColor="
            avatarColors[Math.floor(Math.random() * avatarColors.length)]
          "
          :customClass="'text-white'"
          :photoUrl="user.bio.photo?.link || ''"
          :size="'25'"
          >{{ user.bio.name.first.charAt(0) }}</ts-avatar
        >
        <div class="flex flex-row space-x-2 items-center">
          <ts-normal-text>
            {{ currentUserId == user.id ? "You" : user.bio.name.full }}
          </ts-normal-text>
          <template
            v-if="!hasRemove && !isCheckbox && sessionState != 'previous'"
          >
            <span
              :class="`h-[4px] w-[4px] ${
                paidUsers?.includes(user.id)
                  ? 'bg-primaryGreen'
                  : 'bg-errorColor'
              }  rounded-full`"
            ></span>
            <ts-normal-text
              :color="`${
                paidUsers?.includes(user.id)
                  ? 'text-primaryGreen'
                  : 'text-errorColor'
              }`"
            >
              {{ paidUsers?.includes(user.id) ? "Paid" : "Unpaid" }}
            </ts-normal-text>
            <template v-if="!paidUsers?.includes(user.id)">
              <span
                :class="`h-[4px] w-[4px] bg-paragraphTextLight rounded-full`"
              ></span>
              <template v-if="payForSession">
                <ts-normal-text
                  :color="'text-primaryPurple'"
                  @click.stop="payForSession(user.id)"
                >
                  Pay
                </ts-normal-text>
              </template>
            </template>
          </template>
        </div>
      </div>
      <ts-icon name="remove" v-if="hasRemove" :customClass="'h-[15px]'" />
      <ts-icon
        v-if="isCheckbox"
        :name="`${
          selectedUsers.includes(user.id) ? 'checkbox-active' : 'checkbox'
        }`"
        :customClass="`h-[15px]`"
        @click="selectOption(user.id)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import TsIcon from "../TsIcon/index.vue";
import TsAvatar from "../TsAvatar/index.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import { SingleUser } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsIcon,
    TsAvatar,
    TsNormalText,
  },
  props: {
    users: {
      type: Object as () => SingleUser[],
      required: true,
    },
    paidUsers: {
      type: [],
      required: false,
    },
    hasRemove: {
      type: Boolean,
      default: false,
    },
    isCheckbox: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      required: false,
    },
    payForSession: {
      type: Function,
      required: false,
    },
    currentUserId: {
      type: String,
      required: false,
    },
    sessionState: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  name: "TsUsersList",
  setup(props: any, context: any) {
    const avatarColors = [
      "bg-primaryPurple",
      "bg-primaryGreen",
      "bg-primaryOrange",
      "bg-primaryBlue",
    ];

    const selectedUsers = ref<any[]>([]);

    const selectOption = (key: any) => {
      if (selectedUsers.value.includes(key)) {
        selectedUsers.value = selectedUsers.value.filter(
          (eachKey) => eachKey != key
        );
      } else {
        selectedUsers.value.push(key);
      }
      context.emit("update:modelValue", selectedUsers.value);
    };

    onMounted(() => {
      if (props.modelValue) {
        selectedUsers.value = props.modelValue;
      }
    });

    return {
      avatarColors,
      selectedUsers,
      selectOption,
    };
  },
});
</script>
