<template>
  <div class="w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3">
    <div
      class="py-3 px-3 mdlg:!px-5 mdlg:!py-5 bg-primaryPurple rounded flex justify-between flex-row items-center"
    >
      <div class="flex flex-col space-y-1 items-start">
        <ts-header-text :size="'xl'" :color="'text-white'">
          ${{ FrontendLogic.common.formatCurrency(UserWallet?.balance.amount) }}
        </ts-header-text>
        <ts-normal-text :color="'text-white'">
          Available balance
        </ts-normal-text>
      </div>
      <div>
        <router-link
          :to="`/profile/wallet/${
            TsCommon.accountType.value == 'student' ? 'fund' : 'withdraw'
          }`"
        >
          <ts-button
            :bgColor="'bg-white'"
            :textColor="'text-primaryPurple '"
            :padding="'mdlg:!px-5 mdlg:!py-3 px-4 py-2'"
          >
            {{
              TsCommon.accountType.value == "student"
                ? "Fund wallet"
                : "Withdraw"
            }}
          </ts-button>
        </router-link>
      </div>
    </div>

    <div class="w-full flex flex-col space-y-3 pt-4">
      <router-link
        :to="`/profile/wallet/${
          TsCommon.accountType.value == 'student' ? 'payment' : 'withdrawal'
        }-method`"
      >
        <ts-icon-card :iconName="'card'" :iconSize="'h-[18px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true">
              {{
                TsCommon.accountType.value == "student"
                  ? "Payment"
                  : "Withdrawal"
              }}
              method
            </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>

      <router-link to="/profile/wallet/history">
        <ts-icon-card :iconName="'history'" :iconSize="'h-[18px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true">
              Transaction history
            </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsHeaderText,
  TsButton,
  TsNormalText,
  TsIconCard,
  TsParagraphText,
} from "tutorstack-ui-components";
import { composables } from "../../../composables";
import { UserWallet } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsButton,
    TsHeaderText,
    TsNormalText,
    TsIconCard,
    TsParagraphText,
  },
  name: "WalletPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Wallet`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;
    const UserWallet = ref<UserWallet>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Wallet`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.payments.wallet.GetUserWallet();
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
    });

    return {
      TsCommon,
      UserWallet,
      FrontendLogic,
    };
  },
});
</script>
