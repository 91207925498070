import { AuthUser } from "../../../logic/types/domains/auth";
import { UpdateProfileForm, UpdateRoleForm } from "../../../logic/types/forms/auth";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";
import { Logic } from "../../../logic/modules";

export default class UserAPI extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/
		super("auth/user");
	}

	public async GetAuthUser(): Promise<AxiosResponse<AuthUser>> {
		try {
			const response: AxiosResponse<AuthUser> = await this.axiosInstance.get(
				this.getUrl() + "/"
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}


	public async UpdateUserProfile(data: UpdateProfileForm): Promise<AxiosResponse<AuthUser>> {
		try {

			// convert request data to formData
			const formData: FormData = Logic.common.convertToFormData(data)

			const response: AxiosResponse<AuthUser> = await this.axiosInstance.put(
				this.getUrl() + "/",
				formData
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async UpdateUserRoles(data: UpdateRoleForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl() + "/roles",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async SignOut(): Promise<AxiosResponse<void>> {
		try {
			const response: AxiosResponse<void> = await this.axiosInstance.post(
				this.getUrl() + "/signout"
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

}