<template>
  <span
    :class="`px-2 py-1 text-xs rounded !bg-opacity-[0.05]
	${color == 'purple' ? ' text-primaryPurple bg-primaryPurple ' : ''}
	${color == 'green' ? ' text-primaryGreen bg-primaryGreen ' : ''}
	${color == 'yellow' ? ' text-primaryYellow bg-primaryYellow ' : ''} `"
  >
    <slot />
  </span>
</template>
<script lang="ts">
export default {
  props: {
    color: {
      type: String,
      default: "purple",
    },
  },
  name: "TsBadge",
};
</script>
