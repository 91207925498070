import { SubscribeDeviceForm, ToggleNotificationForm } from '../../types/forms/notifications';
import { AxiosResponse } from "axios";
import { Notification, Paginated } from "../../types/domains";
import { $api } from "../../../services";
import Common from '../Common';

export default class Notifications extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public Notifications: Paginated<Notification> | undefined = undefined
	public Notification: Notification | undefined = undefined

	public ToggleNotificationForm: ToggleNotificationForm = {
		seen: false
	}

	public SubscribeDeviceForm: SubscribeDeviceForm = {
		token: ''
	}



	public GetNotifications = () => {
		try {
			$api.notifications.notifications.fetch().then((response: AxiosResponse<Paginated<Notification>>) => {
				this.Notifications = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetNotification = (notificationId: string) => {
		try {
			$api.notifications.notifications.get(notificationId).then((response: AxiosResponse<Notification>) => {
				this.Notification = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public ToggleNotification = (notificationId: string) => {
		try {
			$api.notifications.notifications.ToggleNotification(notificationId, this.ToggleNotificationForm).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

	public SubscribeDevice = () => {
		try {
			$api.notifications.notifications.SubscribeDevice(this.SubscribeDeviceForm).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

	public UnSubscribeDevice = () => {
		try {
			$api.notifications.notifications.UnSubscribeDevice(this.SubscribeDeviceForm).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}


}