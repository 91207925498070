<template>
  <template v-if="SessionData">
    <div class="w-full mdlg:!py-0 mdlg:px-0 px-3 py-3">
      <ts-session-info :data="SessionData">
        <template v-slot:subject>
          {{
            FrontendLogic.questions.subjects.GetSubjectTitle(
              SessionData?.subjectId || ""
            )
          }}
        </template>
        <template v-slot:date>
          {{ FrontendLogic.common.fomartDate(SessionData.startedAt, "MMM D") }}
        </template>
        <template v-slot:time-to-from>
          {{
            FrontendLogic.common.fomartDate(SessionData.startedAt, "HH:mm a")
          }}
          -
          {{
            FrontendLogic.common.fomartDate(
              SessionData.startedAt + SessionData.lengthInMinutes * 60000,
              "HH:mm a"
            )
          }}
        </template>
        <template v-slot:extras>
          <div
            class="w-full flex flex-row items-center"
            v-if="
              FrontendLogic.sessions.sessions.getSessionStatus(SessionData) ==
              'upcoming'
            "
          >
            <ts-button
              :padding="'px-4 py-2'"
              @click="joinSession(SessionData?.id || '')"
            >
              Enter Session
            </ts-button>
          </div>
          <div
            class="w-full flex flex-row items-center space-x-2"
            v-if="
              FrontendLogic.sessions.sessions.getSessionStatus(SessionData) ==
              'previous'
            "
          >
            <ts-button
              :padding="'px-4 py-2'"
              @click="showRateTutor = true"
              v-if="
                SessionData?.closedAt && TsCommon.accountType.value != 'tutor'
              "
            >
              Rate session
            </ts-button>
          </div>
        </template>
      </ts-session-info>
    </div>

    <div
      class="px-3 mdlg:!px-0 w-full flex flex-col space-y-2 mb-3"
      v-if="
        FrontendLogic.sessions.sessions.getSessionStatus(SessionData) ==
          'previous' &&
        UserWallet?.subscription.active == true &&
        TsCommon.accountType.value != 'tutor'
      "
    >
      <div
        class="w-full flex flex-col space-y-2 border-[1px] rounded border-gray-200 px-3 py-3 mdlg:!px-5 mdlg:!py-5 justify-start"
      >
        <ts-header-text :customClass="'w-full text-left'">
          Session Recordings
        </ts-header-text>

        <template v-if="SessionVideos.length > 0">
          <ts-video
            :customClass="'w-full mdlg:!h-[350px] h-[230px]'"
            :iconSize="'mdlg:!h-[37px] h-[28px]'"
            :video-url="SessionVideos[selectedVideoId - 1]"
          />
          <div class="w-full mt-2 flex flex-row space-x-2 flex-wrap">
            <div
              v-for="(video, index) in SessionVideos"
              :key="index"
              @click="selectedVideoId = index + 1"
              :class="`w-[30px] h-[30px] mdlg:!w-[40px] mdlg:!h-[40px] rounded border-[2px] cursor-pointer border-primaryPurple flex flex-row items-center justify-center
			${selectedVideoId == index + 1 ? 'bg-primaryPurple' : 'bg-white'}`"
            >
              <ts-header-text
                :color="`${
                  selectedVideoId == index + 1
                    ? 'text-white'
                    : 'text-primaryPurple'
                }`"
                size="lg"
              >
                {{ `${index + 1}` }}
              </ts-header-text>
            </div>
          </div>
        </template>
        <ts-empty-state
          :avatarUrl="'/images/no-session.svg'"
          v-else
          :avatarSize="'h-[60px]'"
          :ctaUrl="''"
          :customClass="'h-[200px] mdlg:!h-[240px] '"
        >
          <template v-slot:title> No session recording found </template>
          <template v-slot:subtext> </template>
        </ts-empty-state>
      </div>
    </div>

    <div class="px-3 mdlg:!px-0 w-full flex flex-col space-y-2">
      <div
        class="w-full flex flex-col space-y-2 border-[1px] rounded border-gray-200 px-3 py-3 justify-start"
      >
        <ts-header-text :customClass="'w-full text-left'">
          Participants
        </ts-header-text>

        <ts-users-list
          :users="users"
          :paidUsers="paidUsers"
          :payForSession="payForSession"
          :currentUserId="FrontendLogic.auth.user.AuthUser?.id || ''"
          :sessionState="
            FrontendLogic.sessions.sessions.getSessionStatus(SessionData)
          "
        />
      </div>
    </div>

    <div
      class="px-3 mdlg:!px-0 w-full"
      v-if="
        FrontendLogic.sessions.sessions.getSessionStatus(SessionData) !=
        'previous'
      "
    >
      <div
        v-if="SessionData.paid.length < SessionData.students.length"
        class="w-full flex flex-col justify-start items-start mt-3 mdlg:!mt-0 space-y-3 mdlg:!px-5 mdlg:!py-5 px-3 py-3 border-[1px] rounded"
      >
        <ts-header-text> Payment Methods </ts-header-text>
        <ts-radio-card
          :options="paymentOptions"
          v-model="FrontendLogic.sessions.sessions.PayForSessionForm.methodId"
        />
      </div>
    </div>

    <div class="w-full mdlg:!py-0 mdlg:px-0 px-3 py-3" v-if="SessionData">
      <ts-attachment-list :files="SessionData.attachments" />
    </div>
  </template>

  <ts-modal
    :canClose="true"
    :close="
      () => {
        showRateTutor = false;
      }
    "
    v-if="showRateTutor"
  >
    <ts-tutor-rating
      :tutor="SessionData?.tutor"
      :close="
        () => {
          showRateTutor = false;
        }
      "
      :confirm="executeAction"
      v-model:ratings="FrontendLogic.sessions.sessions.RateSessionForm.rating"
      v-model:review="FrontendLogic.sessions.sessions.RateSessionForm.message"
    >
    </ts-tutor-rating>
  </ts-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  TsSessionInfo,
  TsAttachmentList,
  TsButton,
  TsHeaderText,
  TsUsersList,
  TsRadioCard,
  TsModal,
  TsTutorRating,
  TsVideo,
  TsEmptyState,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import {
  Paginated,
  PaymentMethod,
  SingleSession,
  SingleUser,
  UserWallet,
} from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsSessionInfo,
    TsAttachmentList,
    TsButton,
    TsHeaderText,
    TsUsersList,
    TsRadioCard,
    TsModal,
    TsTutorRating,
    TsVideo,
    TsEmptyState,
  },
  name: "SessionInfoPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Session`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const selectedVideoId = ref(1);

    const SessionData = ref<SingleSession>();
    const PaymentMethods = ref<Paginated<PaymentMethod>>();

    TsCommon.pageTopBarOptions.backRoute = "/sessions";

    const UserWallet = ref<UserWallet>();

    const SessionRecordings = FrontendLogic.sessions.sessions.SessionRecordings;

    const SessionVideos = ref<string[]>([
      "https://s3.amazonaws.com/tutorstack/developement-sessions/635e601b56abcff20021e758/20221030/635e65cf144624389f655352/Rec-635e65cf144624389f655352-1667130833000.mp4",
      "https://s3.amazonaws.com/tutorstack/developement-sessions/635e601b56abcff20021e758/20221030/635e66bc144624389f65535a/Rec-635e66bc144624389f65535a-1667131070000.mp4",
      "https://s3.amazonaws.com/tutorstack/developement-sessions/635e601b56abcff20021e758/20221030/635e687e144624389f65536b/Rec-635e687e144624389f65536b-1667131521000.mp4",
      "https://s3.amazonaws.com/tutorstack/development-sessions/635e601b56abcff20021e758/20221031/636050ec144624389f656734/Rec-636050ec144624389f656734-1667256560000.mp4",
      "https://s3.amazonaws.com/tutorstack/development-sessions/635e601b56abcff20021e758/20221031/636052f4144624389f65674c/Rec-636052f4144624389f65674c-1667257079000.mp4",
    ]);

    SessionRecordings?.forEach((recording) => {
      if (recording.recording) {
        SessionVideos.value.push(recording.recording.link);
      }
    });

    const paymentOptions = ref<any[]>([]);

    const showRateTutor = ref(false);

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Session`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.pageTopBarOptions.backRoute = "/sessions";
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.sessions.sessions.watchProperty("Session", SessionData);
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
      FrontendLogic.payments.wallet.watchProperty(
        "PaymentMethods",
        PaymentMethods
      );
    });

    const users = ref<SingleUser[]>();
    const paidUsers = ref<string[]>();

    watch(SessionData, () => {
      users.value = SessionData.value?.students;
      paidUsers.value = SessionData.value?.paid;
    });

    watch(PaymentMethods, () => {
      paymentOptions.value = [
        {
          name: ["Use Wallet"],
          key: "wallet",
          iconName: "wallet",
        },
      ];
      PaymentMethods.value?.results.forEach((method) => {
        paymentOptions.value.push({
          name: [
            method.data.type == "Card"
              ? `**** **** **** ${method.data.last4Digits}`
              : method.data.email,
          ],
          key: method.id,
          iconName: method.data.type == "Card" ? "card" : "paypal",
        });
      });
    });

    const payForSession = (userId: string) => {
      if (FrontendLogic.sessions.sessions.PayForSessionForm.methodId != "") {
        if (
          FrontendLogic.sessions.sessions.PayForSessionForm.methodId == "wallet"
        ) {
          FrontendLogic.sessions.sessions.PayForSessionForm.useWallet = true;
        } else {
          FrontendLogic.sessions.sessions.PayForSessionForm.useWallet = false;
        }
        FrontendLogic.sessions.sessions.PayForSession(
          SessionData.value?.id || "",
          [userId],
          "/sessions/" + SessionData.value?.id
        );
      }
    };

    const joinSession = (sessionId: string) => {
      FrontendLogic.sessions.sessions.JoinSession(sessionId);
    };

    const executeAction = () => {
      if (FrontendLogic.sessions.sessions.RateSessionForm.rating != 0) {
        FrontendLogic.sessions.sessions.RateSession(
          SessionData.value?.id || ""
        );
      }

      showRateTutor.value = false;
    };

    return {
      SessionData,
      FrontendLogic,
      users,
      paidUsers,
      paymentOptions,
      payForSession,
      joinSession,
      showRateTutor,
      executeAction,
      UserWallet,
      TsCommon,
      SessionVideos,
      selectedVideoId,
    };
  },
});
</script>
