<template>
  <div
    class="w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full flex flex-row items-center justify-between">
      <ts-normal-text> Credit or debit card </ts-normal-text>
      <span
        class="flex flex-row items-center space-x-2 cursor-pointer"
        @click="openModal = true"
      >
        <ts-normal-text :customClass="'text-primaryPurple'">
          Add card
        </ts-normal-text>
        <ts-icon name="plus-purple" :customClass="'h-[10px] '" />
      </span>
    </div>
    <template v-if="CardMethods">
      <ts-icon-card
        :iconName="'card'"
        :iconSize="'h-[17px]'"
        v-for="(card, index) in CardMethods"
        :key="index"
      >
        <template v-slot:name>
          <ts-paragraph-text :faded="true">
            **** **** **** {{ card.data.last4Digits }}
          </ts-paragraph-text>
        </template>
      </ts-icon-card>
    </template>
  </div>

  <div
    class="w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full flex flex-row items-center justify-between">
      <ts-normal-text> Paypal </ts-normal-text>
      <span
        class="flex flex-row items-center space-x-2 cursor-pointer"
        @click="openModal = true"
      >
        <ts-normal-text :customClass="'text-primaryPurple'">
          Add account
        </ts-normal-text>
        <ts-icon name="plus-purple" :customClass="'h-[10px]'" />
      </span>
    </div>
    <template v-if="PaypalMethods">
      <ts-icon-card
        :iconName="'paypal'"
        :iconSize="'h-[17px]'"
        v-for="(paypal, index) in PaypalMethods"
        :key="index"
      >
        <template v-slot:name>
          <ts-paragraph-text :faded="true">
            {{ paypal.email }}
          </ts-paragraph-text>
        </template>
      </ts-icon-card>
    </template>
  </div>

  <ts-modal :canClose="true" :close="closeModal" v-if="openModal">
    <ts-payment-method />
  </ts-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  TsNormalText,
  TsParagraphText,
  TsIcon,
  TsIconCard,
  TsModal,
  TsPaymentMethod,
} from "tutorstack-ui-components";
import { composables } from "../../../composables";
import { Paginated, PaymentMethod } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsNormalText,
    TsIcon,
    TsIconCard,
    TsParagraphText,
    TsModal,
    TsPaymentMethod,
  },
  name: "PaymentMethodPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Payment method",
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const PaymentMethods = ref<Paginated<PaymentMethod>>();

    const CardMethods = ref<PaymentMethod[]>();
    const PaypalMethods = ref<PaymentMethod[]>();

    const openModal = ref(false);

    const closeModal = () => {
      openModal.value = false;
    };

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = "Payment method";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.hasNotification = true;
      TsCommon.pageTopBarOptions.showBottomBorder = false;
      FrontendLogic.payments.wallet.watchProperty(
        "PaymentMethods",
        PaymentMethods
      );
    });

    watch(PaymentMethods, () => {
      CardMethods.value = PaymentMethods.value?.results.filter((methods) => {
        return methods.data.type == "Card";
      });

      PaypalMethods.value = PaymentMethods.value?.results.filter((methods) => {
        return methods.data.type == "Paypal";
      });
    });

    return {
      openModal,
      closeModal,
      CardMethods,
      PaypalMethods,
    };
  },
});
</script>
