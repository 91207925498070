import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col justify-start items-start space-y-3 mdlg:!px-3 mdlg:!py-3 px-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
}
const _hoisted_2 = { class: "flex flex-col col-span-6" }
const _hoisted_3 = { class: "flex flex-col col-span-6" }
const _hoisted_4 = { class: "flex flex-col col-span-12" }
const _hoisted_5 = { class: "flex flex-col col-span-12" }
const _hoisted_6 = { class: "flex flex-col col-span-4" }
const _hoisted_7 = { class: "flex flex-col col-span-4" }
const _hoisted_8 = { class: "flex flex-col col-span-4" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 2,
  class: "col-span-full flex flex-col space-y-2 items-center justify-start"
}
const _hoisted_11 = {
  key: 1,
  class: "w-full mdlg:!py-0 mdlg:px-0 px-3 py-3"
}
const _hoisted_12 = {
  key: 2,
  class: "w-full flex flex-col justify-start items-start mt-3 mdlg:!mt-0 space-y-3 mdlg:!px-5 mdlg:!py-5 px-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
}
const _hoisted_13 = {
  key: 3,
  class: "w-full flex flex-col justify-start items-start mt-3 mdlg:!mt-0 space-y-3 mdlg:!px-5 mdlg:!py-5 px-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
}
const _hoisted_14 = { class: "mdlg:flex flex-row w-full justify-end hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_step_flow = _resolveComponent("ts-step-flow")!
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_select = _resolveComponent("ts-select")!
  const _component_ts_text_area = _resolveComponent("ts-text-area")!
  const _component_ts_file_attachment = _resolveComponent("ts-file-attachment")!
  const _component_ts_tutor_card = _resolveComponent("ts-tutor-card")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_users_list = _resolveComponent("ts-users-list")!
  const _component_ts_form_wrapper = _resolveComponent("ts-form-wrapper")!
  const _component_ts_session_info = _resolveComponent("ts-session-info")!
  const _component_ts_radio_card = _resolveComponent("ts-radio-card")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_datepicker = _resolveComponent("ts-datepicker")!
  const _component_ts_modal = _resolveComponent("ts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ts_step_flow, {
      stepItems: _ctx.stepItems,
      selectedStep: _ctx.selectedStep,
      "onUpdate:selectedStep": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStep) = $event))
    }, null, 8, ["stepItems", "selectedStep"]),
    (_ctx.selectedStep != 'confirm_booking')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ts_form_wrapper, {
            class: "w-full py-3 mdlg:!px-3 px-0 grid grid-cols-12 gap-2",
            parentRefs: _ctx.parentRefs,
            ref: "sessionFormWrapper"
          }, {
            default: _withCtx(() => [
              (_ctx.selectedStep == 'enter_details')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ts_text_field, {
                        placeholder: "Choose a topic",
                        "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
                        paddings: "py-3",
                        modelValue: _ctx.sessionForms.topic,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sessionForms.topic) = $event)),
                        ref: "topic",
                        name: "Topic",
                        rules: [_ctx.FormValidations.RequiredRule],
                        hasTitle: true
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Topic ")
                        ]),
                        _: 1
                      }, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ts_select, {
                        placeholder: "Choose a subject",
                        "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
                        paddings: "py-3",
                        hasTitle: true,
                        modelValue: _ctx.sessionForms.subjectId,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sessionForms.subjectId) = $event)),
                        options: _ctx.FrontendLogic.questions.subjects.GetSubjectOptions()
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Subject ")
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_ts_text_area, {
                        placeholder: "Describe what you want out of the session",
                        modelValue: _ctx.sessionForms.description,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sessionForms.description) = $event)),
                        ref: "description",
                        rules: [_ctx.FormValidations.RequiredRule],
                        name: "Message"
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Message ")
                        ]),
                        _: 1
                      }, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_ts_file_attachment, {
                        placeholder: "Upload file",
                        isMultiple: true,
                        modelValue: _ctx.sessionForms.attachments,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sessionForms.attachments) = $event))
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Attachment ")
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_ts_text_field, {
                        placeholder: "Select day",
                        "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200 cursor-pointer",
                        paddings: "py-3",
                        hasTitle: true,
                        modelValue: _ctx.sessionStartDateTime.date,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sessionStartDateTime.date) = $event)),
                        "update-value": _ctx.selectedDate,
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDatepicker = true)),
                        disabled: true,
                        name: "Date"
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Date ")
                        ]),
                        _: 1
                      }, 8, ["modelValue", "update-value"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_ts_select, {
                        placeholder: "Choose time",
                        "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
                        paddings: "py-3",
                        modelValue: _ctx.sessionStartDateTime.time,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sessionStartDateTime.time) = $event)),
                        hasTitle: true,
                        options: _ctx.TutorAvailableTimeOptions
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Time ")
                        ]),
                        _: 1
                      }, 8, ["modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_ts_select, {
                        placeholder: "hours",
                        "custom-class": "mdlg:px-3 px-2  border-[1px] rounded border-gray-200",
                        paddings: "py-3",
                        options: _ctx.FrontendLogic.sessions.sessions.SessionHours,
                        modelValue: _ctx.sessionForms.lengthInMinutes,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sessionForms.lengthInMinutes) = $event)),
                        hasTitle: true
                      }, {
                        title: _withCtx(() => [
                          _createTextVNode(" Hours ")
                        ]),
                        _: 1
                      }, 8, ["options", "modelValue"])
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.selectedStep == 'choose_tutor' && _ctx.Tutors)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.Tutors.results, (tutor, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-span-12 flex flex-col space-y-2",
                      key: index
                    }, [
                      _createElementVNode("div", {
                        class: "w-full flex flex-col",
                        onClick: _withModifiers(($event: any) => (_ctx.selectTutor(tutor.id)), ["stop"])
                      }, [
                        _createVNode(_component_ts_tutor_card, {
                          customClass: `w-full cursor-pointer ${
                _ctx.selectedTutorId == tutor.id
                  ? '!border-primaryPurple !border-[4px]'
                  : ''
              } `,
                          data: tutor,
                          goToTutor: false,
                          showButtons: false,
                          showSaved: false
                        }, null, 8, ["customClass", "data"])
                      ], 8, _hoisted_9)
                    ]))
                  }), 128))
                : _createCommentVNode("", true),
              (_ctx.selectedStep == 'add_friends')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_ts_select, {
                      placeholder: 'Add friends by email or username',
                      "custom-class": "mdlg:px-3 px-2  border-[1px] w-full rounded border-gray-200",
                      paddings: "py-3",
                      autoComplete: true,
                      onOnSearch: _ctx.handleUserSearch,
                      options: _ctx.UserSelectOptions,
                      onOnOptionSelected: _ctx.handleUserSelected,
                      ref: "invites"
                    }, null, 8, ["onOnSearch", "options", "onOnOptionSelected"]),
                    _createVNode(_component_ts_header_text, { customClass: "text-left w-full" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Added ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ts_users_list, {
                      users: _ctx.selectedUserList,
                      "has-remove": true
                    }, null, 8, ["users"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["parentRefs"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedStep == 'confirm_booking' && _ctx.Session)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_ts_session_info, { data: _ctx.Session }, {
            subject: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.FrontendLogic.questions.subjects.GetSubjectTitle(
            _ctx.Session?.subjectId || ""
          )), 1)
            ]),
            date: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.FrontendLogic.common.fomartDate(_ctx.Session.startedAt, "MMM D")), 1)
            ]),
            "time-to-from": _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.FrontendLogic.common.fomartDate(_ctx.Session.startedAt, "HH:mm a")) + " - " + _toDisplayString(_ctx.FrontendLogic.common.fomartDate(
            _ctx.Session.startedAt + _ctx.Session.lengthInMinutes * 60000,
            "HH:mm a"
          )), 1)
            ]),
            _: 1
          }, 8, ["data"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedStep == 'confirm_booking' && _ctx.sessionType == 'shared')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_ts_header_text, { customClass: "text-left w-full" }, {
            default: _withCtx(() => [
              _createTextVNode(" Pay for ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_users_list, {
            users: _ctx.selectedUserList,
            isCheckbox: true,
            modelValue: _ctx.usersToPayFor,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.usersToPayFor) = $event))
          }, null, 8, ["users", "modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedStep == 'confirm_booking')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_ts_header_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Make payment ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_radio_card, {
            options: _ctx.paymentOptions,
            modelValue: _ctx.FrontendLogic.sessions.sessions.PayForSessionForm.methodId,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.FrontendLogic.sessions.sessions.PayForSessionForm.methodId) = $event))
          }, null, 8, ["options", "modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_ts_button, {
        bgColor: 'bg-primaryPurple',
        padding: 'px-4 py-3',
        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.Session ? _ctx.payForSession() : _ctx.createSession()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.Session ? "Pay" : "Next"), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.showDatepicker)
      ? (_openBlock(), _createBlock(_component_ts_modal, {
          key: 4,
          canClose: true,
          "custom-class": "px-4",
          close: 
      () => {
        _ctx.showDatepicker = false;
      }
    
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_datepicker, {
              availableDays: _ctx.TutorAvailableDaysOptions,
              modelValue: _ctx.selectedDate,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.selectedDate) = $event))
            }, null, 8, ["availableDays", "modelValue"])
          ]),
          _: 1
        }, 8, ["close"]))
      : _createCommentVNode("", true)
  ], 64))
}