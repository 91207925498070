<template>
  <div
    class="flex flex-col w-full justify-between mdlg:!space-y-3 items-center mdlg:pt-1 pt-3 px-0 mdlg:!px-0"
  >
    <div
      class="w-full items-center justify-end flex flex-row mdlg:!px-0 px-3"
      v-if="TsCommon.accountType.value == 'student'"
    >
      <ts-button
        @click="selectedTab = 'answered'"
        :bgColor="`${
          selectedTab == 'answered'
            ? '!bg-primaryPurple'
            : 'border-[1px] border-gray-200 '
        } rounded-r-none`"
        padding="px-4 py-2"
        :textColor="`${
          selectedTab == 'answered' ? 'text-white' : 'text-paragraphTextLight'
        }`"
      >
        Answered
      </ts-button>
      <ts-button
        @click="selectedTab = 'unanswered'"
        bgColor="bg-white"
        :customClass="`${
          selectedTab == 'unanswered'
            ? '!bg-primaryPurple'
            : 'border-[1px] border-gray-200 '
        }  rounded-l-none `"
        padding="px-4 py-2"
        :textColor="`${
          selectedTab == 'unanswered' ? 'text-white' : 'text-paragraphTextLight'
        }`"
      >
        Unanswered
      </ts-button>
    </div>
    <template v-else>
      <div
        v-if="selectedTopTab != 'my_answers'"
        class="w-full flex flex-row items-center space-x-2 mdlg:!space-x-2 justify-between mdlg:!py-3 py-2 mdlg:!border-t-[1px] border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-r-0 border-t-0 border-l-0 mdlg:!px-3 px-3 border-gray-200 mdlg:rounded-md"
      >
        <ts-icon name="info" :customClass="'h-[16px]'" />
        <ts-normal-text :customClass="'w-full text-left'">
          {{
            selectedTopTab == "unheld"
              ? " Questions you hold will be reserved for you to answer within one hour."
              : ""
          }}

          {{
            selectedTopTab == "held"
              ? " Only you can see and answer these questions before the time runs out."
              : ""
          }}
        </ts-normal-text>
        <ts-icon name="close" :customClass="'h-[14px]'" />
      </div>
      <div
        v-if="selectedTopTab == 'unheld'"
        class="w-full flex flex-row items-center space-x-1 justify-between py-2 border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] mdlg:!border-t-[1px] border-r-0 border-t-0 border-l-0 px-2 border-gray-200 mdlg:rounded-md"
      >
        <ts-select
          placeholder="Subjects"
          custom-class="mdlg:px-3 px-2 "
          paddings="py-1"
          :options="FrontendLogic.questions.subjects.GetSubjectOptions(true)"
          v-model="selectedSubject"
        />
      </div>
    </template>

    <div
      class="flex flex-col space-y-3 w-full pt-3 mdlg:!pt-0 px-3 mdlg:!px-0"
      v-if="Questions && Questions?.results?.length"
    >
      <div
        @click="FrontendLogic.common.GoToRoute(`/questions/${question.id}`)"
        v-for="(question, index) in Questions.results"
        :key="index"
        class="w-full flex flex-col"
      >
        <ts-question-card :status="selectedTab" :data="question">
          <template v-slot:subject>
            {{
              FrontendLogic.questions.subjects.GetSubjectTitle(
                question.subjectId
              )
            }}
          </template>
          <template v-slot:past-time>
            {{ moment(question.createdAt).fromNow() }}
          </template>
          <template v-slot:content>
            {{ question.body }}
          </template>
          <template v-slot:extra>
            <div
              class="flex flex-row items-start pt-1 space-x-2"
              v-if="TsCommon.accountType.value == 'tutor'"
            >
              <template v-if="selectedTopTab == 'unheld'">
                <ts-button
                  :padding="'px-4 py-1'"
                  @click.stop="holdQuestion(question.id)"
                >
                  Hold
                </ts-button>
              </template>
              <template v-if="selectedTopTab == 'held'">
                <ts-button
                  :customClass="'border-[1px] border-primaryPurple'"
                  :padding="'px-4 py-1'"
                >
                  Upload video answer
                </ts-button>

                <ts-button
                  :bgColor="'bg-white'"
                  :padding="'px-4 py-1'"
                  :customClass="'border-[1px] border-primaryPurple'"
                  :textColor="'text-primaryPurple'"
                  @click.stop="ReleaseQuestion(question.id)"
                >
                  Release
                </ts-button>
              </template>
            </div>
          </template>
          <template v-slot:time v-if="selectedTopTab == 'held'">
            <span class="flex flex-row items-center space-x-1">
              <ts-icon name="stopwatch-red" :customClass="'h-[12px]'" />
              <ts-normal-text
                :color="'text-errorColor'"
                :customClass="'!text-xs'"
              >
                {{
                  FrontendLogic.common.countDownTime(
                    question?.heldBy?.releasedAt || 0
                  )
                }}
              </ts-normal-text>
            </span>
          </template>
        </ts-question-card>
      </div>
    </div>
    <ts-empty-state
      v-else
      :avatarUrl="'/images/no-question.svg'"
      :avatarSize="'h-[60px]'"
      :ctaUrl="TsCommon.accountType.value != 'tutor' ? '/questions/ask' : ''"
      :customClass="'h-[400px] mdlg:!h-[540px] '"
    >
      <template v-slot:title> No questions here </template>
      <template v-slot:subtext>
        {{
          TsCommon.accountType.value != "tutor"
            ? "Ask questions you need help with and get expert video solutions."
            : ""
        }}
      </template>
      <template v-slot:cta-text> Ask a question </template>
    </ts-empty-state>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  TsButton,
  TsQuestionCard,
  TsSelect,
  TsIcon,
  TsNormalText,
  TsEmptyState,
} from "tutorstack-ui-components";
import {
  composables,
  selectedQuestionTab,
  selectedTopTab,
} from "../../composables";
import { Paginated, Question } from "tutorstack-frontend-logic";
import moment from "moment";

export default defineComponent({
  components: {
    TsButton,
    TsQuestionCard,
    TsSelect,
    TsIcon,
    TsNormalText,
    TsEmptyState,
  },
  name: "QuestionsPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Questions",
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const selectedTab = ref("");

    const selectedSubject = ref("all");

    const Questions = ref<Paginated<Question>>();

    onMounted(() => {
      TsCommon.showBottomBar.value = true;
      TsCommon.resetTopBarOptions();
      if (TsCommon.accountType.value == "student") {
        TsCommon.pageTopBarOptions.type = "basic";
      } else {
        TsCommon.pageTopBarOptions.type = "switch";
        TsCommon.pageTopBarOptions.switchItems = [
          {
            key: "unheld",
            value: "Questions",
          },
          {
            key: "held",
            value: "Holding",
          },
          {
            key: "my_answers",
            value: "My answers",
          },
        ];
      }

      TsCommon.pageTopBarOptions.pageTitle = "My Questions";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.showTitle = true;
      TsCommon.pageTopBarOptions.hasNotification = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.questions.questions.watchProperty("Questions", Questions);

      if (selectedQuestionTab.value != "") {
        selectedTab.value = selectedQuestionTab.value;
        selectedTopTab.value = selectedQuestionTab.value;
      } else {
        selectedTab.value = "unanswered";
        selectedTopTab.value = "unheld";
      }
    });

    const sortQuestions = () => {
      if (selectedTopTab.value == "my_answers") {
        selectedTab.value = "answered";

        FrontendLogic.questions.questions.SortQuestions(
          TsCommon.accountType.value == "student" ? "answered" : "myanswers"
        );
      } else {
        selectedTab.value = "unanswered";
        FrontendLogic.questions.questions.SortQuestions(selectedTopTab.value);
      }
    };

    watch(selectedTopTab, () => {
      sortQuestions();
      selectedQuestionTab.value = selectedTopTab.value;
    });

    watch(selectedTab, () => {
      if (TsCommon.accountType.value == "student") {
        FrontendLogic.questions.questions.SortQuestions(selectedTab.value);
        selectedQuestionTab.value = selectedTab.value;
      }
    });

    const holdQuestion = (questionId: string) => {
      FrontendLogic.questions.questions.HoldQuestions(questionId);
      FrontendLogic.questions.questions.SortQuestions("held");
    };

    const ReleaseQuestion = (questionId: string) => {
      FrontendLogic.questions.questions.ReleaseQuestions(questionId);
      FrontendLogic.questions.questions.SortQuestions("unheld");
    };

    onUnmounted(() => {
      TsCommon.stopWatchItem();
      FrontendLogic.questions.questions.stopWatchAction();
    });

    const filterQuestionBySubject = () => {
      const queryOptions: any = {
        where: [],
      };

      if (selectedSubject.value != "all") {
        queryOptions.where.push({
          field: "subjectId",
          value: selectedSubject.value,
        });
      }

      FrontendLogic.questions.questions.GetQuestions(queryOptions);
    };

    watch(selectedSubject, () => {
      filterQuestionBySubject();
    });
    return {
      selectedTab,
      selectedTopTab,
      TsCommon,
      Questions,
      FrontendLogic,
      moment,
      holdQuestion,
      selectedSubject,
      ReleaseQuestion,
    };
  },
});
</script>
