import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2" }
const _hoisted_2 = ["rows", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ts_normal_text, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _: 3
    }),
    _withDirectives(_createElementVNode("textarea", {
      rows: $props.rows,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.content) = $event)),
      class: _normalizeClass(`focus:outline-none border-[1px] !bg-white resize-none border-gray-200 ${
        $setup.validationStatus ? 'focus:!border-primaryPurple' : '!border-errorColor'
      } rounded ${$props.padding} placeholder-slate-500`),
      placeholder: $props.placeholder,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.checkValidation()))
    }, "\n    ", 42, _hoisted_2), [
      [_vModelText, $setup.content]
    ]),
    (!$setup.validationStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ts_normal_text, {
            customClass: 'pt-1 text-right',
            color: `text-errorColor`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.errorMessage), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}