import { Logic } from '../index';
import { AxiosResponse } from "axios";
import { Paginated, Answer } from "../../types/domains";
import { CreateAnswerForm } from "../../types/forms";
import { $api } from "../../../services";
import Common from "../Common";
import { reactive } from 'vue';

export default class Answers extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public Answers: Paginated<Answer> | undefined = undefined
	public Answer: Answer | undefined = undefined

	public CreateAnswerForm: CreateAnswerForm = {
		attachment: '',
		questionId: ''
	}

	public AnswerLoaders = reactive({
		add: false
	})

	public AnswerUploadPercentage = 0


	public GetAnswers = (questionId: string) => {

		const request = $api.questions.answers.fetch({
			where: [
				{
					field: 'questionId',
					value: questionId
				}
			]
		}).then((response: AxiosResponse<Paginated<Answer>>) => {
			this.Answers = response.data
		})

		return request

	}

	public GetAnswer = (answerId: string) => {
		try {
			$api.questions.answers.get(answerId).then((response: AxiosResponse<Answer>) => {
				this.Answer = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public CreateAnswer = (formIsValid: boolean) => {

		if (formIsValid) {
			Logic.common.showLoader()
			this.AnswerLoaders.add = true
			this.AnswerUploadPercentage = 0
			$api.questions.answers.post(this.CreateAnswerForm, (progressEvent: any) => {
				this.AnswerUploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			}).then((response: AxiosResponse<Answer>) => {
				this.Answers?.results.push(response.data)
				this.GetAnswers(response.data.questionId)
				Logic.questions.questions.GetQuestion(response.data.questionId)
				Logic.common.hideLoader()
				this.AnswerLoaders.add = false
				Logic.common.showAlert("success", 'Answser added');
			}).catch((error: any) => {
				Logic.common.hideLoader()
				this.AnswerLoaders.add = false
				Logic.common.showAlert("success", error.message);
			})
		}

	}

	public UpdateAnswer = (formIsValid: boolean, answerId: string) => {
		try {
			if (formIsValid) {
				$api.questions.answers.put(answerId, this.CreateAnswerForm).then((response: AxiosResponse<Answer>) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public DeleteAnswer = (answerId: string) => {
		try {
			$api.questions.answers.delete(answerId).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}


}