<template>
  <div
    class="flex flex-col space-y-2 w-full items-center mdlg:pt-0 pt-2 mdlg:!px-0 px-3"
  >
    <div class="w-full flex flex-row items-center justify-between">
      <div class="flex flex-row items-center justify-start flex-grow">
        <template v-if="UserProfile">
          <ts-avatar
            :photo-url="`${UserProfile.bio.photo?.link || ''}`"
            size="50"
            custom-class="mdlg:flex hidden mr-2"
            :bg-color="'bg-primaryPurple text-white'"
          >
            {{ UserProfile.bio.name.first.charAt(0) }}
          </ts-avatar>
          <ts-avatar
            :photo-url="`${UserProfile.bio.photo?.link || ''}`"
            size="40"
            custom-class="mdlg:hidden flex mr-2"
            :bg-color="'bg-primaryPurple text-white'"
            >{{ UserProfile.bio.name.first.charAt(0) }}</ts-avatar
          >
        </template>

        <div class="flex flex-col space-y-[2px]">
          <ts-header-text size="xl" custom-class="mdlg:flex hidden">
            {{ UserProfile?.bio.name.full }}
          </ts-header-text>
          <ts-header-text size="lg" custom-class="mdlg:hidden flex">
            {{ UserProfile?.bio.name.full }}
          </ts-header-text>
          <div class="flex flex-row space-x-2 items-center">
            <ts-paragraph-text :faded="true">
              {{ UserProfile?.meta.sessionsAttended }} sessions
            </ts-paragraph-text>
            <span
              :class="`h-[4px] w-[4px] bg-paragraphTextLight rounded-full`"
            ></span>
            <ts-paragraph-text :faded="true">
              {{ UserProfile?.meta.questions }} questions
            </ts-paragraph-text>
          </div>
        </div>
      </div>

      <router-link
        to="/profile/edit"
        class="mdlg:!w-[50px] mdlg:!h-[50px] w-[35px] h-[35px] flex flex-row items-center justify-center border-[1px] border-gray-200 rounded-full"
      >
        <ts-icon name="edit" :customClass="'mdlg:!h-[15px] h-[13px]'" />
      </router-link>
    </div>

    <div class="w-full flex flex-col space-y-3 pt-4">
      <router-link to="/profile/wallet">
        <ts-icon-card :iconName="'wallet'" :iconSize="'h-[18px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true"> Wallet </ts-paragraph-text>
          </template>
          <template v-slot:extra>
            <ts-paragraph-text :faded="true">
              ${{
                FrontendLogic.common.formatCurrency(UserWallet?.balance.amount)
              }}
            </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>

      <router-link to="/profile/subscription">
        <ts-icon-card :iconName="'card'" :iconSize="'h-[20px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true"> Subscription </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>

      <router-link to="/profile/security">
        <ts-icon-card :iconName="'security'" :iconSize="'h-[22px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true"> Security </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>

      <router-link to="/profile/notification">
        <ts-icon-card :iconName="'edit-bell'" :iconSize="'h-[21px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true">
              Manage notifications
            </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>

      <router-link to="/profile/contact-us">
        <ts-icon-card :iconName="'mail'" :iconSize="'h-[16px]'">
          <template v-slot:name>
            <ts-paragraph-text :faded="true"> Contact us </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </router-link>

      <ts-icon-card
        :iconName="'logout'"
        :iconSize="'h-[19px]'"
        @click="FrontendLogic.auth.user.SignOut()"
      >
        <template v-slot:name>
          <ts-paragraph-text :faded="true" :customClass="'!text-red-400'">
            Log out
          </ts-paragraph-text>
        </template>
      </ts-icon-card>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsParagraphText,
  TsAvatar,
  TsHeaderText,
  TsIcon,
  TsIconCard,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { SingleUser, UserWallet } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsParagraphText,
    TsAvatar,
    TsHeaderText,
    TsIcon,
    TsIconCard,
  },
  name: "ProfilePage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Profile",
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const UserProfile = ref<SingleUser>();
    const UserWallet = ref<UserWallet>();

    const topBarType = computed(() => {
      return window.innerWidth > 768 ? "subpage" : "basic";
    });

    onMounted(() => {
      TsCommon.showBottomBar.value = true;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = topBarType.value;
      TsCommon.pageTopBarOptions.pageTitle = "Profile";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.hasNotification = true;
      TsCommon.pageTopBarOptions.showBottomBorder = false;
      TsCommon.bottomTabOptions.subPage = false;

      window.addEventListener("resize", () => {
        TsCommon.pageTopBarOptions.type =
          window.innerWidth > 768 ? "subpage" : "basic";
      });
      FrontendLogic.users.users.watchProperty("UserProfile", UserProfile);
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
    });

    return {
      UserProfile,
      UserWallet,
      FrontendLogic,
    };
  },
});
</script>
