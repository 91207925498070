import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row justify-between items-center pt-3" }
const _hoisted_2 = { class: "w-full flex flex-row justify-between items-center pt-3" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ts_calendar = _resolveComponent("ts-calendar")!
  const _component_ts_session_card = _resolveComponent("ts-session-card")!
  const _component_ts_empty_state = _resolveComponent("ts-empty-state")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ts_header_text, { size: "lg" }, {
        default: _withCtx(() => [
          _createTextVNode(" Upcoming sessions ")
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, { to: "/sessions" }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_normal_text, { color: "text-primaryPurple" }, {
              default: _withCtx(() => [
                _createTextVNode("View all")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_ts_calendar, {
      accountType: $props.accountType,
      onOnDateSelected: $setup.setSelectedDate,
      SessionsByMonth: $setup.SessionsByMonth
    }, null, 8, ["accountType", "onOnDateSelected", "SessionsByMonth"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ts_normal_text, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.selectedDate?.format("dddd, MMM D, YYYY")), 1)
        ]),
        _: 1
      })
    ]),
    ($setup.SessionsByMonth?.results && $setup.SessionsByMonth.results.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.SessionsByMonth.results, (session, index) => {
            return (_openBlock(), _createBlock(_component_ts_session_card, {
              "show-button": false,
              "avatar-url": session.tutor.bio.photo?.link || '',
              status: $setup.getSessionStatus(session)[0],
              "badge-color": `${session.students.length == 1 ? 'purple' : 'green'}`,
              "card-linecolor": $setup.getSessionStatus(session)[1],
              "session-is-close": false,
              key: index,
              data: session
            }, null, 8, ["avatar-url", "status", "badge-color", "card-linecolor", "data"]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_ts_empty_state, {
          key: 1,
          avatarUrl: '/images/no-session.svg',
          avatarSize: 'h-[60px]',
          ctaUrl: '/tutors',
          customClass: 'h-[300px] mdlg:!h-[280px] mt-4 border-[1px] rounded border-gray-200',
          accountType: $props.accountType
        }, {
          title: _withCtx(() => [
            _createTextVNode(" No sessions here ")
          ]),
          subtext: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.accountType != "tutor"
          ? "Book sessions with your tutors, alone or with friends"
          : `All sessions for the day will show up here`), 1)
          ]),
          "cta-text": _withCtx(() => [
            _createTextVNode(" My tutors ")
          ]),
          _: 1
        }, 8, ["accountType"]))
  ], 64))
}