import { Tutor } from "../../../logic/types/domains/users";
import { SavedTutorForm, UpdateTutorSubjectForm } from "../../../logic/types/forms/users";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";

export default class UserApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("users/users");
	}

	public async UpdateSavedTutors(data: SavedTutorForm): Promise<AxiosResponse<Tutor>> {
		try {
			const response: AxiosResponse<Tutor> = await this.axiosInstance.post(
				this.getUrl() + "/tutors/saved",
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async UpdateTutorSubjects(data: UpdateTutorSubjectForm): Promise<AxiosResponse<Tutor>> {
		try {
			const response: AxiosResponse<Tutor> = await this.axiosInstance.post(
				this.getUrl() + "/tutors/subjects",
				data);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}



}