import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "w-full grid-cols-12 grid gap-2" }
const _hoisted_3 = { class: "flex flex-col col-span-6" }
const _hoisted_4 = { class: "flex flex-col col-span-6" }
const _hoisted_5 = { class: "flex flex-row justify-end w-full mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ts_text_field, {
          placeholder: "Enter password",
          "custom-class": "mdlg:px-3 px-2 border-[1px] rounded border-gray-200",
          paddings: "py-3",
          hasTitle: true,
          modelValue: _ctx.FrontendLogic.auth.password.UpdatePasswordForm.oldPassword,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.FrontendLogic.auth.password.UpdatePasswordForm.oldPassword) = $event)),
          type: "password"
        }, {
          title: _withCtx(() => [
            _createTextVNode(" Old Password ")
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ts_text_field, {
          placeholder: "Enter password",
          "custom-class": "mdlg:px-3 px-2  col-span-6 border-[1px] rounded border-gray-200",
          paddings: "py-3",
          hasTitle: true,
          type: "password",
          modelValue: _ctx.FrontendLogic.auth.password.UpdatePasswordForm.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.FrontendLogic.auth.password.UpdatePasswordForm.password) = $event))
        }, {
          title: _withCtx(() => [
            _createTextVNode(" New Password ")
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ts_button, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePassword()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Save ")
        ]),
        _: 1
      })
    ])
  ]))
}