<template>
  <span class="flex flex-row space-x-2 items-center">
    <img src="/images/logo.svg" class="h-[22px]" />
    <ts-header-text> Tutor Stack </ts-header-text>
  </span>

  <div class="flex flex-col space-y-2 pt-6">
    <router-link
      v-for="(tab, index) in tabs"
      :key="index"
      :to="tab.path"
      @mouseover="hoverTab = tab.icon"
      @mouseleave="hoverTab = ''"
      :class="`flex flex-row py-2 items-center px-3 space-x-3 ${
        hoverTab == tab.icon || tabIsActive(tab.routeTag)
          ? 'bg-fadedBackground '
          : ''
      } rounded`"
    >
      <ts-icon
        :name="
          hoverTab == tab.icon || tabIsActive(tab.routeTag)
            ? `${tab.icon}_active`
            : tab.icon
        "
        :custom-class="` ${
          tab.icon_size ? tab.icon_size : 'lg:h-[19px] mdlg:h-[19px]'
        } `"
      />
      <ts-normal-text
        custom-class="pt-[2px]"
        :color="`${
          hoverTab == tab.icon || tabIsActive(tab.routeTag)
            ? 'text-headerText'
            : 'text-gray-400'
        } `"
      >
        {{ tab.name }}
      </ts-normal-text>
    </router-link>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsIcon from "../TsIcon/index.vue";
import { onMounted, ref, watch } from "vue";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsIcon,
  },
  props: {
    tabIsActive: {
      type: Function,
      required: true,
    },
    accountType: {
      type: String,
      default: "",
    },
  },
  name: "TsSideBar",
  setup(props: any) {
    const tabs = ref<any[]>([]);

    const setRoutes = () => {
      tabs.value = [];
      tabs.value.push({
        name: "Home",
        path: "/",
        icon: "home",
        routeTag: "base",
      });
      if (props.accountType == "student") {
        tabs.value.push({
          name: "Tutors",
          path: "/tutors",
          icon: "tutors",
          routeTag: "tutors",
        });
      }

      tabs.value.push(
        {
          name: "Sessions",
          path: "/sessions",
          icon: "sessions",
          routeTag: "sessions",
          icon_size: "lg:h-[17px] mdlg:h-[16px]",
        },
        {
          name: "Questions",
          path: "/questions",
          icon: "questions",
          routeTag: "questions",
        }
      );
    };

    onMounted(() => {
      setRoutes();
    });

    watch(props, () => {
      setRoutes();
    });

    const hoverTab = ref("");

    return {
      hoverTab,
      tabs,
    };
  },
};
</script>
