<template>
  <div
    v-if="review"
    class="w-full items-start flex flex-col mdlg:!py-5 mdlg:!px-5 px-2 py-2 space-y-2 border-[1px] border-gray-200 rounded"
  >
    <div class="flex flex-row items-center space-x-2">
      <ts-avatar :photoUrl="review.user.bio.photo?.link || ''" :size="'33'">
        {{ review.user.bio.name.first.charAt(0) }}
      </ts-avatar>
      <div class="flex flex-col space-y-[1px]">
        <div class="flex flex-row items-center space-x-2">
          <ts-normal-text :color="'text-paragraphTextLight'">
            {{ review.user.bio.name.full }}
          </ts-normal-text>
          <span
            class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
          ></span>
          <ts-normal-text :color="'text-paragraphTextLight'">
            {{ Logic.common.timeFromNow(review.createdAt) }}
          </ts-normal-text>
        </div>
        <ts-ratings :count="review.rating" size="h-[13px]" />
      </div>
    </div>
    <ts-paragraph-text :customClass="'text-left'">
      {{ review.message }}
    </ts-paragraph-text>
  </div>
</template>
<script lang="ts">
import TsNormalText from "../TsTypography/normalText.vue";
import TsRatings from "../TsRatings/index.vue";
import TsAvatar from "../TsAvatar/index.vue";
import TsParagraphText from "../TsTypography/paragraphText.vue";
import { Logic, Review } from "tutorstack-frontend-logic";

export default {
  components: {
    TsNormalText,
    TsRatings,
    TsAvatar,
    TsParagraphText,
  },
  props: {
    review: {
      type: Object as () => Review,
    },
  },
  name: "TsReviewCard",
  setup() {
    return {
      Logic,
    };
  },
};
</script>
