import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-row items-center space-x-1 justify-between py-2 border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-r-0 border-l-0 px-2 border-gray-200 mdlg:rounded-md"
}
const _hoisted_2 = { class: "w-full flex flex-col justify-start items-start space-y-3 mdlg:px-0 px-3" }
const _hoisted_3 = { class: "flex flex-row space-x-2 w-full justify-between items-center mdlg:pt-3 pt-2" }
const _hoisted_4 = {
  key: 0,
  class: "grid grid-cols-12 w-full gap-3"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_select = _resolveComponent("ts-select")!
  const _component_ts_tutor_card = _resolveComponent("ts-tutor-card")!
  const _component_ts_empty_state = _resolveComponent("ts-empty-state")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.selectedTopTab == 'explore_tutors')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ts_select, {
            placeholder: "Subjects",
            "custom-class": "mdlg:px-3 px-2 border-r-[1px] border-gray-200 ",
            paddings: "py-1",
            options: _ctx.FrontendLogic.questions.subjects.GetSubjectOptions(),
            modelValue: _ctx.searchParams.subject,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchParams.subject) = $event))
          }, null, 8, ["options", "modelValue"]),
          _createVNode(_component_ts_select, {
            placeholder: "Price",
            "custom-class": "mdlg:px-3 px-2  border-r-[1px] border-gray-200",
            paddings: "py-1",
            modelValue: _ctx.searchParams.price,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchParams.price) = $event)),
            options: _ctx.priceOptions
          }, null, 8, ["modelValue", "options"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.Tutors && _ctx.Tutors.results.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Tutors.results, (tutor, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-span-12 flex flex-col space-y-2 cursor-pointer",
                  key: index
                }, [
                  _createElementVNode("div", {
                    class: "w-full flex flex-col",
                    onClick: ($event: any) => (_ctx.FrontendLogic.common.GoToRoute('/tutors/' + tutor.id))
                  }, [
                    _createVNode(_component_ts_tutor_card, {
                      "custom-class": "w-full",
                      data: tutor,
                      showSaved: true,
                      saveTutor: _ctx.saveTutor,
                      isSaved: _ctx.UserProfile?.tutors?.includes(tutor.id)
                    }, null, 8, ["data", "saveTutor", "isSaved"])
                  ], 8, _hoisted_5)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_ts_empty_state, {
              key: 1,
              avatarUrl: '/images/saved-tutor.svg',
              avatarSize: 'h-[80px]',
              ctaUrl: '/tutors',
              customClass: 'h-[290px] mdlg:!h-[400px] rounded border-[1px] border-gray-200'
            }, {
              title: _withCtx(() => [
                _createTextVNode(" You have no tutors ")
              ]),
              subtext: _withCtx(() => [
                _createTextVNode(" Browse through our wide range of expert tutors to find and save tutors that meet your specifications. ")
              ]),
              "cta-text": _withCtx(() => [
                _createTextVNode(" Explore tutors ")
              ]),
              _: 1
            }))
      ])
    ])
  ], 64))
}