<template>
  <div
    @click.stop="true"
    class="rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
  >
    <div class="w-full flex flex-col items-center justify-center">
      <ts-header-text :size="'lg'" :customClass="' w-full text-center'">
        Before you leave
      </ts-header-text>
      <ts-normal-text customClass="text-center" color="text-paragraphTextLight">
        Review your tutor
      </ts-normal-text>
    </div>

    <div class="flex flex-col space-y-1 items-center justify-center pt-2">
      <ts-avatar
        :photo-url="`${tutor?.bio.photo?.link}`"
        size="70"
        custom-class="flex"
        :bgColor="'bg-primaryPurple'"
      >
        {{ tutor?.bio.name.first.charAt(0) }}
      </ts-avatar>
      <ts-normal-text customClass="text-center">
        {{ tutor?.bio.name.full }}
      </ts-normal-text>
    </div>

    <div class="w-full flex flex-col space-y-1 mt-2">
      <div
        class="w-full flex flex-row items-center justify-between py-3 px-3 border-[1px] border-gray-200 rounded"
      >
        <ts-normal-text customClass="text-center">
          Tap star to rate
        </ts-normal-text>
        <ts-ratings
          :readonly="false"
          v-model="ratings"
          :count="0"
          custom-class="cursor-pointer"
          size="h-[13px] mdlg:!h-[17px]"
        />
      </div>
      <ts-text-area
        placeholder="Write your review"
        paddings="py-3"
        :hasTitle="false"
        v-model="review"
      >
      </ts-text-area>
    </div>

    <div class="w-full py-3 grid grid-cols-12 gap-2">
      <ts-button
        :customClass="'border-[1px] border-primaryPurple !text-primaryPurple col-span-6'"
        :padding="'px-4 py-3'"
        :bg-color="'bg-white'"
        @click.stop="
          Logic.common.GoToRoute(
            '/sessions/' + Logic.sessions.sessions.Session?.id
          );
          close ? close() : '';
        "
      >
        Leave
      </ts-button>
      <ts-button
        :customClass="'border-[1px] border-primaryPurple col-span-6'"
        :padding="'px-4 py-3'"
        @click.stop="confirm ? confirm() : ''"
      >
        Submit review
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsButton from "../TsButton/index.vue";
import { TsTextArea } from "../TsForm";
import { ref, watch } from "vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsAvatar from "../TsAvatar";
import { Logic, SingleUser } from "tutorstack-frontend-logic";
import TsRatings from "../TsRatings";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsButton,
    TsTextArea,
    TsAvatar,
    TsRatings,
  },
  props: {
    close: Function,
    confirm: Function,
    tutor: {
      type: Object as () => SingleUser,
    },
  },
  name: "TsTutorRating",
  emits: ["update:review", "update:ratings"],
  setup(props: any, context: any) {
    const review = ref("");
    const ratings = ref(0);
    watch(review, () => {
      context.emit("update:review", review.value);
    });
    watch(ratings, () => {
      context.emit("update:ratings", ratings.value);
    });

    return {
      review,
      ratings,
      Logic,
    };
  },
};
</script>
