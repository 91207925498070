import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_4 = { class: "w-full flex flex-row items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_paragraph_text = _resolveComponent("ts-paragraph-text")!
  const _component_ts_icon_card = _resolveComponent("ts-icon-card")!
  const _component_ts_payment_method = _resolveComponent("ts-payment-method")!
  const _component_ts_modal = _resolveComponent("ts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ts_normal_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Credit or debit card ")
          ]),
          _: 1
        }),
        _createElementVNode("span", {
          class: "flex flex-row items-center space-x-2 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal = true))
        }, [
          _createVNode(_component_ts_normal_text, { customClass: 'text-primaryPurple' }, {
            default: _withCtx(() => [
              _createTextVNode(" Add card ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_icon, {
            name: "plus-purple",
            customClass: 'h-[10px] '
          })
        ])
      ]),
      (_ctx.CardMethods)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.CardMethods, (card, index) => {
            return (_openBlock(), _createBlock(_component_ts_icon_card, {
              iconName: 'card',
              iconSize: 'h-[17px]',
              key: index
            }, {
              name: _withCtx(() => [
                _createVNode(_component_ts_paragraph_text, { faded: true }, {
                  default: _withCtx(() => [
                    _createTextVNode(" **** **** **** " + _toDisplayString(card.data.last4Digits), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ts_normal_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Paypal ")
          ]),
          _: 1
        }),
        _createElementVNode("span", {
          class: "flex flex-row items-center space-x-2 cursor-pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal = true))
        }, [
          _createVNode(_component_ts_normal_text, { customClass: 'text-primaryPurple' }, {
            default: _withCtx(() => [
              _createTextVNode(" Add account ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_icon, {
            name: "plus-purple",
            customClass: 'h-[10px]'
          })
        ])
      ]),
      (_ctx.PaypalMethods)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.PaypalMethods, (paypal, index) => {
            return (_openBlock(), _createBlock(_component_ts_icon_card, {
              iconName: 'paypal',
              iconSize: 'h-[17px]',
              key: index
            }, {
              name: _withCtx(() => [
                _createVNode(_component_ts_paragraph_text, { faded: true }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(paypal.email), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    (_ctx.openModal)
      ? (_openBlock(), _createBlock(_component_ts_modal, {
          key: 0,
          canClose: true,
          close: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_payment_method)
          ]),
          _: 1
        }, 8, ["close"]))
      : _createCommentVNode("", true)
  ], 64))
}