<template>
  <div
    v-if="data"
    @click="goToTutor ? Logic.common.GoToRoute('/tutors/' + data?.id) : ''"
    :class="`w-full flex flex-row flex-wrap mdlg:space-x-0 space-x-0 ${customClass} justify-between rounded border-[1px] border-gray-200 lg:py-5 lg:px-5 mdlg:py-4 mdlg:px-4 md:px-3 md:py-3`"
  >
    <div
      class="flex pt-3 px-3 mdlg:!pt-0 mdlg:px-0 mdlg:py-0 flex-row space-x-2 mdlg:items-start lg:items-start items-center lg:w-[16%] mdlg:w-[18%] sm:w-full"
    >
      <ts-image-loader
        :custom-class="`mdlg:!h-[120px] mdlg:!w-[130px] h-[80px] w-[80px] mdlg:!rounded-br-[40px] ${
          avatarColors[Math.floor(Math.random() * avatarColors.length)]
        } !rounded-br-[30px]  rounded relative flex text-xs font-semibold flex-row items-center justify-center`"
        :photo-url="data.bio.photo?.link || ''"
      >
        <span
          class="text-white text-[18px] font-bold"
          v-if="!data.bio.photo?.link"
        >
          {{ Array.from(data.bio.name.first)[0].toUpperCase() }}
        </span>
        <span
          class="mdlg:!h-[16px] mdlg:!w-[16px] h-[13px] w-[13px] bg-onlineGreen rounded-full absolute z-10 right-0 mdlg:bottom-[6px] bottom-[4px]"
        ></span>
      </ts-image-loader>
      <div class="flex mdlg:hidden lg:hidden flex-col space-y-1 items-start">
        <div class="flex flex-row items-center space-x-1">
          <ts-header-text size="base">
            {{ data.bio.name.full }}
          </ts-header-text>
          <ts-icon
            v-if="showSaved"
            :name="`${isSaved ? 'like_active' : 'like'}`"
            :customClass="'h-[15px]'"
            @click.stop="saveTutor ? saveTutor(data?.id, isSaved) : ''"
          />
        </div>
        <ts-ratings :count="data.ratings.avg" />
        <ts-paragraph-text :faded="true"> $22 per hour </ts-paragraph-text>
      </div>
    </div>

    <div
      class="flex flex-col space-y-1 items-start px-3 mdlg:px-2 lg:w-[69%] mdlg:w-[67%] sm:w-full"
    >
      <div class="flex-row items-center space-x-1 hidden mdlg:!flex">
        <ts-header-text size="base" custom-class="hidden mdlg:!inline-block">
          {{ data.bio.name.full }}
        </ts-header-text>
        <ts-icon
          v-if="showSaved"
          :name="`${isSaved ? 'like_active' : 'like'}`"
          :customClass="'h-[15px] cursor-pointer'"
          @click.stop="saveTutor ? saveTutor(data?.id, isSaved) : ''"
        />
      </div>
      <span class="flex flex-row items-center space-x-2 text-xs">
        <ts-icon name="tutors" custom-class="h-[12px]" />
        <ts-paragraph-text :faded="true" custom-class="text-xs">
          {{
            Logic.questions.subjects.GetSubjectTitle(
              data?.tutor.subjects[1] || ""
            )
          }}
        </ts-paragraph-text>
      </span>

      <span class="flex flex-row items-center space-x-2">
        <ts-icon name="profile" custom-class="h-[12px]" />
        <ts-paragraph-text :faded="true" custom-class="text-xs">
          {{ data.meta.students }} students
        </ts-paragraph-text>
        <span class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"></span>
        <ts-paragraph-text :faded="true" custom-class="text-xs">
          {{ data.meta.sessionsHosted }} sessions
        </ts-paragraph-text>
      </span>

      <ts-paragraph-text
        custom-class="text-left pt-1 line-clamp-3"
        :has-readmore="true"
      >
        {{ data.bio.description }}
      </ts-paragraph-text>
    </div>

    <div
      class="flex flex-col space-y-1 mdlg:justify-between items-end mdlg:!w-[15%] lg:!w-[15%] w-full pb-3"
    >
      <ts-ratings custom-class="hidden mdlg:flex" :count="data.ratings.avg" />
      <ts-paragraph-text custom-class="hidden mdlg:inline-block">
        $22 per hour
      </ts-paragraph-text>

      <div
        v-if="showButtons"
        class="mdlg:flex mdlg:flex-col grid grid-cols-12 mdlg:space-y-2 mdlg:!gap-0 gap-2 mdlg:pt-2 mdlg:!px-0 px-3 mdlg:w-auto lg:w-auto mdlg:!pb-0 pt-2 w-full"
      >
        <ts-button
          bg-color="bg-primaryPurple"
          custom-class="col-span-6 w-full"
          padding="py-3 mdlg:py-2"
          @click.stop="
            Logic.common.GoToRoute('/sessions/book/private?tutorId=' + data?.id)
          "
        >
          Book private
        </ts-button>

        <ts-button
          bg-color="bg-primaryGreen"
          custom-class="col-span-6 w-full"
          padding="py-3 mdlg:py-2"
          @click.stop="
            Logic.common.GoToRoute('/sessions/book/shared?tutorId=' + data?.id)
          "
        >
          Book shared
        </ts-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TsImageLoader from "../TsImageLoader/index.vue";
import TsRatings from "../TsRatings/index.vue";
import TsHeaderText from "../TsTypography/headerText.vue";
import TsParagraphText from "../TsTypography/paragraphText.vue";
import TsIcon from "../TsIcon/index.vue";
import TsButton from "../TsButton/index.vue";
import { Logic, Tutor } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsIcon,
    TsImageLoader,
    TsHeaderText,
    TsRatings,
    TsParagraphText,
    TsButton,
  },
  props: {
    data: {
      type: Object as () => Tutor,
    },
    showSaved: {
      type: Boolean,
      default: false,
    },
    isSaved: {
      type: Boolean,
      default: false,
    },
    saveTutor: {
      type: Function,
      required: false,
    },
    goToTutor: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  name: "TsTutorCard",
  setup() {
    const avatarColors = [
      "bg-primaryPurple",
      "bg-primaryGreen",
      "bg-primaryOrange",
      "bg-primaryBlue",
    ];

    return {
      avatarColors,
      Logic,
    };
  },
});
</script>
