<template>
  <div
    class="flex flex-col items-start space-y-2 border-[1px] border-gray-200 rounded px-3 py-3 mdlg:!px-5 mdlg:!py-5"
  >
    <ts-header-text :customClass="'pb-2'"> Discussion </ts-header-text>

    <div
      class="w-full flex flex-row space-x-2 items-start"
      v-for="(message, index) in data"
      :key="index"
    >
      <ts-avatar
        :photoUrl="message.photoUrl"
        :size="'32'"
        :bgColor="'bg-primaryPurple'"
        :customClass="'text-white min-w-[32.5px]'"
      >
        {{ message.photoUrl ? "" : message.username.charAt(0) }}
      </ts-avatar>
      <div class="flex flex-col space-y-[1px] flex-grow">
        <ts-header-text size="xs" :customClass="'text-left'">
          {{ message.username }}
        </ts-header-text>
        <ts-normal-text :customClass="' text-left'">
          {{ message.content }}
        </ts-normal-text>
      </div>
    </div>

    <div
      class="w-full flex flex-row space-x-2 pt-2 items-center justify-between"
    >
      <ts-text-field
        :placeholder="'Write a message'"
        :customClass="'border-[1px] w-full'"
        v-model="body"
      />
      <ts-button :padding="'px-4 py-3'" @click="sendComment()">
        <ts-icon :name="'send'" :customClass="'h-[20px]'" />
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsAvatar from "../TsAvatar/index.vue";
import TsTextField from "../TsForm/textField.vue";
import TsButton from "../TsButton/index.vue";
import TsIcon from "../TsIcon/index.vue";

export default defineComponent({
  components: {
    TsHeaderText,
    TsNormalText,
    TsAvatar,
    TsTextField,
    TsButton,
    TsIcon,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      required: true,
    },
    makeComment: {
      type: Function,
    },
  },
  name: "TsDiscussion",
  setup(props: any) {
    const body = ref("");

    const sendComment = () => {
      if (props.makeComment) {
        props.makeComment(body.value);
        body.value = "";
      }
    };

    return {
      body,
      sendComment,
    };
  },
});
</script>
