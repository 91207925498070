<template>
  <Teleport to="#classroom-container">
    <div class="fixed w-full h-full flex flex-col z-[5880089990000000]">
      <div
        class="w-full grid grid-cols-3 space-x-2 py-2 bg-white px-2 items-center justify-between"
      >
        <div
          class="flex-row items-center justify-start space-x-2 col-span-1 hidden mdlg:flex"
        >
          <ts-button
            padding="px-3 py-2"
            custom-class="!text-xs"
            @click="showConfirmAction('leave')"
          >
            Leave
          </ts-button>
        </div>
        <div
          class="col-span-1 flex flex-row items-center mdlg:!justify-center justify-start"
        >
          <ts-header-text
            size="xl"
            :customClass="'flex flex-row space-x-2 items-center justify-center line-clamp-1 '"
          >
            {{ Session?.topic }}
          </ts-header-text>
        </div>
        <div
          class="flex flex-row items-center justify-end space-x-2 mdlg:!col-span-1 col-span-2"
        >
          <ts-button
            padding="px-3 py-2"
            custom-class="!text-xs inline-block mdlg:!hidden"
            @click="showConfirmAction('leave')"
          >
            Leave
          </ts-button>
          <ts-button
            padding="px-3 py-2"
            custom-class="!text-xs"
            @click="showConfirmAction('close')"
            v-if="Session?.tutor.id == FrontendLogic.auth.user.AuthUser?.id"
          >
            Close
          </ts-button>
          <ts-button
            padding="px-3 py-2"
            @click="showConfirmAction('cancel')"
            custom-class="!text-xs"
            v-if="
              Session?.tutor.id == FrontendLogic.auth.user.AuthUser?.id ||
              Session?.students[0].id == FrontendLogic.auth.user.AuthUser?.id
            "
          >
            Cancel
          </ts-button>
        </div>
      </div>
      <div
        class="flex flex-col w-full h-full z-[48857890099999] relative bg-white"
      >
        <div
          v-if="showLoader"
          class="h-full w-full top-0 left-0 bg-gray-100 animate-pulse absolute"
        ></div>
        <iframe
          class="w-full h-full border-none"
          :src="`https://tutorstack-classroom.vercel.app/preview/${roomId}/${
            TsCommon.accountType.value == 'student'
              ? 'session-member'
              : 'session-tutor'
          }?auth_token=${authToken}&user=${userName}`"
          allow="camera; microphone; display-capture"
          @load="classroomLoaded"
        >
        </iframe>
      </div>
    </div>
  </Teleport>

  <ts-modal
    :canClose="true"
    :close="
      () => {
        showConfirmPrompt = false;
      }
    "
    v-if="showConfirmPrompt"
  >
    <ts-confirm-action
      v-model:reason="actionReason"
      :has-reason="actionType == 'cancel'"
      v-if="modalType == 'prompt'"
      :close="
        () => {
          showConfirmPrompt = false;
        }
      "
      :question="confirmationQuestion"
      :confirm="executeAction"
    />
    <ts-tutor-rating
      :tutor="Session?.tutor"
      v-if="modalType == 'ratings'"
      :close="
        () => {
          showConfirmPrompt = false;
        }
      "
      :confirm="executeAction"
      v-model:ratings="FrontendLogic.sessions.sessions.RateSessionForm.rating"
      v-model:review="FrontendLogic.sessions.sessions.RateSessionForm.message"
    >
    </ts-tutor-rating>
  </ts-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import { composables } from "../../composables";
import { SingleSession } from "tutorstack-frontend-logic";
import {
  TsHeaderText,
  TsButton,
  TsModal,
  TsConfirmAction,
  TsTutorRating,
} from "tutorstack-ui-components";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    TsHeaderText,
    TsButton,
    TsModal,
    TsConfirmAction,
    TsTutorRating,
  },
  name: "SessionClassroom",
  layout: "SubPage",
  setup() {
    useMeta({
      title: "Sessions Classroom",
    });

    const selectedTab = ref("");

    const FrontendLogic = composables.logic;
    const TsCommon = composables.common;

    const Session = ref<SingleSession>();

    const showConfirmPrompt = ref(false);

    const actionType = ref("");

    const actionReason = ref("");

    const modalType = ref("prompt");

    const confirmationQuestion = ref("");

    const route = useRoute();

    const roomId = route.query.roomId?.toString();
    const authToken = route.query.auth_token?.toString();
    const userName = route.query.user?.toString();

    const router = useRouter();

    const showLoader = ref(true);

    onMounted(() => {
      FrontendLogic.sessions.sessions.watchProperty("Session", Session);
    });

    const goBack = () => {
      router.push("/sessions/" + Session.value?.id);
    };

    const classroomLoaded = () => {
      showLoader.value = false;
    };

    const executeAction = () => {
      if (actionType.value == "cancel") {
        FrontendLogic.sessions.sessions.CancleSessionForm.reason =
          actionReason.value;
        FrontendLogic.sessions.sessions.CancleSession(
          true,
          Session.value?.id || ""
        );
      } else if (actionType.value == "close") {
        FrontendLogic.sessions.sessions.CloseSession(Session.value?.id || "");
      } else if (actionType.value == "leave") {
        if (FrontendLogic.sessions.sessions.RateSessionForm.rating != 0) {
          FrontendLogic.sessions.sessions.RateSession(Session.value?.id || "");
        }
      }

      FrontendLogic.common.GoToRoute("/sessions/" + Session.value?.id);
    };

    const showConfirmAction = (action: string) => {
      actionType.value = action;

      if (action == "cancel") {
        confirmationQuestion.value =
          "Are you sure you want to cancle this session?";
        modalType.value = "prompt";
      } else if (action == "close") {
        confirmationQuestion.value =
          "Are you sure you want to close this session?";
        modalType.value = "prompt";
      } else if (action == "leave") {
        if (TsCommon.accountType.value == "student") {
          modalType.value = "ratings";
        } else {
          FrontendLogic.common.GoToRoute("/sessions/" + Session.value?.id);
          return;
        }
      }

      showConfirmPrompt.value = true;
    };

    return {
      composables,
      selectedTab,
      Session,
      FrontendLogic,
      TsCommon,
      goBack,
      roomId,
      classroomLoaded,
      showLoader,
      authToken,
      userName,
      showConfirmPrompt,
      confirmationQuestion,
      actionType,
      actionReason,
      executeAction,
      showConfirmAction,
      modalType,
    };
  },
});
</script>
