import { AxiosResponse } from "axios";
import { Subscription } from "../../../logic/types/domains";
import { FundWalletForm, SubScribeToPlan } from "../../../logic/types/forms";
import { ReadOnlyApiService } from "../../common/ReadOnlyService";

export default class UserWalletApi extends ReadOnlyApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("payment/wallets");
	}

	public async FundWallet(data: FundWalletForm): Promise<AxiosResponse<boolean>> {
		try {
			const response: AxiosResponse<boolean> = await this.axiosInstance.post(
				this.getUrl() + "/fund",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}


	public async SubscribeToPlan(data: SubScribeToPlan): Promise<AxiosResponse<Subscription>> {
		try {
			const response: AxiosResponse<Subscription> = await this.axiosInstance.post(
				this.getUrl() + "/subscriptions",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async CancelCurrentPlan(): Promise<AxiosResponse<Subscription>> {
		try {
			const response: AxiosResponse<Subscription> = await this.axiosInstance.delete(
				this.getUrl() + "/subscriptions"
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async GetUserSubscriptions(): Promise<AxiosResponse<Subscription>> {
		try {
			const response: AxiosResponse<Subscription> = await this.axiosInstance.get(
				this.getUrl() + "/subscriptions"
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

}