<template>
  <div
    class="w-full flex flex-row items-center mdlg:!py-5 mdlg:!px-5 px-2 py-3 gap-2 border-[1px] border-gray-200 rounded"
  >
    <div
      class="flex flex-col space-y-2 items-center h-full justify-center mdlg:!px-8 px-4 py-4 w-1/3"
    >
      <ts-header-text :size="'lg'">
        {{ Logic.users.tutors.Tutor?.ratings.avg.toFixed(1) }}
      </ts-header-text>
      <ts-ratings :size="'mdlg:!h-[18px] h-[14px]'" />
      <ts-normal-text>
        {{ Logic.users.tutors.Tutor?.ratings.count }} reviews
      </ts-normal-text>
    </div>

    <div
      class="flex flex-col space-y-3 flex-grow mdlg:!px-4 px-2 w-2/3 border-l-[1px] border-gray-200"
      v-if="Logic.users.tutors.Tutor"
    >
      <div
        class="flex flex-row items-center mdlg:!space-x-4 space-x-2"
        v-for="(rating, index) in Logic.users.tutors.TutorRatingsArray"
        :key="index"
      >
        <ts-normal-text
          :customClass="`text-xs ${
            rating.count / Logic.users.tutors.Tutor.ratings.count <= 0
              ? 'text-paragraphTextLight'
              : ''
          }`"
        >
          {{ rating.name }}
        </ts-normal-text>
        <span
          class="flex-grow flex relative rounded mdlg:!h-[6px] h-[6px] bg-slate-200"
        >
          <span
            class="absolute h-full rounded bg-primaryYellow"
            :style="`width: ${
              (rating.count / Logic.users.tutors.Tutor.ratings.count) * 100
            }%;`"
          >
          </span>
        </span>
        <ts-normal-text
          :customClass="`text-xs ${
            rating.count / Logic.users.tutors.Tutor.ratings.count
              ? 'text-paragraphTextLight'
              : ''
          }`"
        >
          ({{ rating.count }})
        </ts-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsRatings from "../TsRatings/index.vue";
import { Logic } from "tutorstack-frontend-logic";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsRatings,
  },
  name: "TsRatingBox",
  setup() {
    return {
      Logic,
    };
  },
};
</script>
