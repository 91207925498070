import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-2 items-start" }
const _hoisted_2 = { class: "flex flex-row items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`mdlg:py-6 mdlg:px-6 px-4 py-4 flex flex-col space-y-2 rounded md:items-center items-start justify-center ${$props.bgColor} ${$props.customClass}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_ts_header_text, {
          size: "base",
          color: "text-white"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header")
          ]),
          _: 3
        }),
        _createVNode(_component_ts_icon, {
          name: "arrow-right",
          "custom-class": "h-[15px]"
        })
      ]),
      _createVNode(_component_ts_normal_text, {
        size: "base",
        color: "text-white",
        "custom-class": "text-left leading-tight"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "content")
        ]),
        _: 3
      })
    ])
  ], 2))
}