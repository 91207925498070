<template>
  <div
    v-if="data"
    class="w-full col-span-full border-[1px] px-3 py-3 rounded cursor-pointer border-gray-200 flex flex-row items-center space-x-2"
  >
    <div class="flex flex-col space-y-1 w-full">
      <div class="flex flex-row justify-between w-full items-center">
        <div class="flex flex-row items-center space-x-2">
          <ts-paragraph-text :faded="true" :customClass="'text-xs'">
            <slot name="subject" />
          </ts-paragraph-text>
          <span
            class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
          ></span>
          <ts-paragraph-text :faded="true" :customClass="'text-xs'">
            <slot name="past-time" />
          </ts-paragraph-text>
          <span
            class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
          ></span>
          <ts-paragraph-text :faded="true" :customClass="'text-xs capitalize'">
            {{ status }}
          </ts-paragraph-text>
        </div>
        <slot name="time" />
      </div>
      <ts-paragraph-text :customClass="'text-left line-clamp-3'">
        <slot name="content" />
      </ts-paragraph-text>
      <slot name="extra" />
    </div>
    <ts-image-loader
      v-if="status == 'answered'"
      :customClass="'w-full h-[70px] mdlg:!h-[80px] rounded-md relative w-[90px] mdlg:!w-[120px] border-1 border-transparent'"
      :photoUrl="'/images/default-video.jpg'"
    >
      <div
        class="flex flex-row items-center justify-center rounded absolute h-full w-full top-0 left-0 bg-black bg-opacity-70 z-index"
      >
        <ts-icon name="play-video" :customClass="`h-[17px] cursor-pointer`" />
      </div>
    </ts-image-loader>
  </div>
</template>
<script lang="ts">
import TsParagraphText from "../TsTypography/paragraphText.vue";
import TsImageLoader from "../TsImageLoader";
import TsIcon from "../TsIcon";
import { Question } from "tutorstack-frontend-logic";

export default {
  components: {
    TsParagraphText,
    TsImageLoader,
    TsIcon,
  },
  props: {
    bgColor: {
      type: String,
      default: "bg-primaryPurple",
    },
    data: {
      type: Object as () => Question,
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    customClass: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "py-2 px-4 ",
    },
    status: {
      type: String,
      default: "answered",
    },
  },
  name: "TsQuestionCard",
};
</script>
