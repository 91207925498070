<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <ts-file-attachment :isWrapper="true" v-model="EditProfileForm.photo">
      <template v-slot:content>
        <div class="flex flex-row w-full">
          <ts-image-loader
            :customClass="'relative rounded h-[100px] w-[100px]'"
            :photoUrl="UserProfile?.bio.photo?.link || ''"
          >
            <span
              class="rounded absolute top-0 left-0 h-full w-full bg-black bg-opacity-60 flex items-center justify-center"
            >
              <ts-icon :name="'camera'" :customClass="'h-[20px]'" />
            </span>
          </ts-image-loader>
        </div>
      </template>
    </ts-file-attachment>

    <ts-form-wrapper
      class="w-full grid-cols-12 grid gap-2"
      :parentRefs="parentRefs"
      ref="profileForm"
    >
      <div class="flex flex-col col-span-6">
        <ts-text-field
          placeholder="Ginny"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          name="First name"
          :rules="[FormValidations.RequiredRule]"
          v-model="EditProfileForm.firstName"
          ref="firstName"
        >
          <template v-slot:title> First name </template>
        </ts-text-field>
      </div>

      <div class="flex flex-col col-span-6">
        <ts-text-field
          placeholder="Weasely"
          custom-class="mdlg:px-3 px-2  col-span-6 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          name="Last name"
          :rules="[FormValidations.RequiredRule]"
          v-model="EditProfileForm.lastName"
          ref="lastName"
        >
          <template v-slot:title> Last name </template>
        </ts-text-field>
      </div>

      <!-- <div class="flex flex-col col-span-full">
        <ts-text-field
          placeholder=""
          custom-class="mdlg:px-3 px-2  col-span-full border-[1px] rounded border-gray-200"
          paddings="py-3"
          name="Phone"
          ref="phone"
          :hasTitle="true"
        >
          <template v-slot:title> Phone </template>
          <template v-slot:outer-prefix>
            <ts-select
              placeholder="USA"
              custom-class="mdlg:px-3 px-2 border-[1px] border-r-[0px]  rounded border-gray-200"
              paddings="py-3"
              defaultSize="w-[90px]"
              :hasTitle="false"
            />
          </template>
          <template v-slot:inner-prefix>
            <ts-normal-text> +1 </ts-normal-text>
          </template>
        </ts-text-field>
      </div> -->

      <div class="flex flex-col col-span-12">
        <ts-text-area
          placeholder="Tell us about you"
          paddings="py-3"
          :rules="[FormValidations.RequiredRule]"
          name="Description"
          ref="description"
          :hasTitle="true"
          v-model="EditProfileForm.description"
        >
          <template v-slot:title> Description </template>
        </ts-text-area>
      </div>
    </ts-form-wrapper>

    <div class="flex flex-row justify-end w-full mt-3">
      <ts-button @click="UpdateProfile()" :loading="UserLoaders?.update">
        Save
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsImageLoader,
  TsIcon,
  TsTextField,
  TsButton,
  TsFormWrapper,
  TsFileAttachment,
  TsTextArea,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { SingleUser } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsImageLoader,
    TsIcon,
    TsTextField,
    TsButton,
    TsFormWrapper,
    TsFileAttachment,
    TsTextArea,
  },
  name: "EditProfilePage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Edit Profile`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const UserLoaders = ref<any>();

    const UserProfile = ref<SingleUser>();

    const profileForm = ref();

    const EditProfileForm = FrontendLogic.auth.user.ProfileUpdateForm;

    const FormValidations = composables.logic.forms.validations;

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Edit Profile`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.users.users.watchProperty("UserProfile", UserProfile);
      FrontendLogic.auth.user.watchProperty("UserLoaders", UserLoaders);
    });

    const UpdateProfile = () => {
      const formState: boolean = profileForm.value.validate();
      FrontendLogic.auth.user.UpdateProfile(formState, profileForm.value);
    };

    return {
      EditProfileForm,
      UpdateProfile,
      UserProfile,
      profileForm,
      FormValidations,
      UserLoaders,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
