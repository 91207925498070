import { AuthResponse } from "../../../logic/types/domains/auth";
import { GoogleSignInForm } from "../../../logic/types/forms/auth";
import { ModelApiService } from "../../common/ModelService";
import { AxiosResponse } from "axios";

export default class IdentitiesApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("auth/identities");
	}

	public async GoogleSignIn(data: GoogleSignInForm): Promise<AxiosResponse<AuthResponse>> {
		try {
			const response: AxiosResponse<AuthResponse> = await this.axiosInstance?.post(
				this.getUrl() + "/google",
				data
			);

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}


}