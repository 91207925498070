import { Logic } from '../index';
import { AxiosResponse } from "axios";
import { Paginated, Comment } from "../../types/domains";
import { CommentForm } from "../../types/forms";
import { $api } from "../../../services";
import Common from "../Common";

export default class Comments extends Common {
	constructor() {
		// initiate things here
		super()
	}

	public Comments: Paginated<Comment> | undefined = undefined
	public Comment: Comment | undefined = undefined

	public CreateCommentForm: CommentForm = {
		body: '',
		entity: {
			id: '',
			type: ''
		}
	}

	public GetComments = (entity: string, entityId: string) => {
		try {
			$api.interactions.comments.fetch({
				where: [
					{
						field: "entity.type",
						value: entity
					},
					{
						field: "entity.id",
						value: entityId
					}
				]
			}).then((response: AxiosResponse<Paginated<Comment>>) => {
				this.Comments = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public GetComment = (commentId: string) => {
		try {
			$api.interactions.comments.get(commentId).then((response: AxiosResponse<Comment>) => {
				this.Comment = response.data
			})
		} catch (error) {
			console.log(error)
		}
	}

	public CreateComment = (formIsValid: boolean) => {

		if (formIsValid) {
			Logic.common.showLoader()
			$api.interactions.comments.post(this.CreateCommentForm, () => {
				//
			}, false).then((response: AxiosResponse<Comment>) => {
				this.GetComments(response.data.entity.type, response.data.entity.id)
				Logic.common.hideLoader()
				Logic.common.showAlert('success', 'Comment added')
			}).catch(() => {
				Logic.common.hideLoader()
				Logic.common.showAlert('error', 'Unable to add comment')
			})
		}

	}

	public UpdateComment = (formIsValid: boolean, commentId: string) => {
		try {
			if (formIsValid) {
				$api.interactions.comments.put(commentId, this.CreateCommentForm).then((response: AxiosResponse<Comment>) => {
					console.log(response.data)
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public DeleteAnswer = (commentId: string) => {
		try {
			$api.interactions.comments.delete(commentId).then((response: AxiosResponse<boolean>) => {
				console.log(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

}