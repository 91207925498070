import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row items-center justify-center" }
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-center py-2 pt-1" }
const _hoisted_3 = { class: "w-full py-3 grid grid-cols-12 gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_button = _resolveComponent("ts-button")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _withModifiers(($event: any) => (true), ["stop"]),
    class: "rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ts_icon, {
        name: "alert-circle",
        customClass: "h-[40px]"
      })
    ]),
    _createVNode(_component_ts_header_text, {
      size: 'lg',
      customClass: ' w-full text-center pt-3'
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Upgrade to Tutor Stack Premium ")
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ts_normal_text, { customClass: "text-center" }, {
        default: _withCtx(() => [
          _createTextVNode(" Subscribe to ask questions and get video answers. ")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ts_button, {
        customClass: 'border-[1px] border-primaryPurple !text-primaryPurple col-span-6',
        padding: 'px-4 py-3',
        "bg-color": 'bg-white',
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($props.close ? $props.close() : ''), ["stop"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_button, {
        customClass: 'border-[1px] border-primaryPurple col-span-6',
        padding: 'px-4 py-3',
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.SubscribeToPlan()), ["stop"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Subscribe ")
        ]),
        _: 1
      })
    ])
  ]))
}