<template>
  <div
    @click.stop="true"
    class="rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
  >
    <div class="w-full flex flex-row items-center justify-center">
      <ts-icon name="alert-circle" customClass="h-[40px]" />
    </div>
    <ts-header-text :size="'lg'" :customClass="' w-full text-center pt-3'">
      {{ question }}
    </ts-header-text>

    <div class="w-full" v-if="hasReason">
      <ts-text-area
        placeholder="Type reason"
        paddings="py-3"
        :hasTitle="false"
        v-model="reason"
      >
      </ts-text-area>
    </div>

    <div class="w-full py-3 grid grid-cols-12 gap-2">
      <ts-button
        :customClass="'border-[1px] border-primaryPurple !text-primaryPurple col-span-6'"
        :padding="'px-4 py-3'"
        :bg-color="'bg-white'"
        @click.stop="close ? close() : ''"
      >
        No
      </ts-button>
      <ts-button
        :customClass="'border-[1px] border-primaryPurple col-span-6'"
        :padding="'px-4 py-3'"
        @click.stop="confirm ? confirm() : ''"
      >
        Yes
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsIcon from "../TsIcon/index.vue";
import TsButton from "../TsButton/index.vue";
import { TsTextArea } from "../TsForm";
import { ref, watch } from "vue";

export default {
  components: {
    TsHeaderText,
    TsIcon,
    TsButton,
    TsTextArea,
  },
  props: {
    close: Function,
    confirm: Function,
    question: {
      type: String,
      default: "",
    },
    hasReason: {
      type: Boolean,
      default: false,
    },
  },
  name: "TsConfirmAction",
  emits: ["update:reason"],
  setup(props: any, context: any) {
    const reason = ref("");

    watch(reason, () => {
      context.emit("update:reason", reason.value);
    });

    return {
      reason,
    };
  },
};
</script>
