import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_4 = { class: "w-full flex space-y-2 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_5 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_6 = { class: "flex flex-row items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_paragraph_text = _resolveComponent("ts-paragraph-text")!
  const _component_ts_icon_card = _resolveComponent("ts-icon-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ts_normal_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Bank account ")
          ]),
          _: 1
        }),
        _createElementVNode("span", _hoisted_3, [
          _createVNode(_component_ts_normal_text, { customClass: 'text-primaryPurple' }, {
            default: _withCtx(() => [
              _createTextVNode(" Add account ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_icon, {
            name: "plus-purple",
            customClass: 'h-[10px]'
          })
        ])
      ]),
      _createVNode(_component_ts_icon_card, {
        iconName: 'bank',
        iconSize: 'h-[17px]'
      }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_paragraph_text, { faded: true }, {
            default: _withCtx(() => [
              _createTextVNode(" *****42436 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ts_normal_text, null, {
          default: _withCtx(() => [
            _createTextVNode(" Paypal ")
          ]),
          _: 1
        }),
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_ts_normal_text, { customClass: 'text-primaryPurple' }, {
            default: _withCtx(() => [
              _createTextVNode(" Add account ")
            ]),
            _: 1
          }),
          _createVNode(_component_ts_icon, {
            name: "plus-purple",
            customClass: 'h-[10px]'
          })
        ])
      ]),
      _createVNode(_component_ts_icon_card, {
        iconName: 'paypal',
        iconSize: 'h-[17px]'
      }, {
        name: _withCtx(() => [
          _createVNode(_component_ts_paragraph_text, { faded: true }, {
            default: _withCtx(() => [
              _createTextVNode(" timmy@gmail.com ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}