<template>
  <Teleport to="body">
    <transition name="fade" appear>
      <div
        :class="`fixed top-0 w-screen h-screen bg-black bg-opacity-60 flex flex-col overflow-y-auto ${customClass}`"
        style="z-index: 9999999999999999"
        @click="closeModal()"
      >
        <div
          class="relative w-full flex flex-row justify-center items-center h-full overflow-y-auto"
        >
          <slot />
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script lang="ts">
export default {
  props: {
    canClose: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Function,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  name: "TsModal",
  setup(props: any) {
    const closeModal = () => {
      if (props.canClose) {
        props.close();
      }
    };

    return {
      closeModal,
    };
  },
};
</script>
