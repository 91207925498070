<template>
  <div
    v-if="selectedTopTab == 'explore_tutors'"
    class="w-full flex flex-row items-center space-x-1 justify-between py-2 border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-r-0 border-l-0 px-2 border-gray-200 mdlg:rounded-md"
  >
    <ts-select
      placeholder="Subjects"
      custom-class="mdlg:px-3 px-2 border-r-[1px] border-gray-200 "
      paddings="py-1"
      :options="FrontendLogic.questions.subjects.GetSubjectOptions()"
      v-model="searchParams.subject"
    />
    <ts-select
      placeholder="Price"
      custom-class="mdlg:px-3 px-2  border-r-[1px] border-gray-200"
      paddings="py-1"
      v-model="searchParams.price"
      :options="priceOptions"
    />
    <!-- <ts-select
      placeholder="Schedule"
      custom-class="mdlg:px-3 px-2"
      paddings="py-1"
      v-model="searchParams.schedule"
    /> -->
  </div>
  <div
    class="w-full flex flex-col justify-start items-start space-y-3 mdlg:px-0 px-3"
  >
    <div
      class="flex flex-row space-x-2 w-full justify-between items-center mdlg:pt-3 pt-2"
    >
      <div
        class="grid grid-cols-12 w-full gap-3"
        v-if="Tutors && Tutors.results.length > 0"
      >
        <div
          class="col-span-12 flex flex-col space-y-2 cursor-pointer"
          v-for="(tutor, index) in Tutors.results"
          :key="index"
        >
          <div
            class="w-full flex flex-col"
            @click="FrontendLogic.common.GoToRoute('/tutors/' + tutor.id)"
          >
            <ts-tutor-card
              custom-class="w-full"
              :data="tutor"
              :showSaved="true"
              :saveTutor="saveTutor"
              :isSaved="UserProfile?.tutors?.includes(tutor.id)"
            />
          </div>
        </div>
      </div>
      <ts-empty-state
        v-else
        :avatarUrl="'/images/saved-tutor.svg'"
        :avatarSize="'h-[80px]'"
        :ctaUrl="'/tutors'"
        :customClass="'h-[290px] mdlg:!h-[400px] rounded border-[1px] border-gray-200'"
      >
        <template v-slot:title> You have no tutors </template>
        <template v-slot:subtext>
          Browse through our wide range of expert tutors to find and save tutors
          that meet your specifications.
        </template>
        <template v-slot:cta-text> Explore tutors </template>
      </ts-empty-state>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { TsTutorCard, TsSelect, TsEmptyState } from "tutorstack-ui-components";
import { composables, selectedTopTab } from "../../composables";
import {
  Paginated,
  SelectOption,
  SingleUser,
  Tutor,
} from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsTutorCard,
    TsSelect,
    TsEmptyState,
  },
  name: "TutorsPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Tutors",
    });

    const selectedTab = ref("");
    const UserProfile = ref<SingleUser>();

    const Tutors = ref<Paginated<Tutor>>();
    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const searchParams = reactive({
      subject: "",
      price: 22,
      schedule: 0,
    });

    onMounted(() => {
      TsCommon.showBottomBar.value = true;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "switch";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.switchItems = [
        // {
        //   key: "my_tutors",
        //   value: "My Tutors",
        // },
        {
          key: "explore_tutors",
          value: "Explore Tutors",
        },
      ];

      TsCommon.watchItem("selectedTopTab", selectedTab);
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.users.users.watchProperty("UserProfile", UserProfile);
      FrontendLogic.users.tutors.watchProperty("Tutors", Tutors);
    });

    const priceOptions: SelectOption[] = [
      {
        key: 22,
        value: "$22",
      },
    ];

    watch(searchParams, () => {
      FrontendLogic.users.tutors.GetTutors({}, [
        {
          condition: "in",
          field: "tutor.subjects",
          value: searchParams.subject,
        },
      ]);
    });

    const saveTutor = (tutorId: string, tutorIsSaved: boolean) => {
      FrontendLogic.users.users.SavedTutorForm.tutorId = tutorId;
      FrontendLogic.users.users.SavedTutorForm.add = !tutorIsSaved;
      FrontendLogic.users.users.UpdateUserSavedTutor();
    };

    onUnmounted(() => {
      TsCommon.stopWatchItem();
      FrontendLogic.users.users.stopWatchAction();
    });

    return {
      selectedTab,
      composables,
      FrontendLogic,
      saveTutor,
      UserProfile,
      Tutors,
      searchParams,
      priceOptions,
      selectedTopTab,
    };
  },
});
</script>
