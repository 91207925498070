<template>
  <div
    :class="`py-4 px-2 flex flex-col space-y-2 border-[1px] border-gray-200 rounded ${customClass}`"
  >
    <div class="flex flex-row items-center justify-between px-4">
      <ts-icon
        name="chevron-left"
        custom-class="h-[14px] cursor-pointer"
        @click="
          setCalenderDate(moment(currentSelectedMonth).subtract(1, 'month'))
        "
      />
      <ts-header-text size="lg"> {{ currentMonth }} </ts-header-text>
      <ts-icon
        name="chevron-right"
        custom-class="h-[14px] cursor-pointer"
        @click="setCalenderDate(moment(currentSelectedMonth).add(1, 'month'))"
      />
    </div>

    <div class="w- flex flex-row items-center justify-center pt-2">
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        M
      </ts-header-text>
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        T
      </ts-header-text>
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        W
      </ts-header-text>
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        T
      </ts-header-text>
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        F
      </ts-header-text>
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        S
      </ts-header-text>
      <ts-header-text
        size="base"
        custom-class="flex flex-col items-center justify-center w-[14.2857142857%]"
      >
        S
      </ts-header-text>
    </div>

    <div class="w-full flex flex-row items-center flex-wrap">
      <ts-normal-text
        size="base"
        custom-class="py-[6px] px-[6px] flex flex-col items-center justify-center  w-[14.2857142857%] "
        v-for="(item, index) in [...Array(monthDaysCount).keys()]"
        :key="index"
      >
        <span
          @click="handleSessionFilter(item + 1)"
          :class="`cursor-pointer  flex flex-row items-center justify-center rounded w-[29px] h-[27px] ${
            index == todayDate - 1
              ? '!text-white !bg-primaryPurple'
              : 'text-paragraphText'
          }
		${dayIsActive(item + 1) ? ' bg-fadedBackground' : 'text-paragraphText'}
		${
      !dateIsAvailable(item + 1)
        ? ' text-gray-400 !hover:bg-transparent'
        : 'hover:bg-fadedBackground'
    }`"
        >
          <span>
            {{ item + 1 }}
          </span>
        </span>
      </ts-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import TsIcon from "../TsIcon/index.vue";
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import moment, { Moment } from "moment";
import {
  Logic,
  Paginated,
  SelectOption,
  SingleSession,
} from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsIcon,
    TsHeaderText,
    TsNormalText,
  },
  props: {
    bgColor: {
      type: String,
      default: "bg-primaryPurple",
    },
    customClass: {
      type: String,
      default: "w-full mt-4 col-span-full",
    },
    accountType: {
      type: String,
      default: "student",
    },
    isPicker: {
      type: Boolean,
      default: false,
    },
    dateAllowed: {
      type: Array as () => SelectOption[],
      default: () => [],
    },
  },
  name: "TsCalendar",
  emits: ["OnDateSelected", "update:modelValue"],
  setup(props, context) {
    const currentMonth = ref("");
    const currentSelectedDay = ref<Moment>();
    const monthDaysCount = ref(31);

    const currentSelectedMonth = ref("");

    const SessionsByMonth = ref<Paginated<SingleSession>>();

    const todayDate = ref(0);

    const activeDays = ref<number[]>([]);

    const filterQuery = ref<any>({
      where: [
        {
          field: props.accountType == "student" ? "students.id" : "tutor.id",
          value: Logic.auth.user.AuthUser?.id || "",
        },
      ],
    });

    const setCalenderDate = (date: Moment) => {
      currentMonth.value = date.format("MMMM YYYY");
      monthDaysCount.value = date.daysInMonth();
      currentSelectedMonth.value = date.format("YYYY-MM") + "-01";

      currentSelectedDay.value = date;

      const today = moment(new Date());

      if (currentMonth.value == today.format("MMMM YYYY")) {
        todayDate.value = parseInt(today.format("D"));
      } else {
        todayDate.value = 0;
      }
    };

    onMounted(() => {
      setCalenderDate(moment(new Date()));
      Logic.sessions.sessions.GetCalendarSession(filterQuery.value);
      Logic.sessions.sessions.watchProperty("SessionsByMonth", SessionsByMonth);
      if (!props.isPicker) {
        handleSessionFilter(
          parseInt(currentSelectedDay.value?.format("D") || "0")
        );
      }
    });

    const dayIsActive = (day: number) => {
      if (props.isPicker) {
        return false;
      }
      const thisDay = activeDays.value.filter((activeday: any) => {
        return day == activeday;
      });

      return thisDay.length > 0;
    };

    const handleSessionFilter = (day: number) => {
      const selectedDay = `${currentSelectedDay.value?.format("YYYY-MM-")}${
        day.toString().length == 1 ? `0${day}` : day
      }`;

      if (props.isPicker) {
        if (dateIsAvailable(day)) {
          context.emit("update:modelValue", selectedDay);
        }
      } else {
        if (SessionsByMonth.value) {
          SessionsByMonth.value.results = JSON.parse(
            localStorage.getItem("SessionByMonth") || "{}"
          ).filter((session: SingleSession) => {
            return (
              moment(session.startedAt).format("YYYY-MM-DD") == selectedDay
            );
          });
        }
        context.emit("OnDateSelected", selectedDay);
      }
    };

    watch(SessionsByMonth, () => {
      if (SessionsByMonth.value) {
        activeDays.value = [];
        SessionsByMonth.value.results.forEach((session) => {
          activeDays.value.push(
            parseInt(moment(session.startedAt).format("D"))
          );
        });

        if (!props.isPicker) {
          handleSessionFilter(
            parseInt(currentSelectedDay.value?.format("D") || "0")
          );
        }
      }
    });

    const dateIsAvailable = (day: number) => {
      if (props.isPicker) {
        const selectedDay = `${currentSelectedDay.value?.format("YYYY-MM-")}${
          day.toString().length == 1 ? `0${day}` : day
        }`;

        const date = props.dateAllowed.filter((data) => {
          return data.key == selectedDay;
        });

        return date.length > 0;
      } else {
        return true;
      }
    };

    return {
      currentMonth,
      monthDaysCount,
      setCalenderDate,
      moment,
      currentSelectedMonth,
      todayDate,
      dayIsActive,
      handleSessionFilter,
      dateIsAvailable,
    };
  },
});
</script>
