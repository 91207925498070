import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-between px-4" }
const _hoisted_2 = { class: "w- flex flex-row items-center justify-center pt-2" }
const _hoisted_3 = { class: "w-full flex flex-row items-center flex-wrap" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_icon = _resolveComponent("ts-icon")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`py-4 px-2 flex flex-col space-y-2 border-[1px] border-gray-200 rounded ${_ctx.customClass}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ts_icon, {
        name: "chevron-left",
        "custom-class": "h-[14px] cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.setCalenderDate(_ctx.moment(_ctx.currentSelectedMonth).subtract(1, 'month'))
        ))
      }),
      _createVNode(_component_ts_header_text, { size: "lg" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.currentMonth), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ts_icon, {
        name: "chevron-right",
        "custom-class": "h-[14px] cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCalenderDate(_ctx.moment(_ctx.currentSelectedMonth).add(1, 'month'))))
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" M ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" T ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" W ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" T ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" F ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" S ")
        ]),
        _: 1
      }),
      _createVNode(_component_ts_header_text, {
        size: "base",
        "custom-class": "flex flex-col items-center justify-center w-[14.2857142857%]"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" S ")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...Array(_ctx.monthDaysCount).keys()], (item, index) => {
        return (_openBlock(), _createBlock(_component_ts_normal_text, {
          size: "base",
          "custom-class": "py-[6px] px-[6px] flex flex-col items-center justify-center  w-[14.2857142857%] ",
          key: index
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              onClick: ($event: any) => (_ctx.handleSessionFilter(item + 1)),
              class: _normalizeClass(`cursor-pointer  flex flex-row items-center justify-center rounded w-[29px] h-[27px] ${
            index == _ctx.todayDate - 1
              ? '!text-white !bg-primaryPurple'
              : 'text-paragraphText'
          }
		${_ctx.dayIsActive(item + 1) ? ' bg-fadedBackground' : 'text-paragraphText'}
		${
      !_ctx.dateIsAvailable(item + 1)
        ? ' text-gray-400 !hover:bg-transparent'
        : 'hover:bg-fadedBackground'
    }`)
            }, [
              _createElementVNode("span", null, _toDisplayString(item + 1), 1)
            ], 10, _hoisted_4)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ], 2))
}