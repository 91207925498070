<template>
  <div
    :class="`w-full mdlg:!py-0 mdlg:px-0 px-3 py-3 flex mdlg:!flex-row mdlg:!justify-between ${
      TsCommon.accountType.value == 'student'
        ? 'flex-col space-y-3'
        : 'flex-row justify-between items-center'
    } mdlg:!space-y-0`"
  >
    <div
      class="flex flex-row items-center space-x-3 flex-grow"
      v-if="TsCommon.accountType.value == 'student'"
    >
      <ts-image-loader
        v-if="CurrentTutor"
        custom-class="mdlg:h-[80px] mdlg:w-[80px] h-[60px] w-[60px] mdlg:!rounded-br-[30px] !rounded-br-[20px]  rounded relative"
        :photo-url="`${CurrentTutor?.bio.photo?.link}`"
      >
        <span
          class="mdlg:!h-[12px] mdlg:!w-[12px] h-[10px] w-[10px] bg-onlineGreen rounded-full absolute z-10 right-0 mdlg:bottom-[4px] bottom-[2px]"
        ></span>
      </ts-image-loader>

      <div class="flex flex-col space-y-1">
        <div class="flex flex-row items-center space-x-1">
          <ts-header-text :size="'xl'" :customClass="'text-start'"
            >{{ CurrentTutor?.bio.name?.full }}
          </ts-header-text>
          <ts-icon
            name="like"
            custom-class="h-[14px] mdlg:!inline-block hidden"
          />
        </div>

        <span
          class="flex flex-row items-center space-x-2"
          v-if="CurrentTutor?.tutor"
        >
          <ts-icon name="tutors" custom-class="h-[14px]" />
          <ts-paragraph-text :faded="true">
            {{
              FrontendLogic.questions.subjects.GetSubjectTitle(
                CurrentTutor?.tutor.subjects[1] || ""
              )
            }}
          </ts-paragraph-text>
        </span>

        <span class="flex flex-row items-center space-x-2">
          <ts-icon name="profile" custom-class="h-[14px]" />
          <ts-paragraph-text :faded="true">
            {{ CurrentTutor?.meta?.students }} students
          </ts-paragraph-text>
          <span
            class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
          ></span>
          <ts-paragraph-text :faded="true">
            {{ CurrentTutor?.meta?.sessionsHosted }} sessions
          </ts-paragraph-text>
        </span>
      </div>
    </div>

    <div class="flex flex-row items-center justify-start flex-grow" v-else>
      <template v-if="CurrentTutor">
        <ts-avatar
          :photo-url="`${CurrentTutor?.bio.photo?.link}`"
          size="53"
          :bgColor="'bg-primaryPurple'"
          custom-class="mdlg:flex hidden mr-2"
        >
          {{ CurrentTutor?.bio.name.first.charAt(0) }}
        </ts-avatar>
        <ts-avatar
          :photo-url="`${CurrentTutor?.bio.photo?.link}`"
          size="43"
          custom-class="mdlg:hidden flex mr-2"
          :bgColor="'bg-primaryPurple'"
        >
          {{ CurrentTutor?.bio.name.first.charAt(0) }}
        </ts-avatar>
      </template>

      <div class="flex flex-col space-y-[2px]">
        <ts-header-text size="xl" custom-class="mdlg:flex hidden">
          {{ CurrentTutor?.bio.name?.full }}
        </ts-header-text>
        <ts-header-text size="lg" custom-class="mdlg:hidden flex">
          {{ CurrentTutor?.bio.name?.full }}
        </ts-header-text>
        <div class="flex flex-row space-x-2 items-center">
          <ts-icon :name="'tutors'" :customClass="'h-[13px]'" />
          <ts-paragraph-text :faded="true"> Chemistry </ts-paragraph-text>
        </div>
      </div>
    </div>

    <div
      class="flex mdlg:!flex-col flex-row mdlg:!space-y-2 space-x-2 mdlg:!space-x-0"
      v-if="TsCommon.accountType.value == 'student'"
    >
      <ts-button
        :padding="'px-4 py-2'"
        @click="
          FrontendLogic.common.GoToRoute(
            '/sessions/book/private?tutorId=' + CurrentTutor?.id
          )
        "
      >
        Book Session
      </ts-button>

      <ts-button
        :bgColor="'bg-primaryGreen'"
        :padding="'px-4 py-2'"
        @click="
          FrontendLogic.common.GoToRoute(
            '/sessions/book/shared?tutorId=' + CurrentTutor?.id
          )
        "
      >
        Share Session
      </ts-button>

      <ts-button
        :bgColor="'bg-white'"
        :padding="'px-2 py-2'"
        :customClass="'mdlg:!hidden inline-block border-gray-200 border-[1px]'"
      >
        <ts-icon :name="'like'" :customClass="'h-[14px]'" />
      </ts-button>
    </div>
    <router-link
      v-else
      to="/profile/edit"
      class="mdlg:!w-[50px] mdlg:!h-[50px] w-[35px] h-[35px] flex flex-row items-center justify-center border-[1px] border-gray-200 rounded-full"
    >
      <ts-icon name="edit" :customClass="'mdlg:!h-[15px] h-[13px]'" />
    </router-link>
  </div>

  <ts-tabs :items="infoOptions" v-model:selectedTab="selectedTab">
    <template v-slot:extra v-if="TsCommon.accountType.value == 'tutor'">
      <router-link to="/profile/edit" class="text-primaryPurple">
        Edit
      </router-link>
    </template>
  </ts-tabs>

  <div class="w-full flex flex-col space-y-3 mdlg:px-0 px-3 py-3 mdlg:!py-0">
    <template v-if="selectedTab == 'about'">
      <div
        class="w-full items-start flex flex-col mdlg:!py-5 mdlg:!px-5 px-2 py-2 space-y-2 border-[1px] border-gray-200 rounded"
      >
        <ts-header-text :size="'base'"> Bio </ts-header-text>
        <ts-paragraph-text :customClass="'text-left'">
          {{ CurrentTutor?.bio.description }}
        </ts-paragraph-text>
      </div>

      <div
        class="w-full items-start flex flex-col mdlg:!py-5 mdlg:!px-5 px-2 py-2 space-y-2 border-[1px] border-gray-200 rounded"
      >
        <div class="flex flex-row items-center justify-between w-full">
          <ts-header-text :size="'base'"> Education </ts-header-text>
          <ts-icon
            name="plus-purple"
            :customClass="'h-[12px] cursor-pointer'"
            v-if="TsCommon.accountType.value == 'tutor'"
            @click="
              activeModal = 'education';
              openModal = true;
            "
          />
        </div>
        <template
          v-if="TutorEducations && TutorEducations?.results?.length > 0"
        >
          <div
            class="flex flex-row items-center justify-between w-full"
            v-for="(education, index) in TutorEducations.results"
            :key="index"
          >
            <span class="flex flex-row items-center space-x-1 mdlg:!space-x-3">
              <ts-normal-text> {{ education.school }} </ts-normal-text>
              <span
                class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
              ></span>
              <ts-normal-text> {{ education.degree }}</ts-normal-text>
              <ts-normal-text :color="'text-paragraphTextLight'">
                {{ FrontendLogic.common.fomartDate(education.from, "Y") }} —
                {{ FrontendLogic.common.fomartDate(education.to, "Y") }}
              </ts-normal-text>
            </span>
            <ts-icon
              name="remove"
              :customClass="'h-[12px] cursor-pointer'"
              @click="
                FrontendLogic.users.tutors.DeleteTutorEducation(education.id)
              "
              v-if="TsCommon.accountType.value == 'tutor'"
            />
          </div>
        </template>
      </div>

      <div
        class="w-full items-start flex flex-col mdlg:!py-5 mdlg:!px-5 px-2 py-2 space-y-2 border-[1px] border-gray-200 rounded"
      >
        <div class="flex flex-row items-center justify-between w-full">
          <ts-header-text :size="'base'"> Work </ts-header-text>
          <ts-icon
            name="plus-purple"
            :customClass="'h-[12px] cursor-pointer'"
            v-if="TsCommon.accountType.value == 'tutor'"
            @click="
              activeModal = 'work';
              openModal = true;
            "
          />
        </div>

        <template v-if="TutorWorks && TutorWorks?.results?.length > 0">
          <div
            class="flex flex-row items-center justify-between w-full"
            v-for="(work, index) in TutorWorks.results"
            :key="index"
          >
            <span
              class="flex flex-row items-center justify-start space-x-1 mdlg:!space-x-3"
            >
              <ts-normal-text> {{ work.institution }} </ts-normal-text>
              <span
                class="h-[4px] w-[4px] bg-paragraphTextLight rounded-full"
              ></span>
              <ts-normal-text> {{ work.position }} </ts-normal-text>
              <ts-normal-text :color="'text-paragraphTextLight'">
                {{ FrontendLogic.common.fomartDate(work.from, "Y") }} —
                {{ FrontendLogic.common.fomartDate(work.to, "Y") }}
              </ts-normal-text>
            </span>
            <ts-icon
              name="remove"
              :customClass="'h-[12px] cursor-pointer'"
              v-if="TsCommon.accountType.value == 'tutor'"
              @click="FrontendLogic.users.tutors.DeleteTutorWork(work.id)"
            />
          </div>
        </template>
      </div>
    </template>

    <template v-if="selectedTab == 'reviews'">
      <ts-rating-box />
      <ts-review-card
        v-for="(review, index) in FrontendLogic.users.tutors.TutorReviews
          ?.results"
        :review="review"
        :key="index"
      />
    </template>

    <template v-if="selectedTab == 'settings'">
      <div class="w-full flex flex-col space-y-3">
        <router-link to="/profile/wallet">
          <ts-icon-card :iconName="'wallet'" :iconSize="'h-[16px]'">
            <template v-slot:name>
              <ts-paragraph-text :faded="true"> Wallet </ts-paragraph-text>
            </template>
            <template v-slot:extra>
              <ts-paragraph-text :faded="true">
                ${{
                  FrontendLogic.common.formatCurrency(
                    UserWallet?.balance.amount
                  )
                }}
              </ts-paragraph-text>
            </template>
          </ts-icon-card>
        </router-link>

        <router-link to="/profile/set-availability">
          <ts-icon-card :iconName="'calender'" :iconSize="'h-[18px]'">
            <template v-slot:name>
              <ts-paragraph-text :faded="true">
                Set availability
              </ts-paragraph-text>
            </template>
          </ts-icon-card>
        </router-link>

        <router-link to="/profile/subjects">
          <ts-icon-card :iconName="'tutors_active'" :iconSize="'h-[18px]'">
            <template v-slot:name>
              <ts-paragraph-text :faded="true"> Subjects </ts-paragraph-text>
            </template>
          </ts-icon-card>
        </router-link>

        <router-link to="/profile/security">
          <ts-icon-card :iconName="'security'" :iconSize="'h-[20px]'">
            <template v-slot:name>
              <ts-paragraph-text :faded="true"> Security </ts-paragraph-text>
            </template>
          </ts-icon-card>
        </router-link>

        <router-link to="/profile/notification">
          <ts-icon-card :iconName="'edit-bell'" :iconSize="'h-[19px]'">
            <template v-slot:name>
              <ts-paragraph-text :faded="true">
                Manage notifications
              </ts-paragraph-text>
            </template>
          </ts-icon-card>
        </router-link>

        <router-link to="/profile/contact-us">
          <ts-icon-card :iconName="'mail'" :iconSize="'h-[14px]'">
            <template v-slot:name>
              <ts-paragraph-text :faded="true"> Contact us </ts-paragraph-text>
            </template>
          </ts-icon-card>
        </router-link>

        <ts-icon-card
          :iconName="'logout'"
          :iconSize="'h-[17px]'"
          @click="FrontendLogic.auth.user.SignOut()"
        >
          <template v-slot:name>
            <ts-paragraph-text :faded="true" :customClass="'!text-red-400'">
              Log out
            </ts-paragraph-text>
          </template>
        </ts-icon-card>
      </div>
    </template>
  </div>

  <ts-modal :canClose="true" :close="closeModal" v-if="openModal">
    <ts-add-work v-if="activeModal == 'work'" :close="closeModal" />
    <ts-add-education v-if="activeModal == 'education'" :close="closeModal" />
  </ts-modal>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  TsImageLoader,
  TsHeaderText,
  TsIcon,
  TsParagraphText,
  TsButton,
  TsTabs,
  TsNormalText,
  TsRatingBox,
  TsReviewCard,
  TsAvatar,
  TsModal,
  TsAddWork,
  TsAddEducation,
  TsIconCard,
} from "tutorstack-ui-components";
import { composables } from "../../composables";
import { useRoute } from "vue-router";
import {
  Paginated,
  Tutor,
  TutorEducation,
  TutorWork,
  UserWallet,
} from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsImageLoader,
    TsHeaderText,
    TsIcon,
    TsParagraphText,
    TsButton,
    TsTabs,
    TsNormalText,
    TsRatingBox,
    TsReviewCard,
    TsAvatar,
    TsModal,
    TsAddWork,
    TsAddEducation,
    TsIconCard,
  },
  name: "TutorInfoPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Tutor`,
    });

    const openModal = ref(false);

    const activeModal = ref("education");

    const closeModal = () => {
      openModal.value = false;
    };

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const route = useRoute();
    const routeId: string = route.params?.id.toString() || "";

    const CurrentTutor = ref<Tutor>();
    const TutorEducations = ref<Paginated<TutorEducation>>();
    const TutorWorks = ref<Paginated<TutorWork>>();
    const UserWallet = ref<UserWallet>();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      if (TsCommon.accountType.value == "student") {
        TsCommon.pageTopBarOptions.pageTitle = `Tutor`;
      } else {
        TsCommon.pageTopBarOptions.pageTitle = `Profile`;
      }

      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.users.tutors.GetTutor(routeId);
      FrontendLogic.users.tutors.GetTutorEducations(routeId);
      FrontendLogic.users.tutors.GetTutorWorks(routeId);
      FrontendLogic.users.tutors.watchProperty("Tutor", CurrentTutor);
      FrontendLogic.users.tutors.watchProperty(
        "TutorEducations",
        TutorEducations
      );
      FrontendLogic.users.tutors.watchProperty("TutorWorks", TutorWorks);
      FrontendLogic.questions.subjects.GetSubjects();
      FrontendLogic.payments.wallet.watchProperty("UserWallet", UserWallet);
      FrontendLogic.payments.wallet.GetUserWallet();
    });

    const infoOptions = [
      {
        key: "about",
        value: "About",
      },
      {
        key: "reviews",
        value: "Reviews",
      },
    ];

    if (TsCommon.accountType.value == "tutor") {
      infoOptions.push({
        key: "settings",
        value: "Settings",
      });
    }

    const selectedTab = ref("");

    const ratings = [
      {
        name: "5 stars",
        percentage: 92,
        count: 13,
      },
      {
        name: "4 stars",
        percentage: 5,
        count: 3,
      },
      {
        name: "3 stars",
        percentage: 3,
        count: 1,
      },
      {
        name: "2 stars",
        percentage: 0,
        count: 0,
      },
      {
        name: "1 star",
        percentage: 0,
        count: 0,
      },
    ];

    return {
      infoOptions,
      selectedTab,
      ratings,
      TsCommon,
      activeModal,
      openModal,
      closeModal,
      CurrentTutor,
      TutorEducations,
      TutorWorks,
      FrontendLogic,
      UserWallet,
    };
  },
});
</script>
