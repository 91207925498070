<template>
  <div
    class="w-full col-span-full flex justify-between flex-row items-center border-b-[1px] mdlg:!border-[1px] mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-r-0 border-l-0 border-gray-200 mdlg:rounded-t-[4px] mdlg:rounded-b-[0px]"
  >
    <div
      v-for="(step, index) in stepItems"
      :key="index"
      @click="selectedStepValue = step.key"
      :class="`py-3 flex flex-row w-full items-center cursor-pointer justify-center space-x-2 ${
        selectedStep == step.key ? 'border-b-[1px] border-primaryPurple' : ''
      }  `"
    >
      <ts-normal-text
        :color="`${
          selectedStepValue == step.key
            ? 'text-primaryPurple '
            : 'text-paragraphTextLight'
        }`"
      >
        {{ index + 1 }}
      </ts-normal-text>
      <span
        :class="`h-[4px] w-[4px] rounded-full ${
          selectedStepValue == step.key
            ? 'bg-primaryPurple '
            : 'bg-paragraphTextLight'
        } hidden md:!inline-block`"
      ></span>
      <ts-normal-text
        :color="`${
          selectedStepValue == step.key
            ? 'text-primaryPurple '
            : 'text-paragraphTextLight'
        }`"
        :customClass="'hidden md:!inline-block'"
      >
        {{ step.value }}
      </ts-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import TsNormalText from "../TsTypography/normalText.vue";
import { onMounted, ref, watch } from "vue";

export default {
  components: {
    TsNormalText,
  },
  props: {
    stepItems: {
      required: true,
    },
    selectedStep: {
      type: String,
    },
  },
  emits: ["update:selectedStep"],
  name: "TsStepFlow",
  setup(props: any, context: any) {
    const selectedStepValue = ref("");

    onMounted(() => {
      if (props.stepItems.length > 0) {
        selectedStepValue.value = props.stepItems[0].key;
      }
    });

    watch(selectedStepValue, () => {
      context.emit("update:selectedStep", selectedStepValue.value);
    });

    watch(props, () => {
      selectedStepValue.value = props.selectedStep;
    });

    return {
      selectedStepValue,
    };
  },
};
</script>
