<template>
  <div
    @click.stop="true"
    class="rounded shadow-md bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
  >
    <div class="w-full flex flex-row items-center justify-center">
      <ts-icon name="alert-circle" customClass="h-[40px]" />
    </div>
    <ts-header-text :size="'lg'" :customClass="' w-full text-center pt-3'">
      Upgrade to Tutor Stack Premium
    </ts-header-text>
    <div class="w-full flex flex-row items-center justify-center py-2 pt-1">
      <ts-normal-text customClass="text-center">
        Subscribe to ask questions and get video answers.
      </ts-normal-text>
    </div>

    <div class="w-full py-3 grid grid-cols-12 gap-2">
      <ts-button
        :customClass="'border-[1px] border-primaryPurple !text-primaryPurple col-span-6'"
        :padding="'px-4 py-3'"
        :bg-color="'bg-white'"
        @click.stop="close ? close() : ''"
      >
        Cancel
      </ts-button>
      <ts-button
        :customClass="'border-[1px] border-primaryPurple col-span-6'"
        :padding="'px-4 py-3'"
        @click.stop="SubscribeToPlan()"
      >
        Subscribe
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";

import TsIcon from "../TsIcon/index.vue";
import TsButton from "../TsButton/index.vue";
import { Logic } from "tutorstack-frontend-logic";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsIcon,
    TsButton,
  },
  props: {
    close: Function,
  },
  name: "TsSubscribePrompt",
  setup() {
    const SubscribeToPlan = () => {
      Logic.payments.wallet.SubscribeToPlan("premium-plan");
    };
    return {
      Logic,
      SubscribeToPlan,
    };
  },
};
</script>
