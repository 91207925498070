import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_transaction_history = _resolveComponent("ts-transaction-history")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ts_transaction_history, { data: _ctx.transactionHistory }, null, 8, ["data"])
  ]))
}