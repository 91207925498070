import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-0 mdlg:px-0 px-3 py-3" }
const _hoisted_2 = { class: "py-3 px-3 mdlg:!px-5 mdlg:!py-5 bg-primaryPurple rounded flex justify-between flex-row items-center" }
const _hoisted_3 = { class: "flex flex-col space-y-1 items-start" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-3 pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ts_paragraph_text = _resolveComponent("ts-paragraph-text")!
  const _component_ts_icon_card = _resolveComponent("ts-icon-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ts_header_text, {
          size: 'xl',
          color: 'text-white'
        }, {
          default: _withCtx(() => [
            _createTextVNode(" $" + _toDisplayString(_ctx.FrontendLogic.common.formatCurrency(_ctx.UserWallet?.balance.amount)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_ts_normal_text, { color: 'text-white' }, {
          default: _withCtx(() => [
            _createTextVNode(" Available balance ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          to: `/profile/wallet/${
            _ctx.TsCommon.accountType.value == 'student' ? 'fund' : 'withdraw'
          }`
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_button, {
              bgColor: 'bg-white',
              textColor: 'text-primaryPurple ',
              padding: 'mdlg:!px-5 mdlg:!py-3 px-4 py-2'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.TsCommon.accountType.value == "student"
                ? "Fund wallet"
                : "Withdraw"), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, {
        to: `/profile/wallet/${
          _ctx.TsCommon.accountType.value == 'student' ? 'payment' : 'withdrawal'
        }-method`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ts_icon_card, {
            iconName: 'card',
            iconSize: 'h-[18px]'
          }, {
            name: _withCtx(() => [
              _createVNode(_component_ts_paragraph_text, { faded: true }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.TsCommon.accountType.value == "student"
                  ? "Payment"
                  : "Withdrawal") + " method ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_router_link, { to: "/profile/wallet/history" }, {
        default: _withCtx(() => [
          _createVNode(_component_ts_icon_card, {
            iconName: 'history',
            iconSize: 'h-[18px]'
          }, {
            name: _withCtx(() => [
              _createVNode(_component_ts_paragraph_text, { faded: true }, {
                default: _withCtx(() => [
                  _createTextVNode(" Transaction history ")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}