<template>
  <ts-step-flow :stepItems="stepItems" v-model:selectedStep="selectedStep" />

  <div
    v-if="selectedStep != 'confirm_booking'"
    class="w-full flex flex-col justify-start items-start space-y-3 mdlg:!px-3 mdlg:!py-3 px-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <ts-form-wrapper
      class="w-full py-3 mdlg:!px-3 px-0 grid grid-cols-12 gap-2"
      :parentRefs="parentRefs"
      ref="sessionFormWrapper"
    >
      <template v-if="selectedStep == 'enter_details'">
        <div class="flex flex-col col-span-6">
          <ts-text-field
            placeholder="Choose a topic"
            custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
            paddings="py-3"
            v-model="sessionForms.topic"
            ref="topic"
            name="Topic"
            :rules="[FormValidations.RequiredRule]"
            :hasTitle="true"
          >
            <template v-slot:title> Topic </template>
          </ts-text-field>
        </div>

        <div class="flex flex-col col-span-6">
          <ts-select
            placeholder="Choose a subject"
            custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
            paddings="py-3"
            :hasTitle="true"
            v-model="sessionForms.subjectId"
            :options="FrontendLogic.questions.subjects.GetSubjectOptions()"
          >
            <template v-slot:title> Subject </template>
          </ts-select>
        </div>

        <div class="flex flex-col col-span-12">
          <ts-text-area
            placeholder="Describe what you want out of the session"
            v-model="sessionForms.description"
            ref="description"
            :rules="[FormValidations.RequiredRule]"
            name="Message"
          >
            <template v-slot:title> Message </template>
          </ts-text-area>
        </div>

        <div class="flex flex-col col-span-12">
          <ts-file-attachment
            placeholder="Upload file"
            :isMultiple="true"
            v-model="sessionForms.attachments"
          >
            <template v-slot:title> Attachment </template>
          </ts-file-attachment>
        </div>

        <div class="flex flex-col col-span-4">
          <ts-text-field
            placeholder="Select day"
            custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200 cursor-pointer"
            paddings="py-3"
            :hasTitle="true"
            v-model="sessionStartDateTime.date"
            :update-value="selectedDate"
            @click="showDatepicker = true"
            :disabled="true"
            name="Date"
          >
            <template v-slot:title> Date </template>
          </ts-text-field>
        </div>

        <div class="flex flex-col col-span-4">
          <ts-select
            placeholder="Choose time"
            custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
            paddings="py-3"
            v-model="sessionStartDateTime.time"
            :hasTitle="true"
            :options="TutorAvailableTimeOptions"
          >
            <template v-slot:title> Time </template>
          </ts-select>
        </div>

        <div class="flex flex-col col-span-4">
          <ts-select
            placeholder="hours"
            custom-class="mdlg:px-3 px-2  border-[1px] rounded border-gray-200"
            paddings="py-3"
            :options="FrontendLogic.sessions.sessions.SessionHours"
            v-model="sessionForms.lengthInMinutes"
            :hasTitle="true"
          >
            <template v-slot:title> Hours </template>
          </ts-select>
        </div>
      </template>

      <template v-if="selectedStep == 'choose_tutor' && Tutors">
        <div
          class="col-span-12 flex flex-col space-y-2"
          v-for="(tutor, index) in Tutors.results"
          :key="index"
        >
          <div class="w-full flex flex-col" @click.stop="selectTutor(tutor.id)">
            <ts-tutor-card
              :customClass="`w-full cursor-pointer ${
                selectedTutorId == tutor.id
                  ? '!border-primaryPurple !border-[4px]'
                  : ''
              } `"
              :data="tutor"
              :goToTutor="false"
              :showButtons="false"
              :showSaved="false"
            />
          </div>
        </div>
      </template>

      <template v-if="selectedStep == 'add_friends'">
        <div
          class="col-span-full flex flex-col space-y-2 items-center justify-start"
        >
          <ts-select
            :placeholder="'Add friends by email or username'"
            custom-class="mdlg:px-3 px-2  border-[1px] w-full rounded border-gray-200"
            paddings="py-3"
            :autoComplete="true"
            @OnSearch="handleUserSearch"
            :options="UserSelectOptions"
            @OnOptionSelected="handleUserSelected"
            ref="invites"
          ></ts-select>

          <ts-header-text customClass="text-left w-full">
            Added
          </ts-header-text>

          <ts-users-list :users="selectedUserList" :has-remove="true" />
        </div>
      </template>
    </ts-form-wrapper>
  </div>

  <div
    class="w-full mdlg:!py-0 mdlg:px-0 px-3 py-3"
    v-if="selectedStep == 'confirm_booking' && Session"
  >
    <ts-session-info :data="Session">
      <template v-slot:subject>
        {{
          FrontendLogic.questions.subjects.GetSubjectTitle(
            Session?.subjectId || ""
          )
        }}
      </template>
      <template v-slot:date>
        {{ FrontendLogic.common.fomartDate(Session.startedAt, "MMM D") }}
      </template>
      <template v-slot:time-to-from>
        {{ FrontendLogic.common.fomartDate(Session.startedAt, "HH:mm a") }} -
        {{
          FrontendLogic.common.fomartDate(
            Session.startedAt + Session.lengthInMinutes * 60000,
            "HH:mm a"
          )
        }}
      </template>
    </ts-session-info>
  </div>

  <div
    v-if="selectedStep == 'confirm_booking' && sessionType == 'shared'"
    class="w-full flex flex-col justify-start items-start mt-3 mdlg:!mt-0 space-y-3 mdlg:!px-5 mdlg:!py-5 px-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <ts-header-text customClass="text-left w-full"> Pay for </ts-header-text>

    <ts-users-list
      :users="selectedUserList"
      :isCheckbox="true"
      v-model="usersToPayFor"
    />
  </div>

  <div
    v-if="selectedStep == 'confirm_booking'"
    class="w-full flex flex-col justify-start items-start mt-3 mdlg:!mt-0 space-y-3 mdlg:!px-5 mdlg:!py-5 px-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <ts-header-text> Make payment </ts-header-text>
    <ts-radio-card
      :options="paymentOptions"
      v-model="FrontendLogic.sessions.sessions.PayForSessionForm.methodId"
    />
  </div>

  <div class="mdlg:flex flex-row w-full justify-end hidden">
    <ts-button
      :bgColor="'bg-primaryPurple'"
      :padding="'px-4 py-3'"
      @click="Session ? payForSession() : createSession()"
    >
      {{ Session ? "Pay" : "Next" }}
    </ts-button>
  </div>

  <ts-modal
    :canClose="true"
    custom-class="px-4"
    :close="
      () => {
        showDatepicker = false;
      }
    "
    v-if="showDatepicker"
  >
    <ts-datepicker
      :availableDays="TutorAvailableDaysOptions"
      v-model="selectedDate"
    />
  </ts-modal>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import {
  TsHeaderText,
  TsStepFlow,
  TsSelect,
  TsTextArea,
  TsFileAttachment,
  TsButton,
  TsSessionInfo,
  TsRadioCard,
  TsTextField,
  TsUsersList,
  TsTutorCard,
  TsFormWrapper,
  TsDatepicker,
  TsModal,
} from "tutorstack-ui-components";
import { composables } from "../../../composables";
import { useRoute } from "vue-router";
import moment from "moment";
import {
  Paginated,
  PaymentMethod,
  SingleSession,
  SingleUser,
  Tutor,
} from "tutorstack-frontend-logic";
import { SelectOption } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsHeaderText,
    TsStepFlow,
    TsSelect,
    TsTextArea,
    TsFileAttachment,
    TsModal,
    TsButton,
    TsSessionInfo,
    TsRadioCard,
    TsTextField,
    TsUsersList,
    TsTutorCard,
    TsFormWrapper,
    TsDatepicker,
  },
  name: "BookSessionPage",
  layout: "Dashboard",
  setup() {
    const route = useRoute();

    const sessionType = route.params.type;
    const Tutors = ref<Paginated<Tutor>>();

    const selectedTutorId = ref("");

    const userSearch = ref("");

    const sessionFormWrapper = ref<any>();

    const showDatepicker = ref(false);

    const selectedDate = ref("");

    const selectedUserList = ref<SingleUser[]>([]);

    const usersToPayFor = ref<string[]>([]);

    const Users = ref<Paginated<SingleUser>>();

    useMeta({
      title: `Book ${capitalize(sessionType.toString())} Session`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;
    const Session = ref<SingleSession>();
    const PaymentMethods = ref<Paginated<PaymentMethod>>();
    const TutorAvailableDaysOptions = ref<SelectOption[]>([]);
    const TutorAvailableTimeOptions = ref<SelectOption[]>([]);

    const UserSelectOptions = ref<SelectOption[]>([]);

    const filterQuery = ref({
      where: [
        {
          field:
            TsCommon.accountType.value == "student"
              ? "students.id"
              : "tutor.id",
          value: FrontendLogic.auth.user.AuthUser?.id || "",
        },
      ],
    });

    onMounted(() => {
      TsCommon.showBottomBar.value = true;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Book ${capitalize(
        sessionType.toString()
      )} Session`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = true;
      TsCommon.bottomTabOptions.actions = [
        {
          label: "Next",
          outlined: false,
          action: createSession,
        },
      ];
      if (FrontendLogic.users.users.UserProfile) {
        selectedUserList.value.push(FrontendLogic.users.users.UserProfile);

        usersToPayFor.value.push(FrontendLogic.users.users.UserProfile.id);
      }

      FrontendLogic.sessions.sessions.Session = undefined;
      FrontendLogic.sessions.sessions.watchProperty("Session", Session);
      FrontendLogic.users.tutors.watchProperty("Tutors", Tutors);
      FrontendLogic.payments.wallet.watchProperty(
        "PaymentMethods",
        PaymentMethods
      );
      FrontendLogic.users.users.watchProperty(
        "UserSelectOptions",
        UserSelectOptions
      );
      FrontendLogic.users.users.watchProperty("Users", Users);
      FrontendLogic.sessions.sessions.watchProperty(
        "TutorAvailableDaysOptions",
        TutorAvailableDaysOptions
      );
      FrontendLogic.sessions.sessions.watchProperty(
        "TutorAvailableTimeOptions",
        TutorAvailableTimeOptions
      );
    });

    const selectedStep = ref("enter_details");

    const sessionForms = FrontendLogic.sessions.sessions.CreateSessionForm;

    const payForSession = () => {
      if (usersToPayFor.value.length > 0) {
        if (selectedTutorId.value) {
          if (
            FrontendLogic.sessions.sessions.PayForSessionForm.methodId ==
            "wallet"
          ) {
            FrontendLogic.sessions.sessions.PayForSessionForm.useWallet = true;
          } else {
            FrontendLogic.sessions.sessions.PayForSessionForm.useWallet = false;
          }
          FrontendLogic.sessions.sessions.PayForSession(
            Session.value?.id || "",
            usersToPayFor.value,
            `/sessions/${Session.value?.id}`
          );
        } else {
          selectedStep.value = "choose_tutor";
          return;
        }
      }
    };

    watch(Session, () => {
      if (Session.value) {
        TsCommon.bottomTabOptions.actions = [
          {
            label: "Pay",
            outlined: false,
            action: payForSession,
          },
        ];

        if (Session.value) {
          selectedStep.value = "confirm_booking";
        }
      }
    });

    const stepItems = [];

    if (!route.query.tutorId) {
      stepItems.push({
        key: "choose_tutor",
        value: "Choose tutor",
      });
    } else {
      selectedTutorId.value = route.query.tutorId?.toString();
    }

    stepItems.push({
      key: "enter_details",
      value: "Enter details",
    });

    if (route.params.type == "shared") {
      stepItems.push({
        key: "add_friends",
        value: "Add Friends",
      });
    }

    stepItems.push({
      key: "confirm_booking",
      value: "Confirm booking",
    });

    const paymentOptions = ref<any[]>([]);

    watch(PaymentMethods, () => {
      paymentOptions.value = [
        {
          name: ["Use Wallet"],
          key: "wallet",
          iconName: "wallet",
        },
      ];
      PaymentMethods.value?.results.forEach((method) => {
        paymentOptions.value.push({
          name: [
            method.data.type == "Card"
              ? `**** **** **** ${method.data.last4Digits}`
              : method.data.email,
          ],
          key: method.id,
          iconName: method.data.type == "Card" ? "card" : "paypal",
        });
      });
    });

    const sessionStartDateTime = reactive({
      date: "",
      time: "",
    });

    watch(sessionStartDateTime, () => {
      FrontendLogic.sessions.sessions.setDateAvailableTime(
        sessionStartDateTime.date
      );
    });

    const createSession = () => {
      if (selectedTutorId.value == "") {
        selectedStep.value = "choose_tutor";
        return;
      }

      if (selectedTutorId.value != "" && sessionStartDateTime.time == "") {
        selectedStep.value = "enter_details";
        return;
      }

      if (sessionType == "shared" && sessionForms.invites?.length == 0) {
        selectedStep.value = "add_friends";
        return;
      }

      const startTime = moment(
        sessionStartDateTime.date + " " + sessionStartDateTime.time
      );

      sessionForms.startedAt = parseInt(startTime.format("x"));

      sessionForms.tutorId = selectedTutorId.value;
      if (sessionStartDateTime.time && sessionForms.subjectId != "") {
        const formState: boolean = sessionFormWrapper.value.validate();
        FrontendLogic.sessions.sessions.CreateSession(
          formState,
          filterQuery,
          sessionFormWrapper.value
        );
      }
    };

    watch(selectedStep, () => {
      if (
        selectedStep.value == "confirm_booking" &&
        Session.value == undefined
      ) {
        selectedStep.value = "enter_details";
      }

      if (selectedStep.value == "enter_details") {
        if (selectedTutorId.value == "") {
          selectedStep.value = "choose_tutor";
        } else {
          FrontendLogic.sessions.sessions.GetTutorAvailability(
            selectedTutorId.value
          );
        }
      }
    });

    const FormValidations = composables.logic.forms.validations;

    const selectTutor = (tutorId: string) => {
      selectedTutorId.value = tutorId;
      selectedStep.value = "enter_details";
    };

    const handleUserSearch = (value: string) => {
      FrontendLogic.common.debounce(() => {
        FrontendLogic.users.users.GetUsers(value);
      }, 500);
    };

    const handleUserSelected = (userId: string) => {
      if (Array.isArray(sessionForms.invites)) {
        sessionForms.invites?.push(userId);
      }

      const selectedUser = Users.value?.results.filter((user) => {
        return user.id == userId;
      });

      if (selectedUser && selectedUser.length > 0) {
        selectedUserList.value.push(selectedUser[0]);
      }
    };

    watch(selectedDate, () => {
      sessionStartDateTime.date = selectedDate.value;
      showDatepicker.value = false;
    });

    return {
      stepItems,
      selectedStep,
      paymentOptions,
      FrontendLogic,
      sessionForms,
      createSession,
      sessionStartDateTime,
      Session,
      payForSession,
      Tutors,
      selectedTutorId,
      selectTutor,
      sessionFormWrapper,
      FormValidations,
      userSearch,
      handleUserSearch,
      UserSelectOptions,
      handleUserSelected,
      selectedUserList,
      sessionType,
      TutorAvailableDaysOptions,
      TutorAvailableTimeOptions,
      usersToPayFor,
      showDatepicker,
      selectedDate,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
