<template>
  <div
    class="rounded shadow-md items-center justify-center bg-white md:!py-5 md:!px-5 py-3 pb-4 lg:!text-sm mdlg:!text-[12px] text-xs px-4 flex flex-col lg:!w-[30%] mdlg:!w-[37%] md:!w-[50%] w-full"
    @click.stop="true"
  >
    <ts-header-text custom-class="w-full text-center">
      Pick a date
    </ts-header-text>
    <ts-calendar
      :is-picker="true"
      :date-allowed="availableDays"
      v-model="selectedDate"
    />
  </div>
</template>
<script lang="ts">
import { SelectOption } from "tutorstack-frontend-logic";
import { defineComponent, ref, watch } from "vue";
import TsCalendar from "../TsCalendar";
import { TsHeaderText } from "../TsTypography";

export default defineComponent({
  components: {
    TsCalendar,
    TsHeaderText,
  },
  props: {
    availableDays: {
      type: Array as () => SelectOption[],
    },
  },
  name: "TsDatepicker",
  emits: ["update:modelValue"],
  setup(props, context) {
    const selectedDate = ref("");

    watch(selectedDate, () => {
      context.emit("update:modelValue", selectedDate.value);
    });

    return {
      selectedDate,
    };
  },
});
</script>
