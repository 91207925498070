<template>
  <div
    class="w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0"
  >
    <div class="w-full grid-cols-12 grid gap-2">
      <div class="flex flex-col col-span-12">
        <ts-text-field
          placeholder="0"
          custom-class="mdlg:px-3 px-2 border-[1px] rounded border-gray-200"
          paddings="py-3"
          :hasTitle="true"
          v-model="FrontendLogic.payments.wallet.FundWalletForm.amount"
        >
          <template v-slot:title> Amount </template>
          <template v-slot:outer-suffix>
            <ts-select
              placeholder="USD"
              custom-class="mdlg:px-3 px-2 border-[1px] border-l-[0px]  rounded border-gray-200"
              paddings="py-3"
              defaultSize="w-[90px]"
              :hasTitle="false"
            />
          </template>
        </ts-text-field>
      </div>
    </div>

    <div class="w-full flex flex-col space-y-2">
      <ts-normal-text> Payment method </ts-normal-text>
      <ts-radio-card
        :options="paymentOptions"
        v-model="FrontendLogic.payments.wallet.FundWalletForm.methodId"
      />
    </div>

    <div class="flex flex-row justify-end w-full mt-3">
      <ts-button
        :padding="'mdlg:!px-4 mdlg:!py-3  px-3 py-2'"
        :loading="WalletLoaders?.fund"
        @click="fundWallet()"
      >
        Make Payment
      </ts-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import {
  TsTextField,
  TsButton,
  TsSelect,
  TsRadioCard,
  TsNormalText,
} from "tutorstack-ui-components";
import { composables } from "../../../composables";
import { Paginated, PaymentMethod } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsTextField,
    TsButton,
    TsSelect,
    TsRadioCard,
    TsNormalText,
  },
  name: "FundWalletPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: `Fund wallet`,
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;
    const PaymentMethods = ref<Paginated<PaymentMethod>>();
    const WalletLoaders = ref();

    onMounted(() => {
      TsCommon.showBottomBar.value = false;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "subpage";
      TsCommon.pageTopBarOptions.pageTitle = `Fund wallet`;
      TsCommon.pageTopBarOptions.hasSearch = false;
      TsCommon.bottomTabOptions.subPage = true;
      FrontendLogic.payments.wallet.GetPaymentMethods();
      FrontendLogic.payments.wallet.watchProperty(
        "PaymentMethods",
        PaymentMethods
      );
      FrontendLogic.payments.wallet.watchProperty(
        "WalletLoaders",
        WalletLoaders
      );
    });

    const paymentOptions = ref<any[]>([]);

    const fundWallet = () => {
      if (
        FrontendLogic.payments.wallet.FundWalletForm.amount != 0 &&
        FrontendLogic.payments.wallet.FundWalletForm.methodId != ""
      ) {
        FrontendLogic.payments.wallet.FundUserWallet(true);
      }
    };

    watch(PaymentMethods, () => {
      paymentOptions.value = [];
      PaymentMethods.value?.results.forEach((method) => {
        paymentOptions.value.push({
          name: [
            method.data.type == "Card"
              ? `**** **** **** ${method.data.last4Digits}`
              : method.data.email,
          ],
          key: method.id,
          iconName: method.data.type == "Card" ? "card" : "paypal",
        });
      });
    });

    return {
      paymentOptions,
      WalletLoaders,
      FrontendLogic,
      fundWallet,
    };
  },
});
</script>
