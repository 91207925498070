import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { class: "w-full grid-cols-12 grid gap-2" }
const _hoisted_3 = { class: "flex flex-col col-span-12" }
const _hoisted_4 = { class: "flex flex-row justify-end w-full mt-2" }
const _hoisted_5 = { class: "col-span-full flex flex-col space-y-2 text-start pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_select = _resolveComponent("ts-select")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_header_text = _resolveComponent("ts-header-text")!
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_icon = _resolveComponent("ts-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ts_select, {
          placeholder: "Select one subject you teach perfectly",
          "custom-class": "mdlg:px-3 px-2 border-[1px] rounded border-gray-200",
          paddings: "py-3",
          hasTitle: false,
          options: 
            _ctx.FrontendLogic?.questions?.subjects.GetSubjectOptions() || []
          ,
          modelValue: _ctx.UpdateTutorSubjectForm.subjectId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.UpdateTutorSubjectForm.subjectId) = $event))
        }, null, 8, ["options", "modelValue"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ts_button, {
            padding: 'mdlg:!px-5 mdlg:!py-3 px-5 py-2',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addSubject()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Add ")
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ts_header_text, { size: 'base text-start' }, {
          default: _withCtx(() => [
            _createTextVNode(" Added ")
          ]),
          _: 1
        }),
        (_ctx.UserProfile)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.UserProfile.tutor.subjects, (subjectId, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-full flex flex-row items-center justify-between",
                key: index
              }, [
                _createVNode(_component_ts_normal_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.FrontendLogic.questions.subjects.GetSubjectTitle(subjectId)), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ts_icon, {
                  name: 'remove',
                  customClass: 'h-[13px] cursor-pointer',
                  onClick: ($event: any) => (_ctx.removeSubject(subjectId))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}