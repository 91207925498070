<template>
  <template v-if="Sessions">
    <div
      v-if="Sessions.length > 0"
      class="w-full flex flex-col justify-start items-start h-full space-y-3 mdlg:px-0 px-3"
    >
      <div
        class="flex flex-row space-x-2 w-full justify-between items-center mdlg:pt-1 pt-2"
      >
        <div class="flex flex-col w-full space-y-3">
          <ts-session-card
            :show-button="false"
            :avatar-url="session.tutor.bio.photo?.link || ''"
            :status="selectedTopTab"
            :badge-color="`${
              session.students.length == 1 ? 'purple' : 'green'
            }`"
            :card-linecolor="cardLineColor"
            :session-is-close="false"
            v-for="(session, index) in Sessions"
            :key="index"
            :data="session"
          >
          </ts-session-card>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-row items-center justify-center h-screen absolute top-0 left-0 w-full"
    >
      <ts-empty-state
        :avatarUrl="'/images/no-session.svg'"
        :avatarSize="'h-[60px]'"
        :ctaUrl="TsCommon.accountType.value != 'tutor' ? '/tutors' : ''"
        :customClass="'h-[400px] mdlg:!h-[540px] '"
      >
        <template v-slot:title> No sessions here </template>
        <template v-slot:subtext>
          {{
            TsCommon.accountType.value != "tutor"
              ? "Book sessions with your tutors, alone or with friends"
              : `All your ${selectedTopTab} sessions will show up here`
          }}
        </template>
        <template v-slot:cta-text> My tutors </template>
      </ts-empty-state>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { TsSessionCard, TsEmptyState } from "tutorstack-ui-components";
import {
  composables,
  selectedSessionTab,
  selectedTopTab,
} from "../../composables";
import { SingleSession } from "tutorstack-frontend-logic";

export default defineComponent({
  components: {
    TsSessionCard,
    TsEmptyState,
  },
  name: "SessionPage",
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Sessions",
    });

    const TsCommon = composables.common;
    const FrontendLogic = composables.logic;

    const Sessions = ref<SingleSession[]>([]);

    const cardLineColor = ref("yellow");

    if (selectedSessionTab.value != "") {
      selectedTopTab.value = selectedSessionTab.value;
      selectedTopTab.value = selectedSessionTab.value;
    } else {
      selectedTopTab.value = "upcoming";
      selectedTopTab.value = "upcoming";
    }

    onMounted(() => {
      TsCommon.showBottomBar.value = true;
      TsCommon.resetTopBarOptions();
      TsCommon.pageTopBarOptions.type = "switch";
      TsCommon.pageTopBarOptions.hasSearch = true;
      TsCommon.pageTopBarOptions.switchItems = [
        {
          key: "upcoming",
          value: "Upcoming",
        },
      ];

      if (TsCommon.accountType.value == "student") {
        TsCommon.pageTopBarOptions.switchItems.push({
          key: "pending",
          value: "Pending",
        });
      }

      TsCommon.pageTopBarOptions.switchItems.push({
        key: "previous",
        value: "Previous",
      });

      TsCommon.bottomTabOptions.subPage = false;
      FrontendLogic.sessions.sessions.watchProperty("UserSessions", Sessions);
      FrontendLogic.sessions.sessions.SortSessions(selectedTopTab.value);
    });

    watch(selectedTopTab, () => {
      FrontendLogic.sessions.sessions.SortSessions(selectedTopTab.value);
      selectedSessionTab.value = selectedTopTab.value;
      if (selectedTopTab.value == "upcoming") {
        cardLineColor.value = "yellow";
      } else if (selectedTopTab.value == "pending") {
        cardLineColor.value = "red";
      } else if (selectedTopTab.value == "previous") {
        cardLineColor.value = "gray";
      }
    });

    onUnmounted(() => {
      TsCommon.stopWatchItem();
    });

    return {
      composables,
      selectedTopTab,
      Sessions,
      FrontendLogic,
      cardLineColor,
      TsCommon,
    };
  },
});
</script>
