import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-2 flex-col mdlg:!py-1 mdlg:px-0 px-3 py-3" }
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-between space-x-2 overflow-x-auto pb-1" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2 pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_normal_text = _resolveComponent("ts-normal-text")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_checkbox = _resolveComponent("ts-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Next14Days, (day, index) => {
        return (_openBlock(), _createBlock(_component_ts_button, {
          key: index,
          bgColor: `${
          _ctx.selectedDay == day.date ? 'bg-primaryPurple' : 'bg-white'
        }`,
          textColor: `${
          _ctx.selectedDay == day.date ? 'text-white' : 'text-paragraphTextLight'
        }`,
          padding: 'mdlg:!py-2 mdlg:!px-5 px-4 py-2',
          customClass: ` ${
          _ctx.selectedDay == day.date
            ? 'border-primaryPurple border-[1px]'
            : 'border-gray-200 border-[1px]'
        }`,
          onClick: ($event: any) => {
          _ctx.selectedDay = day.date;
          _ctx.selectedTime.length = 0;
          _ctx.checkExistingAvailibility();
        }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(day.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["bgColor", "textColor", "customClass", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ts_checkbox, {
        options: _ctx.timeOptions,
        onOptionsSelected: _ctx.handleSelect,
        hasSelectAll: true,
        modelValue: _ctx.selectedTime,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTime) = $event)),
        existingOptions: _ctx.existingTime
      }, null, 8, ["options", "onOptionsSelected", "modelValue", "existingOptions"])
    ])
  ]))
}