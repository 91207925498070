import CardsApi from "./Cards";
import PlansApi from "./Plans";
import TransactionsApi from "./Transactions";
import UserWalletApi from "./Wallets";

export const Payment = {
	wallets: new UserWalletApi(),
	cards: new CardsApi(),
	transactions: new TransactionsApi(),
	plans: new PlansApi()
}