import { GoogleSignInForm } from '../../types/forms/auth';
import { $api } from "../../../services";
import { Logic } from '..';

export default class Identities {
	constructor() {
		// initiate things here

	}

	public GoogleSignInForm: GoogleSignInForm = {
		idToken: '',
		accessToken: ''
	}

	public GoogleSignIn = () => {
		Logic.common.showLoader()
		$api.auth.identities.GoogleSignIn(this.GoogleSignInForm).then((response) => {
			Logic.auth.user.AuthUser = response.data.user
			Logic.auth.email.SetTokens(response.data)
			Logic.auth.user.AuthUser = response.data.user
			Logic.auth.email.SaveUserToLocal()
			Logic.common.hideLoader()
			Logic.common.router?.push('/')
		}).catch(() => {
			Logic.common.hideLoader()
		})

	}


}