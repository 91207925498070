<template>
  <router-link
    :to="`/sessions/${data?.id}`"
    :class="`${customClass} px-3 py-3 flex flex-col space-y-1 border-[1px] border-gray-200 rounded
	${cardLinecolor == 'purple' ? '!border-l-primaryPurple' : ''}
	${cardLinecolor == 'green' ? '!border-l-primaryGreen' : ''}
	${cardLinecolor == 'yellow' ? '!border-l-primaryYellow' : ''}
	${cardLinecolor == 'red' ? '!border-l-errorColor' : ''} 
	${cardLinecolor == 'gray' ? '!border-l-primaryGray' : ''} !border-l-[4px] `"
    v-if="data"
  >
    <div class="flex flex-row items-center justify-between">
      <ts-header-text size="base">
        {{ data?.topic }}
      </ts-header-text>
      <ts-badge :color="badgeColor">
        {{ data?.students.length == 1 ? "Private" : "Shared" }}</ts-badge
      >
    </div>
    <span
      v-if="status == 'pending'"
      :class="`flex flex-row items-center space-x-2 text-errorColor text-xs`"
    >
      <ts-icon name="pending-card" custom-class="h-[12px]" />
      <span>
        {{ data?.paid.length }}/{{ data?.students.length }} payment pending
      </span>
    </span>

    <span
      :class="`flex flex-row items-center space-x-2 text-xs ${
        sessionIsClose ? 'text-errorColor' : 'text-paragraphTextLight'
      } `"
    >
      <ts-icon
        v-if="sessionIsClose"
        name="calender-red"
        custom-class="h-[12px]"
      />
      <ts-icon v-else name="calender" custom-class="h-[12px]" />
      <span>
        {{ Logic.common.fomartDate(data?.startedAt || 0, "MMM D") }}
      </span>
      <span
        :class="`h-[5px] w-[5px] ${
          sessionIsClose ? 'bg-errorColor' : 'bg-paragraphTextLight'
        }  rounded-full`"
      ></span>
      <span>
        <template v-if="status != 'previous'">
          {{ Logic.common.fomartDate(data?.startedAt || 0, "HH:mm a") }}
          -
          {{
            Logic.common.fomartDate(
              data?.startedAt || 0 + (data?.lengthInMinutes || 0) * 60000,
              "HH:mm a"
            )
          }}
        </template>
        <template v-else>
          {{ data.lengthInMinutes / 60 }} hour{{
            data.lengthInMinutes > 60 ? "s" : ""
          }}
        </template>
      </span>
    </span>

    <span
      class="flex flex-row items-center space-x-2 pt-1 text-xs text-paragraphText"
    >
      <ts-avatar :photo-url="data?.tutor.bio.photo?.link || ''" size="17" />
      <ts-paragraph-text :faded="true">
        {{ data?.tutor.bio.name.full }}
      </ts-paragraph-text>
    </span>

    <div class="pt-2 w-full text-left justify-start" v-if="showButton">
      <ts-button bg-color="bg-primaryPurple"> Enter Session </ts-button>
    </div>
  </router-link>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsParagraphText from "../TsTypography/paragraphText.vue";
import TsIcon from "../TsIcon/index.vue";
import TsBadge from "../TsBadge/index.vue";
import TsAvatar from "../TsAvatar/index.vue";
import TsButton from "../TsButton/index.vue";
import { Logic, SingleSession } from "tutorstack-frontend-logic";

export default {
  components: {
    TsHeaderText,
    TsParagraphText,
    TsIcon,
    TsBadge,
    TsAvatar,
    TsButton,
  },
  props: {
    cardLinecolor: {
      type: String,
      default: "purple",
    },
    customClass: {
      type: String,
      default: "w-full col-span-full",
    },
    badgeColor: {
      type: String,
      default: "purple",
    },
    avatarUrl: {
      type: String,
      default: "/images/avatar2.png",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    sessionIsClose: {
      type: Boolean,
      default: false,
    },
    paymentPending: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object as () => SingleSession,
    },
    status: {
      type: String,
      default: "pending",
    },
  },
  name: "TsSessionCard",
  setup() {
    return {
      Logic,
    };
  },
};
</script>
