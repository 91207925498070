import { AxiosResponse } from "axios";
import { Question } from "../../../logic/types/domains/questions";
import { ModelApiService } from "../../common/ModelService";

export default class QuestionsApi extends ModelApiService {
	constructor() {
		// this is equivalent to your base_url/sample
		super("questions/questions");
	}

	public async HoldQuestion(questionId: string): Promise<AxiosResponse<Question>> {
		try {
			const response: AxiosResponse<Question> = await this.axiosInstance.post(
				this.getUrl(questionId) + '/hold');

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}

	public async ReleaseQuestion(questionId: string): Promise<AxiosResponse<Question>> {
		try {
			const response: AxiosResponse<Question> = await this.axiosInstance.post(
				this.getUrl(questionId) + '/release');

			return response;
		} catch (err) {
			this.handleErrors(err);
			// you can handle request specific error here
			throw err;
		}
	}
}