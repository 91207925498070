import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex space-y-3 flex-col mdlg:!py-5 mdlg:px-5 px-3 py-3 mdlg:!border-[1px] mdlg:!rounded mdlg:!border-r-[1px] mdlg:!border-l-[1px] border-0" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex flex-col col-span-12" }
const _hoisted_6 = { class: "flex flex-col col-span-12" }
const _hoisted_7 = { class: "flex flex-col col-span-6" }
const _hoisted_8 = { class: "flex flex-col col-span-6" }
const _hoisted_9 = { class: "flex flex-row justify-end w-full mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ts_text_field = _resolveComponent("ts-text-field")!
  const _component_ts_text_area = _resolveComponent("ts-text-area")!
  const _component_ts_select = _resolveComponent("ts-select")!
  const _component_ts_file_attachment = _resolveComponent("ts-file-attachment")!
  const _component_ts_form_wrapper = _resolveComponent("ts-form-wrapper")!
  const _component_ts_button = _resolveComponent("ts-button")!
  const _component_ts_subscribe_prompt = _resolveComponent("ts-subscribe-prompt")!
  const _component_ts_modal = _resolveComponent("ts-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.UserWallet?.subscription.active
          ? ''
          : _ctx.FrontendLogic.common.GoToRoute('/profile/plans')
      )),
        class: "flex flex-row items-center justify-center rounded px-3 py-3 bg-[#ECEEF2] cursor-pointer"
      }, [
        (_ctx.UserWallet?.subscription.active)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createTextVNode("You have "),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.UserWallet?.subscription.data.questions) + " questions", 1),
              _createTextVNode(" to ask")
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, "Upgrade to Tutor Stack Premium"))
      ]),
      _createVNode(_component_ts_form_wrapper, {
        class: "w-full grid-cols-12 grid gap-2",
        ref: "questionForm",
        parentRefs: _ctx.parentRefs
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ts_text_field, {
              placeholder: "",
              "custom-class": "mdlg:px-3 px-2 border-[1px] rounded border-gray-200",
              paddings: "py-3",
              modelValue: _ctx.FrontendLogic.questions.questions.CreateQuestionForm.topic,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.FrontendLogic.questions.questions.CreateQuestionForm.topic) = $event)),
              hasTitle: true,
              name: "Topic",
              rules: [_ctx.FormValidations.RequiredRule],
              ref: "topic"
            }, {
              title: _withCtx(() => [
                _createTextVNode(" Topic ")
              ]),
              _: 1
            }, 8, ["modelValue", "rules"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ts_text_area, {
              placeholder: "Write your question here",
              paddings: "py-3",
              hasTitle: true,
              name: "Question",
              modelValue: _ctx.FrontendLogic.questions.questions.CreateQuestionForm.body,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.FrontendLogic.questions.questions.CreateQuestionForm.body) = $event)),
              rules: [_ctx.FormValidations.RequiredRule],
              ref: "body"
            }, {
              title: _withCtx(() => [
                _createTextVNode(" Question ")
              ]),
              _: 1
            }, 8, ["modelValue", "rules"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ts_select, {
              placeholder: "Select a subject",
              "custom-class": "mdlg:px-3 px-2  col-span-6 border-[1px] rounded border-gray-200",
              paddings: "py-3",
              hasTitle: true,
              modelValue: 
            _ctx.FrontendLogic.questions.questions.CreateQuestionForm.subjectId
          ,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((
            _ctx.FrontendLogic.questions.questions.CreateQuestionForm.subjectId
          ) = $event)),
              options: _ctx.FrontendLogic.questions.subjects.GetSubjectOptions()
            }, {
              title: _withCtx(() => [
                _createTextVNode(" Subject ")
              ]),
              _: 1
            }, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_ts_file_attachment, {
              iconName: 'camera-gray',
              hasTitle: true,
              modelValue: 
            _ctx.FrontendLogic.questions.questions.CreateQuestionForm.attachment
          ,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((
            _ctx.FrontendLogic.questions.questions.CreateQuestionForm.attachment
          ) = $event))
            }, {
              title: _withCtx(() => [
                _createTextVNode(" Image ")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["parentRefs"]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ts_button, {
          padding: 'mdlg:!px-4 mdlg:!py-3 px-3 py-2',
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.sendQuestion())),
          loading: _ctx.QuestionLoaders?.ask
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Ask question ")
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    (
      _ctx.showSubscribePrompt &&
      _ctx.TsCommon.accountType.value != 'tutor' &&
      _ctx.UserWallet?.subscription.active == false
    )
      ? (_openBlock(), _createBlock(_component_ts_modal, {
          key: 0,
          canClose: true,
          close: 
      () => {
        _ctx.showSubscribePrompt = false;
      }
    
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ts_subscribe_prompt, {
              close: 
        () => {
          _ctx.showSubscribePrompt = false;
        }
      ,
              remainingQuestions: _ctx.UserWallet?.subscription.data.questions
            }, null, 8, ["close", "remainingQuestions"])
          ]),
          _: 1
        }, 8, ["close"]))
      : _createCommentVNode("", true)
  ], 64))
}