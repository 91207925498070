<template>
  <div class="w-full flex flex-row justify-between items-center pt-3">
    <ts-header-text size="lg"> Upcoming sessions </ts-header-text>
    <div>
      <router-link to="/sessions">
        <ts-normal-text color="text-primaryPurple"
          >View all</ts-normal-text
        ></router-link
      >
    </div>
  </div>

  <ts-calendar
    :accountType="accountType"
    @on-date-selected="setSelectedDate"
    :SessionsByMonth="SessionsByMonth"
  />

  <div class="w-full flex flex-row justify-between items-center pt-3">
    <ts-normal-text>{{
      selectedDate?.format("dddd, MMM D, YYYY")
    }}</ts-normal-text>
  </div>

  <div
    class="w-full flex flex-col space-y-3 pt-3"
    v-if="SessionsByMonth?.results && SessionsByMonth.results.length > 0"
  >
    <ts-session-card
      :show-button="false"
      :avatar-url="session.tutor.bio.photo?.link || ''"
      :status="getSessionStatus(session)[0]"
      :badge-color="`${session.students.length == 1 ? 'purple' : 'green'}`"
      :card-linecolor="getSessionStatus(session)[1]"
      :session-is-close="false"
      v-for="(session, index) in SessionsByMonth.results"
      :key="index"
      :data="session"
    >
    </ts-session-card>
  </div>
  <ts-empty-state
    v-else
    :avatarUrl="'/images/no-session.svg'"
    :avatarSize="'h-[60px]'"
    :ctaUrl="'/tutors'"
    :customClass="'h-[300px] mdlg:!h-[280px] mt-4 border-[1px] rounded border-gray-200'"
    :accountType="accountType"
  >
    <template v-slot:title> No sessions here </template>
    <template v-slot:subtext>
      {{
        accountType != "tutor"
          ? "Book sessions with your tutors, alone or with friends"
          : `All sessions for the day will show up here`
      }}
    </template>
    <template v-slot:cta-text> My tutors </template>
  </ts-empty-state>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsCalendar from "../TsCalendar/index.vue";
import TsEmptyState from "../TsEmptyState";
import TsSessionCard from "../TsSessionCard/index.vue";
import { onMounted, ref } from "vue";
import { Logic, Paginated, SingleSession } from "tutorstack-frontend-logic";
import moment, { Moment } from "moment";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsCalendar,
    TsSessionCard,
    TsEmptyState,
  },
  props: {
    accountType: {
      type: String,
      default: "student",
    },
  },
  name: "TsCalendarBar",
  setup() {
    const SessionsByMonth = ref<Paginated<SingleSession>>();

    const selectedDate = ref<Moment>();

    onMounted(() => {
      Logic.sessions.sessions.watchProperty("SessionsByMonth", SessionsByMonth);
      selectedDate.value = moment(new Date());
    });

    const getSessionStatus = (session: SingleSession) => {
      if (
        session.paid.length == session.students.length &&
        moment(session.startedAt + 60 * session.lengthInMinutes).isSameOrAfter(
          moment.now()
        )
      ) {
        return ["upcoming", "yellow"];
      } else if (
        session.paid.length < session.students.length &&
        moment(session.startedAt + 60 * session.lengthInMinutes).isSameOrAfter(
          moment.now()
        )
      ) {
        return ["pending", "red"];
      } else if (
        !moment(session.startedAt + 60 * session.lengthInMinutes).isSameOrAfter(
          moment.now()
        )
      ) {
        return ["previous", "gray"];
      } else {
        return ["previous", "gray"];
      }
    };

    const setSelectedDate = (date: string) => {
      selectedDate.value = moment(date);
    };

    return {
      SessionsByMonth,
      getSessionStatus,
      selectedDate,
      setSelectedDate,
    };
  },
};
</script>
