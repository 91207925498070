<template>
  <div
    :class="`mdlg:py-6 mdlg:px-6 px-4 py-4 flex flex-col space-y-2 rounded md:items-center items-start justify-center ${bgColor} ${customClass}`"
  >
    <div class="flex flex-col space-y-2 items-start">
      <span class="flex flex-row items-center space-x-2">
        <ts-header-text size="base" color="text-white">
          <slot name="header" />
        </ts-header-text>
        <ts-icon name="arrow-right" custom-class="h-[15px]" />
      </span>

      <ts-normal-text
        size="base"
        color="text-white"
        custom-class="text-left leading-tight"
      >
        <slot name="content" />
      </ts-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import TsHeaderText from "../TsTypography/headerText.vue";
import TsNormalText from "../TsTypography/normalText.vue";
import TsIcon from "../TsIcon/index.vue";

export default {
  components: {
    TsHeaderText,
    TsNormalText,
    TsIcon,
  },
  props: {
    bgColor: {
      type: String,
      default: "bg-primaryPurple",
    },
    customClass: {
      type: String,
      default: "col-span-4",
    },
  },
  name: "TsCtaCard",
};
</script>
